import { FlagRule } from '../flag-rule.class';
import { FromJsonOption } from '../rule';

export class FlowRule extends FlagRule {
  public keywords: string[] = [$localize`流程`];

  public constructor() {
    super('FLOW');
  }

  public toString() {
    return $localize`流程中`;
  }

  public static schema: string = 'flow';

  public static async fromShortCode(code: string, option: FromJsonOption) {
    return new FlowRule();
  }
}
