<ng-container *ngIf="images$ | async; let images; else: loading">
  <ng-container *ngIf="images.length; else empty">
    <div class="header" i18n>
      <mat-icon>{{ 'image' | messageIcon }}</mat-icon>
      請選擇圖片
    </div>

    <div class="container" fxLayout="row wrap" fxLayoutAlign="start">
      <div
        matRipple
        class="template mat-elevation-z2"
        fxFlex="0 0 260px"
        *ngFor="let image of images"
        (click)="submit(image)"
      >
        <div
          class="image"
          [style.background-image]="'url(' + (image.picture | imageType) + ')'"
        ></div>

        <div class="label">{{ image.name }}</div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #empty>
  <div class="container">
    <gosu-landing-brand
      image="./assets/poster-landing.png"
      title="在圖像編輯器中建立圖片範本"
      i18n-title="在圖像編輯器中建立圖片範本"
      description="透過圖像編輯器的功能，您可以預先建立圖片範本。這樣一來在建立訊息時就可以統一訊息發送的風格，並提升企業形象及發送效率"
      i18n-description="
        透過圖像編輯器的功能，您可以預先建立圖片範本。這樣一來在建立訊息時就可以統一訊息發送的風格，並提升企業形象及發送效率
      "
      callToActionLabel="立即建立"
      i18n-callToActionLabel="立即建立"
      callToActionIcon="add"
      (callToAction)="createTemplate()"
    >
    </gosu-landing-brand>
  </div>
</ng-template>

<ng-template #loading>
  <div class="container">
    <gosu-loading i18n>圖片讀取中，請稍候</gosu-loading>
  </div>
</ng-template>
