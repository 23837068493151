import { Color } from '@ay/util';
import isEqual from 'lodash/isEqual';
import { FlowService } from '../flow.service';
import { Position } from '../flow.util';
import { FormComponent } from './form.component';
import { NodeComponent } from './node.component';

export class Node implements Position {
  public error: string;

  public id: number;

  /** 是否有被修改過 */
  public isModify: boolean = false;

  /** 舊資料 */
  public oriProperty: any = {};

  /** 節點名稱 */
  public name: string;

  /** 顯示的 ICON */
  public icon: string;

  /** 節點類型 */
  public type: string;

  /** X座標 */
  public x?: number = 0;

  /** Y座標 */
  public y?: number = 0;

  /** 原始 X 座標 */
  public oldX: number = 0;

  /** 原始 Y 座標 */
  public oldY: number = 0;

  /** 拖曳時偏移 X 座標 */
  public draggingPaddingX = 0;

  /** 拖曳時偏移 Y 座標 */
  public draggingPaddingY = 0;

  /** 對應的 node component */
  public nodeComponent?: NodeComponent;

  /** 對應的 form component */
  public formComponent?: FormComponent;

  /** toData、checkModify 檢查的屬性欄位 */
  public readonly extraProperties: string[] = [];

  // #region 主要顏色
  protected _color: string = '#E23C3C';

  public get color(): string {
    return this._color;
  }

  public set color(color: string) {
    if (this._color === color) return;
    this._color = color;

    let { red, green, blue } = Color.HEXtoRGB(color);

    if (!Color.isLight(red, green, blue)) {
      this._frontColor = Color.adjustColorLevel(color, -8);
      this._borderColor = Color.adjustColorLevel(color, 3);
    } else {
      this._frontColor = Color.adjustColorLevel(color, 8);
      this._borderColor = Color.adjustColorLevel(color, 3);
    }
  }
  // #endregion 主要顏色

  // #region 前景色，透過color自動算出
  private _frontColor: string;

  public get frontColor(): string {
    return this._frontColor;
  }
  // #endregion

  // #region 邊框顏色，透過color自動算出
  private _borderColor: string;

  public get borderColor(): string {
    return this._borderColor;
  }
  // #endregion

  public display: string = '';

  public flowService: FlowService;

  /** 檢測出節點錯誤 */
  public hasError: boolean = false;

  public constructor() {
    this.color = '#E23C3C';
  }

  /** 更新 node component 節點位置 */
  public updateTransform() {
    if (this.nodeComponent) {
      this.nodeComponent.updateTransform();
    }
  }

  /** 更新 node component 節點大小 */
  public updateSize() {
    if (this.nodeComponent) {
      this.nodeComponent.updateSize();
    }
  }

  public checkModify(): boolean {
    const properties = [
      'id',
      'x',
      'y',
      'name',
      'color',
      'type',
      ...this.extraProperties,
    ];

    this.isModify = properties.reduce(
      (isModify, key) => isModify || !isEqual(this.oriProperty[key], this[key]),
      false,
    );

    return this.isModify;
  }

  public fromJSON(json: any): { data: any } {
    this.id = json.id;
    this.name = json.name;
    this.color = json.color;
    this.type = json.type;
    this.x = json.x;
    this.y = json.y;
    let data = {};

    try {
      data = JSON.parse(json.data);
    } catch (e) {
      console.error(e);
    }

    for (var key in data) {
      this[key] = data[key];
    }

    this.oriProperty = Object.assign({}, this);
    this.oriProperty.color = this.oriProperty._color;
    this.display = json.name;

    return { data };
  }

  public toJSON() {
    return {
      id: this.id,
      name: this.name,
      color: this.color,
      type: this.type,
      x: this.x,
      y: this.y,
      data: JSON.stringify(this.getExtraProperties()),
    };
  }

  public getExtraProperties() {
    let data = {};
    for (let property of this.extraProperties) {
      data[property] = this[property] ?? data[property];
    }
    return data;
  }

  public async getDisplay() {
    return this.name;
  }

  public async afterUpdated() {
    this.display = await this.getDisplay();
  }

  public async reset() {}

  public checkError() {}
}
