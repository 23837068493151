import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { PackageAffectedComponent } from '../package-affected.component';
import { SubmenuComponent } from '../submenu/submenu.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { FileDirective } from '../../components/file.directive';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '../../material/tooltip/tooltip';
import { MatIconButton } from '@angular/material/button';
import { NgIf, NgFor, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
    selector: 'ms-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['package'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgSwitch,
        NgSwitchCase,
        MatIconButton,
        MatTooltip,
        MatIcon,
        FileDirective,
        MatMenuTrigger,
        SubmenuComponent,
    ],
})
export class ToolbarComponent extends PackageAffectedComponent {
  public constructor(protected readonly changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }
}
