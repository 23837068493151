import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { AnnouncementMessage } from './announcement.message';
import { RecordPipe } from '../../pipe/record.pipe';
import { MomentPipe } from '../../pipe/moment.pipe';
import { MatTooltip } from '../../material/tooltip/tooltip';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ms-announcement',
    templateUrl: './announcement.component.html',
    styleUrls: ['./announcement.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['package', 'mode', 'message'],
    standalone: true,
    imports: [
        NgIf,
        MatTooltip,
        MomentPipe,
        RecordPipe,
    ],
})
export class AnnouncementComponent extends BaseComponent<AnnouncementMessage> {
  public constructor(protected readonly changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }
}
