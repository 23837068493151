<ng-container *ngIf="properties && properties.length; else notProperties">
  <mat-form-field *ngFor="let property of properties" legacyAppearance>
    <mat-label i18n>{{ property.name + ' ' + property.key }}</mat-label>
    <input
      matInput
      [(ngModel)]="property.default"
      (ngModelChange)="afterPropertyChange.emit($event)"
    />
  </mat-form-field>
</ng-container>

<ng-template #notProperties>
  <div class="not-properties" i18n>沒有任何參數需要設定</div>
</ng-template>
