import { Injectable } from '@angular/core';

declare var monaco;

@Injectable({
  providedIn: 'root',
})
export class MonacoEditorLoaderService {
  public enableMonaco$ = this._loadMonaco();

  private async _loadMonaco(): Promise<boolean> {
    return new Promise((resolve) => {
      const baseUrl = './assets/monaco/vs';
      if (typeof monaco !== 'undefined') {
        return resolve(true);
      }

      const onGotAmdLoader: any = () => {
        // Load monaco
        (<any>window).require.config({ paths: { vs: `${baseUrl}` } });
        (<any>window).require([`vs/editor/editor.main`], () => resolve(true));
      };

      // Load AMD loader if necessary
      if (!(<any>window).require) {
        const loaderScript: HTMLScriptElement =
          document.createElement('script');
        loaderScript.type = 'text/javascript';
        loaderScript.src = `${baseUrl}/loader.js`;
        loaderScript.addEventListener('load', onGotAmdLoader);
        loaderScript.addEventListener('error', () => resolve(false));
        document.body.appendChild(loaderScript);
      } else {
        onGotAmdLoader();
      }
    });
  }
}
