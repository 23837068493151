import { Component, forwardRef } from '@angular/core';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { host, NodeComponent } from '../node.component';
import { ExchangeNode } from './class';
import { ConditionComponent } from '../condition/condition.component';
import { JunctionComponent } from '../../junction/junction.component';
import { NgIf } from '@angular/common';

@Component({
    selector: ':svg:g.exchange.node',
    templateUrl: './node.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['node'],
    providers: [
        {
            provide: NodeComponent,
            useExisting: forwardRef(() => ExchangeNodeComponent),
        },
    ],
    standalone: true,
    imports: [
        NgIf,
        JunctionComponent,
        ConditionComponent,
    ],
})
export class ExchangeNodeComponent extends NodeComponent<ExchangeNode> {
  public faIcons = { 'fa-exchange-alt': faExchangeAlt };
}
