import { Node } from '../class';

export class GetStatisticNode extends Node {
  public icon = 'insert_chart';

  public configId: string;
  public responseVar: string = 'statistic';
  public isAsync: boolean;
  public start: string;
  public end: string;
  public withDetail: string = 'true';

  public timeGroupWay: string = 'DAY';
  public botIds: number[] = [-1];
  public isProgrammableStart = false;
  public isProgrammableEnd = false;

  public readonly extraProperties = [
    'configId',
    'responseVar',
    'isAsync',
    'start',
    'end',
    'timeGroupWay',
    'botIds',
    'withDetail',
    'isProgrammableStart',
    'isProgrammableEnd',
  ];
}
