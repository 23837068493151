<button
  mat-raised-button
  color="primary"
  disableRipple="true"
  element-ref
  #elementRef="elementRef"
  (click)="pickImage(elementRef)"
  *ngIf="onlyButton"
  i18n
>
  <ng-container *ngIf="value; else uploadBlock">更換</ng-container>圖片
</button>

<div
  *ngIf="!autoSize && !onlyButton"
  matRipple
  class="picture mat-elevation-z4"
  element-ref
  #elementRef="elementRef"
  [style.background-image]="backgroundImage(value | imageType)"
  (click)="pickImage(elementRef)"
>
  <div class="btns">
    <button mat-raised-button color="primary" disableRipple="true" i18n>
      <ng-container *ngIf="value; else uploadBlock">更換</ng-container>圖片
    </button>
    <button
      *ngIf="value"
      mat-raised-button
      color="warn"
      disableRipple="true"
      (click)="deleteImage($event)"
      i18n
    >
      刪除圖片
    </button>
  </div>
</div>

<div
  *ngIf="autoSize && !onlyButton"
  class="auto-size picture mat-elevation-z4"
  element-ref
  #elementRef="elementRef"
  (click)="pickImage(elementRef)"
>
  <img [src]="value | imageType" *ngIf="value" />
  <div class="btns">
    <button mat-raised-button color="primary" disableRipple="true" i18n>
      <ng-container *ngIf="value; else uploadBlock">更換</ng-container>圖片
    </button>
    <button
      *ngIf="value"
      mat-raised-button
      color="warn"
      disableRipple="true"
      (click)="deleteImage($event)"
      i18n
    >
      刪除圖片
    </button>
  </div>
</div>
<ng-template #uploadBlock i18n>上傳</ng-template>
