<mat-checkbox
  class="form-field"
  [(ngModel)]="node.isAsync"
  color="primary"
  i18n
>
  {{ node.isAsync ? notText : '' }}同步模式
</mat-checkbox>

<div fxLayout="row" fxLayoutGap="15px" [style.margin-top.em]="1">
  <div fxFlex="2 0 0">
    <h3 i18n>標籤篩選</h3>
    <gosu-tag-tree-picker
      title="新增篩選標籤"
      i18n-title="新增篩選標籤"
      target="package"
      [(value)]="node.tagIds"
    >
    </gosu-tag-tree-picker>
  </div>

  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label i18n>起始日</mat-label>
    <input
      matInput
      [(ngModel)]="node.start"
      [matDatepicker]="startDatepicker"
    />
    <mat-datepicker-toggle matSuffix [for]="startDatepicker">
    </mat-datepicker-toggle>

    <mat-hint i18n>格式：YYYY-MM-DD</mat-hint>
    <mat-datepicker #startDatepicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label i18n>迄止日</mat-label>
    <input matInput [(ngModel)]="node.end" [matDatepicker]="endDatepicker" />
    <mat-datepicker-toggle matSuffix [for]="endDatepicker">
    </mat-datepicker-toggle>

    <mat-hint i18n>格式：YYYY-MM-DD</mat-hint>
    <mat-datepicker #endDatepicker></mat-datepicker>
  </mat-form-field>
</div>

<div fxLayout="row" fxLayoutGap="15px" [style.margin-top.em]="1">
  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label i18n>回傳變數</mat-label>
    <input matInput [(ngModel)]="node.responseVar" />
  </mat-form-field>

  <mat-checkbox
    class="form-field"
    [(ngModel)]="node.withRecord"
    color="primary"
    fxFlex="1 0 0"
    i18n
  >
    包含訊息資料
  </mat-checkbox>

  <mat-checkbox
    class="form-field"
    [disabled]="!node.withRecord"
    [(ngModel)]="node.withCard"
    color="primary"
    fxFlex="1 0 0"
    i18n
  >
    卡片
  </mat-checkbox>

  <mat-checkbox
    class="form-field"
    [disabled]="!node.withCard"
    [(ngModel)]="node.withButton"
    color="primary"
    fxFlex="1 0 0"
    i18n
  >
    按鈕
  </mat-checkbox>

  <mat-checkbox
    class="form-field"
    [disabled]="!node.withRecord"
    [(ngModel)]="node.withPoster"
    color="primary"
    fxFlex="1 0 0"
    i18n
  >
    海報
  </mat-checkbox>

  <mat-checkbox
    class="form-field"
    [disabled]="!node.withPoster"
    [(ngModel)]="node.withButton"
    color="primary"
    fxFlex="1 0 0"
    i18n
  >
    區塊
  </mat-checkbox>
</div>
