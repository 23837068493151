import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { Flex } from '@ay/bot';
import { ImageTypePipe } from '../../../../../pipe/imageType.pipe';
import {
  calcOffsetBottom,
  calcOffsetEnd,
  calcOffsetStart,
  calcOffsetTop,
} from '../../../flex.util';

@Component({
  selector: 'flex-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[style.top]': 'offsetTop',
    '[style.left]': 'offsetStart',
    '[style.right]': 'offsetEnd',
    '[style.bottom]': 'offsetBottom',
    '[style.marginTop]': "layout == 'vertical'? margin:null",
    '[style.marginLeft]': "layout != 'vertical'? margin:null",
  },
  standalone: true,
  imports: [ImageTypePipe],
})
export class FlexIconComponent {
  @Input()
  public data: Flex.Block.Icon;

  @Input()
  public bubble: Flex.Content.Bubble;

  @Input()
  public flex: Flex.Message;

  @Input()
  public level: number = -1;

  @Input()
  public layout: Flex.BoxLayout;

  @Input()
  public index: number;

  protected positionClass = {
    absolute: 'ExAbs',
  };

  public sizeClass = {
    xxs: 'ExXXs',
    xs: 'ExXs',
    sm: 'ExSm',
    md: 'ExMd',
    lg: 'ExLg',
    xl: 'ExXl',
    xxl: 'ExXXl',
    '3xl': 'Ex3Xl',
    '4xl': 'Ex4Xl',
    '5xl': 'Ex5Xl',
  };

  protected offsetTopClass = {
    none: 'ExTNone',
    xs: 'ExTXs',
    sm: 'ExTSm',
    md: 'ExTMd',
    xl: 'ExTXl',
    xxl: 'ExTXXl',
  };
  protected offsetBottomClass = {
    none: 'ExBNone',
    xs: 'ExBXs',
    sm: 'ExBSm',
    md: 'ExBMd',
    xl: 'ExBXl',
    xxl: 'ExBXXl',
  };
  protected offsetStartClass = {
    none: 'ExLNone',
    xs: 'ExLXs',
    sm: 'ExLSm',
    md: 'ExLMd',
    xl: 'ExLXl',
    xxl: 'ExLXXl',
  };
  protected offsetEndClass = {
    none: 'ExRNone',
    xs: 'ExRXs',
    sm: 'ExRSm',
    md: 'ExRMd',
    xl: 'ExRXl',
    xxl: 'ExRXXl',
  };

  protected get marginClass() {
    return {
      none: this.layout == 'vertical' ? 'ExMgnTNone' : 'ExMgnLNone',
      xs: this.layout == 'vertical' ? 'ExMgnTXs' : 'ExMgnLXs',
      sm: this.layout == 'vertical' ? 'ExMgnTSm' : 'ExMgnLSm',
      md: this.layout == 'vertical' ? 'ExMgnTMd' : 'ExMgnLMd',
      lg: this.layout == 'vertical' ? 'ExMgnTLg' : 'ExMgnLLg',
      xl: this.layout == 'vertical' ? 'ExMgnTXl' : 'ExMgnLXl',
      xxl: this.layout == 'vertical' ? 'ExMgnTXXl' : 'ExMgnLXXl',
    };
  }

  public get margin() {
    if (!this.index || this.marginClass[this.data.margin]) return null;
    return this.data.margin;
  }

  public get width() {
    if (!this.data.aspectRatio) return 1;
    let [width, height] = this.data.aspectRatio
      .split(':')
      .map((val) => parseInt(val));
    return (width / height) * 100;
  }

  public get offsetTop() {
    return calcOffsetTop(this.data.offsetTop, this._elementRef.nativeElement);
  }

  public get offsetBottom() {
    return calcOffsetBottom(
      this.data.offsetBottom,
      this._elementRef.nativeElement,
    );
  }

  public get offsetStart() {
    return calcOffsetStart(
      this.data.offsetStart,
      this._elementRef.nativeElement,
    );
  }

  public get offsetEnd() {
    return calcOffsetEnd(this.data.offsetEnd, this._elementRef.nativeElement);
  }

  public constructor(private readonly _elementRef: ElementRef) {}

  @HostBinding('class')
  public get hostClass(): string {
    const list = ['MdIco', 'fl0'];
    list.push(this.positionClass[this.data.position]);
    list.push(this.sizeClass[this.data.size || 'md']);
    list.push(this.marginClass[this.data.margin]);
    list.push(this.offsetTopClass[this.data.offsetTop]);
    list.push(this.offsetBottomClass[this.data.offsetBottom]);
    list.push(this.offsetStartClass[this.data.offsetStart]);
    list.push(this.offsetEndClass[this.data.offsetEnd]);
    return list.filter(Boolean).join(' ');
  }
}
