import { Content } from '@ay/bot';
import { Message } from '../base/base.message';
import { Card } from './card/card.class';

export class CardsMessage extends Message<Content.Cards> {
  public type = 'cards';

  public constructor() {
    super();
    this.content = new Content.Cards([], $localize`一組卡片`);
  }

  public async loadFromContent(content: Content.Cards) {
    content['__proto__'] = Content.Cards.prototype;
    this.content = content;
    const cards = [];
    for (const data of this.content.cards) {
      if (data instanceof Card) {
        cards.push(data);
      } else {
        const card = new Card();
        await card.fromJson(data);
        cards.push(card);
      }
    }
    this.content.cards = cards;
  }

  public toString() {
    return this.content.alt || $localize`一組卡片`;
  }
}
