import { AsyncPipe, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { BehaviorSubject, firstValueFrom, map } from 'rxjs';
import { EmptyResponseError } from '../../../util/empty-response-error';
import { ImagePickerDialog } from '../../dialog/image';
import { MatContextMenuDirective } from '../../material/context-menu';
import { MatTooltip } from '../../material/tooltip/tooltip';
import { ImageTypePipe } from '../../pipe/imageType.pipe';
import { BaseComponent } from '../base/base.component';
import { ImageMessageService } from './image-message.service';
import { ImageMessage } from './image.message';

@Component({
  selector: 'ms-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['package', 'mode', 'message'],
  standalone: true,
  imports: [
    NgIf,
    MatTooltip,
    MatContextMenuDirective,
    MatMenu,
    MatMenuItem,
    MatIcon,
    MatDivider,
    AsyncPipe,
    ImageTypePipe,
  ],
})
export class ImageComponent
  extends BaseComponent<ImageMessage>
  implements AfterViewInit
{
  public get isTemplate() {
    return this.message.template;
  }
  public alt$ = new BehaviorSubject<string>('');

  public altTip$ = this.alt$.pipe(map((alt) => (alt ? alt : '圖片無法預覽')));

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _imageService: ImageMessageService,
    private readonly _matConnectedDialog: MatConnectedDialog,
  ) {
    super(changeDetectorRef);
  }

  public ngAfterViewInit() {
    this.alt$.next(this.message.content.alt);
  }

  public editTemplate() {
    this._imageService.openTemplateEditor(this.message);
  }

  public async edit() {
    try {
      let url = '';
      if (this.message && this.message.content) {
        url = this.message.content.content;
      }
      const dialog = this._matConnectedDialog.open(ImagePickerDialog, {
        data: { url, useCode: true },
      });
      let res = await firstValueFrom(dialog.afterClosed());
      if (res === undefined) return null;
      this.message.content.content = res;
      this.message.changed();
    } catch (error) {
      if (error instanceof EmptyResponseError) return;
      throw error;
    }
  }
}
