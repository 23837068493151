import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TemplatePropertyDto } from '@ay-gosu/server-shared';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';

@Component({
  selector: 'ms-template-properties-editor',
  templateUrl: './template-properties-editor.component.html',
  styleUrls: ['./template-properties-editor.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatFormField,
    MatInput,
    MatLabel,
    FormsModule,
    LegacyAppearanceDirective,
  ],
})
export class TemplatePropertiesEditorComponent {
  @Input()
  public properties: TemplatePropertyDto[];

  @Output()
  public afterPropertyChange = new EventEmitter();
}
