import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { delay } from 'bluebird';
import { Subject, firstValueFrom } from 'rxjs';
import { bufferTime, first, scan, takeUntil } from 'rxjs/operators';
import { ReadableError } from '../../../../util/readable-error';
import { StaticService } from '../../../service/static.service';
import { RecorderFactory } from '../../media/recorder-factory.class';
import { Recorder } from '../../media/recorder.class';
import { MatButton } from '@angular/material/button';
import { ProgressBarComponent } from '../../../components/progress-bar/progress-bar.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

const TICK_COUNT = 150;
const TICK_DURATION = 50;

@Component({
    selector: 'ms-audio-recorder',
    templateUrl: './recorder.dialog.html',
    styleUrls: ['./recorder.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        ProgressBarComponent,
        NgFor,
        MatButton,
        AsyncPipe,
    ],
})
export class AudioRecorderDialog implements AfterViewInit, OnDestroy {
  public status: 'init' | 'recording' | 'recorded' | 'upload' | 'uploaded' =
    'init';

  public recorder: Recorder;
  public progress$: Subject<number>;

  public instant$ = new Subject();
  public chart$ = this.instant$.pipe(
    scan((prev, curr) => [...prev.slice(-TICK_COUNT + 1), curr], []),
  );

  @ViewChild('player')
  public player: ElementRef<HTMLAudioElement>;

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly _staticService: StaticService,
    private readonly _applicationRef: ApplicationRef,
    private readonly _matDialogRef: MatDialogRef<AudioRecorderDialog>,
  ) {}

  public async ngAfterViewInit(): Promise<void> {
    await delay(1);
    this.regenerateChart();
  }

  public ngOnDestroy(): void {
    if (this.recorder) {
      this.recorder.stop();
    }

    this._destroy$.next();
    this._destroy$.complete();
  }

  public async start() {
    try {
      this.recorder = await RecorderFactory.create(
        { audio: true, video: false },
        'audio/webm;codecs=opus',
      );
      this.recorder.start();
      this.recorder.meter.instant$
        .pipe(
          takeUntil(this.recorder.stop$),
          bufferTime(TICK_DURATION),
          takeUntil(this._destroy$),
        )
        .subscribe((volume) => this.instant$.next(Math.max(...volume)));
      this.status = 'recording';
    } catch (error) {
      throw new ReadableError($localize`無法取用麥克風，請授權網頁使用麥克風`, {
        error,
      });
    }
  }

  public async stop() {
    this.recorder.stop();
    this.status = 'recorded';
    this._applicationRef.tick();
    this.player.nativeElement.src = URL.createObjectURL(
      this.recorder.getBlob(),
    );
  }

  public restart() {
    this.regenerateChart();
    this.start();
    this.status = 'recording';
  }

  public async upload() {
    this.status = 'upload';
    let blob = this.recorder.getBlob();
    let task = await this._staticService.uploadBlob(blob, 'audio.webm');
    this.progress$ = task.progress;
    let response = await firstValueFrom(
      task.uploadSubject.pipe(first((event) => event?.status == 'all-done')),
    );

    this._matDialogRef.close(response.result);
  }

  protected regenerateChart() {
    for (let i = 0; i < TICK_COUNT; i++) {
      this.instant$.next(0);
    }
  }
}
