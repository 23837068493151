import { Component, HostBinding, Input } from '@angular/core';
import { Flex } from '@ay/bot';

@Component({
    selector: 'flex-separator',
    templateUrl: './separator.component.html',
    styleUrls: ['./separator.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[style.border-color]': 'data.color',
        '[style.marginTop]': "layout == 'vertical'? margin:null",
        '[style.marginLeft]': "layout != 'vertical'? margin:null",
    },
    standalone: true,
})
export class FlexSeparatorComponent {
  @Input()
  public data: Flex.Block.Box;

  @Input()
  public bubble: Flex.Content.Bubble;

  @Input()
  public flex: Flex.Message;

  @Input()
  public level: number = -1;

  @Input()
  public layout: Flex.BoxLayout;

  @Input()
  public index: number;

  protected get marginClass() {
    return {
      none: this.layout == 'vertical' ? 'ExMgnTNone' : 'ExMgnLNone',
      xs: this.layout == 'vertical' ? 'ExMgnTXs' : 'ExMgnLXs',
      sm: this.layout == 'vertical' ? 'ExMgnTSm' : 'ExMgnLSm',
      md: this.layout == 'vertical' ? 'ExMgnTMd' : 'ExMgnLMd',
      lg: this.layout == 'vertical' ? 'ExMgnTLg' : 'ExMgnLLg',
      xl: this.layout == 'vertical' ? 'ExMgnTXl' : 'ExMgnLXl',
      xxl: this.layout == 'vertical' ? 'ExMgnTXXl' : 'ExMgnLXXl',
    };
  }

  public get margin() {
    if (!this.index || this.marginClass[this.data.margin]) return null;
    return this.data.margin;
  }

  @HostBinding('class')
  public get hostClass(): string {
    const list = ['MdSep', 'fl0'];
    if (!!this.index) list.push(this.marginClass[this.data.margin]);
    return list.filter(Boolean).join(' ');
  }
}
