import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { BehaviorSubject, Subject, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Package } from '../../package.class';
import { RegularizedFlex } from '../regularized-flex.class';

export const CHILD_TYPES = {
  bubble: ['header', 'hero', 'body', 'footer'],
  header: ['box'],
  hero: ['box', 'image', 'video'],
  body: ['box'],
  footer: ['box'],
  box: ['box', 'image', 'text', 'button', 'filler', 'spacer', 'separator'],
  text: ['span'],
  carousel: ['bubble'],
};

@Injectable({
  providedIn: 'root',
})
export class FlexEditorService {
  public regularizedFlex: RegularizedFlex;
  public undoStep: RegularizedFlex[] = [];
  public redoStep: RegularizedFlex[] = [];
  public beforeChange$ = new Subject<void>();
  public afterChange$ = new Subject<void>();
  public selected$ = new BehaviorSubject<RegularizedFlex>(null);
  public selectedParent: RegularizedFlex;

  public cdkDragData: CdkDrag = null;
  public cdkDropLists: CdkDropList[] = [];
  public cdkDropTarget: CdkDropList;
  public updateCdkDropList$ = new Subject<void>();
  public isInputFocus$ = new BehaviorSubject<boolean>(false);

  public editorDialogRef = null;
  public package: Package;

  public childTypeDisble = {
    bubble: [],
    header: [],
    hero: [],
    body: [],
    footer: [],
    box: [],
    text: [],
    carousel: [],
  };

  public constructor() {
    fromEvent(window, 'keydown')
      .pipe(filter(() => !this.isInputFocus$.value))
      .subscribe((event) => this.onKeydown(event));
    this.beforeChange$.subscribe(() => this.newStep());
    this.selected$.subscribe(() => this.selectParent());
    this.afterChange$.subscribe(() => this.findIsSelect());

    this.updateCdkDropList$.subscribe(() => (this.cdkDropLists = []));
  }

  public reset() {
    this.regularizedFlex = null;
    this.undoStep = [];
    this.redoStep = [];
    this.cdkDropLists = [];
    this.selected$.next(null);
  }

  public newStep() {
    this.undoStep.push(cloneDeep(this.regularizedFlex));
    this.redoStep = [];
  }

  public undo() {
    if (!this.undoStep.length) return;
    let last = this.undoStep.splice(this.undoStep.length - 1, 1);
    this.redoStep.push(cloneDeep(this.regularizedFlex));
    this.regularizedFlex = last[0];
    this.afterChange$.next();
    return false;
  }

  public redo() {
    if (!this.redoStep.length) return;
    let last = this.redoStep.splice(this.redoStep.length - 1, 1);
    this.undoStep.push(cloneDeep(this.regularizedFlex));
    this.regularizedFlex = last[0];
    this.afterChange$.next();
    return false;
  }

  public findChildTypes(data: RegularizedFlex): string[] {
    if (!data) return null;
    const childTypes = cloneDeep(CHILD_TYPES);

    let isContentType = childTypes.bubble.includes(data.type);
    if (isContentType && data.contents?.length) return;

    let menu = childTypes[data.type];

    if (data.type == 'box' && data.layout == 'baseline')
      return ['icon', 'text', 'filler', 'spacer'];
    return menu;
  }

  public handleTypeDisabled() {
    if (!this._isEnableVideo()) {
      this.childTypeDisble.hero = ['video'];
    } else {
      this.childTypeDisble.hero = [];
    }
  }

  private _isEnableVideo() {
    // 只有 bubble 的 hero 可以放 video
    const contents = this.regularizedFlex?.content?.contents;
    if (!contents) return;
    let type = contents?.type;
    if (!type) return;
    if (type === 'bubble') {
      return ['', 'kilo', 'mega', 'giga'].includes(contents?.size || '');
    }
    return false;
  }
  public childTypeOptions(data: RegularizedFlex): {
    type: string;
    disabled: boolean;
  }[] {
    let types = this.findChildTypes(data);
    if (!types) return [];
    this.handleTypeDisabled();
    return types.map((type) => {
      return {
        type,
        disabled: this.childTypeDisble[data.type].includes(type),
      };
    });
  }

  protected onKeydown(event) {
    if (event.key == 'z' && (event.metaKey || event.ctrlKey)) {
      if (event.shiftKey) {
        this.redo();
      } else {
        this.undo();
      }
    }
  }

  protected selectParent(data?: RegularizedFlex) {
    if (!this.selected$.value || !this.regularizedFlex) {
      return;
    }

    if (!data) {
      data = this.regularizedFlex.content.contents as any;
    }

    if (!data.contents || !data.contents.length) {
      return;
    }

    let hasSelect = data.contents.find(
      (content) => content == this.selected$.value,
    );

    if (hasSelect) {
      this.selectedParent = data;
    } else {
      data.contents.forEach((content) => this.selectParent(content));
    }
  }

  protected findIsSelect(data?: RegularizedFlex) {
    if (!data) {
      data = this.regularizedFlex.content.contents as any;
    }

    if (data.isSelect) {
      this.selected$.next(data);
    } else if (data.contents) {
      data.contents.forEach((content) => this.findIsSelect(content));
    }
  }
}
