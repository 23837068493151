import { BotType, ProfileType } from '@ay/bot';
import { firstValueFrom } from 'rxjs';
import { GosuValidate } from '../../../../components/gosu-validator/gosu-validator.class';
import { Node } from '../class';
export type ConditionTargetType = 'profile' | 'bot' | 'company';
export type PropertyOperand =
  | 'string'
  | 'like'
  | 'isExist'
  | 'notExist'
  | 'gt'
  | 'gte'
  | 'lt'
  | 'lte';

export type DatetimeMethod =
  | 'isSame'
  | 'isBefore'
  | 'isAfter'
  | 'isSameNow'
  | 'isBeforeNow'
  | 'isAfterNow';

export class Condition {
  public name: string = '';
  public color: string = '';
  public type: string = 'TEXT';
  public keywords?: number[] = [];
  public logic?: string = '';
  public postIds?: string[] = [];
  public tagIds?: (number | string)[] = [];
  public key?: string = '';
  public operand?: PropertyOperand = 'like';
  public value?: string = '';
  public target?: ConditionTargetType = 'profile';
  public datetime: string;
  public judgeDatetime: string;
  public datetimeFormat: string = 'YYYY-MM-DD';
  public datetimeType: 'date' | 'time' | 'datetime' = 'date';
  public datetimeMethod: DatetimeMethod = 'isSame';
  public hashTags: string[] = [];
  public botIds: number[] = [];
  public profileTypes: ProfileType[] = [];
  public platforms: BotType[] = [];
  public profileIds: number[] = [];
  public lingtelliId: number;
  public lingtelliGroupId: number;
  public lingtelliAnswer: string = '';
  public lingtelliDisplay: string = '';

  public exchangeId: string = '';
  public methodId: string = '';
  public methods: any = [];
  public targetProfileId: string;

  public display: string = '';

  public toJSON() {
    return {
      name: this.name,
      color: this.color,
      type: this.type,
      keywords: this.keywords,
      logic: this.logic,
      postIds: this.postIds,
      tagIds: this.tagIds,
      key: this.key,
      operand: this.operand,
      value: this.value,
      target: this.target,
      hashTags: this.hashTags,
      botIds: this.botIds,
      profileTypes: this.profileTypes,
      platforms: this.platforms,
      profileIds: this.profileIds,
      lingtelliId: this.lingtelliId,
      lingtelliGroupId: this.lingtelliGroupId,
      lingtelliAnswer: this.lingtelliAnswer,
      lingtelliDisplay: this.lingtelliDisplay,
      exchangeId: this.exchangeId,
      methodId: this.methodId,
      targetProfileId: this.targetProfileId,
      datetime: this.datetime,
      judgeDatetime: this.judgeDatetime,
      datetimeFormat: this.datetimeFormat,
      datetimeType: this.datetimeType,
      datetimeMethod: this.datetimeMethod,
    };
  }
}

export class SwitchNode extends Node {
  public icon = 'shuffle';
  public isContinue: boolean;
  public conditions: Condition[] = [];
  public readonly extraProperties = ['conditions', 'isContinue'];

  public constructor() {
    super();
    this._create();
  }

  private _create() {
    let condition = new Condition();
    condition.name = $localize`條件 1`;
    condition.color = '#CCCCCC';
    this.conditions = [condition];
  }

  public async afterUpdated() {
    try {
      this.display = await this.getDisplay();
      for (let index in this.conditions) {
        const condition = this.conditions[index];

        let display = condition.name;
        if (!display.startsWith('條件') && display != '') {
          condition.display = condition.name;
          continue;
        }
        display = $localize`條件` + (index + 1);

        switch (condition.type) {
          case 'TEXT':
            display = $localize`內容:` + condition.key;
            break;

          case 'KEYWORD':
            const keywords = await firstValueFrom(
              this.flowService.keywordService.groupList$,
            );

            display =
              $localize`關鍵字:` +
              condition.keywords
                .map((keyword) => keywords.find((item) => item.id === keyword))
                .filter((keyword) => keyword)
                .map((keyword) => keyword.name);
            break;

          case 'HAS_TAG': {
            const tags = await firstValueFrom(this.flowService.tagService.all$);

            display =
              $localize`有標籤` +
              condition.tagIds
                .map((tagId) => tags.find((tag) => tag.id === +tagId))
                .filter((tag) => tag)
                .map((tag) => `[${tag.name}]`);
            break;
          }

          case 'HAS_PROPERTY': {
            const properties = await firstValueFrom(
              this.flowService.propertyConfigService.all$,
            );

            const property = properties.find(
              (property) => property.key === condition.key,
            );
            display = $localize`屬性:` + property.name;
            break;
          }

          case 'DATETIME':
            let method =
              $localize`等於` +
              (condition.judgeDatetime || $localize` 對照時間`);
            switch (condition.datetimeMethod) {
              case 'isSame':
                method =
                  $localize` = 等於` +
                  (condition.judgeDatetime || $localize` 對照時間`);
                break;
              case 'isBefore':
                method =
                  $localize` < 早於` +
                  (condition.judgeDatetime || $localize` 對照時間`);
                break;
              case 'isAfter':
                method =
                  $localize` > 晚於` +
                  (condition.judgeDatetime || $localize` 對照時間`);
                break;
              case 'isSameNow':
                method = $localize` = 等於觸發當下`;
                break;
              case 'isBeforeNow':
                method = $localize` < 早於觸發當下`;
                break;
              case 'isAfterNow':
                method = $localize` > 晚於觸發當下`;
                break;
            }

            display = (condition.datetime || $localize`比對時間 `) + method;
            break;

          case 'SOURCE_CODE':
            display = condition.logic;
            break;

          case 'FILTER_PROFILE_ID':
            display = $localize`好友編號:` + condition.profileIds;
            break;

          case 'FILTER_PROFILE_TYPE':
            const typeMap = {
              User: $localize`使用者`,
              Group: $localize`群組`,
              Room: $localize`聊天室`,
            };
            display =
              $localize`類型:` +
              condition.profileTypes
                .map((type) => typeMap[type])
                .filter((type) => type);
            break;

          case 'FILTER_PLATFORM':
            display = $localize`平台:` + condition.platforms;
            break;

          case 'FILTER_BOT': {
            const bots = await firstValueFrom(this.flowService.bots$);

            display =
              $localize`機器人:` +
              condition.botIds
                .map((botId) => bots.find((bot) => bot.id === botId))
                .filter((bot) => bot)
                .map((bot) => bot.name);
            break;
          }

          case 'FILTER_POST':
            display = $localize`FB 文章`;
            break;

          case 'FILTER_IG_POST':
            display = $localize`IG 文章`;
            break;

          case 'FILTER_POST_HASHTAG':
            display = $localize`FB 文章 HashTag:` + condition.hashTags;
            break;

          case 'ELSE':
            display = $localize`其他`;
            break;

          case 'LINGTELLI':
            display = condition.lingtelliDisplay || $localize`尚未選擇問題群組`;
            break;
        }

        if (display.length > 30) {
          display = display.substr(0, 27) + '...';
        }

        condition.display = display;
      }
    } catch (error) {
      console.error({ error });
    }
  }

  public checkError(): void {
    this.hasError = false;
    const isCheckError = window.localStorage.getItem('isDebug') === 'true';

    if (isCheckError) {
      if (this.conditions.length === 0) {
        this.hasError = true;
      }

      this.conditions.forEach((condition) => {
        if (this._checkError(condition)) {
          this.hasError = true;
        }
      });
    }
  }

  private _checkError = (condition: Condition) => {
    /* 文字比對 */
    if (condition.type === 'TEXT' && GosuValidate.required(condition.key)) {
      return true;
    }

    /* 關鍵字比對 */
    if (
      condition.type === 'KEYWORD' &&
      GosuValidate.arrayRequired(condition.keywords)
    ) {
      return true;
    }

    /* 日期/時間比對  */
    if (
      condition.type === 'DATETIME' &&
      GosuValidate.required(condition.datetimeFormat)
    ) {
      return true;
    }
    if (
      condition.type === 'DATETIME' &&
      GosuValidate.required(condition.datetime)
    ) {
      return true;
    }
    if (
      condition.type === 'DATETIME' &&
      GosuValidate.required(condition.judgeDatetime)
    ) {
      return true;
    }

    /* 檢測標籤 */
    if (
      condition.type === 'HAS_TAG' &&
      GosuValidate.arrayRequired(condition.tagIds)
    ) {
      return true;
    }

    /* 檢測屬性 */
    if (
      condition.type === 'HAS_PROPERTY' &&
      GosuValidate.required(condition.key)
    ) {
      return true;
    }
    if (
      condition.type === 'HAS_PROPERTY' &&
      condition.key &&
      condition.operand !== 'isExist' &&
      condition.operand !== 'notExist' &&
      GosuValidate.required(condition.value)
    ) {
      return true;
    }

    /* 程式碼 */
    if (
      condition.type === 'SOURCE_CODE' &&
      GosuValidate.required(condition.logic)
    ) {
      return true;
    }

    /* 篩選好友編號 */
    if (
      condition.type === 'FILTER_PROFILE_ID' &&
      GosuValidate.arrayRequired(condition.profileIds)
    ) {
      return true;
    }

    /* 篩選好友類型 */
    if (
      condition.type === 'FILTER_PROFILE_TYPE' &&
      GosuValidate.arrayRequired(condition.profileTypes)
    ) {
      return true;
    }

    /* 篩選平台 */
    if (
      condition.type === 'FILTER_PLATFORM' &&
      GosuValidate.arrayRequired(condition.platforms)
    ) {
      return true;
    }

    /* 篩選機器人 */
    if (
      condition.type === 'FILTER_BOT' &&
      GosuValidate.arrayRequired(condition.botIds)
    ) {
      return true;
    }

    /* 篩選Facebook文章 */
    if (
      condition.type === 'FILTER_POST' &&
      GosuValidate.arrayRequired(condition.postIds)
    ) {
      return true;
    }

    /* 篩選Instagram文章 */
    if (
      condition.type === 'FILTER_IG_POST' &&
      GosuValidate.arrayRequired(condition.postIds)
    ) {
      return true;
    }

    /* 比對Facebook文章HashTag */
    if (
      condition.type === 'FILTER_POST_HASHTAG' &&
      GosuValidate.arrayRequired(condition.hashTags)
    ) {
      return true;
    }

    /* 檢測是否可以兌換 */
    if (
      condition.type === 'CHECK_EXCHANGE' &&
      GosuValidate.required(condition.exchangeId)
    ) {
      return true;
    }
    if (
      condition.type === 'CHECK_EXCHANGE' &&
      GosuValidate.arrayRequired(condition.methodId)
    ) {
      return true;
    }

    /* 語意分析 */
    if (
      condition.type === 'LINGTELLI' &&
      GosuValidate.required(condition.lingtelliAnswer)
    ) {
      return true;
    }

    return false;
  };
}
