<div class="container">
  <mat-form-field legacyAppearance>
    <mat-label i18n>篩選</mat-label>
    <input matInput [(ngModel)]="keyword" name="search" />
  </mat-form-field>

  <div class="fr control-button">
    <button
      mat-raised-button
      [disabled]="!selection.hasValue()"
      (click)="deleteByProfileIds()"
      color="accent"
      i18n
    >
      取消所選的預約任務
    </button>
    <button mat-raised-button color="warn" (click)="deleteAll()" i18n>
      清空全部預約任務
    </button>
  </div>

  <table mat-table [dataSource]="dataSource" class="fw">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!--
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>任務編號</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container> -->

    <ng-container matColumnDef="profileId">
      <th mat-header-cell *matHeaderCellDef i18n>好友編號</th>
      <td mat-cell *matCellDef="let element">{{ element.gosuProfileId }}</td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef i18n>名稱</th>
      <td mat-cell *matCellDef="let element">{{ element.title }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef i18n>預約時間</th>
      <td mat-cell *matCellDef="let element">{{ element.date | moment }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="selection.toggle(row)"
    ></tr>
  </table>

  <mat-paginator
    #paginator
    [length]="total$ | async"
    [hidePageSize]="true"
    [pageSize]="10"
    (page)="page$.next($event.pageIndex + 1)"
    showFirstLastButtons
  ></mat-paginator>
</div>
