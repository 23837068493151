export type QuickReplyDateFormat = 'datetime' | 'date' | 'time';

export type QuickReplyType =
  | 'next'
  | 'email'
  | 'phone'
  | 'date'
  | 'camera'
  | 'album'
  | 'postback'
  | 'message'
  | 'location';

export type QuickReplyDataOptional = {
  initial?: string;
  min?: string;
  max?: string;
};

export interface QuickReply {
  type: QuickReplyType;
  label: string;
  icon?: string;
  data?: any;
  text?: string;
  mode?: QuickReplyDateFormat;
  optional?: QuickReplyDataOptional;
}

export namespace QuickReply {
  export type Any =
    | Next
    | Email
    | Phone
    | Date
    | Camera
    | Album
    | Postback
    | Message
    | Location;

  export class Base {
    public quickReplyId?: number;
    public packageId?: number;

    public constructor(
      public type: QuickReplyType,
      public label: string,
      public icon?: string,
    ) {}
  }

  export class Next extends Base {
    public type: 'next';
    public data: any;
    public constructor(
      public label: string,
      id: string,
      public icon?: string,
      public text?: string,
    ) {
      super('next', label, icon);
      this.data = id;
    }
  }

  export class Email extends Base {
    public type: 'email';

    public constructor(
      public label: string,
      public icon?: string,
    ) {
      super('email', label, icon);
    }
  }

  export class Phone extends Base {
    public type: 'phone';

    public constructor(
      public label: string,
      public icon?: string,
    ) {
      super('phone', label, icon);
    }
  }

  export class Date extends Base {
    public type: 'date';

    public constructor(
      public label: string,
      public mode: QuickReplyDateFormat,
      public optional: QuickReplyDataOptional,
      public icon?: string,
    ) {
      super('date', label, icon);
    }
  }

  export class Camera extends Base {
    public type: 'camera';

    public constructor(
      public label: string,
      public icon?: string,
    ) {
      super('camera', label, icon);
    }
  }

  export class Album extends Base {
    public type: 'album';

    public constructor(
      public label: string,
      public icon?: string,
    ) {
      super('album', label, icon);
    }
  }

  export class Postback extends Base {
    public type: 'postback';

    public constructor(
      public label: string,
      public data: any,
      public icon?: string,
      public text?: string,
    ) {
      super('postback', label, icon);
    }
  }

  export class Message extends Base {
    public type: 'message';

    public constructor(
      public label: string,
      public text: string,
      public icon?: string,
    ) {
      super('message', label, icon);
    }
  }

  export class Location extends Base {
    public type: 'location';

    public constructor(
      public label: string,
      public icon?: string,
    ) {
      super('location', label, icon);
    }
  }
}
