import { AsyncPipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { EditorComponent } from '../../../../components/ngx-monaco-editor/editor.component';
import { MonacoEditorLoaderService } from '../../../../components/ngx-monaco-editor/monaco-editor-loader.service';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { FormComponent } from '../form.component';
import { ResponseNode } from './class';

@Component({
  selector: 'flow-rich-menu-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    MatOption,
    MatInput,
    MatIconButton,
    MatIcon,
    MatButton,
    EditorComponent,
    AsyncPipe,
    LegacyAppearanceDirective,
  ],
})
export class ResponseFormComponent
  extends FormComponent<ResponseNode>
  implements AfterViewInit
{
  public enableMonaco$ = this._monacoEditorLoaderService.enableMonaco$;

  public width = 1200;

  public editorOptions = {
    theme: 'vs-dark',
    language: 'html',
    minimap: { enabled: false },
    snippetSuggestions: 'auto',
    automaticLayout: true,
  };

  public statusCodes: { value: string; label: string }[] = [
    { value: '200', label: $localize`200 - 請求成功 OK` },
    { value: '301', label: $localize`301 - 永久轉移 Moved Permanently` },
    { value: '302', label: $localize`302 - 暫時轉移 Moved Temporarily` },
    { value: '307', label: $localize`307 - 暫時重新導向 Temporary Redirect` },
    { value: '308', label: $localize`308 - Permanent Redirect` },
    { value: '400', label: $localize`400 - 錯誤的請求 Bad Request` },
    { value: '401', label: $localize`401 - 拒絕存取 Unauthorized` },
    { value: '403', label: $localize`403 - 禁止使用 Forbidden` },
    { value: '404', label: $localize`404 - 找不到 Not Found` },
    {
      value: '500',
      label: $localize`500 - 內部伺服器錯誤 Internal Server Error`,
    },
    { value: '504', label: $localize`504 - 閘道超時 Gateway Timeout` },
  ];

  public constructor(
    public elementRef: ElementRef,
    private readonly _monacoEditorLoaderService: MonacoEditorLoaderService,
  ) {
    super(elementRef);
  }

  public addHeader() {
    this.node.headers.push({ key: '', value: '' });
  }

  public removeHeader(index: number) {
    this.node.headers.splice(index, 1);
  }

  public onMonacoEditorInit(event) {
    console.log(event);
  }
}
