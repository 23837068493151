import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { combineLatest, fromEvent, ReplaySubject } from 'rxjs';
import { map, share, startWith, switchMap } from 'rxjs/operators';
import { BasicDialog } from '../../dialog/basic';
import { BaseComponent } from '../base/base.component';
import { PosterMessageService } from './poster-message.service';
import { PosterMessage } from './poster.message';
import { AreaTypePipe } from '../../pipe/area-type.pipe';
import { ImageTypePipe } from '../../pipe/imageType.pipe';
import { MatDivider } from '@angular/material/divider';
import { ElementRefDirective } from '../../components/element-ref.directive';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatContextMenuDirective } from '../../material/context-menu';
import { MatTooltip } from '../../material/tooltip/tooltip';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'ms-poster',
    templateUrl: './poster.component.html',
    styleUrls: ['./poster.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['package', 'mode', 'message'],
    standalone: true,
    imports: [
        NgIf,
        MatTooltip,
        MatContextMenuDirective,
        NgFor,
        MatMenu,
        MatMenuItem,
        MatIcon,
        ElementRefDirective,
        MatDivider,
        AsyncPipe,
        ImageTypePipe,
        AreaTypePipe,
    ],
})
export class PosterComponent
  extends BaseComponent<PosterMessage>
  implements AfterViewInit
{
  @ViewChild('poster')
  public posterDom: ElementRef<HTMLDivElement>;

  public posterDom$ = new ReplaySubject<ElementRef<HTMLElement>>(1);

  public image$ = this.message$.pipe(
    switchMap(
      (message) =>
        new Promise<HTMLImageElement>((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.src = message.content.content;
        }),
    ),
    share(),
  );

  public scale$ = combineLatest([
    this.posterDom$,
    fromEvent(window, 'resize').pipe(startWith(null)),
  ]).pipe(
    map(([posterDom, event]) => posterDom.nativeElement.clientWidth / 1040),
  );

  public rate$ = this.image$.pipe(
    map((image) => image.height / image.width),
    share(),
  );

  public height$ = combineLatest([
    this.posterDom$,
    fromEvent(window, 'resize').pipe(startWith(null)),
    this.rate$,
  ]).pipe(
    map(([posterDom, event, rate]) => {
      const domWidth = posterDom.nativeElement.clientWidth;
      return domWidth * rate;
    }),
    startWith(300),
    share(),
  );

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _posterMessageService: PosterMessageService,
    private readonly _basicDialog: BasicDialog,
  ) {
    super(changeDetectorRef);
  }

  public ngAfterViewInit() {
    this.posterDom$.next(this.posterDom);
  }

  public editPoster() {
    this._posterMessageService.openEditor(this.message, this.package);
  }

  public async editAlt(elementRef: ElementRef) {
    const name = await this._basicDialog.rename(this.message.content.alt, {
      elementRef,
    });
    this.message.content.alt = name;
  }
}
