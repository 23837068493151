<ng-container *ngIf="cards$ | async; let cards; else: loading">
  <ng-container *ngIf="cards.length; else empty">
    <div class="header" i18n>
      <mat-icon>{{ "cards" | messageIcon }}</mat-icon>
      從卡片中挑選
    </div>

    <div class="container">
      <div
        fxLayout="row wrap"
        fxLayoutAlign="space-between start"
        fxLayoutGap="16px"
      >
        <div
          class="wrap"
          fxFlex="0 0 225px"
          *ngFor="let card of cards; let index = index"
          (click)="toggleCard(index)"
        >
          <div class="title">{{ card.templateName }}</div>
          <ms-card
            [card]="card"
            selectable="true"
            [ngClass]="{ selected: selected[index] }"
          >
          </ms-card>
          <span class="checkbox" *ngIf="selected[index]">
            {{ selected[index] }}
          </span>
        </div>
      </div>
    </div>

    <div class="footer">
      <span *ngIf="count" i18n> 已經選擇 {{ count }} 張卡片 </span>
      <button
        mat-raised-button
        color="primary"
        (click)="submit()"
        [disabled]="count === 0"
        i18n
      >
        <mat-icon>done</mat-icon>
        確定
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #empty>
  <div class="container">
    <gosu-landing-brand
      image="./assets/cards-landing.png"
      title="在圖像編輯器中建立卡片範本"
      i18n-title="在圖像編輯器中建立卡片範本"
      description="透過圖像編輯器的功能，您可以預先建立卡片範本。這樣一來在建立訊息時就可以統一訊息發送的風格，並提升企業形象及發送效率"
      i18n-description="透過圖像編輯器的功能，您可以預先建立卡片範本。這樣一來在建立訊息時就可以統一訊息發送的風格，並提升企業形象及發送效率"
      callToActionIcon="add"
      callToActionLabel="立即建立"
      i18n-callToActionLabel="立即建立"
      (callToAction)="createTemplate()"
    >
    </gosu-landing-brand>
  </div>
</ng-template>

<ng-template #loading>
  <div class="container">
    <gosu-loading i18n>卡片讀取中，請稍候</gosu-loading>
  </div>
</ng-template>
