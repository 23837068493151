<div class="header" i18n>編輯 {{ card.templateName }}</div>

<div class="container" fxLayout="row nowrap">
  <ms-template-preview
    [template]="card.template"
    [notifier]="notifier"
    [message]="message"
    (afterTemplateExec)="afterTemplateExec($any($event))"
  >
    <ms-card [card]="card" [message]="message" mode="READ"></ms-card>
  </ms-template-preview>

  <div class="form">
    <mat-tab-group
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      class="tiny-tab-group"
    >
      <mat-tab label="覆寫內容" i18n-label="覆寫內容">
        <ms-template-card-customize
          [card]="card"
          [message]="message"
          [package]="package"
        >
        </ms-template-card-customize>
      </mat-tab>

      <mat-tab
        label="參數"
        i18n-label="參數"
        *ngIf="card.template.properties.length"
      >
        <ms-template-properties-editor
          [properties]="card.template.properties"
          (afterPropertyChange)="afterPropertyChange($event)"
        >
        </ms-template-properties-editor>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<div class="footer">
  <div class="tar">
    <button mat-raised-button color="primary" (click)="close()" i18n>
      確認
    </button>
  </div>
</div>
