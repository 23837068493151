import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function validateName(array: any[], name?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value === name) return null;

    const isValid = !array.includes(value);

    return !isValid ? { validateName: true } : null;
  };
}
