import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
} from '@angular/core';
import { DialogPosition } from '@angular/material/dialog';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { bind } from '@ay/util';
import { delay } from 'bluebird';
import {
  Subject,
  Subscription,
  firstValueFrom,
  fromEvent as observableFromEvent,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectableService } from '../../../service/selectable.service';
import { AddConnectionCommand } from '../command/addConnection';
import { AddNodeCommand } from '../command/addNode';
import { Invoker } from '../command/invoker';
import { BlueprintConnection, Connection } from '../connection/class';
import { FlowService } from '../flow.service';
import { generateNode } from '../flow.util';
import { NextNodeDialogComponent } from '../next-node-dialog/next-node-dialog.component';
import { Node } from '../node/class';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: ':svg:g.junction',
    templateUrl: './junction.component.html',
    styleUrls: ['./junction.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        ExtendedModule,
    ],
})
export class JunctionComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class')
  public class = 'junction';

  private readonly _destroy$ = new Subject<void>();

  public get dark() {
    return this.flow.chart.darkTheme;
  }

  private _em: number;

  private _em$ = this.flow.em
    .pipe(takeUntil(this._destroy$))
    .subscribe((em) => (this._em = em));

  /** 訂閱 */
  private _link$: Subscription;
  private _stopLink$: Subscription;

  @Input()
  public disabled: boolean = false;

  @Input()
  public dash: boolean = false;

  // #region color
  private _color: string;

  public get color(): string {
    return this._color;
  }

  @Input()
  public set color(color: string) {
    if (this._color === color) return;
    this._color = color;
    this.flow.connectionList.map((connection) => {
      if (connection.start === this) {
        connection.color = color;
      }
    });
  }
  // #endregion

  @Input()
  public type: 'input' | 'output' = 'input';

  @Input()
  public dx: number = null;

  @Input()
  public dy: number = null;

  public get x() {
    const el = this._elementRef.nativeElement;
    const { left, width } = el.getBoundingClientRect();
    const { paddingX, scrollX, zoom } = this.flow.chart;
    return (left + width / 2 - paddingX) / zoom + scrollX;
  }

  public get y() {
    const el = this._elementRef.nativeElement;
    const { top, height } = el.getBoundingClientRect();
    const { paddingY, scrollY, zoom } = this.flow.chart;
    return (top + height / 2 - paddingY) / zoom + scrollY;
  }

  @Input()
  public node: Node = null;

  @Input()
  public direction: string = 'left';

  @Input()
  public index = 0;

  public constructor(
    public _elementRef: ElementRef,
    public flow: FlowService,
    private readonly _selectable: SelectableService,
    private readonly _invoker: Invoker,
    private readonly _matConnectedDialog: MatConnectedDialog,
  ) {}

  public ngAfterViewInit() {
    const el = this._elementRef.nativeElement;

    switch (this.type) {
      case 'output':
        el.addEventListener('mousedown', this._startLink);
        break;

      case 'input':
        el.addEventListener('mouseenter', this._linked);
        break;
    }
  }

  public async ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    await delay(1);
    this._em$.unsubscribe();
    this.flow.connectionList = this.flow.connectionList.filter(
      (connect) => connect.start !== this && connect.end !== this,
    );
  }

  @bind
  private _startLink(event: MouseEvent) {
    this._selectable.disable();
    this.flow.blueprintConnection = new BlueprintConnection(
      this,
      null,
      this.color,
    );
    this._setBlueprintConnectionFromEvent(event);

    this._link$ = observableFromEvent(document.body, 'mousemove')
      .pipe(takeUntil(this._destroy$))
      .subscribe(this._link);

    this._stopLink$ = observableFromEvent(document.body, 'mouseup')
      .pipe(takeUntil(this._destroy$))
      .subscribe(this._stopLink);

    event.stopPropagation();
  }

  @bind
  private _link(event: MouseEvent) {
    if (event.which === 0) {
      return this._stopLink(event);
    }

    if (this.flow.blueprintConnection) {
      this._setBlueprintConnectionFromEvent(event);

      if (this.flow.blueprintConnection.component) {
        this.flow.blueprintConnection.component.updatePath();
      }
    }
  }

  @bind
  private async _stopLink(event: MouseEvent) {
    this._link$.unsubscribe();
    this._stopLink$.unsubscribe();
    this._selectable.enable();
    await this._createConnection(event);
    this.flow.blueprintConnection = null;
    event.stopPropagation();
  }

  private _setBlueprintConnectionFromEvent(event: MouseEvent) {
    const chart = this.flow.chart;
    const connection = this.flow.blueprintConnection;
    const { zoom } = chart;

    connection.x = (event.clientX - chart.paddingX) / zoom + chart.scrollX;
    connection.y = (event.clientY - chart.paddingY) / zoom + chart.scrollY;

    if (!event.altKey) {
      connection.x -= (connection.x + this._em / 2) % this._em;
      connection.y -= (connection.y + (this._em % 4)) % (this._em / 2);
    }
  }

  private async _createConnection(event: MouseEvent) {
    if (!this.flow.blueprintConnection) return;

    if (!this.flow.blueprintConnection.end) {
      this.flow.blueprintConnection.end = await this._askAddNode(event);
    }

    if (!this.flow.blueprintConnection.end) {
      return;
    }

    let alreadyLink = !!this.flow.connectionList.find(
      (connection) =>
        connection.start == this.flow.blueprintConnection.start &&
        connection.end == this.flow.blueprintConnection.end,
    );

    let connectToSelf =
      this.flow.blueprintConnection.start.node ==
      this.flow.blueprintConnection.end.node;

    if (alreadyLink || connectToSelf) {
      return;
    }

    this._invoker.do(
      new AddConnectionCommand(
        this.flow,
        new Connection(
          this.flow.blueprintConnection.start,
          this.flow.blueprintConnection.end,
          this.color,
        ),
      ),
    );
  }

  private async _askAddNode(event: MouseEvent): Promise<JunctionComponent> {
    const dialogRef = this._matConnectedDialog.open(NextNodeDialogComponent, {
      panelClass: 'dialog-container-p0',
    });

    let position: DialogPosition = {};
    if (event.pageY > window.innerHeight / 2) {
      position.bottom = window.innerHeight - event.pageY + 'px';
    } else {
      position.top = event.pageY + 'px';
    }
    if (event.pageX > window.innerWidth / 2) {
      position.right = window.innerWidth - event.pageX + 'px';
    } else {
      position.left = event.pageX + 'px';
    }

    dialogRef.updatePosition(position);

    const config = await firstValueFrom(dialogRef.afterClosed());

    if (!config) {
      return;
    }

    const { x, y, start } = this.flow.blueprintConnection;
    const node = await generateNode(config.type, this.flow);

    node.icon = config.toolbar.icon;
    node.color = config.toolbar.color;
    node.type = config.type;
    node.name = config.toolbar.name || config.name;
    node.x = x;
    node.y = y - this._em;

    if (node.type === 'TextMessageEventNode') {
      node['isSpecial'] = true;
      node['specialCount'] = '1';
      node['specialMode'] = 'monopolize';
    }

    if (!event.altKey) {
      node.x -= (node.x + this._em / 2) % this._em;
      node.x -= (node.x + (this._em % 4)) % (this._em / 2);
    }

    // 太靠近原本的節點
    if (Math.hypot(node.x - start.x, node.y - start.y) < 30) {
      node.x = start.x + this._em;
      node.y = start.y - this._em;
    }

    await node.afterUpdated();
    this._invoker.do(new AddNodeCommand(this.flow, node));
    this.flow.tick();

    return node.nodeComponent.junctions[0];
  }

  @bind
  private _linked(event: MouseEvent) {
    if (
      this.flow.blueprintConnection &&
      this.flow.blueprintConnection.start &&
      this.flow.blueprintConnection.start != this &&
      this.type === 'input'
    ) {
      this.flow.blueprintConnection.end = this;
    }

    const el = this._elementRef.nativeElement;
    el.addEventListener('mouseleave', this._delink);
  }

  @bind
  private _delink(event: MouseEvent) {
    if (this.flow.blueprintConnection) {
      if (this.flow.blueprintConnection.end === this) {
        this.flow.blueprintConnection.end = null;
      }
    }

    const el = this._elementRef.nativeElement;
    el.removeEventListener('mouseleave', this._delink);
  }
}
