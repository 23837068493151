import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { ExchangeModel } from '@ay-gosu/server-shared';
import { GosuValidatorComponent } from '../../../../components/gosu-validator/gosu-validator.component';
import { ProgrammableComponent } from '../../../../components/programmable/programmable.component';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { ExchangeService } from '../../../../service/exchange.service';
import { FormComponent } from '../form.component';
import { ExchangeNode } from './class';

@Component({
  selector: 'flow-exchange-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatCheckbox,
    FormsModule,
    ProgrammableComponent,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    NgIf,
    NgFor,
    GosuValidatorComponent,
    MatInput,
    AsyncPipe,
    LegacyAppearanceDirective,
  ],
})
export class ExchangeFormComponent
  extends FormComponent<ExchangeNode>
  implements AfterContentInit, OnDestroy
{
  public constructor(
    public elementRef: ElementRef,
    public exchangeService: ExchangeService,
  ) {
    super(elementRef);
  }

  public notText = $localize`非`;

  public ngOnDestroy() {
    this.node.checkError();
  }

  public ngAfterContentInit(): void {
    this.initialMethods();
  }

  public methods: any = [];

  private initialMethods() {
    if (!this.node.exchangeId) {
      return;
    }

    const exchangeId = parseInt(this.node.exchangeId, 10);
    if (isNaN(exchangeId)) {
      return;
    }

    this.select(exchangeId);
  }

  public checkIsCode(data: string) {
    return data.startsWith('${') && data.endsWith('}');
  }

  public async select(id: number) {
    this.methods = [];
    try {
      const exchange = await ExchangeModel.fetch(id);
      this.methods = exchange.methods;
      if (this.node.methodId) {
        const hasMethod = this.methods.some(
          (method: any) => method.id === this.node.methodId,
        );
        if (!hasMethod && !this.checkIsCode(this.node.methodId)) {
          this.node.methodId = '';
        }
      }
    } catch (error) {
      if (!this.checkIsCode(this.node.exchangeId)) {
        this.node.exchangeId = '';
      }
      if (!this.checkIsCode(this.node.methodId)) {
        this.node.methodId = '';
      }
      console.error(error);
    }
  }
}
