<div class="container">
  <div class="full ovh" fxLayout="column" fxLayoutAlign="start start">
    <mat-form-field class="search" legacyAppearance>
      <mat-label i18n>篩選貼文</mat-label>
      <input matInput name="search_post" type="text" [(ngModel)]="searchKey" />
      <button
        *ngIf="searchKey"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="searchKey = ''"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-tab-group
      fxFlex="auto"
      class="fw"
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      [(selectedIndex)]="selectedTabIndex"
    >
      <mat-tab>
        <ng-template mat-tab-label i18n>
          <mat-icon class="tab-icon">check_circle</mat-icon>
          已選貼文 ({{ selectedPostCount$ | async }})
        </ng-template>
        <ng-container
          [ngTemplateOutlet]="postList"
          [ngTemplateOutletContext]="{
            list: selectedPosts$ | async,
            noPostInfo: searchKey
              ? noPostInfos[0][0]
              : noPostInfos[0][1]
          }"
        >
        </ng-container>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label i18n>
          <mat-icon class="tab-icon">art_track</mat-icon>
          公開貼文 ({{ publicPostCount$ | async }})
        </ng-template>
        <ng-container
          [ngTemplateOutlet]="postList"
          [ngTemplateOutletContext]="{
            list: publicPosts$ | async,
            noPostInfo: searchKey
              ? noPostInfos[1][0]
              : noPostInfos[1][1]
          }"
        >
        </ng-container>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label i18n>
          <mat-icon class="tab-icon">schedule</mat-icon>
          預約排程 ({{ scheduledPostCount$ | async }})
        </ng-template>
        <ng-container
          [ngTemplateOutlet]="postList"
          [ngTemplateOutletContext]="{
            list: scheduledPosts$ | async,
            noPostInfo: searchKey
              ? noPostInfos[2][0]
              : noPostInfos[2][1]
          }"
        >
        </ng-container>
      </mat-tab>
    </mat-tab-group>

    <div class="fw" *ngIf="selectedTabIndex === 1">
      <mat-paginator
        class="fr"
        [hidePageSize]="true"
        [length]="publicPostCount$ | async"
        pageSize="10"
        [pageIndex]="publicPageIndex"
        (page)="publicPageIndex = $event.pageIndex"
      >
      </mat-paginator>
    </div>

    <div class="fw" *ngIf="selectedTabIndex === 2">
      <mat-paginator
        class="fr"
        [hidePageSize]="true"
        [length]="scheduledPostCount$ | async"
        pageSize="10"
        [pageIndex]="scheduledPageIndex"
        (page)="scheduledPageIndex = $event.pageIndex"
      >
      </mat-paginator>
    </div>

    <div class="bottom-bar fw tar">
      <mat-icon
        *ngIf="botsName$ | async; let str"
        class="bot-names fl curd"
        matTooltip="{{ str }} 的貼文清單"
        i18n-matTooltip="{{ str }} 的貼文清單"
        >info</mat-icon
      >
      <button
        mat-raised-button
        color="primary"
        (click)="updateList()"
        [disabled]="isUpdated"
      >
        <ng-container *ngIf="isUpdated;else notUpdate" i18n
          >已經更新</ng-container
        >
        <ng-template #notUpdate i18n>更新貼文</ng-template>
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="close()"
        cdkFocusInitial
        i18n
      >
        關閉
      </button>
    </div>
  </div>

  <ng-template #postList let-list="list" let-noPostInfo="noPostInfo">
    <div *ngIf="list && !list.length" class="no-post-info fw tac">
      {{ noPostInfo }}
    </div>
    <ng-container *ngFor="let post of list">
      <div
        class="fw post curp"
        (click)="selectPost(post)"
        fxLayout="row"
        fxLayoutAlign="start start"
      >
        <div class="img-container posr ovh">
          <img
            class="posac fw"
            *ngIf="post?.picture"
            [src]="post?.picture"
            (error)="post.picture = null"
          />
          <img
            class="posac fw fake"
            *ngIf="!post?.picture"
            src="
              https://gosubarpublic.blob.core.windows.net/static/20190521140310_3_35_ad364489-1c23-4e88-9913-ed31a17c6c02.png
            "
          />
        </div>
        <div fxFlex="1 1 100%" class="fh ovh">
          <div *ngIf="post.name || post.story" class="title omit">
            {{ post.story || post.name }}
          </div>

          <div *ngIf="post.isDeleted" class="deleted-tag"></div>

          <div
            class="content"
            [innerHTML]="
              post?.message || '' | facebookHashTagHtml | wrapHtml
            "
            [class.no-title]="!post.name && !post.story"
          ></div>
          <div class="menu-bar fw">
            <mat-icon
              class="tool curd"
              (click)="stopClickEvent($event)"
              matTooltip="{{ post.postId }}"
              >info</mat-icon
            >
            <mat-icon
              class="tool curp"
              (click)="openPostTab(post, $event)"
              matTooltip="分頁開啟此貼文"
              i18n-matTooltip="分頁開啟此貼文"
              >remove_red_eye</mat-icon
            >
            <mat-icon
              class="tool curp"
              *ngIf="post.link"
              (click)="openPostLink(post, $event)"
              matTooltip="開啟貼文夾帶的連結 {{ post.link }}"
              i18n-matTooltip="開啟貼文夾帶的連結 {{ post.link }}"
              >link</mat-icon
            >

            <mat-icon
              class="tool curd"
              *ngIf="!post.isPublished"
              (click)="stopClickEvent($event)"
              matTooltip="預約發布: {{
                post.scheduledPublishTime | moment: 'YYYY-MM-DD HH:mm:ss'
              }}"
              i18n-matTooltip="預約發布: {{
                post.scheduledPublishTime | moment: 'YYYY-MM-DD HH:mm:ss'
              }}"
              >av_timer</mat-icon
            >

            <mat-icon class="status"
              >{{ isSelected(post) ? "check_circle" : "panorama_fish_eye"
              }}</mat-icon
            >
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>
  </ng-template>
</div>
