import {
  APP_BASE_HREF,
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButton } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Content } from '@ay/bot';
import range from 'lodash/range';
import {
  Subject,
  combineLatest,
  firstValueFrom,
  from,
  fromEvent,
  merge,
  of,
} from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import urlJoin from 'url-join';
import { LandingBrandComponent } from '../../../components/landing-brand/landing-brand.component';
import { LoadingComponent } from '../../../components/loading/loading.component';
import { TemplateService } from '../../../service/template.service';
import { MessageIconPipe } from '../../message-icon.pipe';
import { ButtonFactory } from '../card/button.factory';
import { Card } from '../card/card.class';
import { CardComponent } from '../card/card.component';

const CARD_WIDTH = 225;
const GAP = 16;

@Component({
  selector: 'ms-card-template-picker-dialog',
  templateUrl: './template-picker.dialog.html',
  styleUrls: ['./template-picker.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatIcon,
    FlexModule,
    NgFor,
    CardComponent,
    NgClass,
    ExtendedModule,
    MatButton,
    LandingBrandComponent,
    LoadingComponent,
    AsyncPipe,
    MessageIconPipe,
  ],
})
export class CardTemplatePickerDialog implements OnDestroy {
  public cards$ = from(this._templateService.getList()).pipe(
    map((templates) =>
      templates.filter((template) => template.type === 'Card'),
    ),
    map((templates) =>
      templates.map((template) => {
        try {
          const data = JSON.parse(template.data);
          const card = new Card();
          card.image = template.picture;
          card.title = data.title;
          card.content = data.content;
          card.buttons = data.buttons
            .filter((button) => button.type !== 'none' && button.type !== '')
            .map((button) => ButtonFactory.fromButton(button));
          card.templateName = template.name;
          card.template = new Content.Template(
            'card',
            template.id,
            template.properties as any,
          );
          return card;
        } catch (error) {
          console.error(error);
          return null;
        }
      }),
    ),
  );

  public selected: number[] = [];

  private readonly _destroy$ = new Subject<void>();

  public width$ = merge(of(0), fromEvent(window, 'resize'))
    .pipe(
      takeUntil(this._destroy$),
      map((_) => this._elementRef.nativeElement.clientWidth),
    )
    .pipe(shareReplay(1));

  public empties$ = combineLatest([this.cards$, this.width$]).pipe(
    map(([cards, width]) => {
      let countPerRow = ~~(width / (CARD_WIDTH + GAP));
      let remaining = cards.length % countPerRow;
      return range(0, countPerRow - remaining);
    }),
  );

  public count: number = 0;

  public constructor(
    private readonly _matDialogRef: MatDialogRef<
      CardTemplatePickerDialog,
      Card[]
    >,
    private readonly _templateService: TemplateService,
    private readonly _elementRef: ElementRef<HTMLDivElement>,
    @Inject(APP_BASE_HREF)
    private readonly _baseHref: string,
  ) {}

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public async submit() {
    let cards = await firstValueFrom(this.cards$);
    cards = cards.filter((card, i) => this.selected[i]);
    this._matDialogRef.close(cards);
  }

  public toggleCard(index: number) {
    if (this.selected[index]) {
      let current = this.selected[index];
      for (let i in this.selected) {
        if (this.selected[i] > current) this.selected[i]--;
      }
      this.selected[index] = null;
    } else {
      this.selected[index] = this.count + 1;
    }
    this.count = this.selected.filter((v) => v).length;
  }

  public createTemplate() {
    const url = urlJoin(this._baseHref, '/imaging');
    window.open(url);
    this._matDialogRef.close();
  }
}
