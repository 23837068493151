import { Component, ElementRef } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import {
  MatFormField,
  MatHint,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { FormComponent } from '../form.component';
import { ExecuteFTPNode } from './class';

@Component({
  selector: 'flow-execute-ftp-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatCheckbox,
    FormsModule,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatInput,
    MatSuffix,
    MatHint,
    LegacyAppearanceDirective,
  ],
})
export class ExecuteFTPFormComponent extends FormComponent<ExecuteFTPNode> {
  public methods: string[] = ['ftp', 'sftp'];
  public responseTypes: string[] = ['json', 'csv', 'tsv', 'string', 'rss'];
  public encodings: string[] = ['utf-8', 'big5'];

  public notText = $localize`非`;

  public constructor(public elementRef: ElementRef) {
    super(elementRef);
  }
}
