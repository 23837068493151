import { RandomNode } from '../node/random';
import { SwitchNode } from '../node/switch';
import { Command } from './command';

export class OrderConditionCommand extends Command {
  public constructor(
    private readonly _node: SwitchNode | RandomNode,
    private readonly _condition: any,
    private readonly _targetCondition: any,
  ) {
    super();
    this._node.conditions.indexOf(_condition);
  }

  public async do() {
    let index = this._node.conditions.indexOf(this._condition);
    let tar = this._node.conditions.indexOf(this._targetCondition);
    if (index == -1 || tar == -1 || index == tar) return;
    this._node.conditions[tar] = this._condition;
    this._node.conditions[index] = this._targetCondition;

    this._node.nodeComponent.updateNearbyConnection();
  }

  public async undo() {
    this.do();
  }
}
