import { RuleOp } from '@ay-gosu/types';
import {
  ensurePropertyIsArray,
  ensureTheElementIsWhitelisted,
  ensureTheElementsAreWhitelisted,
} from '@ay/util';
import { delay } from 'bluebird';
import {
  StatusMap,
  StatusPipe,
  StatusType,
  StatusTypes,
} from '../../../../pipe/status.pipe';
import { FromJsonOption, Rule } from '../rule';
import { ruleJoin } from '../ruleJoin';

export class StatusRule extends Rule<StatusType[]> {
  public static op = ['IN_LIST'] as RuleOp[];

  public constructor(...value: StatusType[]) {
    super('STATUS');
    this.value = value;
  }

  public async afterTypeChanged(isUserEvent = false) {
    ensurePropertyIsArray(this, 'value');
    this.value = ensureTheElementsAreWhitelisted(this.value, ...StatusTypes);
    this.op = ensureTheElementIsWhitelisted(this.op, ...StatusRule.op);
    if (isUserEvent) {
      await delay(1);
      this.component.status.statusTrigger.open();
    }
  }

  public toRuleObject(json: any = {}) {
    super.toRuleObject(json);
    json.value = this.value.join(',');
    return json;
  }

  public async fromJSON(json: any, option: FromJsonOption) {
    await super.fromJSON(json, option);
    this.value = json.value.split(',').filter((row: string) => row != '');
  }

  public similar(keyword: string): boolean {
    return this.value.reduce(
      (prev, status) => prev || StatusMap[status].indexOf(keyword) !== -1,
      false,
    );
  }

  public toString(): string {
    let pipe = new StatusPipe();
    let status = this.value.map((status) => pipe.transform(status));
    return $localize`狀態是${ruleJoin(status)}`;
  }

  public static schema: string = 'status';

  public toShortCode(): string {
    return `${this.value.join(',')}`;
  }

  public static async fromShortCode(code: string, option: FromJsonOption) {
    let type = ensureTheElementsAreWhitelisted(code.split(','), ...StatusTypes);
    let rule = new StatusRule(...(type as StatusType[]));
    rule.op = 'IN_LIST';
    return rule;
  }

  public checkError(): boolean {
    if (this.value && this.value.length === 0) {
      return true;
    }
    return false;
  }
}
