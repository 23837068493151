<div class="container flex-col full posr ovh">
  <ng-container *ngIf="noChatbot$ | async">
    <p i18n>請先登入語智網進行語意資料庫同步</p>
    <div class="fw tac re-login-button">
      <button
        mat-raised-button
        color="primary"
        (click)="openLoginDialog()"
        i18n
      >
        登入
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="(noChatbot$ | async) === false">
    <div class="fw">
      <mat-form-field class="chatbot-selector" legacyAppearance>
        <mat-label i18n>語意資料庫</mat-label>
        <mat-select [(ngModel)]="lingtelliId">
          <mat-option
            *ngFor="let bot of lingtelliService.list$ | async"
            [value]="bot.id"
          >
            {{ bot.chatbotName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        class="add-button fr"
        (click)="openLoginDialog()"
        i18n
      >
        新增語意資料庫
      </button>
    </div>
    <div class="fw" *ngIf="verifyAccessToken$ | async">
      <mat-form-field class="fw" legacyAppearance>
        <mat-label i18n>搜尋</mat-label>
        <input matInput [(ngModel)]="keyword" />
      </mat-form-field>
    </div>

    <div class="fw f1 ovya">
      <ng-container *ngIf="(verifyAccessToken$ | async) === false">
        <p class="not-login-info tac" i18n>
          請重新登入語智網進行語意資料庫同步或選擇其他語意資料庫
        </p>
        <div class="tac re-login-button">
          <button
            mat-raised-button
            color="primary"
            (click)="openLoginDialog()"
            i18n
          >
            登入
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="verifyAccessToken$ | async">
        <mat-accordion>
          <mat-expansion-panel
            *ngFor="let group of groups$ | async"
            [expanded]="group?.group === expandedId"
            (opened)="expandedId = group?.group"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ (group?.question)[0]?.content | ensureTextLength: 20 }}
              </mat-panel-title>
              <mat-panel-description i18n>
                {{ group?.question?.length }} 個問法
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-list>
              <mat-list-item *ngFor="let question of group?.question">
                <div class="fw flex-row">
                  <div
                    class="f1 question-content omit"
                    [title]="question?.content"
                  >
                    {{ question?.content }}
                  </div>
                  <button
                    mat-button
                    class="fr"
                    color="primary"
                    #updateQuestionButton
                    (click)="
                      updateQuestion(
                        updateQuestionButton._elementRef,
                        question.content,
                        question.id
                      )
                    "
                    i18n
                  >
                    編輯
                  </button>
                  <button
                    mat-button
                    class="fr"
                    color="warn"
                    (click)="deleteQuestion(question.id)"
                    [disabled]="group?.question?.length < 2"
                    i18n
                  >
                    刪除
                  </button>
                </div></mat-list-item
              >
            </mat-list>
            <mat-action-row>
              <button
                mat-button
                color="warn"
                (click)="deleteGroup(group?.group)"
                i18n
              >
                刪除問題群組
              </button>
              <button
                mat-button
                color="primary"
                #createQuestionButton
                (click)="
                  createQuestion(group?.group, createQuestionButton._elementRef)
                "
                i18n
              >
                新增問題
              </button>
              <button mat-button (click)="selectGroup(group)" i18n>
                選擇問題群組
              </button>
            </mat-action-row>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
    </div>
    <div class="bottom fw" *ngIf="verifyAccessToken$ | async">
      <button
        mat-raised-button
        color="primary"
        class="create-group-button fl"
        *ngIf="lingtelliId$ | async"
        #createGroupButton
        (click)="createGroup(createGroupButton._elementRef)"
        i18n
      >
        建立問題群組
      </button>
      <button
        mat-raised-button
        color="accent"
        class="create-group-button fl"
        *ngIf="lingtelliId$ | async"
        (click)="trainChatbot()"
        i18n
      >
        訓練語意資料庫
      </button>
      <mat-paginator
        #paginator
        [length]="total$ | async"
        [hidePageSize]="true"
        [pageSize]="10"
        (page)="page$.next($event.pageIndex + 1)"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </ng-container>
</div>
