import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Message } from '../base/base.message';
import { PackageAffectedComponent } from '../package-affected.component';
import { SubmenuComponent } from '../submenu/submenu.component';
import { MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatProgressBar } from '@angular/material/progress-bar';
import { QuickRepliesComponent } from '../quick-replies/quick-replies.component';
import { MatIcon } from '@angular/material/icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FactoryComponent } from '../factory/factory.component';
import { MatContextMenuDirective } from '../../material/context-menu';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'ms-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['package'],
    exportAs: 'msPreview',
    standalone: true,
    imports: [
        NgIf,
        CdkDropList,
        MatContextMenuDirective,
        NgFor,
        CdkDrag,
        FactoryComponent,
        NgClass,
        ExtendedModule,
        MatIcon,
        QuickRepliesComponent,
        MatProgressBar,
        MatMenu,
        MatMenuItem,
        SubmenuComponent,
    ],
})
export class PreviewComponent extends PackageAffectedComponent {
  public selected: Message;

  @Input()
  public mode: 'READ' | 'EDIT' = 'EDIT';

  @ViewChild('menu')
  public menu: ElementRef;

  public position: number = 0;

  @ViewChild('dragUploadInput')
  public dragUploadInput: ElementRef<HTMLInputElement>;

  public get selectedIndex() {
    if (!this.package) return -1;
    return this.package.messages.indexOf(this.selected);
  }

  public get isFirstMessage() {
    return this.selectedIndex === 0;
  }

  public get isLatestMessage() {
    if (!this.package) return false;
    return this.selectedIndex === this.package.messages.length - 1;
  }

  public constructor(protected changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  public messageDropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.package.messages,
      event.previousIndex,
      event.currentIndex,
    );
    this.package.changed();
  }

  public async delete() {
    this.package.messages.splice(this.selectedIndex, 1);
    this.package.changed();
  }

  public swapUpward() {
    let index = this.selectedIndex;
    this.package.swap(index, index - 1);
  }

  public swapDownward() {
    let index = this.selectedIndex;
    this.package.swap(index, index + 1);
  }
}
