import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { firstValueFrom, merge } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatContextMenuDirective } from '../../material/context-menu';
import { BaseComponent } from '../base/base.component';
import { FlexEditorComponent } from './editor/editor.component';
import { FlexEditorService } from './editor/editor.service';
import { FlexMessage } from './flex.message';
import { FlexPreviewComponent } from './preview/preview.component';
import { RegularizedFlex } from './regularized-flex.class';

@Component({
  selector: 'ms-flex',
  templateUrl: './flex.component.html',
  styleUrls: ['./flex.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['package', 'mode', 'message'],
  standalone: true,
  imports: [
    MatContextMenuDirective,
    FlexPreviewComponent,
    MatMenu,
    MatMenuItem,
    MatIcon,
    MatDivider,
    AsyncPipe,
  ],
})
export class FlexComponent extends BaseComponent<FlexMessage> {
  public regularizedFlex$ = merge(
    this.message$.pipe(map((value) => ({ change: 'message', value }))),
    this.flexEditorService.selected$.pipe(
      map((value) => ({ change: 'selected', value })),
    ),
  ).pipe(
    map((data) => {
      if (data.change === 'message') {
        this.flexEditorService.regularizedFlex =
          RegularizedFlex.fromContentFlex(data.value.content);
        this.flexEditorService.package = this.package;
      }

      return this.flexEditorService.regularizedFlex;
    }),
    shareReplay(1),
  );

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _matConnectedDialog: MatConnectedDialog,
    public flexEditorService: FlexEditorService,
  ) {
    super(changeDetectorRef);
  }

  public async editFlex() {
    const regularizedFlex = await firstValueFrom(this.regularizedFlex$);

    const response = await firstValueFrom(
      this._matConnectedDialog
        .open(FlexEditorComponent, {
          width: '95vw',
          height: '95vh',
          data: { flex: regularizedFlex, package: this.package },
          disableClose: true,
        })
        .afterClosed(),
    );

    if (response) {
      const recordId = this.message.content.recordId;
      this.message.content = response.toContentFlex();
      this.message.content.recordId = recordId;
      this.message.changed();
    }
    this.message$.next(this.message);
  }
}
