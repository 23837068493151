import { Injectable } from '@angular/core';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { firstValueFrom } from 'rxjs';
import { Package } from '../package.class';
import { FlexEditorComponent } from './editor/editor.component';
import { FlexMessage } from './flex.message';
import { FlexPickerComponent } from './picker/picker.component';
import { RegularizedFlex } from './regularized-flex.class';

@Injectable({
  providedIn: 'root',
})
export class FlexMessageService {
  public constructor(
    private readonly _matConnectedDialog: MatConnectedDialog,
  ) {}

  public async createFromEditor(pkg: Package): Promise<FlexMessage> {
    const flex = await firstValueFrom(
      this._matConnectedDialog.open(FlexPickerComponent).afterClosed(),
    );

    if (!flex) return;

    const regularizedFlex = RegularizedFlex.fromContentFlex(flex);
    const response = await firstValueFrom(
      this._matConnectedDialog
        .open(FlexEditorComponent, {
          width: '95vw',
          height: '95vh',
          data: { flex: regularizedFlex, package: pkg },
          disableClose: true,
        })
        .afterClosed(),
    );

    if (!response) return;

    const content = response.toContentFlex();
    const message = new FlexMessage();
    await message.loadFromContent(content);
    return message;
  }
}
