import { GosuValidate } from '../../../../components/gosu-validator/gosu-validator.class';
import { Node } from '../class';

export type Target = 'profile' | 'bot' | 'company';

export type CommandItem = 'tag' | 'property';

export type CommandAction =
  | 'remove'
  | 'set'
  | 'clear'
  | 'add'
  | 'plus'
  | 'minus';

export interface Command {
  item: CommandItem;
  action: CommandAction;
  tagIds: any; // string | number[]
  key: string;
  value: string;
  reason: string;
}

export class SetterNode extends Node {
  public icon = 'label';

  public target: Target = 'profile';

  public targetId: string;

  public action: CommandAction = 'set';

  public item: CommandItem;

  public commands: Command[] = [];

  /** property / variable key or tag id */
  public key: string;

  /** property / variable key or tag id */
  public tagIds: string | number[] = [];

  /** property / variable value */
  public value: string;

  /** variable 存活時間 */
  public variableTime: number;

  public isAsync = false;

  public processed = false;

  public readonly extraProperties = [
    'target',
    'isAsync',
    'targetId',
    'commands',
    'action',
    'item',
    'tagIds',
    'key',
    'value',
    'variableTime',
    'processed',
  ];

  public checkError(): void {
    this.hasError = false;
    const isCheckError = window.localStorage.getItem('isDebug') === 'true';

    if (isCheckError) {
      if (this.commands.length === 0) {
        this.hasError = true;
      }
      const duplicateKeys = this.commands
        .map((command) => command.key)
        .filter((key, index, keys) => keys.indexOf(key) !== index);

      this.commands.forEach((command) => {
        if (this._checkError(command, duplicateKeys)) {
          this.hasError = true;
        }
      });
    }
  }

  private _checkError = (command: Command, keys: string[]) => {
    if (command.item === 'tag' && GosuValidate.arrayRequired(command.tagIds)) {
      return true;
    }
    if (command.item === 'property' && GosuValidate.required(command.key)) {
      return true;
    }
    if (
      command.item === 'property' &&
      GosuValidate.bannedOptions(command.key, keys)
    ) {
      return true;
    }
    const noCheckValue = ['clear'];
    if (
      command.item === 'property' &&
      command.key &&
      !noCheckValue.includes(command.action) &&
      GosuValidate.required(command.value)
    ) {
      return true;
    }
    return false;
  };
}
