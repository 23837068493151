import { Injectable } from '@angular/core';
import { TemplateDto, TemplatePropertyDto } from '@ay-gosu/server-shared';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { Content } from '@ay/bot';
import { firstValueFrom } from 'rxjs';
import { ImagePickerDialog } from '../../dialog/image';
import { ImageMessage } from './image.message';
import { ImageTemplateEditorDialog } from './template-editor/template-editor.component';
import { ImageTemplatePickerDialog } from './template-picker/template-picker.component';

@Injectable({
  providedIn: 'root',
})
export class ImageMessageService {
  public constructor(protected matConnectedDialog: MatConnectedDialog) {}

  public async createFromUploader(): Promise<ImageMessage> {
    const dialogRef = this.matConnectedDialog.open(ImagePickerDialog, {
      data: { url: '', useCode: true },
    });

    const src: string = await firstValueFrom(dialogRef.afterClosed());
    if (src == null) return null;

    const content = new Content.Image(src, src, $localize`一張圖片`);
    const message = new ImageMessage();
    await message.loadFromContent(content);
    return message;
  }

  public async createFromTemplatePicker(): Promise<ImageMessage> {
    const dialogRef = this.matConnectedDialog.open(ImageTemplatePickerDialog);

    const template: TemplateDto = await firstValueFrom(dialogRef.afterClosed());

    if (template == null) return null;

    const content = new Content.Template(
      'image',
      template.id,
      this.convertTemplateProperties(template.properties),
    );
    const message = new ImageMessage();
    await message.loadFromContent(content);
    return message;
  }

  public async openTemplateEditor(message: ImageMessage) {
    this.matConnectedDialog.open(ImageTemplateEditorDialog, {
      data: message,
    });
  }

  protected convertTemplateProperties(
    properties: TemplatePropertyDto[],
  ): { key: string; name: string; default: string }[] {
    return properties.map((property) => ({
      key: property.key,
      name: property.name,
      default: property.default,
    }));
  }
}
