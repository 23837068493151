import { FocusMonitor } from '@angular/cdk/a11y';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem } from '@angular/material/menu';
import { TagDto } from '@ay-gosu/server-shared';
import { ignoreError } from '@ay/util';
import { ReplaySubject, Subject, combineLatest, firstValueFrom } from 'rxjs';
import { shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { EmptyResponseError } from '../../../util/empty-response-error';
import { TagDialog } from '../../dialog/tag';
import { MatContextMenuDirective } from '../../material/context-menu';
import { TagService, TagTargetType } from '../../service/tag.service';

export type TagTarget = 'Profile' | 'Package' | 'Keyword';

@Component({
  selector: 'gosu-tag-tree-picker',
  templateUrl: './tag-tree-picker.component.html',
  styleUrls: ['./tag-tree-picker.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: TagTreePickerComponent },
  ],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatContextMenuDirective,
    MatIconButton,
    MatIcon,
    MatButton,
    MatMenu,
    MatMenuItem,
    AsyncPipe,
  ],
})
export class TagTreePickerComponent
  implements MatFormFieldControl<number[]>, ControlValueAccessor, OnDestroy
{
  @Input()
  public title = $localize`貼上標籤`;

  @Input()
  public target: TagTargetType;

  //#region Two-way binding data
  private _value: number[];

  @Output()
  public valueChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  public value$ = new ReplaySubject<number[]>(1);

  public get value(): number[] {
    return this._value;
  }

  @Input()
  public set value(data: number[]) {
    if (this._value === data) return;
    if ((data as any) === '') data = [];
    if (!(data instanceof Array)) data = [data];
    data = data || [];
    this._value = data;
    this.valueChange.emit(this._value);
    this.value$.next(data);
  }
  //#endregion Two-way binding data

  public tags$ = combineLatest([this.value$, this._tagService.all$]).pipe(
    switchMap(async ([tagIds, all]) =>
      tagIds
        .map((id) => all.find((tag) => tag.id === id))
        .filter((tag) => tag != null),
    ),
    shareReplay(1),
  );

  public selected: TagDto;

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    @Optional()
    @Self()
    public ngControl: NgControl,
    private _tagService: TagService,
    private _tagDialog: TagDialog,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.stateChanges.next();
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.valueChange.pipe(takeUntil(this._destroy$)).subscribe((e) => fn(e));
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  public stateChanges = new Subject<void>();

  public static nextId = 0;

  @HostBinding()
  public id = `gosu-tree-picker-${TagTreePickerComponent.nextId++}`;

  public placeholder: string;
  public focused: boolean;
  public empty: boolean;
  public shouldLabelFloat: boolean;
  public required: boolean;
  public disabled: boolean;
  public errorState: boolean;
  public controlType?: string;
  public autofilled?: boolean;

  @HostBinding('attr.aria-describedby')
  public describedBy = '';

  public setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  public onContainerClick(event: MouseEvent): void {}

  public async addTag(button: MatButton) {
    const elementRef = button._elementRef;

    const tags = await firstValueFrom(this.tags$);

    await this._tagDialog
      .add(this.target, { tags, elementRef })
      .catch(ignoreError(EmptyResponseError));

    this.value = tags.map((tag) => tag.id);
  }

  public delete(tag: TagDto) {
    let index = this.value.indexOf(tag.id);
    if (index === -1) return;
    this.value.splice(index, 1);
    this.value = this.value.slice();
    this.value$.next(this.value);
    this.valueChange.emit(this.value);
  }
}
