import * as uuid from 'uuid';

export type ActionType =
  | 'uri'
  | 'message'
  | 'next'
  | 'add-through-promotion'
  | 'share-message-through-promotion';

export namespace Area {
  export type Any =
    | Uri
    | Message
    | Next
    | AddThroughPromotion
    | ShareMessageThroughPromotion;

  export class Base {
    // 在轉化短網址時所需要的資訊，為了統計用途
    public areaId?: number;
    public posterId?: number;
    public recordId?: number;
    public packageId?: number;
    public isSelected?: boolean;

    public constructor(
      public type: ActionType,
      public x: number,
      public y: number,
      public width: number,
      public height: number,
    ) {}
  }

  export class Uri extends Base {
    public type: 'uri';

    public constructor(
      public content: string,
      public x: number,
      public y: number,
      public width: number,
      public height: number,
    ) {
      super('uri', x, y, width, height);
    }
  }
  export class Message extends Base {
    public type: 'message';

    public constructor(
      public content: string,
      public x: number,
      public y: number,
      public width: number,
      public height: number,
    ) {
      super('message', x, y, width, height);
    }
  }
  export class Next extends Base {
    public type: 'next';

    public constructor(
      public x: number,
      public y: number,
      public width: number,
      public height: number,
      // 用來識別每一個 Next 按鈕，如果不給值將會是 UUID
      public data: string = uuid.v4(),
    ) {
      super('next', x, y, width, height);
    }
  }

  export class AddThroughPromotion extends Base {
    public type: 'add-through-promotion';

    public constructor(
      public x: number,
      public y: number,
      public width: number,
      public height: number,
    ) {
      super('add-through-promotion', x, y, width, height);
    }
  }

  export class ShareMessageThroughPromotion extends Base {
    public type: 'share-message-through-promotion';

    public constructor(
      public promotionId: number,
      public x: number,
      public y: number,
      public width: number,
      public height: number,
    ) {
      super('share-message-through-promotion', x, y, width, height);
    }
  }
}
