<ng-container *ngIf="package">
  <ng-container *ngFor="let item of package.toolbars">
    <ng-container [ngSwitch]="item.mode">
      <ng-template ngSwitchCase="CLICK">
        <button
          mat-icon-button
          [matTooltip]="item.tooltip"
          (click)="package.insert(item.onClick(package))"
        >
          <mat-icon>{{ item.icon }}</mat-icon>
        </button>
      </ng-template>

      <ng-template ngSwitchCase="UPLOAD_FILE">
        <button
          mat-icon-button
          (file)="package.insert(item.onUpload($event))"
          [accept]="item.accept"
          [matTooltip]="item.label + ' - ' + item.tooltip"
          matTooltipPosition="after"
        >
          <mat-icon>{{ item.icon }}</mat-icon>
        </button>
      </ng-template>

      <ng-template ngSwitchCase="SUBMENU">
        <button
          mat-icon-button
          [matTooltip]="item.tooltip"
          [matMenuTriggerFor]="menu.matMenu"
        >
          <mat-icon>{{ item.icon }}</mat-icon>
        </button>
        <ms-submenu
          #menu
          [toolbars]="item.submenu"
          [package]="package"
        ></ms-submenu>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
