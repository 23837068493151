<svg:rect
  [attr.width]="width"
  [attr.height]="height"
  [attr.rx]="em"
  [attr.fill]="node.color"
  [attr.stroke]="node.borderColor"
></svg:rect>

<svg:text
  class="mi"
  [attr.x]="0.5 * em"
  [attr.y]="0.5 * em"
  [style.font-size.px]="em"
  alignment-baseline="text-before-edge"
  text-anchor="start"
  [attr.fill]="node.frontColor"
>
  {{ node.icon }}
</svg:text>

<svg:text
  class="name"
  [attr.x]="2 * em"
  [attr.y]="0.25 * em"
  [style.font-size.px]="em"
  alignment-baseline="text-before-edge"
  text-anchor="start"
  [attr.fill]="node.frontColor"
>
  {{ node?.display }}
</svg:text>

<svg:g
  class="junction"
  [index]="0"
  [dx]="0"
  [dy]="1 * em"
  type="input"
  [color]="node.borderColor"
  [node]="node"
></svg:g>

<svg:g class="conditions">
  <svg:g
    *ngFor="let condition of node.conditions; let conditionIndex = index"
    class="condition"
    [index]="conditionIndex + 1"
    [text]="condition.name"
    [color]="condition.color"
  ></svg:g>
</svg:g>
