import { Injectable } from '@angular/core';
import { Flex } from '@ay/bot';

export interface FlexCheckResult {
  valid: boolean;
  message?: string;
}

@Injectable({ providedIn: 'root' })
export class FlexCheckService {
  public checkFlexMessage(content: Flex.Message): FlexCheckResult {
    console.log(content);
    if (content.type !== 'flex') {
      return { valid: false, message: 'Invalid type: Flex.type' };
    }
    if (content.altText && typeof content.altText !== 'string') {
      return { valid: false, message: 'Invalid type: Flex.altText' };
    }
    if (content.contents && typeof content.contents !== 'object') {
      return { valid: false, message: 'Invalid type: Flex.contents' };
    }
    if (
      content.contents.type !== 'bubble' &&
      content.contents.type !== 'carousel'
    ) {
      return { valid: false, message: 'Invalid type: Flex.contents.type' };
    }
    if (content.contents.type === 'bubble') {
      const result = this.checkFlexContentBubble(content.contents);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }
    if (content.contents.type === 'carousel') {
      const result = this.checkFlexContentCarousel(content.contents);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }
    return { valid: true };
  }

  public checkFlexContentBubble(content: Flex.Content.Bubble): FlexCheckResult {
    if (content.type !== 'bubble') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Content.Bubble.type',
      };
    }

    if (
      content.size &&
      content.size !== 'nano' &&
      content.size !== 'micro' &&
      content.size !== 'kilo' &&
      content.size !== 'mega' &&
      content.size !== 'giga'
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Content.Bubble.size',
      };
    }

    if (
      content.direction &&
      content.direction !== 'ltr' &&
      content.direction !== 'rtl'
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Content.Bubble.direction',
      };
    }

    if (content.header) {
      const result = this.checkFlexBlockBox(content.header);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.hero && content.hero.type === 'box') {
      const result = this.checkFlexBlockBox(content.hero);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.hero && content.hero.type === 'image') {
      const result = this.checkFlexBlockImage(content.hero);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.hero && content.hero.type === 'video') {
      const result = this.checkFlexBlockVideo(content.hero);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.body) {
      // Flex 的 body 支援透過 θ 開頭的字串類型，來嵌入變數，所以如果是字串就不檢查其內容
      if (typeof content.body !== 'string') {
        const result = this.checkFlexBlockBox(content.body);
        if (!result.valid) {
          return { valid: false, message: result.message };
        }
      }
    }

    if (content.footer) {
      const result = this.checkFlexBlockBox(content.footer);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.styles) {
      const result = this.checkFlexStyleBubble(content.styles);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.action) {
      const result = this.checkFlexActionObject(content.action);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.hero && content.hero.type === 'video') {
      if (
        content?.size &&
        content?.size !== 'kilo' &&
        content?.size !== 'mega' &&
        content?.size !== 'giga'
      ) {
        return {
          valid: false,
          message:
            'Invalid type: Video is allowed only for kilo, mega, giga bubble.',
        };
      }
    }

    return { valid: true };
  }

  public checkFlexContentCarousel(
    content: Flex.Content.Carousel,
  ): FlexCheckResult {
    if (content.type !== 'carousel') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Content.Carousel.type',
      };
    }

    if (content.contents) {
      if (!Array.isArray(content.contents)) {
        return {
          valid: false,
          message: 'Invalid type: Flex.Content.Carousel.contents',
        };
      }
      const hasVideoInCarousel = content.contents.some(
        (item) => item.hero?.type === 'video',
      );
      if (hasVideoInCarousel) {
        return {
          valid: false,
          message: 'Invalid type: Video cannot be used in carousel',
        };
      }
      for (let item of content.contents) {
        if (!item || typeof item !== 'object')
          return {
            valid: false,
            message: 'Invalid type: Flex.Content.Carousel.contents',
          };

        const result = this.checkFlexContentBubble(item);
        if (!result.valid) {
          return { valid: false, message: result.message };
        }
      }
    }

    return { valid: true };
  }

  public checkFlexBlockBox(content: Flex.Block.Box): FlexCheckResult {
    if (content.type !== 'box') {
      return { valid: false, message: 'Invalid type: Flex.Block.Box.type' };
    }

    if (content.contents) {
      if (!Array.isArray(content.contents)) {
        return {
          valid: false,
          message: 'Invalid type: Flex.Block.Box.contents',
        };
      }

      for (let item of content.contents) {
        if (!item || typeof item !== 'object')
          return {
            valid: false,
            message: 'Invalid type: Flex.Block.Box.contents',
          };

        if (item.type === 'box') {
          const result = this.checkFlexBlockBox(item);
          if (!result.valid) {
            return { valid: false, message: result.message };
          }
        }

        if (item.type === 'button') {
          const result = this.checkFlexBlockButton(item);
          if (!result.valid) {
            return { valid: false, message: result.message };
          }
        }

        if (item.type === 'image') {
          const result = this.checkFlexBlockImage(item);
          if (!result.valid) {
            return { valid: false, message: result.message };
          }
        }

        if (item.type === 'icon') {
          const result = this.checkFlexBlockIcon(item);
          if (!result.valid) {
            return { valid: false, message: result.message };
          }
        }

        if (item.type === 'text') {
          const result = this.checkFlexBlockText(item);
          if (!result.valid) {
            return { valid: false, message: result.message };
          }
        }

        if (item.type === 'separator') {
          const result = this.checkFlexBlockSeparator(item);
          if (!result.valid) {
            return { valid: false, message: result.message };
          }
        }

        if (item.type === 'filler') {
          const result = this.checkFlexBlockFiller(item);
          if (!result.valid) {
            return { valid: false, message: result.message };
          }
        }

        if (item.type === 'spacer') {
          const result = this.checkFlexBlockSpacer(item);
          if (!result.valid) {
            return { valid: false, message: result.message };
          }
        }

        if (item.type === 'span') {
          const result = this.checkFlexBlockSpan(item);
          if (!result.valid) {
            return { valid: false, message: result.message };
          }
        }
      }
    }

    if (
      content.layout &&
      content.layout !== 'horizontal' &&
      content.layout !== 'vertical' &&
      content.layout !== 'baseline'
    ) {
      return { valid: false, message: 'Invalid type: content.layout' };
    }

    if (
      content.backgroundColor &&
      typeof content.backgroundColor !== 'string'
    ) {
      return { valid: false, message: 'Invalid type: content.backgroundColor' };
    }

    if (content.borderColor && typeof content.borderColor !== 'string') {
      return { valid: false, message: 'Invalid type: content.borderColor' };
    }

    if (
      content.borderWidth &&
      content.borderWidth !== 'none' &&
      content.borderWidth !== 'light' &&
      content.borderWidth !== 'normal' &&
      content.borderWidth !== 'medium' &&
      content.borderWidth !== 'semi-bold' &&
      content.borderWidth !== 'bold' &&
      !this._isSize(content.borderWidth)
    ) {
      return { valid: false, message: 'Invalid type: content.borderWidth' };
    }
    if (
      content.cornerRadius &&
      content.cornerRadius !== 'none' &&
      content.cornerRadius !== 'xs' &&
      content.cornerRadius !== 'sm' &&
      content.cornerRadius !== 'md' &&
      content.cornerRadius !== 'lg' &&
      content.cornerRadius !== 'xl' &&
      content.cornerRadius !== 'xxl' &&
      !this._isSize(content.cornerRadius)
    ) {
      return { valid: false, message: 'Invalid type: content.cornerRadius' };
    }

    if (content.width && typeof content.width !== 'string') {
      return { valid: false, message: 'Invalid type: content.width' };
    }

    if (content.height && typeof content.height !== 'string') {
      return { valid: false, message: 'Invalid type: content.height' };
    }

    if (content.flex && typeof content.flex !== 'number') {
      return { valid: false, message: 'Invalid type: content.flex' };
    }

    if (
      content.spacing &&
      content.spacing !== 'none' &&
      content.spacing !== 'xs' &&
      content.spacing !== 'sm' &&
      content.spacing !== 'md' &&
      content.spacing !== 'lg' &&
      content.spacing !== 'xl' &&
      content.spacing !== 'xxl' &&
      !this._isSize(content.spacing)
    ) {
      return { valid: false, message: 'Invalid type: content.spacing' };
    }

    if (
      content.margin &&
      !this._isMargin(content.margin) &&
      !this._isSize(content.margin)
    ) {
      return { valid: false, message: 'Invalid type: content.margin' };
    }

    if (content.paddingAll && typeof content.paddingAll !== 'string') {
      return { valid: false, message: 'Invalid type: content.paddingAll' };
    }

    if (content.paddingTop && typeof content.paddingTop !== 'string') {
      return { valid: false, message: 'Invalid type: content.paddingTop' };
    }

    if (content.paddingBottom && typeof content.paddingBottom !== 'string') {
      return { valid: false, message: 'Invalid type: content.paddingBottom' };
    }

    if (content.paddingStart && typeof content.paddingStart !== 'string') {
      return { valid: false, message: 'Invalid type: content.paddingStart' };
    }

    if (content.paddingEnd && typeof content.paddingEnd !== 'string') {
      return { valid: false, message: 'Invalid type: content.paddingEnd' };
    }

    if (
      content.position &&
      content.position !== 'relative' &&
      content.position !== 'absolute'
    ) {
      return { valid: false, message: 'Invalid type: content.position' };
    }

    if (content.offsetTop && typeof content.offsetTop !== 'string') {
      return { valid: false, message: 'Invalid type: content.offsetTop' };
    }

    if (content.offsetBottom && typeof content.offsetBottom !== 'string') {
      return { valid: false, message: 'Invalid type: content.offsetBottom' };
    }

    if (content.offsetStart && typeof content.offsetStart !== 'string') {
      return { valid: false, message: 'Invalid type: content.offsetStart' };
    }

    if (content.offsetEnd && typeof content.offsetEnd !== 'string') {
      return { valid: false, message: 'Invalid type: content.offsetEnd' };
    }

    if (
      content.justifyContent &&
      content.justifyContent !== 'center' &&
      content.justifyContent !== 'flex-start' &&
      content.justifyContent !== 'flex-end' &&
      content.justifyContent !== 'space-between' &&
      content.justifyContent !== 'space-around' &&
      content.justifyContent !== 'space-evenly'
    ) {
      return { valid: false, message: 'Invalid type: content.justifyContent' };
    }

    if (
      content.alignItems &&
      content.alignItems !== 'center' &&
      content.alignItems !== 'flex-start' &&
      content.alignItems !== 'flex-end'
    ) {
      return { valid: false, message: 'Invalid type: content.alignItems' };
    }

    return { valid: true };
  }

  public checkFlexBlockButton(content: Flex.Block.Button): FlexCheckResult {
    if (content.type !== 'button') {
      return { valid: false, message: 'Invalid type: Flex.Block.Button.type' };
    }

    if (!content.action) {
      const result = this.checkFlexActionObject(content.action);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.flex && typeof content.flex !== 'number') {
      return { valid: false, message: 'Invalid type: Flex.Block.Button.flex' };
    }

    if (
      content.margin &&
      !this._isMargin(content.margin) &&
      !this._isSize(content.margin)
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Button.margin',
      };
    }

    if (
      content.position &&
      content.position !== 'relative' &&
      content.position !== 'absolute'
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Button.position',
      };
    }

    if (content.offsetTop && typeof content.offsetTop !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Button.offsetTop',
      };
    }

    if (content.offsetBottom && typeof content.offsetBottom !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Button.offsetBottom',
      };
    }

    if (content.offsetStart && typeof content.offsetStart !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Button.offsetStart',
      };
    }

    if (content.offsetEnd && typeof content.offsetEnd !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Button.offsetEnd',
      };
    }

    if (content.height && content.height !== 'sm' && content.height !== 'md') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Button.height',
      };
    }

    if (
      content.style &&
      content.style !== 'link' &&
      content.style !== 'primary' &&
      content.style !== 'secondary'
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Button.style' };
    }

    if (content.color && typeof content.color !== 'string') {
      return { valid: false, message: 'Invalid type: Flex.Block.Button.color' };
    }

    if (
      content.gravity &&
      content.gravity !== 'top' &&
      content.gravity !== 'bottom' &&
      content.gravity !== 'center'
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Button.gravity',
      };
    }

    return { valid: true };
  }

  public checkFlexBlockImage(content: Flex.Block.Image): FlexCheckResult {
    if (content.type !== 'image') {
      return { valid: false, message: 'Invalid type: Flex.Block.Image.type' };
    }
    if (content.url && typeof content.url !== 'string') {
      return { valid: false, message: 'Invalid type: Flex.Block.Image.url' };
    }
    if (content.flex && typeof content.flex !== 'number') {
      return { valid: false, message: 'Invalid type: Flex.Block.Image.flex' };
    }
    if (
      content.margin &&
      !this._isMargin(content.margin) &&
      !this._isSize(content.margin)
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Image.margin' };
    }
    if (
      content.position &&
      content.position !== 'relative' &&
      content.position !== 'absolute'
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Image.position',
      };
    }
    if (content.offsetTop && typeof content.offsetTop !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Image.offsetTop',
      };
    }
    if (content.offsetBottom && typeof content.offsetBottom !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Image.offsetBottom',
      };
    }
    if (content.offsetStart && typeof content.offsetStart !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Image.offsetStart',
      };
    }
    if (content.offsetEnd && typeof content.offsetEnd !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Image.offsetEnd',
      };
    }
    if (
      content.align &&
      content.align !== 'start' &&
      content.align !== 'end' &&
      content.align !== 'center'
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Image.align' };
    }
    if (
      content.gravity &&
      content.gravity !== 'top' &&
      content.gravity !== 'bottom' &&
      content.gravity !== 'center'
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Image.gravity',
      };
    }

    if (
      content.size &&
      content.size !== 'xxs' &&
      content.size !== 'xs' &&
      content.size !== 'sm' &&
      content.size !== 'md' &&
      content.size !== 'lg' &&
      content.size !== 'xl' &&
      content.size !== 'xxl' &&
      content.size !== '3xl' &&
      content.size !== '4xl' &&
      content.size !== '5xl' &&
      content.size !== 'full' &&
      !this._isSize(content.size)
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Image.size' };
    }
    if (
      content.aspectMode &&
      content.aspectMode !== 'cover' &&
      content.aspectMode !== 'fit'
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Image.aspectMode',
      };
    }
    if (content.animated && typeof content.animated !== 'boolean') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Image.animated',
      };
    }
    return { valid: true };
  }

  public checkFlexBlockVideo(content: Flex.Block.Video): FlexCheckResult {
    if (content.type !== 'video') {
      return { valid: false, message: 'Invalid type: Flex.Block.Video.type' };
    }
    if (content.url && typeof content.url !== 'string') {
      return { valid: false, message: 'Invalid type: Flex.Block.Video.url' };
    }
    if (content.previewUrl && typeof content.previewUrl !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Video.previewUrl',
      };
    }
    if (content.altContent) {
      if (content.altContent.type !== 'image') {
        return {
          valid: false,
          message: 'Invalid type: Flex.Block.Video.altContent.type',
        };
      }
      if (
        content.altContent.size &&
        content.altContent.size !== 'xxs' &&
        content.altContent.size !== 'xs' &&
        content.altContent.size !== 'sm' &&
        content.altContent.size !== 'md' &&
        content.altContent.size !== 'lg' &&
        content.altContent.size !== 'xl' &&
        content.altContent.size !== 'xxl' &&
        content.altContent.size !== '3xl' &&
        content.altContent.size !== '4xl' &&
        content.altContent.size !== '5xl' &&
        content.altContent.size !== 'full' &&
        !this._isSize(content.altContent.size)
      ) {
        return {
          valid: false,
          message: 'Invalid type: Flex.Block.Video.altContent.size',
        };
      }
      if (
        content.altContent.aspectRatio &&
        !this._isRatio(content.altContent.aspectRatio)
      ) {
        return {
          valid: false,
          message: 'Invalid type: Flex.Block.Video.altContent.aspectRatio',
        };
      }
      if (
        content.altContent.aspectMode &&
        content.altContent.aspectMode !== 'cover' &&
        content.altContent.aspectMode !== 'fit'
      ) {
        return {
          valid: false,
          message: 'Invalid type: Flex.Block.Video.altContent.aspectMode',
        };
      }

      if (typeof content.altContent.url !== 'string') {
        return {
          valid: false,
          message: 'Invalid type: Flex.Block.Video.altContent.url',
        };
      }
    }
    if (content.aspectRatio && !this._isRatio(content.aspectRatio)) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Video.aspectRatio',
      };
    }
    return {
      valid: true,
    };
  }

  public checkFlexBlockIcon(content: Flex.Block.Icon): FlexCheckResult {
    if (content.type !== 'icon') {
      return { valid: false, message: 'Invalid type: Flex.Block.Icon.type' };
    }
    if (content.url && typeof content.url !== 'string') {
      return { valid: false, message: 'Invalid type: Flex.Block.Icon.url' };
    }
    if (
      content.margin &&
      !this._isMargin(content.margin) &&
      !this._isSize(content.margin)
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Icon.margin' };
    }
    if (
      content.position &&
      content.position !== 'relative' &&
      content.position !== 'absolute'
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Icon.position',
      };
    }
    if (content.offsetTop && typeof content.offsetTop !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Icon.offsetTop',
      };
    }
    if (content.offsetBottom && typeof content.offsetBottom !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Icon.offsetBottom',
      };
    }
    if (content.offsetStart && typeof content.offsetStart !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Icon.offsetStart',
      };
    }
    if (content.offsetEnd && typeof content.offsetEnd !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Icon.offsetEnd',
      };
    }

    if (
      content.size &&
      content.size !== 'xxs' &&
      content.size !== 'xs' &&
      content.size !== 'sm' &&
      content.size !== 'md' &&
      content.size !== 'lg' &&
      content.size !== 'xl' &&
      content.size !== 'xxl' &&
      content.size !== '3xl' &&
      content.size !== '4xl' &&
      content.size !== '5xl' &&
      !this._isSize(content.size)
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Icon.size' };
    }
    if (content.aspectRatio && !this._isRatio(content.aspectRatio)) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Icon.aspectRatio',
      };
    }
    return { valid: true };
  }

  public checkFlexBlockText(content: Flex.Block.Text): FlexCheckResult {
    if (content.type !== 'text') {
      return { valid: false, message: 'Invalid type: Flex.Block.Text.type' };
    }

    if (content.text && typeof content.text !== 'string') {
      return { valid: false, message: 'Invalid type: Flex.Block.Text.text' };
    }

    if (content.contents) {
      if (!Array.isArray(content.contents)) {
        return {
          valid: false,
          message: 'Invalid type: Flex.Block.Text.contents',
        };
      }

      for (let item of content.contents) {
        if (!item || typeof item !== 'object')
          return {
            valid: false,
            message: 'Invalid type: Flex.Block.Text.contents',
          };

        const result = this.checkFlexBlockSpan(item);
        if (!result.valid) {
          return { valid: false, message: result.message };
        }
      }
    }

    if (content.flex && typeof content.flex !== 'number') {
      return { valid: false, message: 'Invalid type: Flex.Block.Text.flex' };
    }

    if (
      content.margin &&
      !this._isMargin(content.margin) &&
      !this._isSize(content.margin)
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Text.margin' };
    }

    if (
      content.position &&
      content.position !== 'relative' &&
      content.position !== 'absolute'
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Text.position',
      };
    }

    if (content.offsetTop && typeof content.offsetTop !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Text.offsetTop',
      };
    }

    if (content.offsetBottom && typeof content.offsetBottom !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Text.offsetBottom',
      };
    }

    if (content.offsetStart && typeof content.offsetStart !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Text.offsetStart',
      };
    }

    if (content.offsetEnd && typeof content.offsetEnd !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Text.offsetEnd',
      };
    }

    if (
      content.size &&
      content.size !== 'xxs' &&
      content.size !== 'xs' &&
      content.size !== 'sm' &&
      content.size !== 'md' &&
      content.size !== 'lg' &&
      content.size !== 'xl' &&
      content.size !== 'xxl' &&
      content.size !== '3xl' &&
      content.size !== '4xl' &&
      content.size !== '5xl' &&
      !this._isSize(content.size)
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Text.size' };
    }

    if (
      content.align &&
      content.align !== 'start' &&
      content.align !== 'end' &&
      content.align !== 'center'
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Text.align' };
    }

    if (
      content.gravity &&
      content.gravity !== 'top' &&
      content.gravity !== 'bottom' &&
      content.gravity !== 'center'
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Text.gravity' };
    }

    if (
      content.weight &&
      content.weight !== 'regular' &&
      content.weight !== 'bold'
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Text.weight' };
    }

    if (
      content.style &&
      content.style !== 'normal' &&
      content.style !== 'italic'
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Text.style' };
    }

    if (
      content.decoration &&
      content.decoration !== 'none' &&
      content.decoration !== 'underline' &&
      content.decoration !== 'line-through'
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Text.decoration',
      };
    }

    if (
      content.adjustMode &&
      content.adjustMode !== 'shrink-to-fit' &&
      content.adjustMode !== 'none'
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Text.adjustMode',
      };
    }

    return { valid: true };
  }

  public checkFlexBlockSeparator(
    content: Flex.Block.Separator,
  ): FlexCheckResult {
    if (content.type !== 'separator') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Separator.type',
      };
    }
    if (
      content.margin &&
      !this._isMargin(content.margin) &&
      !this._isSize(content.margin)
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Separator.margin',
      };
    }
    if (content.color && typeof content.color !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Separator.color',
      };
    }
    return { valid: true };
  }

  public checkFlexBlockFiller(content: Flex.Block.Filler): FlexCheckResult {
    if (content.type !== 'filler') {
      return { valid: false, message: 'Invalid type: Flex.Block.Filler.type' };
    }

    if (content.flex && typeof content.flex !== 'number') {
      return { valid: false, message: 'Invalid type: Flex.Block.Filler.flex' };
    }
    return { valid: true };
  }

  public checkFlexBlockSpacer(content: Flex.Block.Spacer): FlexCheckResult {
    if (content.type !== 'spacer') {
      return { valid: false, message: 'Invalid type: Flex.Block.Spacer.type' };
    }

    if (
      content.size &&
      content.size !== 'xs' &&
      content.size !== 'sm' &&
      content.size !== 'md' &&
      content.size !== 'lg' &&
      content.size !== 'xl' &&
      content.size !== 'xxl' &&
      !this._isSize(content.size)
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Spacer.size' };
    }
    return { valid: true };
  }

  public checkFlexBlockSpan(content: Flex.Block.Span): FlexCheckResult {
    if (content.type !== 'span') {
      return { valid: false, message: 'Invalid type: Flex.Block.Span.type' };
    }

    if (content.text && typeof content.text !== 'string') {
      return { valid: false, message: 'Invalid type: Flex.Block.Span.text' };
    }

    if (content.color && typeof content.color !== 'string') {
      return { valid: false, message: 'Invalid type: Flex.Block.Span.color' };
    }

    if (
      content.size &&
      content.size !== 'xxs' &&
      content.size !== 'xs' &&
      content.size !== 'sm' &&
      content.size !== 'md' &&
      content.size !== 'lg' &&
      content.size !== 'xl' &&
      content.size !== 'xxl' &&
      content.size !== '3xl' &&
      content.size !== '4xl' &&
      content.size !== '5xl' &&
      !this._isSize(content.size)
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Span.size' };
    }

    if (
      content.weight &&
      content.weight !== 'regular' &&
      content.weight !== 'bold'
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Span.weight' };
    }

    if (
      content.style &&
      content.style !== 'normal' &&
      content.style !== 'italic'
    ) {
      return { valid: false, message: 'Invalid type: Flex.Block.Span.style' };
    }

    if (
      content.decoration &&
      content.decoration !== 'none' &&
      content.decoration !== 'underline'
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Block.Span.decoration',
      };
    }

    return { valid: true };
  }

  public checkFlexBackgroundObjectLinearGradient(
    content: Flex.BackgroundObject.LinearGradient,
  ): FlexCheckResult {
    if (content.type !== 'linearGradient') {
      return {
        valid: false,
        message: 'Invalid type: Flex.BackgroundObject.LinearGradient.type',
      };
    }

    if (content.angle && typeof content.angle !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.BackgroundObject.LinearGradient.angle',
      };
    }

    if (content.startColor && typeof content.startColor !== 'string') {
      return {
        valid: false,
        message:
          'Invalid type: Flex.BackgroundObject.LinearGradient.startColor',
      };
    }

    if (content.endColor && typeof content.endColor !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.BackgroundObject.LinearGradient.endColor',
      };
    }

    if (content.centerColor && typeof content.centerColor !== 'string') {
      return {
        valid: false,
        message:
          'Invalid type: Flex.BackgroundObject.LinearGradient.centerColor',
      };
    }

    if (content.centerPosition && typeof content.centerPosition !== 'string') {
      return {
        valid: false,
        message:
          'Invalid type: Flex.BackgroundObject.LinearGradient.centerPosition',
      };
    }

    return { valid: true };
  }

  public checkFlexActionObject(
    content: Flex.ActionObject.Any,
  ): FlexCheckResult {
    if (content.type === 'message') {
      const result = this.checkFlexActionObjectMessage(content);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.type === 'uri') {
      const result = this.checkFlexActionObjectURI(content);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.type === 'datetimepicker') {
      const result = this.checkFlexActionObjectDatetimePicker(content);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.type === 'camera') {
      const result = this.checkFlexActionObjectCamera(content);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.type === 'cameraRoll') {
      const result = this.checkFlexActionObjectCameraRoll(content);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.type === 'location') {
      const result = this.checkFlexActionObjectLocation(content);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.type === 'add-through-promotion') {
      const result = this.checkFlexActionObjectAddThroughPromotion(content);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.type === 'share-message-through-promotion') {
      const result =
        this.checkFlexActionObjectShareMessageThroughPromotion(content);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    return { valid: true };
  }

  public checkFlexActionObjectPostback(
    content: Flex.ActionObject.Postback,
  ): FlexCheckResult {
    if (content.type !== 'postback') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.Postback.type',
      };
    }

    if (content.data && typeof content.data !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.Postback.data',
      };
    }

    if (content.displayText && typeof content.displayText !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.Postback.displayText',
      };
    }
    return { valid: true };
  }

  public checkFlexActionObjectMessage(
    content: Flex.ActionObject.Message,
  ): FlexCheckResult {
    if (content.type !== 'message') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.Message.type',
      };
    }

    if (content.text && typeof content.text !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.Message.text',
      };
    }
    return { valid: true };
  }

  public checkFlexActionObjectURI(
    content: Flex.ActionObject.URI,
  ): FlexCheckResult {
    if (content.type !== 'uri') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.URI.type',
      };
    }

    if (content.uri && typeof content.uri !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.URI.uri',
      };
    }

    if (content.altUri && typeof content.altUri !== 'object') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.URI.altUri',
      };
    }

    if (content.altUri.desktop && typeof content.altUri.desktop !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.URI.altUri.desktop',
      };
    }

    return { valid: true };
  }

  public checkFlexActionObjectDatetimePicker(
    content: Flex.ActionObject.DatetimePicker,
  ): FlexCheckResult {
    if (content.type !== 'datetimepicker') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.DatetimePicker.type',
      };
    }

    if (content.data && typeof content.data !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.DatetimePicker.data',
      };
    }

    if (
      content.mode !== 'date' &&
      content.mode !== 'time' &&
      content.mode !== 'datetime'
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.DatetimePicker.mode',
      };
    }

    if (content.initial && typeof content.initial !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.DatetimePicker.initial',
      };
    }

    if (content.max && typeof content.max !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.DatetimePicker.max',
      };
    }

    if (content.min && typeof content.min !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.DatetimePicker.min',
      };
    }

    return { valid: true };
  }

  public checkFlexActionObjectCamera(
    content: Flex.ActionObject.Camera,
  ): FlexCheckResult {
    if (content.type !== 'camera') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.Camera.type',
      };
    }

    if (content.label && typeof content.label !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.Camera.label',
      };
    }
    return { valid: true };
  }

  public checkFlexActionObjectCameraRoll(
    content: Flex.ActionObject.CameraRoll,
  ): FlexCheckResult {
    if (content.type !== 'cameraRoll') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.CameraRoll.type',
      };
    }
    return { valid: true };
  }

  public checkFlexActionObjectLocation(
    content: Flex.ActionObject.Location,
  ): FlexCheckResult {
    if (content.type !== 'location') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.Location.type',
      };
    }
    return { valid: true };
  }

  public checkFlexActionObjectAddThroughPromotion(
    content: Flex.ActionObject.AddThroughPromotion,
  ): FlexCheckResult {
    if (content.type !== 'add-through-promotion') {
      return {
        valid: false,
        message: 'Invalid type: Flex.ActionObject.AddThroughPromotion.type',
      };
    }
    return { valid: true };
  }

  public checkFlexActionObjectShareMessageThroughPromotion(
    content: Flex.ActionObject.ShareMessageThroughPromotion,
  ): FlexCheckResult {
    if (content.type !== 'share-message-through-promotion') {
      return {
        valid: false,
        message:
          'Invalid type: Flex.ActionObject.ShareMessageThroughPromotion.type',
      };
    }

    if (content.promotionId && typeof content.promotionId !== 'number') {
      return {
        valid: false,
        message:
          'Invalid type: Flex.ActionObject.ShareMessageThroughPromotion.promotionId',
      };
    }

    return { valid: true };
  }

  public checkFlexStyleBubble(content: Flex.Style.Bubble): FlexCheckResult {
    if (content.header) {
      const result = this.checkFlexStyleBlock(content.header);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.hero) {
      const result = this.checkFlexStyleBlock(content.hero);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.body) {
      const result = this.checkFlexStyleBlock(content.body);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    if (content.footer) {
      const result = this.checkFlexStyleBlock(content.footer);
      if (!result.valid) {
        return { valid: false, message: result.message };
      }
    }

    return { valid: true };
  }

  public checkFlexStyleBlock(content: Flex.Style.Block): FlexCheckResult {
    if (
      content.backgroundColor &&
      typeof content.backgroundColor !== 'string'
    ) {
      return {
        valid: false,
        message: 'Invalid type: Flex.Style.Block.backgroundColor',
      };
    }

    if (content.separator && typeof content.separator !== 'boolean') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Style.Block.separator',
      };
    }

    if (content.separatorColor && typeof content.separatorColor !== 'string') {
      return {
        valid: false,
        message: 'Invalid type: Flex.Style.Block.separatorColor',
      };
    }

    return { valid: true };
  }

  private _isMargin(text: string): boolean {
    return (
      text === 'none' ||
      text === 'xs' ||
      text === 'sm' ||
      text === 'md' ||
      text === 'lg' ||
      text === 'xl' ||
      text === 'xxl'
    );
  }

  private _isSize(text: string): boolean {
    const pattern = /^\d+(px|em|rem|vw|vh|pt|cm|mm|in|%)$/;
    return pattern.test(text);
  }

  private _isRatio(text: string): boolean {
    const pattern = /^\d+:\d+$/;
    return pattern.test(text);
  }
}
