import { NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { FileDirective } from '../../components/file.directive';
import { MatTooltip } from '../../material/tooltip/tooltip';
import { MessageToolbar } from '../factory/message-toolbar';
import { Package } from '../package.class';

@Component({
  selector: 'ms-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['package'],
  exportAs: 'msSubmenu',
  standalone: true,
  imports: [
    MatMenu,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    MatMenuItem,
    MatIcon,
    MatTooltip,
    FileDirective,
    MatMenuTrigger,
  ],
})
export class SubmenuComponent {
  @Input()
  public package: Package;

  @Input()
  public toolbars: MessageToolbar[];

  @ViewChild('matMenu', { static: true })
  public matMenu: MatMenu;
}
