<ng-container [ngSwitch]="data?.content?.contents?.type">
  <ng-template ngSwitchCase="bubble">
    <flex-bubble
      [data]="data.content.contents"
      [bubble]="data.content.contents"
      [flex]="data"
      [style.pointerEvents]="canSelect ? 'auto' : 'none'"
      class="flexSolo"
    >
    </flex-bubble>
  </ng-template>
  <ng-template ngSwitchCase="carousel">
    <flex-carousel [data]="data.content.contents" [flex]="data">
    </flex-carousel>
  </ng-template>
</ng-container>
