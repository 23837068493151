import { Injectable } from '@angular/core';
import { TagDto } from '@ay-gosu/server-shared';
import {
  MatConnectedDialog,
  MatConnectedDialogConfig,
} from '@ay-gosu/ui/common/connected-dialog';
import { ExposedPromise } from '@ay/util';
import extend from 'lodash/extend';
import { firstValueFrom, takeUntil } from 'rxjs';
import { TagService, TagTargetType } from '../../service/tag.service';
import { DialogService } from '../dialog.service';
import { AddTagComponent } from './add-tag/add-tag.component';
import { CreateTagComponent } from './create-tag/create-tag.component';

export interface AddTagDialogConfig extends MatConnectedDialogConfig {
  targetId?: number;
  tags?: TagDto[];
}

export interface CreateTagDialogConfig extends MatConnectedDialogConfig {
  parentTag?: TagDto;
}

@Injectable({
  providedIn: 'root',
})
export class TagDialog extends DialogService {
  public constructor(
    protected connectedDialog: MatConnectedDialog,
    private readonly _tagService: TagService,
  ) {
    super(connectedDialog);
  }

  public async add(
    targetType: TagTargetType,
    config?: AddTagDialogConfig,
  ): Promise<number> {
    const exposedPromise = new ExposedPromise<number>();

    const dialogRef = super._open(
      AddTagComponent,
      extend({}, config, {
        panelClass: 'dialog-container-p0',
      }) as MatConnectedDialogConfig,
      exposedPromise,
    );

    const instance = dialogRef.componentInstance;

    instance.targetType = targetType;

    if (config.id) {
      instance.id = config.targetId;
    }

    if (config.tags) {
      instance.selected = config.tags;
    }

    instance.createTag
      .pipe(takeUntil(exposedPromise.promise))
      .subscribe(async (event) => {
        try {
          const created = await this.create(
            targetType,
            extend({}, config, { panelClass: 'dialog-container-p0' }),
          );

          // 如果有建立標籤，就加入到選擇清單中
          if (created) {
            const all = await firstValueFrom(this._tagService.all$);
            const tag = all.find((tag) => tag.id === created.id);
            instance.selected.push(tag);
          }
        } catch (error) {
          console.error(error);
        }
      });

    return exposedPromise.promise;
  }

  public async create(
    targetType: TagTargetType,
    config?: CreateTagDialogConfig,
  ): Promise<{ id: number }> {
    const exposedPromise = new ExposedPromise<{ id: number }>();

    const dialogRef = this._open(
      CreateTagComponent,
      extend({}, config) as MatConnectedDialogConfig,
      exposedPromise,
    );

    const instance = dialogRef.componentInstance;
    instance.targetType = targetType;

    if (config && config.parentTag) {
      instance.parentTag = config.parentTag;
    }

    return exposedPromise.promise;
  }
}
