import { Connection } from '../connection/class';
import { FlowService } from '../flow.service';
import { RandomNode } from '../node/random';
import { SwitchNode } from '../node/switch';
import { Command } from './command';

export class DeleteConditionCommand extends Command {
  protected oldJunction: any;
  protected oldConnections: Connection[];
  protected oldIdx: number;

  public constructor(
    protected flowService: FlowService,
    protected node: SwitchNode | RandomNode,
    protected condition: any,
  ) {
    super();
    this.oldIdx = this.node.conditions.indexOf(condition);
  }

  public async do() {
    let index = this.node.conditions.indexOf(this.condition);
    if (index === -1) throw $localize`node 中找不到要刪除的 condition`;
    let junctions = this.node.nodeComponent.junctions.slice(1);
    let junction = junctions[index];
    this.oldJunction = junction;
    this.oldConnections = this.flowService.connectionList.filter(
      (connection) =>
        connection.start == junction || connection.end == junction,
    );
    this.flowService.connectionList = this.flowService.connectionList.filter(
      (connection) =>
        connection.start != junction && connection.end != junction,
    );
    this.node.conditions.splice(index, 1);
    this.node.nodeComponent.updateNearbyConnection();
  }

  public async undo() {
    this.node.conditions.splice(this.oldIdx, 0, this.condition);
    this.flowService.connectionList = [
      ...this.flowService.connectionList,
      ...this.oldConnections,
    ];

    this.flowService.tick();
    let junctions = this.node.nodeComponent.junctions.slice(1);
    let junction = junctions[this.oldIdx];
    this.oldConnections.forEach((connection) => {
      if (connection.end == this.oldJunction) {
        connection.end = junction;
      } else if (connection.start == this.oldJunction) {
        connection.start = junction;
      }
    });
    this.oldJunction = junction;
    this.node.nodeComponent.updateNearbyConnection();
  }
}
