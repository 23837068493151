<div *ngIf="image" class="image">
  <img [src]="image" />
</div>

<mat-icon *ngIf="icon" class="icon">{{ icon }}</mat-icon>

<h1 *ngIf="title" class="title">{{ title }}</h1>

<p class="description">
  <ng-content></ng-content>

  {{ description }}
</p>

<div class="button-wrap">
  <button
    *ngIf="callToActionLabel"
    mat-raised-button
    color="primary"
    element-ref
    #elementRef="elementRef"
    (click)="clickCallToActionButton($event, elementRef)"
  >
    <mat-icon *ngIf="callToActionIcon">{{ callToActionIcon }}</mat-icon>
    <label class="label">
      {{ callToActionLabel }}
    </label>
  </button>
</div>
