<ng-container *ngIf="!message.templateLost; else templateLost">
  <img
    [src]="message.content.content | imageType"
    [alt]="altTip$ | async"
    [matTooltip]="altTip$ | async"
    [matContextMenu]="contextMenu"
    [matContextMenuDisable]="mode === 'READ'"
  />
  <div class="arrow"></div>
</ng-container>

<mat-menu #contextMenu class="tiny-menu">
  <ng-container *ngIf="isTemplate">
    <button mat-menu-item (click)="editTemplate()" i18n>
      <mat-icon>settings</mat-icon>編輯參數
    </button>

    <mat-divider> </mat-divider>
  </ng-container>

  <button mat-menu-item (click)="edit()" *ngIf="!isTemplate" i18n>
    <mat-icon>settings</mat-icon> 更換圖片
  </button>

  <button mat-menu-item (click)="delete()" i18n>
    <mat-icon>delete</mat-icon> 刪除
  </button>

  <button mat-menu-item (click)="swapUpward()" [disabled]="isFirstMessage" i18n>
    <mat-icon>arrow_upward</mat-icon> 上移
  </button>

  <button
    mat-menu-item
    (click)="swapDownward()"
    [disabled]="isLastMessage"
    i18n
  >
    <mat-icon>arrow_downward</mat-icon> 下移
  </button>
</mat-menu>

<ng-template #templateLost>
  <div class="mat-elevation-z2 template-lost" i18n>
    <mat-icon class="mat-18">search_off</mat-icon><br />
    圖像編輯器中的圖片已被刪除<br />無法預覽
  </div>
</ng-template>
