import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PromotionChannelModel, StaticModel } from '@ay-gosu/server-shared';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { Button } from '@ay/bot';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { FileDirective } from '../../../../components/file.directive';
import { ImagePickerComponent } from '../../../../components/image-picker/image-picker.component';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MessageAffectedComponent } from '../../../message-affected.component';
import { PackageFactoryService } from '../../../package-factory.service';
import { Package } from '../../../package.class';
import { Card } from '../../card/card.class';
import { CardsMessage } from '../../cards.message';

@Component({
  selector: 'ms-template-card-customize',
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['message'],
  standalone: true,
  imports: [
    MatCheckbox,
    FormsModule,
    NgIf,
    ImagePickerComponent,
    MatFormField,
    MatInput,
    NgFor,
    MatLabel,
    MatSelect,
    MatOption,
    MatButton,
    FileDirective,
    MatProgressBar,
    AsyncPipe,
    LegacyAppearanceDirective,
  ],
})
export class CustomizeComponent extends MessageAffectedComponent<CardsMessage> {
  @Input()
  public card: Card;

  @Input()
  public package: Package;

  @ViewChild('uploadProgressDialog')
  protected uploadProgressDialog: TemplateRef<any>;

  public uploadProgress: number;

  public promotions$ = of([]).pipe(
    mergeMap((_) =>
      PromotionChannelModel.list(1, 100).then((res) =>
        res.data.filter((row) => row.type === 'SHARE_MESSAGE'),
      ),
    ),
  );

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _matConnectedDialog: MatConnectedDialog,
    private readonly _matSnackBar: MatSnackBar,
    public readonly packageFactoryService: PackageFactoryService,
  ) {
    super(changeDetectorRef);
  }

  public onChanged() {
    this.message.changed();
  }

  public uploadFile(button: Button.Uri, files: FileList) {
    const file = files.item(0);
    const dialogRef = this._matConnectedDialog.open(this.uploadProgressDialog, {
      disableClose: true,
    });

    StaticModel.upload(file.name, file).subscribe({
      next: (response) => {
        this.uploadProgress = response.percentage * 100;
        if (response.status === 'all-done') {
          button.uri = response.result;
          dialogRef.close();
          this._matSnackBar.open($localize`檔案上傳成功`);
          this.onChanged();
        }
      },
      error: (error) => {
        console.error(error);
        dialogRef.close();
        this._matSnackBar.open($localize`檔案上傳發生錯誤`);
      },
    });
  }
}
