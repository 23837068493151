import { Injectable } from '@angular/core';
import { TemplateDto, TemplateModel } from '@ay-gosu/server-shared';
import { CacheableService } from './cacheable.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateService extends CacheableService<TemplateDto> {
  protected _get(ids: number[]): Promise<TemplateDto[]> {
    return TemplateModel.getMultiple(ids);
  }

  public getList(): Promise<TemplateDto[]> {
    return TemplateModel.list();
  }
}
