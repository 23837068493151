<mat-form-field legacyAppearance>
  <mat-label i18n>編號篩選</mat-label>
  <input
    matInput
    [(ngModel)]="node.ids"
    matTooltip="使用者編號篩選，逗號分隔"
    i18n-matTooltip="使用者編號篩選，逗號分隔"
  />
</mat-form-field>

<mat-form-field legacyAppearance>
  <mat-label i18n>暱稱篩選</mat-label>
  <input
    matInput
    [(ngModel)]="node.nickname"
    matTooltip="社群平台暱稱搜尋"
    i18n-matTooltip="社群平台暱稱搜尋"
  />
</mat-form-field>

<mat-form-field legacyAppearance>
  <mat-label i18n>狀態篩選</mat-label>
  <input
    matInput
    [(ngModel)]="node.message"
    matTooltip="使用者狀態篩選"
    i18n-matTooltip="使用者狀態篩選"
  />
</mat-form-field>

<div class="row">
  <h3 i18n>類型篩選</h3>
  <div fxLayout="row">
    <mat-checkbox
      fxFlex="1 0 0"
      *ngFor="let type of profileTypes"
      [(ngModel)]="type.checked"
      (ngModelChange)="afterProfileTypesChanged()"
      value="true"
      color="primary"
    >
      {{ type.label }}
    </mat-checkbox>
  </div>
  <mat-divider></mat-divider>
</div>

<programmable
  title="篩選標籤"
  i18n-title="篩選標籤"
  [value]="$any(node.tag)"
  (valueChange)="node.tag = $any($event)"
  dataType="number[]"
  class="w-full"
>
  <div class="row !block w-full">
    <h3 i18n>標籤篩選</h3>
    <gosu-tag-tree-picker
      fxFlex="1 0 0"
      target="profile"
      [(value)]="node.tag"
      title="篩選標籤"
      i18n-title="篩選標籤"
    >
    </gosu-tag-tree-picker>

    @if (deletedTagNames && deletedTagNames.length > 0) {
      <gosu-validator
        [(ngModel)]="node.tag"
        [rules]="{ bannedOptions: true }"
        [offsetX]="3"
        [offsetY]="38"
        [message]="noneExistTag"
        [payload]="{ bannedOptions: deletedTagNames }"
      ></gosu-validator>
    }

    <mat-divider></mat-divider>
  </div>
</programmable>

<div class="row">
  <h3 i18n>屬性篩選</h3>
  <div
    class="property"
    fxLayout="row"
    fxLayoutGap="1em"
    *ngFor="let property of node.property; let index = index"
  >
    <div class="no">{{ index + 1 }}.</div>
    <mat-form-field legacyAppearance>
      <mat-label i18n>屬性</mat-label>
      <mat-select [(ngModel)]="property.key">
        <mat-option
          *ngFor="let config of configs$ | async"
          [value]="config.key"
        >
          {{ config.name }} ({{ config.key }})
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field legacyAppearance>
      <mat-label i18n>運算元</mat-label>
      <mat-select [(ngModel)]="property.type" i18n>
        <mat-option value="string">完全符合</mat-option>
        <mat-option value="like">包含</mat-option>
        <mat-option value="isExist">屬性存在</mat-option>
        <mat-option value="notExist">屬性不存在</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      legacyAppearance
      *ngIf="['string', 'like'].includes(property.type); else empty"
    >
      <mat-label i18n>值</mat-label>
      <input matInput [(ngModel)]="property.value" />
    </mat-form-field>

    <ng-template #empty>
      <div fxFlex="1 0 0"></div>
    </ng-template>

    <button mat-icon-button (click)="removeProperty(index)">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <div class="tac">
    <button mat-stroked-button (click)="addProperty()" i18n>
      <mat-icon>add</mat-icon>增加屬性篩選
    </button>
  </div>
  <mat-divider></mat-divider>
</div>

<mat-form-field legacyAppearance>
  <mat-label i18n>回傳變數</mat-label>
  <input
    matInput
    matTooltip="篩選結果將存入 tmp 下，回傳型態為物件陣列"
    i18n-matTooltip="篩選結果將存入 tmp 下，回傳型態為物件陣列"
    [(ngModel)]="node.responseVar"
  />
</mat-form-field>

<div class="row" fxLayout="row">
  <mat-checkbox
    fxFlex="1 0 0"
    color="primary"
    [(ngModel)]="node.includeInfo"
    matTooltip="回傳的每一個元素包含平台類型(platformType)、類型(type)、狀態(status)、照片(picture)、個人狀態(message)、封鎖時間(unfollowedAt)"
    i18n-matTooltip="
      回傳的每一個元素包含平台類型(platformType)、類型(type)、狀態(status)、照片(picture)、個人狀態(message)、封鎖時間(unfollowedAt)
    "
    i18n
  >
    回傳額外資訊
  </mat-checkbox>
  <mat-checkbox
    fxFlex="1 0 0"
    color="primary"
    [(ngModel)]="node.includeProperty"
    matTooltip="回傳的每一個元素包含屬性陣列(properties)，其每一個元素都由 key, value 組成"
    i18n-matTooltip="
      回傳的每一個元素包含屬性陣列(properties)，其每一個元素都由 key, value 組成
    "
    i18n
  >
    回傳屬性
  </mat-checkbox>
  <mat-checkbox
    fxFlex="1 0 0"
    color="primary"
    [(ngModel)]="node.includeTag"
    matTooltip="回傳的每一個元素包含標籤陣列(tags)，其每一個元素都由 id, name 組成"
    i18n-matTooltip="
      回傳的每一個元素包含標籤陣列(tags)，其每一個元素都由 id, name 組成
    "
    i18n
  >
    回傳標籤
  </mat-checkbox>
</div>
