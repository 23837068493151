<mat-form-field class="w-full" legacyAppearance>
  <mat-label i18n>回應狀態</mat-label>
  <mat-select [(ngModel)]="node.statusCode">
    @for (statusCode of statusCodes; let index = $index; track index) {
      <mat-option [value]="statusCode.value">
        {{ statusCode.label }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>

<div class="my-3 text-title-color">
  <h3 class="flex flex-row !justify-between !items-center gap-2">
    <mat-label i18n>標頭 (Header)</mat-label>
    <button mat-icon-button (click)="addHeader()" color="primary">
      <mat-icon>add</mat-icon>
    </button>
  </h3>

  @for (header of node.headers; let index = $index; track index) {
    <div class="flex flex-row gap-2 !justify-between !items-center w-full">
      <mat-form-field class="flex-1" legacyAppearance>
        <mat-label>Key</mat-label>
        <input matInput [(ngModel)]="header.key" />
      </mat-form-field>

      <mat-form-field class="flex-1" legacyAppearance>
        <mat-label>Value</mat-label>
        <input matInput [(ngModel)]="header.value" />
      </mat-form-field>

      <button
        mat-icon-button
        (click)="removeHeader(index)"
        class="opacity-30 hover:opacity-100 transition-opacity duration-300"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  }
</div>

@if (enableMonaco$ | async) {
  <ngx-monaco-editor
    class="flex-1 min-h-[400px] w-full !p-0"
    [options]="editorOptions"
    [(ngModel)]="node.response"
    (init)="onMonacoEditorInit($event)"
  >
  </ngx-monaco-editor>
} @else {
  <textarea
    class="text-primary font-mono p-6 border-none leading-6 bg-black"
    [(ngModel)]="node.response"
  ></textarea>
}
