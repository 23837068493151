// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { CreateDataSourceDto } from './dto/create-data-source.dto';
import { ListDataSourceItemDto } from './dto/list-data-source-item.dto';
import { UpdateDataSourceDto } from './dto/update-data-source.dto';

export class DataSourceModel {
  /**
   * 列出資料來源設定
   * 透過 order 欄位，由小到大排序
   */
  static list(): Promise<ListDataSourceItemDto[]> {
    return wsc.execute('/ws/data-source/list') as any;
  }

  /**
   * 建立資料來源設定
   * - 回傳資料來源設定的 ID
   */
  static create(body: CreateDataSourceDto): Promise<number> {
    return wsc.execute('/ws/data-source/create', body) as any;
  }

  /**
   * 編輯資料來源設定
   * - 只能編輯名稱
   */
  static update(id: number, body: UpdateDataSourceDto): Promise<void> {
    return wsc.execute('/ws/data-source/update', id, body) as any;
  }

  /**
   * 排序資料來源清單
   */
  static sort(ids: number[]): Promise<void> {
    return wsc.execute('/ws/data-source/sort', ids) as any;
  }

  /**
   * 刪除資料來源設定
   * - 如果裡面有任何資料，就不能刪除了(拋出錯誤 DATA_SOURCE_ALREADY_BE_USED)
   */
  static delete(id: number): Promise<void> {
    return wsc.execute('/ws/data-source/delete', id) as any;
  }
}
// dad37b68f2ab46184f28b346e75ce0f9541a48b7deb046f33a2ed688e4f5586b
