import { ReadableError } from '../../../util/readable-error';
import { Recorder } from './recorder.class';

export class RecorderFactory {
  public static async create(config: MediaStreamConstraints, type: string) {
    try {
      const stream: MediaStream = await RecorderFactory.getUserMedia(config);
      return new Recorder(stream, type);
    } catch (error) {
      throw new ReadableError($localize`無法取用麥克風或鏡頭`, { error });
    }
  }

  public static getUserMedia(
    constraints: MediaStreamConstraints,
  ): Promise<MediaStream> {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      return navigator.mediaDevices.getUserMedia(constraints);
    }

    let media =
      navigator['getUserMedia'] ||
      navigator['webkitGetUserMedia'] ||
      navigator['mozGetUserMedia'] ||
      navigator['msGetUserMedia'];

    return media(constraints);
  }
}
