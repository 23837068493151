import { FlagRule } from '../flag-rule.class';
import { FromJsonOption } from '../rule';

export class SentRule extends FlagRule {
  public keywords: string[] = [$localize`已發送`];

  public constructor() {
    super('SENT');
  }

  public toString() {
    return $localize`已發送`;
  }

  public static schema: string = 'sent';

  public static async fromShortCode(code: string, option: FromJsonOption) {
    return new SentRule();
  }
}
