export interface CanCreateShortCode {
  toShortCode(): string;
}

export class Bracket {
  public lines: (string | Bracket)[] = [];

  public constructor(public start: number, public end: number = null) {}

  public toJSON() {
    return this.lines;
  }
}

export class ShortCodeDecoder {
  public decode(code: string) {
    return this._findAllBrackets(code)[0];
  }

  private _findAllBrackets(code: string) {
    let brackets: Bracket[] = [];
    let buffer = '';
    let bracket: Bracket = new Bracket(0);

    function flushBuffer() {
      if (buffer !== '') {
        bracket.lines.push(buffer);
      }
      buffer = '';
    }

    for (let pos = 0; pos < code.length; pos++) {
      let char = code[pos];
      if (char === '(') {
        let oldBracket = bracket;
        bracket = new Bracket(pos);
        brackets.push(bracket);
        oldBracket.lines.push(bracket);
        flushBuffer();
        continue;
      } else if (char === ')') {
        bracket.end = pos + 1;
        flushBuffer();
        let index = brackets.findIndex((exist) => exist === bracket);
        bracket = brackets[index - 1] || brackets[index];
        continue;
      } else if (char === ';') {
        flushBuffer();
        continue;
      } else {
        buffer += char;
      }
    }

    flushBuffer();

    return brackets;
  }
}
