import { FlagRule } from '../flag-rule.class';
import { FromJsonOption } from '../rule';

export class SendingRule extends FlagRule {
  public keywords: string[] = [$localize`發送中`];

  public constructor() {
    super('SENDING');
  }

  public toString() {
    return $localize`發送中`;
  }

  public static schema: string = 'sending';

  public static async fromShortCode(code: string, option: FromJsonOption) {
    return new SendingRule();
  }
}
