<svg:rect
  [attr.width]="width"
  [attr.height]="height"
  [attr.rx]="em"
  [attr.fill]="node.color"
  [attr.stroke]="node.borderColor"
></svg:rect>

<svg:circle
  *ngIf="node.hasError"
  [attr.cx]="width - 0.55 * em"
  [attr.cy]="-0.15 * em"
  [attr.r]="0.5 * em"
  fill="#FFFFFF"
></svg:circle>

<svg:text
  *ngIf="node.hasError"
  class="mi"
  [attr.x]="width - 1.2 * em"
  [attr.y]="-0.8 * em"
  [style.font-size.em]="1.3"
  alignment-baseline="text-before-edge"
  text-anchor="start"
  fill="#F44336"
>
  error
</svg:text>

<svg
  [attr.x]="0.6 * em"
  [attr.y]="0.6 * em"
  [attr.width]="0.8 * em"
  [attr.height]="0.8 * em"
  role="img"
  aria-hidden="true"
  focusable="false"
  data-prefix="fas"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 512 512"
>
  <svg:path
    fill="currentColor"
    [attr.d]="faIcons[node.icon].icon[4]"
  ></svg:path>
</svg>

<svg:text
  class="name"
  [attr.x]="2 * em"
  [attr.y]="0.25 * em"
  [style.font-size.px]="em"
  alignment-baseline="text-before-edge"
  text-anchor="start"
  [attr.fill]="node.frontColor"
>
  {{ node?.display }}
</svg:text>

<svg:g
  class="junction"
  [index]="0"
  [dx]="0"
  [dy]="1 * em"
  type="input"
  [color]="node.borderColor"
  [node]="node"
></svg:g>

<svg:g
  class="junction"
  [index]="1"
  [dx]="width"
  [dy]="1 * em"
  type="output"
  [color]="node.borderColor"
  [node]="node"
></svg:g>

<svg:g class="conditions" *ngIf="!node.isAsync">
  <svg:g
    class="condition"
    [index]="2"
    [y]="1"
    text="異常"
    i18n-text="異常"
  ></svg:g>
</svg:g>
