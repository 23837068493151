import { NgFor, NgIf } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PropertyConfigDto } from '@ay-gosu/server-shared';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { Map, groupBy } from '@ay/util';
import { firstValueFrom } from 'rxjs';
import { GosuValidatorComponent } from '../../../../components/gosu-validator/gosu-validator.component';
import { CreatePropertyConfigDialog } from '../../../../dialog/create-property-config';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../../material/tooltip/tooltip';
import { PropertyConfigService } from '../../../../service/property-config.service';
import { FlowService } from '../../flow.service';
import { FormComponent } from '../form.component';
import { GetterNode, Pair } from './class';

@Component({
  selector: 'flow-getter-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    MatOption,
    NgIf,
    MatInput,
    MatTooltip,
    GosuValidatorComponent,
    NgFor,
    MatSelectTrigger,
    MatIcon,
    MatSuffix,
    MatIconButton,
    MatButton,
    LegacyAppearanceDirective,
  ],
})
export class GetterFormComponent
  extends FormComponent<GetterNode>
  implements AfterContentInit, OnDestroy
{
  public configList: PropertyConfigDto[] = [];
  public configs: Map<PropertyConfigDto[]> = {};
  public node: GetterNode;
  public paramConfigData: PropertyConfigDto = {};
  public showConfigForm = false;
  public showIdInput = false;
  public targetMap: Map<string> = {
    profile: $localize`使用者`,
    bot: $localize`機器人`,
    record: $localize`聊天記錄`,
    company: $localize`組織`,
  };

  public targets: string[] = ['profile', 'bot', 'record', 'company'];

  public constructor(
    public readonly flowService: FlowService,
    public readonly elementRef: ElementRef,
    private readonly _propertyConfigService: PropertyConfigService,
    private readonly _matConnectedDialog: MatConnectedDialog,
    private readonly _snackBar: MatSnackBar,
  ) {
    super(elementRef);
  }

  public ngOnDestroy() {
    this.node.checkError();
  }

  public filterList(keyword: string) {
    return this.configList.filter(
      (option) => option.name.includes(keyword) || option.key.includes(keyword),
    );
  }

  public targetChange() {
    this.configList = this.configs[this.node.target];
    if (!this.configList) return;
  }

  public ngAfterContentInit() {
    this.getPropertyConfigList();
    this.showIdInput = !!this.node.targetId;
  }

  public async createPropertyConfig() {
    let res = await this._propertyConfigService.create(this.paramConfigData);
    if (res) {
      this._snackBar.open($localize`新增屬性設定成功`);
      this.getPropertyConfigList();
      this.paramConfigData = {} as any;
    } else {
      this._snackBar.open($localize`新增屬性設定失敗`);
    }
    this.showConfigForm = false;
  }

  public async getPropertyConfigList() {
    let list = await firstValueFrom(this._propertyConfigService.all$);
    this.configs = groupBy(list, 'targetType');
    this.targetChange();
  }

  public checkPair() {
    this.node.pairs.forEach((pair) => {
      if (pair.key && !pair.responseVar) pair.responseVar = pair.key;
    });
  }

  public addPair() {
    this.node.pairs.push({ key: '', responseVar: '' });
  }

  public removePair(idx: number) {
    this.node.pairs.splice(idx, 1);
  }

  public afterPropertyChanged(pair: Pair, value: string) {
    if (pair.key === pair.responseVar || pair.responseVar === '') {
      pair.responseVar = value;
    }
    pair.key = value;
  }

  public async addPropertyConfig() {
    const dialogRef = this._matConnectedDialog.open(CreatePropertyConfigDialog);
    const key = await firstValueFrom(dialogRef.afterClosed());
    if (key) {
      this.node.pairs.push({ key, responseVar: key });
    }
  }

  public selected(select: MatOption | MatOption[]) {
    if (!(select instanceof MatOption)) return;
    return select?.viewValue;
  }
}
