import { Component, ElementRef } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../../material/tooltip/tooltip';
import { FormComponent } from '../form.component';
import { MemberNode } from './class';

@Component({
  selector: 'member-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatTooltip,
    FlexModule,
    MatCheckbox,
    LegacyAppearanceDirective,
  ],
})
export class MemberFormComponent extends FormComponent<MemberNode> {
  public width = 400;

  public constructor(public elementRef: ElementRef) {
    super(elementRef);
  }
}
