export function calcOffsetTop(offsetTop: string, elementRef: HTMLElement) {
  return calcOffset(offsetTop, elementRef, 'height');
}

export function calcOffsetStart(offsetStart: string, elementRef: HTMLElement) {
  return calcOffset(offsetStart, elementRef, 'width');
}

export function calcOffsetBottom(
  offsetBottom: string,
  elementRef: HTMLElement,
) {
  return calcOffset(offsetBottom, elementRef, 'height');
}

export function calcOffsetEnd(offsetEnd: string, elementRef: HTMLElement) {
  return calcOffset(offsetEnd, elementRef, 'width');
}

function calcOffset(
  offset: string,
  elementRef: HTMLElement,
  direction: 'width' | 'height',
) {
  if (offset === undefined) return;

  if (offset.includes('%')) {
    const parent = (elementRef as HTMLElement)?.parentElement;
    const full =
      direction === 'width' ? parent.offsetWidth : parent.offsetHeight;
    let value = (full * parseFloat(offset)) / 100;
    return value + 'px';
  }

  return offset;
}
