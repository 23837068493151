import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BypassSecurityService {
  public constructor(private readonly _domSanitizer: DomSanitizer) {}

  public bypassSecurityUrl(
    url: string,
    checkers: (() => BypassSecurityCheckerFunction)[] = [],
  ) {
    if (!url || typeof url !== 'string') {
      return null;
    }

    if (checkers.every((checker) => checker()(url))) {
      return this._domSanitizer.bypassSecurityTrustUrl(url);
    }

    throw new Error(`Invalid url: ${url}`);
  }

  public bypassSecurityHtml(html: string) {
    if (!html || typeof html !== 'string') {
      return null;
    }

    return this._domSanitizer.bypassSecurityTrustHtml(
      DOMPurify.sanitize(html, { ADD_ATTR: ['target'] }),
    );
  }

  public static isBypassSecurityUrl = (): BypassSecurityCheckerFunction => {
    return function (url: string): boolean {
      return url.startsWith('http://') || url.startsWith('https://');
    };
  };

  public static isBypassImageFile = (): BypassSecurityCheckerFunction => {
    return function (url: string) {
      return url.startsWith('data:image');
    };
  };

  public static isBypassAudioFile = (): BypassSecurityCheckerFunction => {
    return function (url: string) {
      return url.startsWith('data:audio');
    };
  };

  public static isBypassVideoFile = (): BypassSecurityCheckerFunction => {
    return function (url: string) {
      return url.startsWith('data:video');
    };
  };

  public static isBypassWhiteList = (): BypassSecurityCheckerFunction => {
    const whiteList = [window.location.origin, environment.gosubarpublic];
    return function (url: string) {
      const pass = whiteList.some((origin) => url.startsWith(origin));
      return pass;
    };
  };
}

export interface BypassSecurityCheckerFunction {
  (url: string): boolean;
}
