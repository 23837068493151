import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, TemplateRef } from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatIconButton } from '@angular/material/button';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MatOption } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatHint, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import {
  MatConnectedDialog,
  MatConnectedDialogRef,
} from '@ay-gosu/ui/common/connected-dialog';
import Bluebird from 'bluebird';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ElementRefDirective } from '../../../../components/element-ref.directive';
import { ImagePickerComponent } from '../../../../components/image-picker/image-picker.component';
import {
  ProgrammableComponent,
  checkValueWithCode,
} from '../../../../components/programmable/programmable.component';
import { ColorPickerComponent } from '../../../../material/color-picker/color-picker/color-picker.component';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../../material/tooltip';
import { RegularizedFlex } from '../../regularized-flex.class';
import { FlexChinesePipe } from '../chinese.pipe';
import { FlexEditorService } from '../editor.service';
import { ERROR_MESSAGE } from '../error';
import { FlexFormActionComponent } from './action/action.component';
import { BackgroundComponent } from './background/background.component';
import { FORM_CONTROLS, FormCard, FormControl, FormProperty } from './form';
import { FlexFormOffsetComponent } from './offset/offset.component';
import { FlexFormPaddingComponent } from './padding/padding.component';
import { FlexFormStyleComponent } from './style/style.component';

@Component({
  selector: 'flex-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatCard,
    MatCardTitle,
    MatDivider,
    MatCardContent,
    NgFor,
    ProgrammableComponent,
    MatFormField,
    MatSelect,
    MatOption,
    MatHint,
    MatInput,
    MatAutocompleteTrigger,
    MatAutocomplete,
    ColorPickerComponent,
    ImagePickerComponent,
    MatIconButton,
    MatSuffix,
    ElementRefDirective,
    MatIcon,
    FlexFormStyleComponent,
    FlexFormOffsetComponent,
    FlexFormPaddingComponent,
    BackgroundComponent,
    FlexFormActionComponent,
    AsyncPipe,
    FlexChinesePipe,
    LegacyAppearanceDirective,
    MatTooltip,
  ],
})
export class FlexFormComponent implements OnDestroy {
  public data: FormCard = {};
  public errorFunction: (data: RegularizedFlex) => {};
  public errorMsg: string;
  public onSelectInit = false;
  private readonly _destroy$ = new Subject<void>();

  public tooltipDialogRef: MatConnectedDialogRef<any, any>;

  public constructor(
    public readonly service: FlexEditorService,
    private matConnectedDialog: MatConnectedDialog,
  ) {}

  public updateFormWhenSelectedChanged = this.service.selected$
    .pipe(takeUntil(this._destroy$))
    .subscribe((selected) => {
      this.onSelectInit = true;
      this.data = {};
      if (!selected) return;

      this.data = this.getFormControls(selected);
      this.errorFunction = ERROR_MESSAGE[selected.type];
      this.data.properties.forEach(
        (property) =>
          (property.error = this.errorFunction(selected)[property.name]),
      );
      setTimeout(() => {
        this.onSelectInit = false;
      }, 100);
    });

  public getFormControls(selected) {
    const formControls = cloneDeep(FORM_CONTROLS);
    this._handleOptionDisabled(formControls);
    return formControls[selected.type] || {};
  }

  private _handleOptionDisabled(formControls: FormControl) {
    const contents = this.service.regularizedFlex?.content?.contents;
    if (!contents) return;

    //當 bubble 內有 video 時，nano 和 micro 不能選
    if (contents.type === 'bubble') {
      contents.contents.forEach((content) => {
        if (
          content.type === 'hero' &&
          content.contents.find((c) => c.type === 'video')
        ) {
          const bubbleSize = formControls.bubble.properties.find(
            (p) => p.name === 'size',
          );
          if (bubbleSize) {
            bubbleSize.options = bubbleSize.options.map((option) => {
              if (option.value === 'nano' || option.value === 'micro') {
                option.disabled = true;
              }
              return option;
            });
          }
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public setValue(
    select: RegularizedFlex,
    value: string | FormProperty,
    key?: string,
    property?: FormProperty,
  ) {
    if (this.onSelectInit) return;
    this.service.beforeChange$.next();

    let falseValues = [undefined, null, ''];

    if (key) {
      select[key] =
        // 數字類型的欄位，如果不是程式碼，則轉換成回數字
        property?.isNumber && !checkValueWithCode(value as any)
          ? Number(value)
          : value;
      if (falseValues.includes(select[key]) || this._isObjEmpty(select[key])) {
        delete select[key];
      }
    } else {
      Object.keys(value).forEach((_key) => {
        select[_key] = value[_key];
        if (falseValues.includes(select[_key])) {
          delete select[_key];
        }
      });
    }

    if (select.type === 'video' && select.altContent) {
      if (key === 'previewUrl') {
        select.altContent.url = value;
      } else if (key === 'aspectRatio') {
        select.altContent.aspectRatio = value;
      }
    }

    if (property) {
      property.error = this.errorFunction(select)[key];
    }

    //沒有setTimeout會造成頁面重新渲染，導致blur事件失效
    setTimeout(() => {
      this.service.afterChange$.next();
    });
  }

  public async fixValueWhenBlur(
    select: RegularizedFlex,
    name: string,
    property: FormProperty,
  ) {
    if (select.type === 'video' && name === 'url') {
      await Bluebird.delay(100);
      const url = select.url;
      const fixUrl = this._removeBBCode(url);
      this.setValue(select, fixUrl, name, property);
    }
  }

  private _removeBBCode(input) {
    if (!input) return;

    // 移除字首的 BBCode 標籤
    const startBBCodeRegex = /^\[[^\[\]]+\]/;
    if (startBBCodeRegex.test(input)) {
      input = input.replace(startBBCodeRegex, '').trim();
    }

    // 移除字尾的 BBCode 標籤
    const endBBCodeRegex = /\[[^\[\]]+\]$/;
    if (endBBCodeRegex.test(input)) {
      input = input.replace(endBBCodeRegex, '').trim();
    }

    return input;
  }

  public setStyle(select: RegularizedFlex, value: string | FormProperty) {
    if (!select || !value) return;
    let styles = this.service.selectedParent.styles;
    if (!styles) this.service.selectedParent.styles = {};
    Object.keys(value).forEach((key) => {
      let falseValues = [undefined, null, ''];
      if (falseValues.includes(value[key])) delete value[key];
    });
    this.service.selectedParent.styles[select.type] = value;
  }

  private _isObjEmpty(obj) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  public openTooltipDialog(e: MouseEvent, template: TemplateRef<any>) {
    e.stopPropagation();
    this.tooltipDialogRef = this.matConnectedDialog.open(template);
  }
}
