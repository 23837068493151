import { Content } from '@ay/bot';
import cloneDeep from 'lodash/cloneDeep';
import extend from 'lodash/extend';

export class RegularizedFlex {
  public type: string;
  public contents: RegularizedFlex[] = [];
  public isExpanded: boolean = false;
  public isSelect: boolean = false;
  [key: string]: any;

  public static fromContentFlex(flex: Content.Flex) {
    let data = new RegularizedFlex();
    const cloned = cloneDeep(flex);
    data = extend(data, cloned);
    if (data.content === null) return null;
    if (data.content.contents['type'] == 'bubble') {
      data.content.contents = this.fromBubble(data.content.contents);
    } else {
      data.content.contents.contents.map((bubble) => this.fromBubble(bubble));
    }

    return data;
  }

  protected static fromBubble(bubble: RegularizedFlex): RegularizedFlex {
    let contents = [];
    contents.push(this.fromBlock(bubble, 'header'));
    contents.push(this.fromBlock(bubble, 'hero'));
    contents.push(this.fromBlock(bubble, 'body'));
    contents.push(this.fromBlock(bubble, 'footer'));
    bubble['contents'] = contents;
    return bubble;
  }

  protected static fromBlock(data: RegularizedFlex, key: string) {
    const value = data[key];

    if (!data[key]) {
      data[key] = { type: key, contents: [] };
    } else {
      data[key] = { type: key, contents: [value] };
    }

    return data[key];
  }

  public toContentFlex(): Content.Flex {
    const data = cloneDeep(this) as any;
    this.deleteBlockExtraAttributes(data.content);

    if (data.content.contents.type == 'bubble') {
      this.convertBubble(data.content.contents);
    } else {
      data.content.contents.contents.map((bubble) =>
        this.convertBubble(bubble),
      );
    }

    return new Content.Flex(data.content);
  }

  protected convertBubble(bubble: RegularizedFlex) {
    this.convertRootBlock(bubble, 'header');
    this.convertRootBlock(bubble, 'hero');
    this.convertRootBlock(bubble, 'body');
    this.convertRootBlock(bubble, 'footer');

    delete bubble.contents;
  }

  protected convertRootBlock(parent: RegularizedFlex, key: string) {
    let block = parent.contents.find((content) => content.type == key);

    if (!block || !block.contents?.length) {
      delete parent[key];
      return;
    }

    block = block.contents[0];

    if (block.type == 'image') {
      delete block.contents;
    }

    parent[key] = block;
  }

  protected deleteBlockExtraAttributes(data: RegularizedFlex) {
    delete data.isExpanded;
    delete data.isSelect;
    if (data.contents) {
      if (data.contents instanceof Array) {
        data.contents.forEach((content) =>
          this.deleteBlockExtraAttributes(content),
        );
      } else {
        this.deleteBlockExtraAttributes(data.contents);
      }
    }
  }
}
