import { Injectable } from '@angular/core';
import { Content, ContentType, PrivateMessage } from '@ay/bot';
import { AccountService } from '../service/account.service';
import { CouponService } from '../service/coupon.service';
import { AnnouncementMessage } from './announcement/announcement.message';
import { AudioMessage } from './audio/audio.message';
import { Message } from './base/base.message';
import { CardsMessage } from './cards/cards.message';
import { CouponRecordMessage } from './coupon/coupon-record/coupon-record.message';
import { CouponMessage } from './coupon/coupon.message';
import { FileMessage } from './file/file.message';
import { FlexMessage } from './flex/flex.message';
import { ImageMessage } from './image/image.message';
import { LocationMessage } from './location/location.message';
import { PosterMessage } from './poster/poster.message';
import { TextMessage } from './text/text.message';
import { VideoMessage } from './video/video.message';

@Injectable({
  providedIn: 'root',
})
export class MessageFactoryService {
  private readonly _messageFactories: {
    [type: string]: () => Message;
  } = {
    text: () => new TextMessage(),
    poster: () => new PosterMessage(),
    audio: () => new AudioMessage(),
    card: () => new CardsMessage(),
    cards: () => new CardsMessage(),
    image: () => new ImageMessage(),
    video: () => new VideoMessage(),
    coupon: () => new CouponMessage(this._couponService),
    'coupon-record': () => new CouponRecordMessage(),
    location: () => new LocationMessage(),
    announcement: () => new AnnouncementMessage(),
    unfollow: () => new AnnouncementMessage(),
    follow: () => new AnnouncementMessage(),
    leave: () => new AnnouncementMessage(),
    join: () => new AnnouncementMessage(),
    file: () => new FileMessage(),
    memberJoined: () => new AnnouncementMessage(),
    memberLeft: () => new AnnouncementMessage(),
    postback: () => new AnnouncementMessage(),
    action: () => new AnnouncementMessage(),
    'add-reaction': () => new AnnouncementMessage(),
    'add-comment': () => new AnnouncementMessage(),
    'remove-comment': () => new AnnouncementMessage(),
    flex: () => new FlexMessage(),
  };

  public constructor(
    private readonly _couponService: CouponService,
    private readonly _accountService: AccountService,
  ) {}

  public create(type: ContentType) {
    const messageFactory = this._messageFactories[type];
    if (messageFactory === undefined) {
      throw $localize`不支援的訊息類型 ${type}`;
    }
    return messageFactory();
  }

  public async createFromContent(
    content: Content.Any,
  ): Promise<Message<Content.Any>> {
    let type = content.type;

    if (content.type === 'template') {
      type = content.targetType;
    }

    const messageFactory = this._messageFactories[type];
    if (messageFactory === undefined) {
      throw $localize`不支援的訊息類型 ${type}`;
    }

    const message = messageFactory();
    await message.loadFromContent(content);
    return message;
  }

  public async createFromPrivateMessage(pm: PrivateMessage) {
    const message = await this.createFromContent(pm.record);
    message.sender = await this._accountService.fetchName(pm.accountId);
    message.accountId = pm.accountId;
    message.direction = pm.type;
    message.createdAt = new Date(pm.timestamp);
    message.status = pm.status;
    message.reason = pm.reason;

    message.userProfileId = pm.userProfileId;
    if (message.direction === 'Receive' && !pm.userProfileId) {
      message.userProfileId = pm.profileId;
    }

    return message;
  }
}

export class MessageFactory {
  public createInstance: () => Message;
  public createManual?: () => Promise<Message>;
}
