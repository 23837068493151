import { Component, ElementRef } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle,
} from '@angular/material/datepicker';
import {
  MatFormField,
  MatHint,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TagTreePickerComponent } from '../../../../components/tag-tree-picker/tag-tree-picker.component';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { FormComponent } from '../form.component';
import { FindRecordNode } from './class';

@Component({
  selector: 'flow-get-records-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD',
        },
        display: {
          dateInput: 'YYYY-MM-DD',
          monthYearLabel: 'YYYY MMM',
          dateA11yLabel: 'YYYY-MM-DD',
          monthYearA11yLabel: 'YYYY MMMM',
        },
      },
    },
  ],
  standalone: true,
  imports: [
    MatCheckbox,
    FormsModule,
    FlexModule,
    TagTreePickerComponent,
    MatFormField,
    MatLabel,
    MatInput,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatHint,
    MatDatepicker,
    LegacyAppearanceDirective,
  ],
})
export class FindPackageFormComponent extends FormComponent<FindRecordNode> {
  public constructor(public readonly elementRef: ElementRef) {
    super(elementRef);
  }

  public notText = $localize`非`;
}
