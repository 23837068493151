import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { Content } from '@ay/bot';
import { firstValueFrom } from 'rxjs';
import { FileSizePipe } from '../../pipe/file-size.pipe';
import { AudioMessage } from './audio.message';
import { AudioRecorderDialog } from './recorder/recorder.dialog';
import { AudioUploaderDialog } from './uploader/uploader.dialog';
const AUDIO_SIZE_LIMIT = 10 * 1024 * 1024;

@Injectable({
  providedIn: 'root',
})
export class AudioMessageService {
  public constructor(
    private readonly _matConnectedDialog: MatConnectedDialog,
    private readonly _fileSizePipe: FileSizePipe,
    private readonly _matSnackBar: MatSnackBar,
  ) {}

  public async createFromRecorder(): Promise<AudioMessage | null> {
    const dialogRef = this._matConnectedDialog.open(AudioRecorderDialog);
    const src: string = await firstValueFrom(dialogRef.afterClosed());
    if (!src) return null;
    const content = new Content.Audio(src);
    const message = new AudioMessage();
    await message.loadFromContent(content);
    return message;
  }

  public async createFromFile(file: File): Promise<AudioMessage> {
    if (file.size > AUDIO_SIZE_LIMIT) {
      const size = this._fileSizePipe.transform(AUDIO_SIZE_LIMIT, 'Byte');
      this._matSnackBar.open($localize`檔案大小最大只能 ${size}`);
      return;
    }

    const dialogRef = this._matConnectedDialog.open(AudioUploaderDialog, {
      data: file,
    });

    const src = await firstValueFrom(dialogRef.afterClosed());

    if (!src) return null;

    const content = new Content.Audio(src);
    const message = new AudioMessage();
    await message.loadFromContent(content);
    return message;
  }
}
