import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'operands',
    pure: true,
    standalone: true,
})
export class OperandsPipe implements PipeTransform {
  public transform(dataType: 'string' | 'number' | 'list'): string[] {
    if (dataType != 'number') return ['string', 'like', 'isExist', 'notExist'];
    return ['string', 'isExist', 'notExist', 'gt', 'gte', 'lt', 'lte'];
  }
}
