import { FlagRule } from '../flag-rule.class';
import { FromJsonOption } from '../rule';

export class ReservationRule extends FlagRule {
  public keywords: string[] = [$localize`預約`, $localize`延遲`];

  public constructor() {
    super('RESERVATION');
  }

  public toString() {
    return $localize`預約`;
  }

  public static schema: string = 'reservation';

  public static async fromShortCode(code: string, option: FromJsonOption) {
    return new ReservationRule();
  }
}
