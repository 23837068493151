import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'operandTranslate',
    pure: true,
    standalone: true,
})
export class OperandTranslatePipe implements PipeTransform {
  private _typeMap = {
    string: $localize`完全符合`,
    like: $localize`包含`,
    isExist: $localize`存在`,
    notExist: $localize`不存在`,
    gt: $localize`大於`,
    gte: $localize`大於等於`,
    lt: $localize`小於`,
    lte: $localize`小於等於`,
  };
  public transform(input: string): string {
    let res = this._typeMap[input];
    if (res) return res;
    return input;
  }
}
