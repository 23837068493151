import { NgIf } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import {
  MatFormField,
  MatHint,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import {
  MatList,
  MatListItem,
  MatListSubheaderCssMatStyler,
} from '@angular/material/list';
import { MatSelect } from '@angular/material/select';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import * as chrono from 'chrono-node';
import moment from 'moment';
import { ProgrammableComponent } from '../../../../components/programmable/programmable.component';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../../material/tooltip/tooltip';
import {
  ScheduleTaskManagerComponent,
  ScheduleTaskManagerData,
} from '../../schedule-task-manager/schedule-task-manager.component';
import { FormComponent } from '../form.component';
import { TimerNode } from './class';

@Component({
  selector: 'flow-timer-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    MatOption,
    NgIf,
    MatCheckbox,
    MatButton,
    MatInput,
    MatSuffix,
    MatHint,
    ProgrammableComponent,
    MatList,
    MatDivider,
    MatListSubheaderCssMatStyler,
    MatListItem,
    MatTooltip,
    LegacyAppearanceDirective,
  ],
})
export class TimerFormComponent extends FormComponent<TimerNode> {
  public width = 600;
  public now = moment().format('YYYY-MM-DD HH:mm:ss');

  public constructor(
    public elementRef: ElementRef,
    private _matConnectedDialog: MatConnectedDialog,
  ) {
    super(elementRef);
    setTimeout(() => {
      this.fixTimerType();
    }, 300);
  }

  public fixTimerType() {
    if (this.node.timerType === ('setTimeout' as any))
      this.node.timerType = 'DelaySeconds';
  }

  public relativeTimePreview(val: string): string {
    if (!val) return $localize`無法辨識`;
    let datetime: Date;

    datetime = chrono.parseDate(val, new Date(), {
      forwardDate: true,
    });
    if (datetime === null) {
      datetime = chrono.zh.parseDate(val, new Date(), {
        forwardDate: true,
      });
    }
    if (!datetime) return $localize`無法辨識`;
    return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
  }

  public openDialog(): void {
    this._matConnectedDialog.open<
      ScheduleTaskManagerComponent,
      ScheduleTaskManagerData
    >(ScheduleTaskManagerComponent, {
      width: '800px',
      data: { nodeId: this.node.id },
    });
  }

  public onTimerTypeChanged() {
    this.node.processed = this.node.timerType !== 'DelaySeconds';
  }

  public exampleDate = {
    chirstmas: moment()
      .month(11)
      .date(25)
      .hour(0)
      .minute(0)
      .second(0)
      .format('YYYY-MM-DD HH:mm:ss'),
    newYear:
      "${moment('" +
      moment().add(1, 'year').month(0).date(1).format('YYYY-MM-DD') +
      "').add(-10,'minute').format('YYYY-MM-DD HH:mm:ss')}",
    motherDay:
      "${moment('" +
      moment().month(4).date(1).format('YYYY-MM-DD') +
      "').day('Sunday').add(2,'week').format('YYYY-MM-DD HH:mm:ss')}",
  };
}
