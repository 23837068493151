import { GosuValidate } from '../../../../components/gosu-validator/gosu-validator.class';
import { Node } from '../../node/class';

export class ExchangeNode extends Node {
  public icon = 'fa-exchange-alt';

  public action: string = 'GET_EXCHANGE_LIST';
  public exchangeId: string = '';
  public methodId: string = '';
  public profileId: string = '';
  public isAsync: false = false;
  public responseVar: string = 'exchange';
  public targetProfileId: string;

  public readonly extraProperties = [
    'responseVar',
    'action',
    'exchangeId',
    'methodId',
    'profileId',
    'isAsync',
    'targetProfileId',
  ];

  public async getDisplay() {
    if (this.name !== '兌換') return this.name;
    switch (this.action) {
      case 'GET_EXCHANGE_LIST':
        return $localize`讀取兌換活動清單`;

      case 'GET_METHOD_LIST':
        return $localize`讀取兌換辦法清單`;

      case 'EXCHANGE':
        return $localize`兌換`;
    }
  }

  public checkError(): void {
    this.hasError = false;
    const isCheckError = window.localStorage.getItem('isDebug') === 'true';

    if (isCheckError) {
      switch (this.action) {
        case 'GET_EXCHANGE_LIST':
          break;

        case 'GET_METHOD_LIST':
          if (GosuValidate.required(this.exchangeId)) {
            this.hasError = true;
          }
          break;

        case 'EXCHANGE':
          if (GosuValidate.required(this.exchangeId)) {
            this.hasError = true;
          }
          if (GosuValidate.required(this.methodId)) {
            this.hasError = true;
          }
          break;
      }
    }
  }
}
