import { RichMenuDto } from '@ay-gosu/server-shared';
import { Node } from '../class';

export class RichMenuNode extends Node {
  public icon = 'menu';
  public profileId: string;
  public botId: number;
  public richMenuId: string;
  public action: 'link' | 'unlink' = 'link';
  public processed = true;
  public richMenu: RichMenuDto;
  public readonly extraProperties = [
    'profileId',
    'action',
    'botId',
    'richMenuId',
    'processed',
  ];
}
