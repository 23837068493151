<mat-form-field legacyAppearance>
  <mat-label i18n>字體大小</mat-label>
  <mat-select [(ngModel)]="node.fontSize">
    <mat-option value="12">12</mat-option>
    <mat-option value="14">14</mat-option>
    <mat-option value="18">18</mat-option>
    <mat-option value="24">24</mat-option>
    <mat-option value="48">48</mat-option>
    <mat-option value="74">74</mat-option>
  </mat-select> </mat-form-field
><br />

<mat-form-field class="text" legacyAppearance>
  <mat-label i18n>標題</mat-label>
  <input matInput [(ngModel)]="node.name" />
</mat-form-field>

<mat-form-field class="text" legacyAppearance>
  <mat-label i18n>文字內容</mat-label>
  <textarea matInput [(ngModel)]="node.text" rows="5"></textarea>
</mat-form-field>
