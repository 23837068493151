<svg:rect
  [attr.width]="nodeComponent.width"
  [attr.height]="em * 2"
  [attr.rx]="em"
  [attr.fill]="color"
  [attr.stroke]="nodeComponent.node.borderColor"
  [attr.stroke-width]="1"
></svg:rect>

<svg:text
  [attr.x]="em"
  [attr.y]="0.25 * em"
  [style.font-size.px]="em"
  alignment-baseline="text-before-edge"
  [attr.fill]="frontColor"
>
  {{ text }}
</svg:text>

<svg:g
  class="junction"
  [index]="index"
  [dx]="nodeComponent.width"
  [dy]="em"
  type="output"
  [node]="nodeComponent.node"
  [color]="borderColor"
  [dash]="dash"
></svg:g>
