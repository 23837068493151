import { Node } from '../class';

export type ProfileType = 'User' | 'Group' | 'Room';
export type ProfilePropertyType = 'string' | 'like' | 'isExist' | 'notExist';
export type ProfilePropertyCondition = {
  key: string;
  type: ProfilePropertyType;
  value: string;
};

export class FindProfileNode extends Node {
  public icon = 'search';
  public tag: number[] = [];
  public property: ProfilePropertyCondition[] = [];
  public ids = '';
  public nickname = '';
  public message = '';
  public profileType: ProfileType[] = ['User', 'Group', 'Room'];
  public responseVar = 'profiles';
  public includeTag = false;
  public includeProperty = false;
  public includeInfo = false;
  public readonly extraProperties = [
    'tag',
    'property',
    'ids',
    'nickname',
    'message',
    'profileType',
    'responseVar',
    'includeTag',
    'includeProperty',
    'includeInfo',
  ];
}
