import { Injectable } from '@angular/core';
import { Command } from './command';

@Injectable({
  providedIn: 'root',
})
export class Invoker {
  protected redoStack: Command[] = [];
  protected undoStack: Command[] = [];

  public async do(command: Command) {
    await command.do();
    this.undoStack.push(command);
    this.redoStack = [];
  }

  public async redo() {
    const command = this.redoStack.pop();
    if (command === undefined) return;
    this.undoStack.push(command);
    await command.do();
  }

  public undo() {
    const command = this.undoStack.pop();
    if (command === undefined) return;
    command.undo();
    this.redoStack.push(command);
  }

  public clear() {
    this.redoStack = [];
    this.undoStack = [];
  }
}
