import { AsyncPipe } from '@angular/common';
import { Component, Inject, Optional, forwardRef } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { getDutchPaginatorIntl } from '../../../paginator';
import { CouponTypePipe } from '../../../pipe/coupon-type.pipe';
import { MomentPipe } from '../../../pipe/moment.pipe';
import {
  CouponColumnWithStatus,
  CouponService,
} from '../../../service/coupon.service';
import { MessageIconPipe } from '../../message-icon.pipe';
import { Package } from '../../package.class';
import { CouponPreviewComponent } from '../coupon-preview/coupon-preview.component';

@Component({
  selector: 'cp-coupon-picker',
  templateUrl: './coupon-picker.component.html',
  styleUrls: ['./coupon-picker.component.scss'],
  standalone: true,
  imports: [
    MatIcon,
    FlexModule,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatPaginator,
    CouponPreviewComponent,
    MatButton,
    AsyncPipe,
    CouponTypePipe,
    MomentPipe,
    forwardRef(() => MessageIconPipe),
  ],
  providers: [{ provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }],
})
export class CouponPickerComponent {
  public selectedCoupon: CouponColumnWithStatus = null;

  public allList$ = this._couponService.all$.pipe(
    map((list) =>
      list.filter((coupon) => {
        if (coupon.isLimited && this._package?.origin === 'broadcast') {
          return false;
        }
        return [`開放領取中`, `開放使用中`].includes(coupon.status);
      }),
    ),
  );
  public page$ = new BehaviorSubject<number>(0);

  public pageSize$ = new BehaviorSubject<number>(10);

  public list$ = combineLatest(this.allList$, this.page$, this.pageSize$).pipe(
    map(([list, page, pageSize]) => {
      const fileterList = list.slice(page * pageSize, (page + 1) * pageSize);
      if (fileterList.length) this.selectedCoupon = fileterList[0];
      let source = new MatTableDataSource(fileterList);
      return source;
    }),
  );

  public total$ = this.allList$.pipe(map((item) => item.length));

  public columns: string[] = ['status', 'title', 'type', 'time'];

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly _package: Package,
    @Optional()
    private readonly _matDialogRef: MatDialogRef<CouponPickerComponent, number>,
    private readonly _couponService: CouponService,
  ) {}

  public submit() {
    this._matDialogRef.close(this.selectedCoupon?.id);
  }
}
