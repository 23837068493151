import { Node } from '../class';

export class ReplyNode extends Node {
  public icon = 'reply';
  public content: string = $localize`嗨`;
  public way: string = 'private_replies';
  public imgUrl: string = '';
  public processed = true;
  public readonly extraProperties = ['content', 'way', 'imgUrl', 'processed'];
}
