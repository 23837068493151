import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isString',
    pure: true,
    standalone: true,
})
export class IsStringPipe implements PipeTransform {
  public transform(value: any) {
    return typeof value === 'string';
  }
}
