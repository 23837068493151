import { Content } from '@ay/bot';
import { Message } from '../base/base.message';

export class AnnouncementMessage extends Message<Content.Text> {
  public type = 'announcement';

  public constructor() {
    super();
    if (this.content === undefined) {
      this.content = new Content.Text('');
    }
  }

  public async loadFromContent(content: Content.Text) {
    if (this.content === undefined) {
      this.content = new Content.Text('');
    }
    this.content.type = content.type as any;
  }

  public toString(): string {
    return this.content.content;
  }

  public toJSON(): Content.Text {
    throw new Error($localize`公告類訊息僅顯示用，不會儲存回資料庫`);
  }
}
