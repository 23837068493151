import {
  ChangeDetectorRef,
  Component,
  Inject,
  forwardRef,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Content } from '@ay/bot';
import { Subject } from 'rxjs';
import { MessageAffectedComponent } from '../../message-affected.component';
import { TemplatePreviewComponent } from '../../template/template-preview/template-preview.component';
import { TemplatePropertiesEditorComponent } from '../../template/template-properties-editor/template-properties-editor.component';
import { PosterComponent } from '../poster.component';
import { PosterMessage } from '../poster.message';

@Component({
  selector: 'ms-poster-template-editor-dialog',
  templateUrl: './template-editor.dialog.html',
  styleUrls: ['./template-editor.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    TemplatePreviewComponent,
    forwardRef(() => PosterComponent),
    TemplatePropertiesEditorComponent,
    MatButton,
  ],
})
export class PosterTemplateEditorDialog extends MessageAffectedComponent<PosterMessage> {
  public notifier = new Subject<void>();

  public constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected matDialogRef: MatDialogRef<PosterTemplateEditorDialog>,
    @Inject(MAT_DIALOG_DATA)
    message: PosterMessage,
  ) {
    super(changeDetectorRef);
    this.message = message;
  }

  public afterTemplateExec(result: Content.Poster) {
    this.message.content.content = result.content;
  }

  public afterPropertyChange(event: any) {
    this.notifier.next();
  }

  public close() {
    this.matDialogRef.close();
  }
}
