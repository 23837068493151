import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  MatError,
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PropertyConfigTargetType } from '@ay-gosu/server-shared';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';
import { LegacyAppearanceDirective } from '../../material/legacy/mat-form-field/legacy-appearance.directive';
import { PropertyConfigService } from '../../service/property-config.service';
import { TranslateService } from '../../service/translate.service';

@Component({
  selector: 'dl-create-property-config-dialog',
  templateUrl: './create-property-config.dialog.html',
  styleUrls: ['./create-property-config.dialog.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    MatOption,
    MatInput,
    NgIf,
    MatError,
    MatIconButton,
    MatSuffix,
    MatIcon,
    MatCheckbox,
    MatButton,
    LegacyAppearanceDirective,
  ],
})
export class CreatePropertyConfigDialog implements OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  public displayInList: boolean;

  public displayInDetail: boolean;

  @Input()
  public set target(target: PropertyConfigTargetType) {
    this.targetTypeControl.setValue(target);
  }

  public targetTypeControl = new FormControl('profile');

  public nameControl = new FormControl('', [Validators.required]);

  public keyControl = new FormControl(
    '',
    [
      Validators.required,
      this._onlyAlphaOrNumberOrUnderline.bind(this),
      this._startWithoutNumber.bind(this),
    ],
    this._duplicateKeyValidator.bind(this),
  );

  public dataTypeControl = new FormControl('string', [Validators.required]);

  public group = new FormGroup<any>({
    targetType: this.targetTypeControl,
    name: this.nameControl,
    key: this.keyControl,
    dataType: this.dataTypeControl,
  });

  private _whenTargetTypeChangedRevalidateKeyCtrl =
    this.targetTypeControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => this.keyControl.markAsDirty());

  public lastTranslatedName = '';

  public constructor(
    private _matDialogRef: MatDialogRef<any>,
    private _propertyConfigService: PropertyConfigService,
    private _matSnackBar: MatSnackBar,
    private _translateService: TranslateService,
  ) {}

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public async translateKey() {
    let en = await this._translateService.translateKey(this.nameControl.value);
    this.keyControl.setValue(en);
    this.lastTranslatedName = this.nameControl.value;
  }

  public _onlyAlphaOrNumberOrUnderline(ctrl: FormControl) {
    if (/\W/.test(ctrl.value)) {
      return { onlyAlphaOrNumberOrUnderline: true };
    }
    return false;
  }

  public _startWithoutNumber(ctrl: FormControl) {
    if (/^[0-9]/.test(ctrl.value)) {
      return { startWithoutNumber: true };
    }
    return null;
  }

  private async _duplicateKeyValidator(control: FormControl) {
    const configs = await firstValueFrom(this._propertyConfigService.all$);

    const targetType = this.targetTypeControl.value;
    const key = this.keyControl.value;

    const exist = configs.find(
      (config) => config.targetType === targetType && config.key === key,
    );

    if (exist) {
      return { duplicateKey: { valid: false } };
    } else {
      return null;
    }
  }

  public async submit() {
    const value = this.group.getRawValue();
    value.displayInDetail = this.displayInDetail;
    value.displayInList = this.displayInList;
    await this._propertyConfigService.create(value);
    this._matDialogRef.close(value.key);
    this._matSnackBar.open($localize`成功建立屬性${value.name}`);
  }
}
