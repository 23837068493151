import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { NGX_MONACO_EDITOR_CONFIG, NgxMonacoEditorConfig } from './config';
import { MonacoEditorLoaderService } from './monaco-editor-loader.service';

let loadedMonaco = false;
let loadPromise: Promise<void>;
declare const require: any;

@Component({ template: '' })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class BaseEditor implements AfterViewInit, OnDestroy {
  @ViewChild('editorContainer', { static: true })
  public editorContainer: ElementRef;

  @Output()
  public init = new EventEmitter<any>();

  protected _editor: any;
  protected _options: any;
  protected _windowResizeSubscription: Subscription;

  public constructor(
    @Inject(NGX_MONACO_EDITOR_CONFIG)
    protected readonly editorConfig: NgxMonacoEditorConfig,
    protected readonly loaderService: MonacoEditorLoaderService,
  ) {}

  public ngAfterViewInit(): void {
    this.loaderService.enableMonaco$.then((enabled) => {
      if (enabled) {
        this.initMonaco(this._options);
      }
    });
  }

  protected abstract initMonaco(options: any): void;

  public ngOnDestroy() {
    if (this._windowResizeSubscription) {
      this._windowResizeSubscription.unsubscribe();
    }
    if (this._editor) {
      this._editor.dispose();
      this._editor = undefined;
    }
  }
}
