import { Component, HostBinding, Input } from '@angular/core';
import { Flex } from '@ay/bot';

@Component({
    selector: 'flex-filler',
    templateUrl: './filler.component.html',
    styleUrls: ['./filler.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[style.flex]': "data.flex + ' ' + data.flex + ' 0'",
    },
    standalone: true,
})
export class FlexFillerComponent {
  @Input()
  public data: Flex.Block.Filler;

  @Input()
  public bubble: Flex.Content.Bubble;

  @Input()
  public flex: Flex.Message;

  @Input()
  public level: number = -1;

  protected flexClass = ['fl0', 'fl1', 'fl2'];

  @HostBinding('class')
  public get hostClass(): string {
    const list = ['mdBxFiller'];
    list.push(this.flexClass[this.data.flex]);
    return list.filter(Boolean).join(' ');
  }
}
