<div class="picture-wrap">
  <ng-container *ngIf="profile$ | async; let profile">
    <img
      class="picture"
      [src]="profile.picture"
      element-ref
      #elementRef="elementRef"
      (load)="onProfilePictureLoaded(elementRef)"
      (error)="onProfilePictureError(elementRef)"
    />
  </ng-container>
</div>
