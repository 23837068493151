import { RuleOp } from '@ay-gosu/types';
import { ensureTheElementIsWhitelisted } from '@ay/util';
import { delay } from 'bluebird';
import { PropertyRule } from '../property.class';

export class NumberPropertyRule extends PropertyRule {
  public static op = [
    'LESS',
    'EQUAL',
    'GREATER',
    'GREATER_EQUAL',
    'LESS_EQUAL',
    'NOT_EQUAL',
    'BETWEEN',
    'EXISTS',
    'NOT_EXISTS',
  ] as RuleOp[];

  public async afterTypeChanged(isUserEvent = false) {
    this.op = ensureTheElementIsWhitelisted(this.op, ...NumberPropertyRule.op);
    await delay(1);
    this.value = parseInt(this.value + '', 10) || 0;
    this.value2 = parseInt(this.value2 + '', 10) || 0;
    if (isUserEvent) {
      this.component.property.number.opTrigger.openMenu();
    }
  }

  public toString() {
    switch (this.op) {
      case 'EXISTS':
        return $localize`[${this.property.name}]有資料`;
      case 'NOT_EXISTS':
        return $localize`[${this.property.name}]沒有資料`;
      case 'LESS':
        return $localize`[${this.property.name}]小於'${this.value}'`;
      case 'LESS_EQUAL':
        return $localize`[${this.property.name}]小於等於'${this.value}'`;
      case 'EQUAL':
        return $localize`[${this.property.name}]等於於'${this.value}'`;
      case 'GREATER_EQUAL':
        return $localize`[${this.property.name}]大於等於'${this.value}'`;
      case 'GREATER':
        return $localize`[${this.property.name}]大於'${this.value}'`;
      case 'NOT_EQUAL':
        return $localize`[${this.property.name}]不於'${this.value}'`;
      case 'BETWEEN':
        return $localize`[${this.property.name}]介於'${this.value}'~'${this.value2}'`;
    }
  }

  public toRuleObject(json: any = {}) {
    super.toRuleObject(json);
    json.key = this.property.key;
    this.value = parseInt(this.value + '', 10) || 0;
    this.value2 = parseInt(this.value2 + '', 10) || 0;
    if (this.op == 'BETWEEN') {
      json.value = this.value;
      json.value2 = this.value2;
    } else {
      json.value = this.value;
    }
    return json;
  }
}
