import { NgFor } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { FlowService } from '../../flow.service';
import { FormComponent } from '../form.component';
import { SendStackerNode } from './class';

@Component({
  selector: 'flow-card-stacker-form',
  templateUrl: './form.component.html',
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatSelect,
    NgFor,
    MatOption,
    LegacyAppearanceDirective,
  ],
})
export class SendStackerFormComponent extends FormComponent<SendStackerNode> {
  public width = 400;

  public cardMergeLimits: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  public constructor(
    public readonly flowService: FlowService,
    public readonly elementRef: ElementRef,
  ) {
    super(elementRef);
  }
}
