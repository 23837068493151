export const videoUrlTooltip = `
  <div>
    <span>
      如您已準備好影片但不知道如何獲得「.mp4」的網址，<br>
    </span>
    <span>
      您可以先將影片上傳至免費圖片空間，如：Imgur。<br><br>
    </span>
    <span>
      如何使用 Imgur 獲得影片網址？<br><br>
    </span>
    <span>
      1. 前往 Imgur.com<br>
    </span>
    <span>
      2. 上傳您的影片<br>
    </span>
    <span>
      3. 「Get Share Link」並複製「BBCode (Forums)」的網址<br><br>
    </span>
    <span>
      請注意：Imgur只能上傳60秒以內的影片。<br><br>
    </span>
    <img src="./assets/flex/video-1.png" alt="Imgur">
    <img src="./assets/flex/video-2.png" alt="Imgur">
  </div>`;

export const flexTooltip = `<div>
    <span>
      當同一容器內包含不同素材區塊時，可透過「彈性變<br>
    </span>
    <span>
      數」設定每個區塊的呈現比例。<br><br>
    </span>
    <span>
      舉例：下圖容器1內包含圖片1、容器2包含圖片2和圖片<br>
    </span>
    <span>
      3。容器1和容器2的寬度比例為 2:1，故容器1的彈性係數<br>
    </span>
    <span>
      為「2」、容器2的彈性係數為「1」；同理，圖片2和圖<br>
    </span>
    <span>
      片3的高度比例也是 2:1，故圖片2的彈性係數為「2」、<br>
    </span>
    <span>
      圖片3的彈性係數為「1」。<br>
    </span>
    <img src="./assets/flex/flex-1.png" alt="Imgur">
  </div>`;
