import { AsyncPipe, NgFor } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostListener } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCard, MatCardContent, MatCardImage } from '@angular/material/card';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { Content, Flex } from '@ay/bot';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

export interface Template {
  title: string;
  data: Flex.Message;
  imgUrl: string;
}

@Component({
  selector: 'ms-flex-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    MatCard,
    MatCardImage,
    MatCardContent,
    MatDivider,
    MatButton,
    AsyncPipe,
    MatIconButton,
    MatIcon,
  ],
})
export class FlexPickerComponent {
  public selected: Flex.Message;

  public templates$: Observable<Template[]> = environment.directus.url
    ? this._httpClient
        .get(
          `${environment.directus.url}/${environment.directus.project}/items/flex_templates`,
          { params: { fields: ['title', 'image.data', 'data'].join(',') } },
        )
        .pipe(
          map((res) =>
            res['data'].map((raw) => {
              const { title, data, image } = raw;
              return {
                title,
                data: new Content.Flex(JSON.parse(data)),
                imgUrl: image.data.full_url,
              };
            }),
          ),
        )
    : of(
        environment.flexTemplates.map((template) => ({
          ...template,
          data: new Content.Flex(template.data as any),
        })),
      );

  public constructor(
    private readonly _matDialogRef: MatDialogRef<FlexPickerComponent>,
    private readonly _httpClient: HttpClient,
  ) {}

  @HostListener('window:keyup', ['$event'])
  protected onKeyup(event) {
    if (event.code == 'Enter') {
      this.submit();
    }
  }

  public submit() {
    this._matDialogRef.close(cloneDeep(this.selected));
  }

  public close() {
    this._matDialogRef.close();
  }
}
