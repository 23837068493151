<div class="container">
  <ng-container *ngIf="status === 'upload'; else recording">
    <gosu-progress-bar [value]="progress$ | async" i18n>
      影片上傳中，請稍候
    </gosu-progress-bar>
  </ng-container>

  <ng-template #recording>
    <video class="full" #video></video> <br />
  </ng-template>

  <div class="button-wrap">
    <button
      mat-raised-button
      color="primary"
      (click)="start()"
      *ngIf="status === 'init'"
      i18n
    >
      開始錄影
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="stop()"
      *ngIf="status === 'recording'"
      i18n
    >
      結束
    </button>

    <ng-container *ngIf="status === 'recorded'">
      <button mat-raised-button color="primary" (click)="upload()" i18n>
        確定上傳
      </button>

      <button mat-raised-button (click)="restart()" i18n>重新錄製</button>
    </ng-container>
  </div>
</div>
