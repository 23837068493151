<div class="header" i18n>編輯 {{ message.templateName }}</div>

<div class="container" fxLayout="row nowrap">
  <ms-template-preview
    [message]="message"
    [template]="message.template"
    [notifier]="notifier"
    (afterTemplateExec)="afterTemplateExec($any($event))"
  >
    <ms-poster [message]="message" mode="READ"> </ms-poster>
  </ms-template-preview>

  <div class="form">
    <ms-template-properties-editor
      [properties]="message.template.properties"
      (afterPropertyChange)="afterPropertyChange($event)"
    >
    </ms-template-properties-editor>
  </div>
</div>

<div class="footer">
  <div class="tar">
    <button mat-raised-button color="primary" (click)="close()" i18n>
      確認
    </button>
  </div>
</div>
