<div class="flex flex-row gap-4">
  <mat-checkbox
    class="w-[140px]"
    [(ngModel)]="node.isAsync"
    color="primary"
    i18n
  >
    {{ node.isAsync ? notText : '' }}同步模式
  </mat-checkbox>

  <mat-checkbox
    class="w-[140px]"
    [(ngModel)]="node.processed"
    value="true"
    color="primary"
    i18n
  >
    標示為已處理
  </mat-checkbox>
</div>

<div class="flex flex-row gap-4">
  <mat-form-field class="flex-1" legacyAppearance>
    <mat-label>Method</mat-label>
    <mat-select [(ngModel)]="node.method">
      <mat-option value="get">GET</mat-option>
      <mat-option value="post">POST</mat-option>
      <mat-option value="delete">DELETE</mat-option>
      <mat-option value="put">PUT</mat-option>
      <mat-option value="patch">PATCH</mat-option>
    </mat-select>
  </mat-form-field>

  <programmable [(value)]="node.uri" class="flex-4">
    <mat-form-field class="w-full" legacyAppearance>
      <mat-label>URL</mat-label>
      <input matInput [(ngModel)]="node.uri" />
    </mat-form-field>
  </programmable>
</div>

<div class="flex flex-row gap-4">
  <mat-form-field
    class="flex-1"
    legacyAppearance
    matTooltip="回傳的資料會存入 tmp.回傳變數 中"
    i18n-matTooltip="回傳的資料會存入 tmp.回傳變數 中"
  >
    <mat-label i18n>回傳變數名稱</mat-label>
    <input matInput [(ngModel)]="node.responseVar" />
    <button
      mat-icon-button
      matSuffix
      (click)="copy('tmp.' + node.responseVar)"
      class="text-white opacity-30 hover:opacity-100 transition-opacity"
    >
      <mat-icon>content_copy</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field
    class="flex-1"
    legacyAppearance
    matTooltip="回傳的資料會先經過解析，如果解析失敗則會發生異常"
    i18n-matTooltip="回傳的資料會先經過解析，如果解析失敗則會發生異常"
  >
    <mat-label i18n>回傳資料型態</mat-label>
    <mat-select [(ngModel)]="node.responseType" name="item" i18n>
      <mat-option value="json">JSON</mat-option>
      <mat-option value="csv">CSV</mat-option>
      <mat-option value="rss">RSS / XML</mat-option>
      <mat-option value="string">純文字</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="flex-1" legacyAppearance>
    <mat-label i18n>編碼</mat-label>
    <mat-select [(ngModel)]="node.encoding" name="item" i18n>
      <mat-option
        value="utf-8"
        matTooltip="會自動去除 UTF8-BOM"
        i18n-matTooltip="會自動去除 UTF8-BOM"
      >
        UTF 8
      </mat-option>
      <mat-option value="big5">BIG 5</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="flex flex-row gap-4">
  <mat-form-field
    class="flex-1"
    legacyAppearance
    matTooltip="當 API 請求成功後，會將回傳的資料快取在記憶體中，未來如果有相同參數的請求，會直接回傳快取的資料，單位為毫秒，0 為不使用快取，最多為 600000 毫秒(10 分鐘)"
    i18n-matTooltip="
      當 API
      請求成功後，會將回傳的資料快取在記憶體中，未來如果有相同參數的請求，會直接回傳快取的資料，單位為毫秒，0
      為不使用快取，最多為 600000 毫秒(10 分鐘)
    "
  >
    <mat-label i18n>快取時間</mat-label>
    <input
      matInput
      type="number"
      [(ngModel)]="node.cacheTime"
      min="0"
      max="600000"
    />
    <span matTextSuffix i18n>毫秒</span>
  </mat-form-field>

  <mat-form-field
    class="flex-1"
    legacyAppearance
    matTooltip="當 API 請求失敗時，會自動重試的次數，0 為不使用重試，最多為 10 次"
    i18n-matTooltip="
      當 API 請求失敗時，會自動重試的次數，0 為不使用重試，最多為 10 次
    "
  >
    <mat-label i18n>重試次數</mat-label>
    <input matInput type="number" [(ngModel)]="node.retry" max="10" min="0" />
  </mat-form-field>

  <mat-form-field class="flex-1" legacyAppearance>
    <mat-label i18n>重試間隔</mat-label>
    <input
      matInput
      type="number"
      [(ngModel)]="node.retryInterval"
      min="0"
      max="10000"
      matTooltip="當 API 請求失敗時，會自動重試的間隔，0 為不使用重試，最多為 10000 毫秒"
      i18n-matTooltip="
        當 API 請求失敗時，會自動重試的間隔，0 為不使用重試，最多為 10000 毫秒
      "
    />
    <span matTextSuffix i18n>毫秒</span>
  </mat-form-field>
</div>

<div class="mt-2">
  <mat-tab-group
    (selectedIndexChange)="activeTab = $event"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
  >
    <mat-tab label="標頭 (Headers)" i18n-label="標頭 (Headers)">
      @for (header of node.headers; let index = $index; track index) {
        <div class="mt-2 flex flex-row !items-center gap-4">
          <div class="w-6 text-center pt-6 opacity-30">{{ index + 1 }}.</div>

          <mat-form-field class="flex-1" legacyAppearance>
            <mat-label>Key</mat-label>
            <input matInput [(ngModel)]="header.key" />
          </mat-form-field>

          <programmable [(value)]="header.value" class="flex-1">
            <mat-form-field class="w-full" legacyAppearance>
              <mat-label>Value</mat-label>
              <input matInput [(ngModel)]="header.value" />
            </mat-form-field>
          </programmable>

          <button
            mat-icon-button
            (click)="removeHeader(index)"
            class="opacity-30 hover:opacity-100 transition-opacity"
            matTooltip="刪除"
            i18n-matTooltip="刪除"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      }

      <br />

      <div class="flex !justify-center">
        <button mat-stroked-button (click)="addHeader()" i18n>
          <mat-icon>add</mat-icon>新增標頭
        </button>
      </div>
    </mat-tab>

    <mat-tab
      label="請求參數 (Query String)"
      i18n-label="請求參數 (Query String)"
    >
      @for (query of node.query; let index = $index; track index) {
        <div class="mt-2 flex flex-row !items-center gap-4">
          <div class="w-6 text-center pt-6 opacity-30">{{ index + 1 }}.</div>

          <mat-form-field class="flex-1" legacyAppearance>
            <mat-label>Key</mat-label>
            <input matInput [(ngModel)]="query.key" />
          </mat-form-field>

          <programmable [(value)]="query.value" class="flex-1">
            <mat-form-field class="w-full" legacyAppearance>
              <mat-label>Value</mat-label>
              <input matInput [(ngModel)]="query.value" />
            </mat-form-field>
          </programmable>

          <button
            mat-icon-button
            (click)="removeQuery(index)"
            class="opacity-30 hover:opacity-100 transition-opacity"
            matTooltip="刪除"
            i18n-matTooltip="刪除"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      }

      <br />

      <div class="tac">
        <button mat-stroked-button (click)="addQueryString()" i18n>
          <mat-icon>add</mat-icon>
          新增請求參數
        </button>
      </div>
    </mat-tab>

    @if (['post', 'put', 'patch', 'delete'].includes(node.method)) {
      <mat-tab label="資料 (Body)" i18n-label="資料 (Body)">
        <mat-form-field class="w-[260px]" legacyAppearance>
          <mat-select
            [(ngModel)]="node.bodyType"
            (ngModelChange)="afterChangeBodyType(node.bodyType)"
            i18n
          >
            <mat-option value="x-www-form-urlencoded">
              表單格式 x-www-form-urlencoded
            </mat-option>
            <mat-option value="raw">純文字 raw</mat-option>
          </mat-select>
        </mat-form-field>

        @switch (node.bodyType) {
          @case ('raw') {
            @if (activeTab === 2 && (enableMonaco$ | async)) {
              <ngx-monaco-editor
                class="!p-0"
                [options]="editorOptions"
                [(ngModel)]="node.body"
              >
              </ngx-monaco-editor>
            } @else {
              <textarea
                class="text-primary font-mono p-6 w-full h-[400px] border-none bg-code leading-6"
                [(ngModel)]="node.body"
              >
              </textarea>
            }
          }

          @case ('x-www-form-urlencoded') {
            @for (form of node.body; let index = $index; track index) {
              <div class="mt-2 flex flex-row !items-center gap-4">
                <div class="w-6 text-center pt-6 opacity-30">
                  {{ index + 1 }}.
                </div>

                <mat-form-field class="flex-1" legacyAppearance>
                  <mat-label>Key</mat-label>
                  <input matInput [(ngModel)]="form.key" />
                </mat-form-field>

                <programmable [(value)]="form.value" class="flex-1">
                  <mat-form-field class="w-full" legacyAppearance>
                    <mat-label>Value</mat-label>
                    <input matInput [(ngModel)]="form.value" />
                  </mat-form-field>
                </programmable>

                <button
                  mat-icon-button
                  class="opacity-30 hover:opacity-100 transition-opacity"
                  (click)="removeBody(index)"
                  matTooltip="刪除"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            }

            <br />

            <div class="flex flex-row !justify-center">
              <button mat-stroked-button (click)="addBody()" i18n>
                <mat-icon>add</mat-icon>
                新增資料欄位
              </button>
            </div>
          }
        }
      </mat-tab>
    }
  </mat-tab-group>
</div>
