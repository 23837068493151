import { Component } from '@angular/core';
import { host, NodeComponent } from '../node.component';
import { FindRecordNode } from './class';
import { JunctionComponent } from '../../junction/junction.component';
import { NgIf } from '@angular/common';

@Component({
    selector: ':svg:g.get-records.node',
    templateUrl: '../node.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['node'],
    standalone: true,
    imports: [NgIf, JunctionComponent],
})
export class FindPackageNodeComponent extends NodeComponent<FindRecordNode> {}
