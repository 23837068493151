import { NgFor, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardTitle,
} from '@angular/material/card';
import { MatOption } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { ProgrammableComponent } from '../../../../../components/programmable/programmable.component';
import { ColorPickerComponent } from '../../../../../material/color-picker/color-picker/color-picker.component';
import { LegacyAppearanceDirective } from '../../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { RegularizedFlex } from '../../../regularized-flex.class';
import { FlexChinesePipe } from '../../chinese.pipe';
import { FlexEditorService } from '../../editor.service';
import { ERROR_MESSAGE } from '../../error';
import { FormProperty } from '../form';
import { FLEX_PROP_OPTIONS } from '../options';

@Component({
  selector: 'form-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatDivider,
    MatCardContent,
    FormsModule,
    ReactiveFormsModule,
    ProgrammableComponent,
    MatFormField,
    MatSelect,
    NgFor,
    MatOption,
    NgIf,
    ColorPickerComponent,
    MatInput,
    MatHint,
    FlexChinesePipe,
    LegacyAppearanceDirective,
  ],
})
export class BackgroundComponent implements OnChanges {
  @Input()
  public formValue: RegularizedFlex;

  @Output()
  public formValueChange = new EventEmitter();

  public options = FLEX_PROP_OPTIONS;

  public formGroup: FormGroup = new FormGroup({
    type: new FormControl(''),
  });

  public backgroundProperties: { [key: string]: FormProperty[] } = {
    linearGradient: [
      { name: 'angle', hint: 'ex: 90deg' },
      { name: 'startColor', hint: '#RRGGBB or #RRGGBBAA' },
      { name: 'endColor', hint: '#RRGGBB or #RRGGBBAA' },
      { name: 'centerColor', hint: '#RRGGBB or #RRGGBBAA' },
      { name: 'centerPosition', hint: 'ex: 50%' },
    ],
  };

  public errorFunction: (data: RegularizedFlex) => {};

  public constructor(public flexEditorService: FlexEditorService) {}

  public ngOnChanges(changes: SimpleChanges) {
    let type = this.formValue?.background?.type;
    this.changeBackgroundType(type, true);
    this.findError();
  }

  public async changeBackgroundType(type: string, init = false) {
    if (!this.backgroundProperties[type]) {
      this.formGroup = new FormGroup({
        type: new FormControl(type),
      });
      return;
    }
    let typeControl = { type: new FormControl(type) };
    let controls = this.backgroundProperties[type]
      .map((property) => {
        let key = property.name;
        let value = key == 'angle' ? '0deg' : '';

        if (
          this.formValue &&
          this.formValue['background'] &&
          this.formValue['background'][key]
        )
          value = this.formValue['background'][key];

        return { [key]: new FormControl(value) };
      })
      .reduce((a, b) => Object.assign(a, b));

    this.formGroup = new FormGroup(Object.assign(typeControl, controls));
  }

  protected findError() {
    if (!this.backgroundProperties[this.formGroup.value.type])
      this.formGroup.controls.type.setValue('');

    if (!this.formGroup.value.type) return;
    this.errorFunction = ERROR_MESSAGE[this.formValue.type];

    this.backgroundProperties[this.formGroup.value.type].forEach((property) => {
      let hasError = this.errorFunction(this.formValue)['background'];
      if (hasError) property.error = hasError[property.name];
      else delete property.error;
    });
  }

  public async doChange() {
    Object.keys(this.formGroup.value).forEach((key) => {
      if (!this.formGroup.value[key]) delete this.formGroup.value[key];
    });

    this.formValueChange.emit(this.formGroup.value);
    this.findError();
  }
}
