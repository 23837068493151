import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[element-ref], [elementRef]',
  exportAs: 'elementRef',
  standalone: true,
})
export class ElementRefDirective<T = any> extends ElementRef<T> {
  public constructor(protected elementRef: ElementRef<T>) {
    super(elementRef.nativeElement);
  }
}
