import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Content } from '@ay/bot';
import { Subject } from 'rxjs';
import { ImageTemplateEditorDialog } from '../../image/template-editor/template-editor.component';
import { Package } from '../../package.class';
import { Card } from '../card/card.class';
import { CardsMessage } from '../cards.message';
import { MatButton } from '@angular/material/button';
import { TemplatePropertiesEditorComponent } from '../../template/template-properties-editor/template-properties-editor.component';
import { NgIf } from '@angular/common';
import { CustomizeComponent } from './customize/customize.component';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { CardComponent } from '../card/card.component';
import { TemplatePreviewComponent } from '../../template/template-preview/template-preview.component';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'ms-card-template-editor-dialog',
    templateUrl: './template-editor.dialog.html',
    styleUrls: ['./template-editor.dialog.scss'],
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['message'],
    standalone: true,
    imports: [
        FlexModule,
        TemplatePreviewComponent,
        CardComponent,
        MatTabGroup,
        MatTab,
        CustomizeComponent,
        NgIf,
        TemplatePropertiesEditorComponent,
        MatButton,
    ],
})
export class CardTemplateEditorDialog {
  public notifier = new Subject<void>();

  @Input()
  public card: Card;

  public message = new CardsMessage();

  @Input()
  public package: Package;

  public constructor(
    private readonly _matDialogRef: MatDialogRef<ImageTemplateEditorDialog>,
    @Inject(MAT_DIALOG_DATA)
    data: { card: Card; message: CardsMessage; package: Package },
  ) {
    this.card = data.card;
    this.message = data.message;
    this.package = data.package;
  }

  public async afterTemplateExec(result: Content.Cards) {
    let card = result.cards[0] as Card;
    this.card.loading = false;
    this.card.image = card.image;
    this.card.title = card.title;
    this.card.content = card.content;
  }

  public afterPropertyChange($event) {
    this.notifier.next();
  }

  public close() {
    this._matDialogRef.close();
  }
}
