import { Node } from '../class';

export class FindRecordNode extends Node {
  public icon = 'list';

  public tagIds: number[] = [];
  public responseVar: string = 'records';
  public isAsync: boolean;
  public start: string;
  public end: string;
  public withRecord: boolean;
  public withCard: boolean;
  public withButton: boolean;
  public withPoster: boolean;
  public withArea: boolean;

  public readonly extraProperties = [
    'tagIds',
    'responseVar',
    'isAsync',
    'start',
    'end',
    'withRecord',
    'withCard',
    'withButton',
    'withPoster',
    'withArea',
  ];
}
