import { Component, Input } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { AffectedComponent } from './affected.component';
import { Package } from './package.class';

@Component({
  selector: 'package-affected-component',
  template: '',
})
export class PackageAffectedComponent extends AffectedComponent<Package> {
  //#region package
  private _package: Package = null;

  public package$ = new ReplaySubject<Package>(1);

  public get package(): Package {
    return this._package;
  }

  @Input()
  public set package(pkg: Package) {
    if (this._package === pkg) return;
    this._package = pkg;
    this.package$.next(pkg);
    this.affecter$.next(pkg);
  }
  //#endregion package
}
