import { firstValueFrom } from 'rxjs';
import { Node } from '../class';
import { GosuValidate } from '../../../../components/gosu-validator/gosu-validator.class';

export type Pair = {
  key: string;
  responseVar: string;
};
export class GetterNode extends Node {
  public icon = 'assignment';

  public target: 'profile' | 'bot' | 'company' = 'profile';

  public targetId: string;

  public isAsync = false;

  public pairs: Pair[] = [{ key: '', responseVar: '' }];

  public readonly extraProperties = [
    'target',
    'targetId',
    'isAsync',
    'key',
    'responseVar',
    'pairs',
  ];

  public async getDisplay() {
    if (this.name !== '讀取屬性') {
      return this.name;
    }

    if (this.pairs.length && this.pairs[0].key) {
      const properties = await firstValueFrom(
        this.flowService.propertyConfigService.all$,
      );

      const key = this.pairs[0].key;
      const property = properties.find((property) => property.key === key);

      return $localize`讀取 ` + (property ? property.name : key);
    } else if (this.pairs.length > 1) {
      return $localize`讀取` + this.pairs.length + $localize`個屬性`;
    } else {
      return $localize`讀取屬性`;
    }
  }

  public checkError(): void {
    this.hasError = false;
    const isCheckError = window.localStorage.getItem('isDebug') === 'true';

    if (isCheckError) {
      this.pairs.forEach((pair) => {
        if (this._checkError(pair)) {
          this.hasError = true;
        }
      });
    }
  }

  private _checkError = (pair: Pair) => {
    if (GosuValidate.required(pair.key)) {
      return true;
    }
    if (GosuValidate.required(pair.responseVar)) {
      return true;
    }
    return false;
  };
}
