<div class="container">
  <div class="colors">
    <button
      *ngFor="let color of colors"
      class="color"
      mat-icon-button
      [style.background-color]="color"
      (click)="setColor(color)"
    ></button>
  </div>

  <mat-divider></mat-divider>

  <button mat-button (click)="openAdvancedPalette()">
    <div>
      <mat-icon>color_lens</mat-icon>
      <span i18n>自訂顏色</span>
    </div>
  </button>
</div>
