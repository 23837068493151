<a
  [ngClass]="'fxC' + level"
  [style.backgroundColor]="
    !data?.style || data?.style === 'link' ? '' : data.color
  "
  [style.color]="!data?.style || data?.style === 'link' ? data.color : ''"
>
  <div #font class="font" [style.fontSize.px]="fontSize">
    {{ $any(data?.action)?.label }}
  </div>
</a>
