<div class="header" i18n>編輯卡片</div>

<div class="container" fxLayout="row nowrap">
  <div class="preview">
    <ms-card
      [card]="$any(card)"
      [message]="message"
      [package]="package"
      mode="READ"
    ></ms-card>
  </div>
  <div class="form">
    <gosu-image-picker
      [(value)]="card.image"
      (valueChange)="onChanged()"
      [useCode]="true"
    >
    </gosu-image-picker>

    <br />

    <mat-form-field legacyAppearance>
      <mat-label i18n>標題</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="card.title"
        (ngModelChange)="onChanged()"
      />
      <gosu-validator
        [(ngModel)]="card.title"
        [rules]="{ required: true }"
        [offsetY]="20"
      ></gosu-validator>
    </mat-form-field>

    <mat-form-field legacyAppearance>
      <mat-label i18n>內容</mat-label>
      <textarea
        matInput
        [(ngModel)]="card.content"
        (ngModelChange)="onChanged()"
      ></textarea>
      <gosu-validator
        [(ngModel)]="card.content"
        [rules]="{ required: true }"
        [offsetY]="20"
      ></gosu-validator>
    </mat-form-field>

    <mat-accordion displayMode="flat" multi="false">
      <mat-expansion-panel
        #buttonPanel
        *ngFor="let button of card.buttons; let index = index"
      >
        <mat-expansion-panel-header>
          {{ 1 + index }}. {{ button.label }}
        </mat-expansion-panel-header>

        <mat-action-row>
          <button mat-button (click)="remove(index)" i18n>刪除</button>
        </mat-action-row>

        <mat-form-field floatLabel="always" class="select" legacyAppearance>
          <mat-select
            [(ngModel)]="button.type"
            placeholder="類型"
            i18n-placeholder="類型"
          >
            <mat-option *ngIf="package.enabledActions.uri" value="uri" i18n>
              連結 / 檔案
            </mat-option>

            <mat-option
              *ngIf="package.enabledActions.message"
              value="message"
              i18n
            >
              說話
            </mat-option>

            <mat-option *ngIf="package.enabledActions.next" value="next" i18n>
              下一個流程
            </mat-option>

            <ng-container
              *ngIf="packageFactoryService.enablePromotionChannel$ | async"
            >
              <mat-option
                *ngIf="package.enabledActions.shareMessageThroughPromotion"
                value="share-message-through-promotion"
                i18n
              >
                分享機器人 (推廣通路)
              </mat-option>

              <mat-option
                *ngIf="package.enabledActions.addThroughPromotion"
                value="add-through-promotion"
                i18n
              >
                加入機器人 (推廣通路)
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field legacyAppearance>
          <mat-label i18n>按鈕文字</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="button.label"
            (ngModelChange)="onChanged()"
          />
          <gosu-validator
            [(ngModel)]="button.label"
            [rules]="{ required: true }"
            [offsetY]="20"
          ></gosu-validator>
        </mat-form-field>

        <ng-container *ngIf="button.type === 'uri'">
          <mat-form-field floatLabel="always" legacyAppearance>
            <input
              matInput
              type="text"
              placeholder="網址"
              i18n-placeholder="網址"
              [(ngModel)]="button.uri"
              (ngModelChange)="onChanged()"
            />
            <gosu-validator
              [(ngModel)]="button.uri"
              [rules]="{ required: true, uri: true }"
              [offsetY]="20"
            >
            </gosu-validator>
          </mat-form-field>

          <div class="tar">
            <button mat-button (file)="uploadFile(button, $event)" i18n>
              上傳檔案
            </button>
          </div>
        </ng-container>

        <mat-form-field *ngIf="button.type === 'message'" legacyAppearance>
          <mat-label i18n>訊息內容</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="button.text"
            (ngModelChange)="onChanged()"
          />
          <gosu-validator
            [(ngModel)]="button.text"
            [rules]="{ required: true }"
            [offsetY]="20"
          ></gosu-validator>
        </mat-form-field>

        <mat-form-field *ngIf="button.type === 'next'" legacyAppearance>
          <mat-label i18n>引導文字</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="button.text"
            (ngModelChange)="onChanged()"
          />
          <gosu-validator
            [(ngModel)]="button.text"
            [rules]="{ required: true }"
            [offsetY]="20"
          ></gosu-validator>
        </mat-form-field>

        <mat-form-field
          legacyAppearance
          *ngIf="button.type === 'share-message-through-promotion'"
        >
          <mat-label i18n>推廣通路</mat-label>
          <mat-select
            [(ngModel)]="button['promotionId']"
            (ngModelChange)="onChanged()"
          >
            <mat-option
              *ngFor="let promotion of promotions$ | async"
              [value]="promotion.id"
            >
              {{ promotion.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="add-card-button-wrap">
      <button
        mat-flat-button
        class="add-card-button"
        *ngIf="card.buttons.length < 4"
        (click)="add()"
        i18n
      >
        <mat-icon>add</mat-icon>
        新增按鈕
      </button>
    </div>
  </div>
</div>

<div class="footer">
  <div class="text">
    <span i18n> 卡片適用： </span>
    <span>
      <mat-icon>check_circle</mat-icon>
      LINE、
    </span>
    <span [class.disabled]="card.buttons.length >= 4">
      <mat-icon>
        {{
          card.buttons.length >= 4 ? 'radio_button_unchecked' : 'check_circle'
        }}
      </mat-icon>
      Facebook
    </span>
    <mat-icon [matTooltip]="platformText">info</mat-icon>
  </div>

  <button mat-button (click)="confirm()" color="primary" i18n>確定</button>
</div>

<ng-template #platformText>
  <div class="platform-text">
    <mat-icon>info</mat-icon>
    <div class="content">
      <div class="text">
        <img src="./assets/icon-line.png" alt="" />
        <span i18n>
          LINE卡片訊息規範：<br />
          • 一個訊息內最多包含10張卡片<br />
          • 每張訊息最多包含4個按鈕
        </span>
      </div>
      <div class="text">
        <img src="./assets/icon-fb.png" alt="" />
        <span i18n>
          Facebook卡片訊息規範：<br />
          • 一個訊息內最多包含10張卡片<br />
          • 每張訊息最多包含3個按鈕<br />
          • 如您欲使用留言轉私訊的功能（起始點為「使用者回應文章」），則基於
          Facebook 官方限制，只可以回覆 1 張卡片。
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #uploadProgressDialog>
  <div class="container">
    <div class="upload-progress">
      <h2 i18n>檔案上傳中，請稍後</h2>
      <mat-progress-bar
        mode="determinate"
        [value]="uploadProgress"
        color="primary"
      >
      </mat-progress-bar>
    </div>
  </div>
</ng-template>
