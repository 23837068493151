import { Pipe, PipeTransform } from '@angular/core';
import { PackageFactoryService } from './package-factory.service';

@Pipe({
    name: 'messageIcon',
    pure: true,
    standalone: true,
})
export class MessageIconPipe implements PipeTransform {
  public constructor(
    private readonly _packageFactoryService: PackageFactoryService,
  ) {}

  public transform(type: string): any {
    return this._packageFactoryService.toolbars[type].icon;
  }
}
