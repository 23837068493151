import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { KonamiCode } from '../../../service/konami-code.service';
import { PageService } from '../../../service/page.service';
import { FlowService } from '../flow.service';
import { Config, configs } from '../node';
import { IconComponent } from '../../../components/icon/icon.component';
import { MatTooltip } from '../../../material/tooltip/tooltip';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'fl-next-node-dialog',
    templateUrl: './next-node-dialog.component.html',
    styleUrls: ['./next-node-dialog.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        MatTooltip,
        IconComponent,
        NgIf,
    ],
})
export class NextNodeDialogComponent implements OnInit {
  private configs = configs.filter((config) => config.toolbar);

  public generals = this.configs.filter(
    (config) => !config.toolbar.expert && config.type !== 'TextNode',
  );

  public experts = this.configs.filter((config) => config.toolbar.expert);

  public constructor(
    private _matDialogRef: MatDialogRef<any>,
    public flow: FlowService,
    public konamiCode: KonamiCode,
    public pageService: PageService,
  ) {}

  public ngOnInit() {
    this.configs
      .filter((node) => node.toolbar.hiddenFn)
      .map(
        async (node) =>
          (node.toolbar.hidden = await node.toolbar.hiddenFn(this.pageService)),
      );
  }

  public select(node: Config) {
    this._matDialogRef.close(node);
  }
}
