import { Component, Input } from '@angular/core';
import { ProgressBarMode, MatProgressBar } from '@angular/material/progress-bar';

@Component({
    selector: 'gosu-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    standalone: true,
    imports: [MatProgressBar],
})
export class ProgressBarComponent {
  @Input()
  public mode: ProgressBarMode = 'determinate';

  @Input()
  public text = '';

  @Input()
  public color = 'primary';

  @Input()
  public value: number = 0;
}
