<div fxLayout="row" fxLayoutGap="15px">
  <mat-checkbox
    class="form-field"
    fxFlex="1 0 0"
    [(ngModel)]="node.isAsync"
    color="primary"
    i18n
  >
    {{ node.isAsync ? notText : '' }}同步模式
  </mat-checkbox>
</div>

<div fxLayout="row">
  <programmable fxFlex="1 0 0" [(value)]="node.action">
    <mat-form-field legacyAppearance>
      <mat-label i18n>行為</mat-label>
      <mat-select [(ngModel)]="node.action" i18n>
        <mat-option value="GET_EXCHANGE_LIST">讀取兌換活動清單</mat-option>
        <mat-option value="GET_METHOD_LIST">讀取兌換辦法清單</mat-option>
        <mat-option value="EXCHANGE">執行兌換</mat-option>
      </mat-select>
    </mat-form-field>
  </programmable>

  <programmable
    [(value)]="node.exchangeId"
    dataType="number"
    fxFlex="1 0 0"
    *ngIf="['GET_METHOD_LIST', 'EXCHANGE'].includes(node.action)"
  >
    <mat-form-field legacyAppearance>
      <mat-label i18n>兌換活動</mat-label>
      <mat-select
        [(ngModel)]="node.exchangeId"
        (ngModelChange)="select($event)"
      >
        <mat-option
          *ngFor="let exchange of exchangeService.list$ | async"
          [value]="exchange.id"
        >
          {{ exchange.name }}
        </mat-option>
      </mat-select>
      <gosu-validator
        [(ngModel)]="node.exchangeId"
        [rules]="{ required: true }"
        [offsetY]="20"
      ></gosu-validator>
    </mat-form-field>
  </programmable>

  <programmable
    [(value)]="node.methodId"
    dataType="number"
    fxFlex="1 0 0"
    *ngIf="['EXCHANGE'].includes(node.action)"
  >
    <mat-form-field legacyAppearance>
      <mat-label i18n>兌換辦法</mat-label>
      <mat-select [(ngModel)]="node.methodId">
        <mat-option *ngFor="let method of methods" [value]="method.id">
          {{ method.name }}
        </mat-option>
      </mat-select>
      <gosu-validator
        [(ngModel)]="node.methodId"
        [rules]="{ required: true }"
        [offsetY]="20"
      ></gosu-validator>
    </mat-form-field>
  </programmable>
</div>

<div
  fxLayout="row"
  fxLayoutGap="15px"
  *ngIf="['EXCHANGE'].includes(node.action)"
>
  <mat-form-field legacyAppearance>
    <mat-label i18n>指定兌換對象</mat-label>
    <input
      matInput
      [(ngModel)]="node.targetProfileId"
      matTooltip="沒有指定時，兌換給觸發者；只能填入一個兌換對象"
      i18n-matTooltip="沒有指定時，兌換給觸發者；只能填入一個兌換對象"
    />
  </mat-form-field>
</div>

<div fxLayout="row" fxLayoutGap="15px">
  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label i18n>回傳變數</mat-label>
    <input matInput [(ngModel)]="node.responseVar" />
  </mat-form-field>
</div>
