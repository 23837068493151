import { Pipe, PipeTransform } from '@angular/core';
import { PROPERTY_NAME, TYPE_NAME } from './chinese.service';

@Pipe({
  name: 'cht',
  pure: true,
  standalone: true,
})
export class FlexChinesePipe implements PipeTransform {
  public transform(value: string, isType?: boolean, type?: string): string {
    if (value == 'promotionId') return $localize`推廣通路`;
    else if (value == 'scheme') return $localize`連結類型(僅支援LINE)`;
    else if (isType) return TYPE_NAME[value] || value;
    else if (type === 'video' && value === 'url') return $localize`影片連結`;
    return PROPERTY_NAME[value] || value;
  }
}
