import { RuleType } from '@ay-gosu/types';
import { CLASS } from '@ay/util';
import { GroupRule } from '../group/rule.class';
import { BotRule } from './bot/bot.class';
import { DraftRule } from './draft/draft.class';
import { FlowRule } from './flow/flow.class';
import { NameRule } from './name/name.class';
import { PlatformRule } from './platform/platform.class';
import { ProfileRule } from './profile/profile.class';
import { ProfileTypeRule } from './profileType/profileType.class';
import { ListPropertyRule } from './property/list/list.class';
import { NumberPropertyRule } from './property/number/number.class';
import { PropertyRule } from './property/property.class';
import { StringPropertyRule } from './property/string/string.class';
import { ReservationRule } from './reservation/reservation.class';
import { FromJsonOption, Rule } from './rule';
import { SentRule } from './sent/sent.class';
import { StatusRule } from './status/status.class';
import { TagRule } from './tag/tag.class';
import { TemplateRule } from './template/template.class';
import { SendingRule } from './sending/sending.class';

export interface RuleClass {
  new (...args: any): Rule;
  schema: string;
  fromShortCode(code: string, option: FromJsonOption): Promise<Rule>;
}

export const RuleClassMap: {
  [key: string]: RuleClass;
} = {
  TAG: TagRule,
  PROPERTY: PropertyRule,
  BOT: BotRule,
  PLATFORM: PlatformRule,
  STATUS: StatusRule,
  PROFILE_TYPE: ProfileTypeRule,
  PROFILE: ProfileRule,
  DRAFT: DraftRule,
  RESERVATION: ReservationRule,
  NAME: NameRule,
  SENT: SentRule,
  SENDING: SendingRule,
  FLOW: FlowRule,
  GROUP: GroupRule,
  TEMPLATE: TemplateRule,
};

export const PropertyRuleClassMap = {
  string: StringPropertyRule,
  number: NumberPropertyRule,
  list: ListPropertyRule,
};

export class RuleFactory {
  public static getClassOfRule(rule: Rule) {
    if (rule.type === 'PROPERTY') {
      let propertyRule = rule as PropertyRule;
      try {
        let dataType = propertyRule.property.dataType;
        return PropertyRuleClassMap[dataType];
      } catch (e) {
        return PropertyRule;
      }
    }

    return RuleClassMap[rule.type] as CLASS<Rule>;
  }

  public static ensureType(rule: Rule) {
    if (RuleClassMap[rule.type] === undefined) {
      console.error(`不支援的規則類型 ${rule.type}`);
      return;
    }
    rule['__proto__'] = RuleFactory.getClassOfRule(rule)['prototype'];
  }

  public static create(type: RuleType) {
    if (RuleClassMap[type] === undefined) {
      console.error($localize`不支援的規則類型 ${type}`);
      return;
    }
    return new RuleClassMap[type]();
  }

  public static async fromJSON(json: any, option: FromJsonOption) {
    let rule = RuleFactory.create(json.type);
    await rule.fromJSON(json, option);
    rule.afterTypeChanged(false);
    return rule;
  }
}
