import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'directusTranslation',
    standalone: true,
})
export class DirectusTranslationPipe implements PipeTransform {
  public transform(translation: Translation[], defaultValue: string = ''): any {
    const tw = translation
      ? translation.find((t) => t.locale === 'zh-TW')
      : null;
    return tw ? tw.translation : defaultValue;
  }
}

export interface Translation {
  newItem: boolean;
  locale: string;
  translation: string;
}
