<div fxLayout="row" fxLayoutGap="15px">
  <mat-form-field fxFlex="1.6 0 0" legacyAppearance>
    <mat-label i18n>設定對象</mat-label>
    <mat-select [(ngModel)]="node.target" (ngModelChange)="afterTargetChange()">
      <mat-option value="profile" i18n>使用者</mat-option>
      <mat-option value="bot" i18n>機器人</mat-option>
      <mat-option value="company" i18n>組織</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    fxFlex="2.5 0 0"
    *ngIf="node.target !== 'company'; else empty"
    legacyAppearance
  >
    <mat-label></mat-label>
    <mat-select
      [placeholder]="targetMap[node.target] + categoryText"
      [(ngModel)]="showIdInput"
      (ngModelChange)="node.targetId = null"
    >
      <mat-option [value]="false" i18n>
        觸發事件的{{ targetMap[node.target] }}
      </mat-option>
      <mat-option [value]="true" i18n
        >特定的{{ targetMap[node.target] }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field
    legacyAppearance
    fxFlex="2.5 0 0"
    *ngIf="node.target !== 'company' && showIdInput; else empty"
  >
    <mat-label></mat-label>
    <input
      matInput
      [placeholder]="targetMap[node.target] + indexText"
      [(ngModel)]="node.targetId"
      [matTooltip]="'如果不指定，將修改執行此流程的' + targetMap[node.target]"
    />
    <gosu-validator
      [(ngModel)]="node.targetId"
      [rules]="{ required: true }"
      [offsetY]="20"
    ></gosu-validator>
  </mat-form-field>

  <ng-template #empty>
    <div fxFlex="2.5 0 0"></div>
  </ng-template>

  <mat-checkbox
    class="form-field"
    [(ngModel)]="node.isAsync"
    color="primary"
    fxFlex="1.5 0 0"
    i18n
  >
    {{ node.isAsync ? notText : '' }}同步模式
  </mat-checkbox>

  <mat-checkbox
    class="form-field"
    fxFlex="0 0 140px"
    [(ngModel)]="node.processed"
    value="true"
    color="primary"
    i18n
  >
    標示為已處理
  </mat-checkbox>
</div>

<div class="settings">
  <flow-setter-node-command
    *ngFor="let command of node.commands; let index = index"
    [no]="index + 1"
    [command]="command"
    [checkError]="node.checkError.bind(node)"
    [node]="node"
    [target]="node.target"
    (delete)="deleteCommand(index)"
  >
    <div fxLayout="row" [ngSwitch]="command.item"></div>
  </flow-setter-node-command>
</div>

<ng-template #footer>
  <button mat-button (click)="addCommand()" i18n>
    <mat-icon>add</mat-icon>
    新增設定動作
  </button>

  <button mat-button (click)="addPropertyConfig()" i18n>
    <mat-icon>assignment</mat-icon>
    新增屬性
  </button>

  <button
    mat-button
    color="primary"
    class="add-condition-btn"
    (click)="dynamic.submit()"
    i18n
  >
    確定
  </button>
</ng-template>
