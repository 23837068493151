import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { AuthorizationModel, Contract } from '@ay-gosu/server-shared';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import moment from 'moment';
import { combineLatest, firstValueFrom, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  shareReplay,
  tap,
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MatTooltip } from '../../material/tooltip';
import { ActivityRecordComponent } from '../../pages/activity-record/activity-record.component';
import { CompanyEditorComponent } from '../../pages/company/company-editor/company-editor.component';
import { SelectCompanyComponent } from '../../pages/login/select-company/select-company.component';
import { MomentPipe } from '../../pipe/moment.pipe';
import { CompanyService } from '../../service/company.service';
import { CustomizeService } from '../../service/customize.service';
import { KonamiCode } from '../../service/konami-code.service';
import { TokenService } from '../../service/token.service';
import { ElementRefDirective } from '../element-ref.directive';
import { GosuIconComponent } from '../gosu-icon/icon.component';
import { LogoComponent } from '../gosu-logo/logo.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'gosu-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatIconButton,
    MatIcon,
    LogoComponent,
    GosuIconComponent,
    IconComponent,
    NgTemplateOutlet,
    MatTooltip,
    MatButton,
    RouterLink,
    ElementRefDirective,
    FlexModule,
    MatMenuTrigger,
    MatMenu,
    MatDivider,
    MatMenuItem,
    MatCheckbox,
    AsyncPipe,
    MomentPipe,
  ],
})
export class ToolbarComponent {
  public environment = environment;

  @ViewChild('companyMenuTrigger', { static: false })
  public companyMenuTrigger: MatMenuTrigger;

  @ViewChild('noContractDialog')
  public noContractDialog: TemplateRef<any>;

  @ViewChild('expiredDialog')
  public expiredDialog: TemplateRef<any>;

  @ViewChild('overLimitDialog')
  public overLimitDialog: TemplateRef<any>;

  @Output()
  public iconClick = new EventEmitter();

  public logged$ = this.tokenService.account$.pipe(map((account) => !!account));

  public contract$ = this.companyService.company$.pipe(
    filter((company) => !!company),
    map((company) => company?.contracts[0]),
    tap((contract) => this._showError(contract)),
    shareReplay(1),
  );

  public showNotifications$ = combineLatest([
    this.logged$,
    this._konamiCode.unlock$,
  ]).pipe(
    map(
      ([logged, unlock]) =>
        logged && unlock && !this.customizeService.isCustomize,
    ),
  );

  public lastContract$ = this.companyService.company$.pipe(
    mergeMap(() => AuthorizationModel.fetchLastContract()),
    shareReplay(1),
  );

  public company$ = this.companyService.company$.pipe(
    filter((company) => !!company),
    map((company) => company),
    shareReplay(1),
  );

  public announcement$ = environment.directus.url
    ? this._httpClient
        .get(
          `${environment.directus.url}/${environment.directus.project}/items/announcements?sort=sort&limit=1`,
        )
        .pipe(
          map((response) => response['data'][0]),
          catchError(() => of(null)),
          shareReplay(1),
        )
    : of(null);

  public today = moment();

  public keep = {
    start: moment().add(1, 'day'),
    end: moment().add(30, 'day'),
  };

  public constructor(
    public tokenService: TokenService,
    public companyService: CompanyService,
    public matConnectedDialog: MatConnectedDialog,
    private _konamiCode: KonamiCode,
    private _httpClient: HttpClient,
    public router: Router,
    public customizeService: CustomizeService,
  ) {}

  public toggleActivityRecord(elementRef: ElementRef) {
    this.matConnectedDialog.open(ActivityRecordComponent, {
      elementRef,
      panelClass: 'dialog-container-p0',
    });
  }

  public async logout() {
    this.tokenService.logout();
  }

  public async editCompany() {
    this.matConnectedDialog.open(CompanyEditorComponent, {
      disableClose: true,
    });
  }

  public async selectCompany(elementRef: ElementRef, event: MouseEvent) {
    event.stopPropagation();
    const dialogRef = this.matConnectedDialog.open(SelectCompanyComponent, {
      elementRef,
      panelClass: 'dialog-container-p0',
      data: { isDialog: true },
    });

    await firstValueFrom(dialogRef.beforeClosed());
    this.companyMenuTrigger.closeMenu();
  }

  public dateRange(contract: Contract): number {
    if (!contract) return 0;
    return moment(contract.end).diff(moment(contract.start), 'day');
  }

  private async _showError(contract: Contract) {
    if (this.customizeService.isCustomize) return;

    let noShowDialogPage =
      this.router.url.includes('market-place') ||
      this.router.url.includes('payment');
    const token = await firstValueFrom(this.tokenService.token$);

    if (!token || noShowDialogPage) return;

    if (
      !!contract &&
      contract?.friendOverLimit &&
      contract.contractNotify &&
      !this.hideFriendOverLimit
    ) {
      this.matConnectedDialog.open(this.overLimitDialog, {
        disableClose: true,
      });
      return;
    }

    if (!!contract) return;

    let lastContract = await firstValueFrom(this.lastContract$);

    if (!lastContract) {
      this.matConnectedDialog.open(this.noContractDialog, {
        disableClose: true,
      });
      return;
    }

    if (moment(lastContract.end).isAfter()) return;

    this.matConnectedDialog.open(this.expiredDialog, {
      disableClose: true,
    });

    this.keep = {
      start: moment(lastContract.end).add(1, 'day'),
      end: moment(lastContract.end).add(30, 'day'),
    };
  }

  public get hideFriendOverLimit() {
    return window.localStorage.getItem('hideFriendOverLimit') === 'true';
  }

  public set hideFriendOverLimit(value: boolean) {
    window.localStorage.setItem('hideFriendOverLimit', value.toString());
  }

  public stopNotify(e) {
    this.hideFriendOverLimit = e.checked;
  }
}
