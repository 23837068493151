import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { ExposedPromise } from '@ay/util';
import { delay } from 'bluebird';
import { firstValueFrom } from 'rxjs';
import { FileSizePipe } from '../../../pipe/file-size.pipe';
import { ImageUploaderDialog } from '../uploader/uploader.dialog';
import { FileDirective } from '../../../components/file.directive';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

const IMAGE_SIZE_LIMIT = 1 * 1024 * 1024;
@Component({
    selector: 'dl-image-picker',
    templateUrl: './picker.dialog.html',
    styleUrls: ['./picker.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatIcon,
        MatButton,
        FileDirective,
    ],
})
export class ImagePickerDialog implements AfterViewInit {
  @ViewChild('dragUploadInput')
  public dragUploadInput: ElementRef<HTMLInputElement>;

  @ViewChild('urlInput')
  public urlInput: ElementRef<HTMLInputElement>;

  @Input()
  public mode = 'server';

  @Input()
  public types: string[] = null;

  @Input()
  public width: number | [number, number] = null;

  @Input()
  public height: number | [number, number] = null;

  @Input()
  public size = IMAGE_SIZE_LIMIT;

  private _isUploading = false;

  public constructor(
    private readonly _matDialogRef: MatDialogRef<ImagePickerDialog>,
    private readonly _matConnectedDialog: MatConnectedDialog,
    private readonly _matSnackBar: MatSnackBar,
    private readonly _fileSizePipe: FileSizePipe,
    @Inject(MAT_DIALOG_DATA)
    public data: { url: string; useCode: boolean },
  ) {}

  public async ngAfterViewInit() {
    await delay(100);
    this.urlInput.nativeElement.focus();
  }

  public async uploadFiles(files: FileList) {
    if (files.length == 0) {
      this._matSnackBar.open($localize`沒有選擇任何檔案`);
      return;
    }

    let file = files[0];

    await this.uploadFile(file);
  }

  private async _loadAsImage(file: File) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = (error) => reject(error);
      image.src = URL.createObjectURL(file);
    });
  }

  private async _verification(file: File): Promise<string[]> {
    const errors = [];

    if (this.types) {
      if (!this.types.includes(file.type.split('/')[1])) {
        errors.push($localize`不支援的圖片格式，僅支援${this.types}`);
      }
    } else {
      if (!file.type.startsWith('image/')) {
        errors.push($localize`不支援的圖片格式`);
      }
    }

    if (this.size && file.size > this.size) {
      const size = this._fileSizePipe.transform(this.size, 'Byte');
      errors.push($localize`檔案大小最大只能 ${size}`);
    }

    if (this.width || this.height) {
      const image = await this._loadAsImage(file);
      if (this.width) {
        if (this.width instanceof Array) {
          if (image.width < this.width[0] || image.width > this.width[1]) {
            errors.push(
              $localize`寬度必須介於 ${this.width[0]} ~ ${this.width[1]} `,
            );
          }
        } else {
          if (image.width != this.width) {
            errors.push($localize`寬度只能是 ${this.width}`);
          }
        }
      }

      if (this.height) {
        if (this.height instanceof Array) {
          if (image.height < this.height[0] || image.height > this.height[1]) {
            errors.push(
              $localize`高度必須介於 ${this.height[0]} ~ ${this.height[1]} `,
            );
          }
        } else {
          if (image.height != this.height) {
            errors.push($localize`高度只能是 ${this.height}`);
          }
        }
      }
    }

    return errors;
  }

  public async uploadFile(file: File) {
    if (this._isUploading) {
      return;
    }

    const errors = await this._verification(file);

    if (errors.length) {
      this._matSnackBar.open(errors.join('、'));
      return;
    }

    if (this.mode === 'server') {
      try {
        this._isUploading = true;
        const dialogRef = this._matConnectedDialog.open(ImageUploaderDialog);
        dialogRef.componentInstance.file = file;
        let src = await firstValueFrom(dialogRef.afterClosed());
        this._matDialogRef.close(src);
      } catch (error) {
        // 使用者取消上傳檔案
        this._isUploading = false;
      }
    } else if (this.mode === 'client') {
      const src = await this.convertFile(file);
      this._matDialogRef.close(src);
    }
  }

  protected async convertFile(file: File) {
    const exposedPromise = new ExposedPromise();
    let reader = new FileReader();
    reader.onload = () => exposedPromise.resolve(reader.result);
    reader.onerror = () => exposedPromise.resolve(reader.error);
    reader.readAsDataURL(file);
    return exposedPromise.promise;
  }

  public async dragUpload() {
    let files = this.dragUploadInput.nativeElement.files;
    await this.uploadFiles(files);
  }

  public async buttonUpload(files: FileList) {
    await this.uploadFiles(files);
  }

  public async afterURLChanged(event: Event) {
    let url = (event.target as HTMLInputElement).value;
    let hasCode = url.includes('${') && url.includes('}');
    if (hasCode && this.data && this.data.useCode) {
      this._matDialogRef.close(url);
      return;
    }
    let file = await this.getImageFileFromURL(url);
    await this.uploadFile(file);
  }

  public async afterURLPaste($event: ClipboardEvent) {
    let files = $event.clipboardData.files;
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      if (file.type && file.type.startsWith('image/')) {
        this.uploadFile(file);
        return;
      }
    }

    let url = $event.clipboardData.getData('text');
    if (url) {
      let file = await this.getImageFileFromURL(url);
      await this.uploadFile(file);
    }
  }

  public async getImageFileFromURL(url: string) {
    let promise = new ExposedPromise();
    let img = document.createElement('IMG') as HTMLImageElement;
    img.setAttribute('crossOrigin', 'Anonymous');

    img.onload = (event) => {
      let canvas = document.createElement('CANVAS') as HTMLCanvasElement;
      canvas.width = img.width;
      canvas.height = img.height;
      let context = canvas.getContext('2d');
      context.drawImage(img, 0, 0);
      canvas.toBlob(async (file: any) => {
        file.name = 'image.png';
        file.lastModifiedDate = new Date();
        file.__proto__ = File.prototype;
        promise.resolve(file);
      }, 'image/png');
    };

    img.onerror = (event) => {
      let hasCode = url.includes('${') && url.includes('}');
      if (hasCode && this.data.useCode) return;
      this._matSnackBar.open($localize`圖片網址錯誤`);
    };

    img.src = url;
    return promise.promise;
  }
}
