import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { map } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { VideoMessage } from './video.message';
import { BypassSecurityService } from '../../service/bypass-security.service';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'ms-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['package', 'mode', 'message'],
    standalone: true,
    imports: [AsyncPipe],
})
export class VideoComponent extends BaseComponent<VideoMessage> {
  public url$ = this.message$.pipe(
    map((message) =>
      this._bypassSecurityService.bypassSecurityUrl(message.content.content, [
        BypassSecurityService.isBypassWhiteList,
      ]),
    ),
  );

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _bypassSecurityService: BypassSecurityService,
  ) {
    super(changeDetectorRef);
  }
}
