import { Component, ElementRef } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { FormComponent } from '../form.component';
import { GetAccountLinkUrlNode } from './class';

@Component({
  selector: 'flow-get-account-link-url-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatCheckbox,
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    LegacyAppearanceDirective,
  ],
})
export class GetAccountLinkUrlFormComponent extends FormComponent<GetAccountLinkUrlNode> {
  public node: GetAccountLinkUrlNode;
  public width = 400;

  public notText = $localize`非`;

  public constructor(public readonly elementRef: ElementRef) {
    super(elementRef);
  }
}
