import { Content } from '@ay/bot';
import { Message } from '../base/base.message';

export class FlexMessage extends Message<Content.Flex> {
  public type = 'flex';

  public constructor() {
    super();
    this.content = new Content.Flex({
      type: 'flex',
      altText: '',
      contents: { type: 'bubble' },
    });
  }

  public async loadFromContent(content: Content.Flex): Promise<void> {
    this.content = content;
  }

  public toString() {
    return this.content.content.altText || $localize`一則 Flex 訊息`;
  }
}
