import { Node } from '../class';

export class ExecuteAPINode extends Node {
  public icon = 'http';

  /**
   * 回傳類型
   */
  public responseType: 'json' | 'csv' | 'string' = 'json';

  /**
   * 協定
   */
  public method: 'get' | 'post' | 'put' | 'delete' | 'patch' = 'get';

  /**
   * POST BODY 資料傳輸型態
   * @type {("x-www-form-urlencoded" | "raw")}
   */
  public bodyType: 'x-www-form-urlencoded' | 'raw' = 'x-www-form-urlencoded';

  /**
   * 資源網址
   */
  public uri: string = '';

  /**
   * 標頭檔
   */
  public headers: { key: string; value: string }[] = [];

  /**
   * 查詢網址參數
   */
  public query: { key: string; value: string }[] = [];

  /**
   * 夾帶內容
   */
  public body: { key: string; value: string }[] | any = [];

  /**
   * 回傳值寫入的變數
   */
  public responseVar: string = 'data';

  //編碼
  public encoding: 'utf-8' | 'big5' = 'utf-8';

  // 快取時間
  public cacheTime: string = '0';

  // 重試次數
  public retry: number = 0;

  // 重試間隔
  public retryInterval: number = 1000;

  public isAsync = false;

  public processed = false;

  public readonly extraProperties = [
    'responseType',
    'isAsync',
    'method',
    'uri',
    'headers',
    'query',
    'body',
    'responseVar',
    'bodyType',
    'encoding',
    'cacheTime',
    'processed',
    'retry',
    'retryInterval',
  ];
}
