import { NgFor, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardTitle,
} from '@angular/material/card';
import { MatOption } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatHint } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ProgrammableComponent } from '../../../../../components/programmable/programmable.component';
import { ColorPickerComponent } from '../../../../../material/color-picker/color-picker/color-picker.component';
import { LegacyAppearanceDirective } from '../../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { RegularizedFlex } from '../../../regularized-flex.class';
import { FlexChinesePipe } from '../../chinese.pipe';
import { FlexEditorService } from '../../editor.service';
import { ERROR_MESSAGE } from '../../error';
import { FormProperty } from '../form';
import { FLEX_PROP_OPTIONS } from '../options';

@Component({
  selector: 'form-style',
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.scss'],
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatDivider,
    MatCardContent,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    NgIf,
    ProgrammableComponent,
    MatFormField,
    MatSelect,
    MatOption,
    ColorPickerComponent,
    MatHint,
    FlexChinesePipe,
    LegacyAppearanceDirective,
  ],
})
export class FlexFormStyleComponent implements OnChanges {
  @Input()
  public formValue: RegularizedFlex;

  @Output()
  public formValueChange = new EventEmitter();

  public options = FLEX_PROP_OPTIONS.Separator;

  public styles: FormProperty[] = [
    { name: 'backgroundColor', hint: '#RRGGBB or #RRGGBBAA' },
    { name: 'separator', options: FLEX_PROP_OPTIONS.Separator },
    { name: 'separatorColor', hint: '#RRGGBB or #RRGGBBAA' },
  ];

  public formGroup: FormGroup = new FormGroup({
    backgroundColor: new FormControl(),
    separator: new FormControl(),
    separatorColor: new FormControl(),
  });

  public errorFunction: (data: RegularizedFlex) => {};

  public constructor(public readonly flexEditorService: FlexEditorService) {}

  public ngOnChanges() {
    if (this.formValue)
      this.formGroup = new FormGroup({
        backgroundColor: new FormControl(this.formValue['backgroundColor']),
        separator: new FormControl(this.formValue['separator']),
        separatorColor: new FormControl(this.formValue['separatorColor']),
      });
    this.findError();
  }

  public findError() {
    let selected = this.flexEditorService.selected$.value;
    this.errorFunction = ERROR_MESSAGE[selected.type];
    if (!this.flexEditorService.selectedParent.styles) return;
    this.styles.forEach((style) => {
      let hasError = this.errorFunction(
        this.flexEditorService.selectedParent.styles[selected.type],
      );
      if (hasError) style.error = hasError[style.name];
      else delete style.error;
    });
  }
}
