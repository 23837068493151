import { Content } from '@ay/bot';
import { TemplateModel } from '@ay-gosu/server-shared';
import { Message } from '../base/base.message';

export class ImageMessage extends Message<Content.Image> {
  public type = 'image';

  public templateName: string;

  public template: Content.Template;

  public templateLost = false;

  public constructor() {
    super();
    this.content = new Content.Image('');
  }

  public async loadFromContent(content: Content.Image | Content.Template) {
    if (content.type === 'image') {
      this.content = content;
    } else {
      await this.loadFromTemplate(content);
    }
  }

  public toString() {
    throw this.content.alt || $localize`一張圖片`;
  }

  public toJSON() {
    if (this.template) {
      return this.template;
    } else {
      return this.content;
    }
  }

  protected async loadFromTemplate(content: Content.Template) {
    this.template = content;
    if (!content.preview) {
      await this.loadPreview(content);
    } else {
      this.content = content.preview as Content.Image;
    }
    this.template.preview = this.content;
  }

  protected async loadPreview(content: any) {
    const result: Content.Image = (await TemplateModel.exec(
      content.templateId,
      content.properties,
    )) as any;

    if (result === null) {
      this.templateLost = true;
      return;
    }

    if (result.type !== 'image') {
      throw $localize`圖像範本的類型${result.type}與訊息的類型${this.type}不一致`;
    }

    this.content = result;
  }
}
