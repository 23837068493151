import { Component, forwardRef } from '@angular/core';
import { host, NodeComponent } from '../node.component';
import { RandomNode } from './class';
import { ConditionComponent } from '../condition/condition.component';
import { NgFor } from '@angular/common';
import { JunctionComponent } from '../../junction/junction.component';

@Component({
    selector: ':svg:g.random.node',
    templateUrl: './node.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['node'],
    providers: [
        {
            provide: NodeComponent,
            useExisting: forwardRef(() => RandomNodeComponent),
        },
    ],
    standalone: true,
    imports: [
        JunctionComponent,
        NgFor,
        ConditionComponent,
    ],
})
export class RandomNodeComponent extends NodeComponent<RandomNode> {}
