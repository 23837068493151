export const ExhibitionEventDTS = $localize`
/** 展場活動 */
declare class ExhibitionEvent {
    /** 活動編號 */
    eventId: string;
    background: string;
    botId: number;
    currentGameId: number;
    extra: any;
    id: number;
    images: string;
    name: string;
    style: string;
    validityPeriod: string;
    defaultAvatar: string;
    /** 載入展場活動 */
    constructor(
    /** 活動編號 */
    eventId: string);
    /** 讀取資料 */
    fetch(): Promise<ExhibitionEvent>;
    /** 建立展場活動 */
    static create(event: EventDto): Promise<{
        eventId: string;
        password: string;
    }>;
    /** 更新資料 */
    update(json: EventDto): Promise<void>;
    /** 重置遊戲 */
    reset(): Promise<boolean>;
    /** 讀取活動下所有遊戲編號 */
    fetchAllGameIds(): Promise<number[]>;
    /** 讀取某場遊戲 */
    game(
    /** 遊戲編號 */
    gameId?: number): Game;
    /** 建立遊戲 */
    createGame(game: GameDto): Promise<Game>;
    /** 檢測使用者是否存在於該活動中 */
    exist(profileId: number): Promise<boolean>;
    /** 將使用者加入到活動中 */
    join(...profileId: number[]): Promise<boolean>;
    /** 使用者離開活動及活動下的所有遊戲 */
    leave(profileId: number): Promise<boolean>;
    /** 排行榜 */
    rank(type?: string): Rank;
    /** 發送指令 */
    sendCommand(
    /** 分類 */
    type: string,
    /** 內容 */
    content: any,
    /** 使用者編號 */
    profileId?: number): Promise<boolean>;
    /** 讀取活動中的指令 */
    fetchCommands(
    /** 分類 */
    type: string,
    /** 分頁 */
    page?: number,
    /** 每頁幾筆，如果為 0 ，則不回傳資料，只回傳筆數 */
    countPerPage?: number): Promise<{
        commands?: {
            type: string;
            content: any;
            profileId?: number;
            createdAt: number;
        }[];
        page?: number;
        total: number;
    }>;
    private _fillData;
    /** 將排行榜上分數大於 N 的玩家，移動前往下一關 */
    nextGame(type: "RANK_SCORE", score: number, rankType?: string): Promise<MoveGameResponseType>;
    /** 將排行榜上前 N 位的玩家，移動前往下一關 */
    nextGame(type: "RANK_TOP", top: number, rankType?: string): Promise<MoveGameResponseType>;
    /** 將有選擇某個選項的所有玩家，移動前往下一關 */
    nextGame(type: "CHOOSE_OPTION", optionId: number): Promise<MoveGameResponseType>;
    /** 將目前遊戲室中的所有玩家，移動前往下一關 */
    nextGame(type: "IN_CURRENT_GAME"): Promise<MoveGameResponseType>;
    /** 將目前活動中的所有玩家，移動前往下一關 */
    nextGame(type: "IN_EVENT"): Promise<MoveGameResponseType>;
    /** 移動前往下一關，但不移動玩家 */
    nextGame(type: "NONE"): Promise<MoveGameResponseType>;
    /** 將目前遊戲室中的所有玩家，移動前往下一關 */
    nextGame(): Promise<MoveGameResponseType>;
    /** 將排行榜上分數大於 N 的玩家，移動前往至某一關 */
    jumpGame(type: "RANK_SCORE", toGameId: number, score: number, rankType?: string): Promise<MoveGameResponseType>;
    /** 將排行榜上前 N 位的玩家，移動前往至某一關 */
    jumpGame(type: "RANK_TOP", top: number, rankType?: string): Promise<MoveGameResponseType>;
    /** 將有選擇某個選項的所有玩家，移動前往至某一關 */
    jumpGame(type: "CHOOSE_OPTION", toGameId: number, optionId: number): Promise<MoveGameResponseType>;
    /** 將目前遊戲室中的所有玩家，移動前往至某一關 */
    jumpGame(type: "IN_CURRENT_GAME", toGameId: number): Promise<MoveGameResponseType>;
    /** 將目前活動中的所有玩家，移動前往至某一關 */
    jumpGame(type: "IN_EVENT", toGameId: number): Promise<MoveGameResponseType>;
    /** 移動前往至某一關，但不移動玩家 */
    jumpGame(type: "NONE", toGameId: number): Promise<MoveGameResponseType>;
    /** 將目前遊戲室中的所有玩家，移動前往至某一關 */
    jumpGame(toGameId: number): Promise<MoveGameResponseType>;
    /** 讀取有加入活動的使用者編號 */
    fetchProfiles(
    /** 第 N 頁 */
    page?: number,
    /** 每頁幾筆，如果為 0 ，則不回傳資料，只回傳筆數 */
    pageSize?: number): Promise<{
        profileIds?: number[];
        page?: number;
        total: number;
    }>;
    getExtraByKey<T>(key: string, defVal?: T): T;
    hasProfileJoin(): Promise<boolean>;
    getProfileCount(): Promise<number>;
    readonly isInValidityPeriod: boolean;
}
/** 遊戲 */
declare class Game {
    /** 活動編號 */
    eventId: string;
    /** 遊戲編號 */
    gameId: number;
    background: string;
    extra: any;
    images: string[];
    joinable: boolean;
    name: string;
    order: number;
    passerbyAmount: number;
    defaultAvatar: string;
    style: string;
    type: string;
    constructor(
    /** 活動編號 */
    eventId: string,
    /** 遊戲編號 */
    gameId: number,
    /** 預仔入的資料 */
    data?: {});
    getExtraByKey<T>(key: string, defVal?: T): T;
    /** 更新遊戲資料 */
    update(
    /** 要更新的資料 */
    json: GameDto): Promise<void>;
    /** 讀取遊戲資料 */
    fetch(): Promise<any>;
    /** 發送指令 */
    sendCommand(
    /** 指令類型 */
    type: string,
    /** 內容 */
    content: any,
    /** 使用者編號 */
    profileId?: number): Promise<boolean>;
    /** 讀取活動中的指令 */
    fetchCommands(
    /** 分類 */
    type: string,
    /** 分頁 */
    page?: number,
    /** 每頁幾筆，如果為 0 ，則不回傳資料，只回傳筆數 */
    countPerPage?: number): Promise<{
        commands?: {
            type: string;
            content: any;
            profileId?: number;
            createdAt: number;
        }[];
        page?: number;
        total: number;
    }>;
    /** 以 Option 的 label 進行投票 */
    choiceByOptionLabel(profileId: number, label: string, options?: {
        type?: string;
        score?: number;
        ensureUnique?: boolean;
        allowUpdateOnUnique?: boolean;
        autoJoinProfile?: boolean;
    }): Promise<ProfileChoiceResponseDto[]>;
    /** 將活動中的所有使用者加入 */
    joinAll(): Promise<boolean>;
    /** 檢查使用者是否在遊戲中 */
    exist(profileId: number): Promise<boolean>;
    /** 取得使用者於遊戲中做的選擇 */
    fetchChoices(profileId: number): Promise<ProfileChoiceResponseDto[]>;
    /** 將使用者加入到遊戲中 */
    join(...profileId: number[]): Promise<boolean>;
    /** 讓使用者離開遊戲 */
    leave(profileId: number): Promise<boolean>;
    /** 讀取參與活動的使用者 */
    fetchProfiles(page?: number, pageSize?: number): Promise<{
        profileIds?: number[];
        page?: number;
        total: number;
    }>;
    /** 讀取所有的選項 */
    fetchAllOption(): Promise<Option[]>;
    /** 讀取所有的投票統計 */
    fetchChoiceStatistics(): Promise<{
        [optionId: string]: {
            [type: string]: number;
        };
    }>;
    /** 建立選擇體選項 */
    createOption(option: OptionDto): Promise<Option>;
    /** 讀取使用者投票的狀態 */
    fetchProfileChoices(profileId: number): Promise<ProfileChoiceResponseDto[]>;
    private _fillData;
}
/** 選擇題選項 */
declare class Option {
    /** 活動編號 */
    eventId: string;
    /** 遊戲編號 */
    gameId: number;
    /** 選項編號 */
    optionId: number;
    label: string;
    id: number;
    choices: {
        [type: string]: number;
    };
    extra: any;
    constructor(
    /** 活動編號 */
    eventId: string,
    /** 遊戲編號 */
    gameId: number,
    /** 選項編號 */
    optionId: number,
    /** 預先加載的資料 */
    data?: any);
    remove(): Promise<any>;
    /** 讀取資料 */
    fetch(): Promise<this>;
    /** 更新資料 */
    update(json: OptionDto): Promise<any>;
    /** 將選擇某個選項的玩家，移至另一個遊戲中 */
    moveToGame(toGameId: number): Promise<any>;
    /** 讀取投票結果 */
    statistics(): Promise<{
        [key: string]: number;
    }>;
    /** 投票 */
    choice(
    /** 使用者編號 */
    profileId: number, values?: {
        type?: string;
        score?: number;
        ensureUnique?: boolean;
        allowUpdateOnUnique?: boolean;
        autoJoinProfile?: boolean;
    }): Promise<boolean>;
    private _fillData;
}
/** 排行榜 */
declare class Rank {
    /** 活動編號 */
    eventId: string;
    /** 排行榜分類 */
    type: string;
    constructor(
    /** 活動編號 */
    eventId: string,
    /** 排行榜分類 */
    type?: string);
    /** 更新排行榜資訊 */
    update(
    /** 使用者編號 */
    profileId: number,
    /** 分數 */
    score: number): Promise<boolean>;
    /** 將排行榜上前 N 名玩家，移至另一個遊戲 */
    moveTopToGame(
    /** 前 N 名 */
    top: number,
    /** 移至的遊戲 ID */
    toGameId: number): Promise<any>;
    /** 將排行榜分數高於 N 的玩家，移至另一個遊戲 */
    moveScoreToGame(
    /** 分數 */
    score: number,
    /** 移至的遊戲 ID */
    toGameId: number): Promise<any>;
    /** 讀取排行榜資料 */
    get(
    /** 分頁，預設 1 */
    page?: number,
    /** 每頁筆數，預設 100*/
    countPerPage?: number): Promise<{
        profileId: number;
        score: number;
    }[]>;
}
declare class GameDto {
    /** 遊戲名稱 */
    name?: string;
    /** 遊戲類型 */
    type?: string;
    /** 背景圖片 */
    background?: string;
    /** 預設大頭貼 */
    defaultAvatar?: string;
    /** CSS 樣式 */
    style?: string;
    /** 頁面上圖片 */
    images?: string[];
    /** 額外資訊 */
    extra?: any;
    /** 排序 */
    order?: number;
}
declare class EventDto {
    /** 活動名稱 */
    name?: string;
    /** 活動類型 */
    type?: string;
    /** 背景圖片 */
    background?: string;
    /** 有效日期 */
    validityPeriod?: string;
    /** 預設大頭貼 */
    defaultAvatar?: string;
    /** CSS 樣式 */
    style?: string;
    /** 頁面上圖片 */
    images?: string[];
    /** 額外資訊 */
    extra?: any;
}
declare class OptionDto {
    label: string;
    extra?: any;
}
declare type MoveGameResponseType = {
    movedProfileCount: number;
    previousGameId: number;
    currentGameId: number;
};
declare class ProfileChoiceResponseDto {
    id: number;
    optionId: number;
    type: string;
    score: number;
}
`;
