<div *ngIf="unauthorized; else content" class="unauthorized">
  <h1 class="title" i18n>這是需要額外申請的服務</h1>
  <p class="content" i18n>
    若有需要申請此服務請與我們聯絡， 我們將竭誠為您服務！
  </p>
</div>

<ng-template #content>
  <ng-container *ngIf="collections; else loading">
    <div fxLayout="row" fxLayoutGap="15px">
      <mat-checkbox
        class="form-field"
        fxFlex="1 0 0"
        [(ngModel)]="node.isAsync"
        color="primary"
        i18n
      >
        {{ node.isAsync ? notText : '' }}同步模式
      </mat-checkbox>

      <button
        mat-icon-button
        (click)="refresh()"
        matTooltip="重新讀取資料集資訊"
        i18n-matTooltip="重新讀取資料集資訊"
      >
        <icon icon="refresh"></icon>
      </button>
    </div>

    <mat-form-field
      *ngIf="collections.length !== 0; else emptyCollections"
      legacyAppearance
    >
      <mat-label i18n>資料集</mat-label>
      <mat-select
        [(ngModel)]="node.collection"
        name="item"
        (ngModelChange)="select($event)"
      >
        <mat-select-trigger>
          <ng-container *ngIf="collection; else emptyCollection">
            <icon [icon]="collection.icon || '/assets/directus_collection.svg'">
            </icon>
            {{
              collection.translation
                | directusTranslation: collection.collection
            }}
          </ng-container>
        </mat-select-trigger>

        <mat-option
          *ngFor="let collection of collections"
          [value]="collection.collection"
        >
          <icon [icon]="collection.icon || '/assets/directus_collection.svg'">
          </icon>
          {{
            collection.translation | directusTranslation: collection.collection
          }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="collection; else emptyCollection">
      <mat-form-field legacyAppearance>
        <mat-label i18n>操作</mat-label>
        <mat-select [(ngModel)]="node.action">
          <mat-select-trigger>
            <span [ngSwitch]="node.action">
              <ng-container *ngFor="let action of actions">
                <ng-container *ngSwitchCase="action.action">
                  <icon [icon]="action.icon"></icon>
                  {{ action.label }}
                </ng-container>
              </ng-container>
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let action of actions" [value]="action.action">
            <icon [icon]="action.icon"></icon>
            {{ action.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field legacyAppearance>
        <mat-label i18n>回傳欄位</mat-label>
        <input matInput [(ngModel)]="node.responseVar" />
      </mat-form-field>

      <span [ngSwitch]="node.action">
        <ng-template ngSwitchCase="READ">
          <ng-container *ngTemplateOutlet="read"></ng-container>
        </ng-template>
        <ng-template ngSwitchCase="CREATE">
          <ng-container *ngTemplateOutlet="create"></ng-container>
        </ng-template>
        <ng-template ngSwitchCase="UPDATE">
          <ng-container *ngTemplateOutlet="update"></ng-container>
        </ng-template>
        <ng-template ngSwitchCase="DELETE">
          <ng-container *ngTemplateOutlet="delete"></ng-container>
        </ng-template>
      </span>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #read>
  <div fxLayout="row wrap">
    <mat-form-field fxFlex="5 0 400px" legacyAppearance>
      <mat-label i18n>要讀取欄位</mat-label>
      <mat-select [(ngModel)]="node.fields" multiple="true">
        <mat-option *ngFor="let field of fields" [value]="field.field">
          <ng-container
            *ngTemplateOutlet="fieldDisplay; context: { $implicit: field }"
          >
          </ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="!collection.single">
      <mat-form-field fxFlex="1 0 80px" class="offset" legacyAppearance>
        <mat-label i18n>起始筆數</mat-label>
        <input matInput [(ngModel)]="node.offset" />
      </mat-form-field>

      <mat-form-field fxFlex="1 0 80px" class="limit" legacyAppearance>
        <mat-label i18n>讀取筆數</mat-label>
        <input matInput [(ngModel)]="node.limit" />
      </mat-form-field>

      <mat-form-field fxFlex="2 0 160px" class="order" legacyAppearance>
        <mat-label i18n>排序欄位</mat-label>
        <input matInput [(ngModel)]="node.sort" />
      </mat-form-field>

      <mat-form-field fxFlex="3 0 240px" legacyAppearance>
        <mat-label i18n>關鍵字搜尋</mat-label>
        <input matInput [(ngModel)]="node.q" />
      </mat-form-field>

      <mat-form-field fxFlex="2 0 160px" legacyAppearance>
        <mat-label i18n>狀態</mat-label>
        <mat-select [(ngModel)]="node.status">
          <mat-option
            *ngFor="let option of statusOptions"
            [value]="option.value"
          >
            {{ option.name }} ({{ option.value }})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>

  <div class="filter" *ngIf="!collection.single">
    <div *ngFor="let filter of node.filter; let index = index">
      <div class="no">{{ index + 1 }}.</div>

      <mat-form-field legacyAppearance>
        <mat-label i18n>欄位</mat-label>
        <mat-select
          [(ngModel)]="filter.field"
          (ngModelChange)="selectFilterField($event, filter)"
        >
          <mat-option *ngFor="let field of fields" [value]="field.field">
            <ng-container
              *ngTemplateOutlet="fieldDisplay; context: { $implicit: field }"
            >
            </ng-container>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="filter.field">
        <mat-form-field legacyAppearance>
          <mat-label i18n>運算子</mat-label>
          <mat-select [(ngModel)]="filter.op" i18n>
            <mat-option value="eq">等於</mat-option>
            <mat-option value="neq">不等於</mat-option>
            <mat-option value="lt">小於</mat-option>
            <mat-option value="lte">小於等於</mat-option>
            <mat-option value="gt">大於</mat-option>
            <mat-option value="gte">大於等於</mat-option>
            <mat-option value="in">在清單中</mat-option>
            <mat-option value="nin">不在清單中</mat-option>
            <mat-option value="like">包含字串</mat-option>
            <mat-option value="rlike">包含字串（適用萬用字元）</mat-option>
            <mat-option value="nlike">不包含字串</mat-option>
            <mat-option value="nrlike">不包含字串（適用萬用字元）</mat-option>
            <mat-option value="between">介於</mat-option>
            <mat-option value="nbetween">不介於</mat-option>
            <mat-option value="null">是空的(null)</mat-option>
            <mat-option value="empty">是空的(null/falsy)</mat-option>
            <mat-option value="nnull">不是空的(null)</mat-option>
            <mat-option value="nempty">不是空的(null/falsy)</mat-option>
            <mat-option
              value="all"
              *ngIf="relations.includes(filter._field.interface)"
            >
              包含所有的
            </mat-option>
            <mat-option
              value="has"
              *ngIf="relations.includes(filter._field.interface)"
            >
              有一個或多個
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="!noValueOp.includes(filter.op)" legacyAppearance>
          <mat-label i18n>值</mat-label>
          <input matInput [(ngModel)]="filter.value" />
        </mat-form-field>
      </ng-container>

      <button
        mat-button
        color="warn"
        class="remove"
        (click)="removeFilter(index)"
        i18n
      >
        刪除
      </button>
    </div>
  </div>
</ng-template>

<ng-template #create>
  <div class="forms">
    <div class="field" fxLayout="row" *ngFor="let field of fields">
      <mat-checkbox
        matTooltip="寫入"
        i18n-matTooltip="寫入"
        color="primary"
        [(ngModel)]="node.enable[field.field]"
      >
      </mat-checkbox>

      <mat-form-field fxFlex="1 0 0" legacyAppearance>
        <mat-label>
          <ng-container
            *ngTemplateOutlet="fieldDisplay; context: { $implicit: field }"
          >
          </ng-container>
        </mat-label>
        <input
          matInput
          [(ngModel)]="node.body[field.field]"
          [disabled]="!node.enable[field.field]"
        />
        <mat-hint *ngIf="field.note">{{ field.note }}</mat-hint>
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template #update>
  <mat-form-field legacyAppearance>
    <mat-label i18n>編號</mat-label>
    <input matInput [(ngModel)]="node.itemId" />
  </mat-form-field>
  <ng-container *ngTemplateOutlet="create"></ng-container>
</ng-template>

<ng-template #delete>
  <mat-form-field legacyAppearance>
    <mat-label i18n>編號</mat-label>
    <input matInput [(ngModel)]="node.itemId" />
  </mat-form-field>
</ng-template>

<ng-template #fieldDisplay let-field>
  <ng-container *ngIf="field.translation; else onlyField">
    {{ field.translation | directusTranslation }}
    <span class="field"> ({{ field.field }}) </span>
  </ng-container>
  <ng-template #onlyField>
    <span class="field"> {{ field.field }} </span>
  </ng-template>
</ng-template>

<ng-template #emptyCollection i18n> 請選擇資料集合 </ng-template>

<ng-template #emptyCollections>
  <div class="empty" i18n>
    目前 Directus 中還沒有任何集合，請先到 Directus 中建立
  </div>
</ng-template>

<ng-template #loading>
  <gosu-loading i18n>資料集讀取中請稍後</gosu-loading>
</ng-template>

<ng-template #footer>
  <button
    mat-button
    (click)="addFilter()"
    *ngIf="collection && !collection.single && node.action === 'READ'"
    i18n
  >
    <mat-icon>add</mat-icon>
    新增過濾規則
  </button>

  <button mat-button class="add-condition-btn" (click)="dynamic.submit()" i18n>
    確定
  </button>
</ng-template>
