import { Area } from './area';
import { Button } from './button';
import { Card as CardContent } from './card';
import { Flex as FlexContent } from './flex';

export type ContentType =
  | 'text'
  | 'image'
  | 'video'
  | 'audio'
  | 'cards'
  | 'card'
  | 'location'
  | 'file'
  | 'poster'
  | 'article'
  | 'confirm'
  | 'template'
  | 'coupon'
  | 'coupon-record'
  | 'flex';

export type CouponRecordViaType =
  | 'command'
  | 'share'
  | 'qrcode'
  | 'broadcast'
  | 'flow'
  | 'privateMessage';

export namespace Content {
  export type Any =
    | Text
    | Image
    | Video
    | Audio
    | Cards
    | Card
    | Location
    | File
    | Poster
    | Article
    | Confirm
    | Template
    | Coupon
    | CouponRecord
    | Flex;

  export class Base {
    // 在轉化短網址時所需要的資訊，為了統計用途
    public logId?: number;
    public recordId?: number;
    public packageId?: number;

    public order?: number;
    public extra?: any = {};

    public constructor(
      // 訊息類型
      public type: ContentType,
    ) {}
  }

  export class Text extends Base {
    public type: 'text';

    public constructor(
      // 訊息內容
      public content: string,
    ) {
      super('text');
    }
  }

  export class Image extends Base {
    public type: 'image';

    public constructor(
      // 圖片網址
      public content: string,
      // 縮圖網址
      public preview: string = '',
      // 圖片大綱
      public alt: string = '',
    ) {
      super('image');
    }
  }

  export class Video extends Base {
    public type: 'video';

    public constructor(
      // 影片網址
      public content: string,
      // 縮圖網址
      public preview: string = '',
      // 圖片大綱
      public alt: string = '',
    ) {
      super('video');
    }
  }

  export class Audio extends Base {
    public type: 'audio';

    public constructor(
      // 聲音檔網址
      public content: string,
      // 音訊時間
      public duration: number = 0,
      // 大綱
      public alt: string = '',
    ) {
      super('audio');
    }
  }

  export class Location extends Base {
    public type: 'location';

    public constructor(
      // 標題
      public title: string,
      // 地址
      public content: string,
      // 經度
      public latitude: number,
      // 緯度
      public longitude: number,
    ) {
      super('location');
    }
  }

  export class File extends Base {
    public type: 'file';

    public constructor(
      // 檔案標題
      public title: string,
      // 檔案網址
      public content: string,
      // 大綱
      public alt: string = '',
    ) {
      super('file');
    }
  }

  export class Cards extends Base {
    public type: 'cards';

    public constructor(
      // 卡片
      public cards: (CardContent | Template)[],
      // 當設備不支援時替代的文字
      public alt: string = '',
    ) {
      super('cards');
    }
  }

  export class Card extends Base {
    public type: 'card';

    public cards: (CardContent | Template)[] = [];

    public constructor(
      // 圖片網址
      image: string,
      // 標題
      title: string,
      // 內容
      content: string,
      // 按鈕
      buttons: Button.Any[] = [],
      // 當設備不支援時替代的文字
      public alt: string = '',
    ) {
      super('card');
      this.cards[0] = new CardContent(image, title, content, buttons);
    }
  }

  export class Poster extends Base {
    public type: 'poster';

    public posterId: number;

    public constructor(
      // 圖片網址
      public content: string,
      // 當設備不支援時替代的文字
      public alt: string = '',
      // 行為
      public areas: Area.Any[] = [],
    ) {
      super('poster');
    }
  }

  export class Template extends Base {
    public type: 'template';

    public constructor(
      targetType: 'image',
      templateId: number,
      properties?: { key: string; name: string; default: string }[],
      preview?: Content.Image,
    );
    public constructor(
      targetType: 'poster',
      templateId: number,
      properties?: { key: string; name: string; default: string }[],
      preview?: Content.Poster,
    );
    public constructor(
      targetType: 'card',
      templateId: number,
      properties?: { key: string; name: string; default: string }[],
      preview?: Content.Cards,
      customize?: { [key: string]: any },
    );
    public constructor(
      targetType: null,
      templateId: number,
      properties?: { key: string; name: string; default: string }[],
      preview?: Content.Any,
      customize?: { [key: string]: any },
    );
    public constructor(
      public targetType: 'card' | 'poster' | 'image' | null,
      public templateId: number,
      public properties: { key: string; name: string; default: string }[] = [],
      public preview: Content.Cards | Content.Poster | Content.Image = null,
      public customize: { [key: string]: any } = {},
    ) {
      super('template');
    }
  }

  export class Article extends Base {
    public type: 'article';

    public constructor(
      // 文章標題
      public title: string,
      // 文章內容
      public content: string,
      // 文章圖片
      public preview?: string,
      // 大綱(無法顯示時的替代文字)
      public alt: string = title,
    ) {
      super('article');
    }
  }

  export class Confirm extends Base {
    public type: 'confirm';

    public buttons: Button.Any[] = [];

    public constructor(
      // 問題
      public title: string,
      // OK按鈕
      ok: Button.Any,
      // Cancel按鈕
      cancel: Button.Any,
      // 大綱(無法顯示時的替代文字)
      public alt: string = title,
    ) {
      super('confirm');
      this.buttons = [ok, cancel];
    }
  }

  /** 優惠券的範本 */
  export class Coupon extends Base {
    public type: 'coupon';
    public viaType: CouponRecordViaType;
    public info: string;
    public shareProfileId: number;
    public accountId: number;

    public constructor(public couponId: number) {
      super('coupon');
    }
  }

  /** 發出去一張一張的優惠券，會有序號等資訊 */
  export class CouponRecord extends Base {
    public type: 'coupon-record';

    public no: string;
    public couponType: 'discount' | 'free' | 'gift';
    public image: string;
    public title: string;
    public altText: string;
    public content: string;
    public description: string;
    public startAt: Date;
    public endAt: Date;
    public shareText?: string;

    public constructor(public couponId: number) {
      super('coupon-record');
    }
  }

  export class Flex extends Base {
    public type: 'flex';
    public constructor(public content: FlexContent.Message) {
      super('flex');
    }
  }
}
