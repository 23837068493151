import { NgIf } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { GosuValidate } from './gosu-validator.class';

interface ValidationRules {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  email?: boolean;
  uri?: boolean;
  arrayRequired?: boolean;
  bannedOptions?: boolean;
}

@Component({
  selector: 'gosu-validator',
  templateUrl: './gosu-validator.component.html',
  styleUrls: ['./gosu-validator.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GosuValidatorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf],
})
export class GosuValidatorComponent implements ControlValueAccessor, OnInit {
  @Input() public rules: ValidationRules;
  @Input() public offsetX = 0;
  @Input() public offsetY = 0;
  @Input() public message = '';
  @Input() public payload: {
    bannedOptions: (string | number)[];
  };

  public ngOnInit() {
    const validators = [];
    if (this.rules.required) {
      validators.push(Validators.required);
    }
    if (this.rules.minLength) {
      validators.push(Validators.minLength(this.rules.minLength));
    }
    if (this.rules.maxLength) {
      validators.push(Validators.maxLength(this.rules.maxLength));
    }
    if (this.rules.pattern) {
      validators.push(Validators.pattern(this.rules.pattern));
    }
    if (this.rules.email) {
      validators.push(Validators.email);
    }
    //array內的新增刪除並不會觸發驗證,因為監測不到,必須讓array重新賦值才會觸發(ex: array=[...array])
    if (this.rules.arrayRequired) {
      validators.push(this._arrayRequiredValidator());
    }
    if (this.rules.bannedOptions) {
      validators.push(this._selectBannedOptionValidator());
    }
    if (this.rules.uri) {
      validators.push(urlValidator);
    }

    this.control.setValidators(validators);
  }

  public control = new FormControl();

  // 實現 ControlValueAccessor 接口的方法
  public writeValue(value: any): void {
    this.control.setValue(value, { emitEvent: false });
  }

  public registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    // 可以用於處理當控件被觸摸時的事件
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  public getErrorMessage() {
    if (this.message) {
      return this.message;
    }
    if (this.control.hasError('required')) {
      return $localize`必填欄位`;
    }
    if (this.control.hasError('minLength')) {
      return $localize`最小長度為${this.rules.minLength}個字元`;
    }
    if (this.control.hasError('maxLength')) {
      return $localize`最小長度為${this.rules.maxLength}個字元`;
    }
    if (this.control.hasError('pattern')) {
      return $localize`格式不符`;
    }
    if (this.control.hasError('email')) {
      return $localize`email格式不符`;
    }
    if (this.control.hasError('selectBannedOption')) {
      return $localize`此選項已經停用`;
    }
    if (this.control.hasError('uri')) {
      return $localize`錯誤的網址格式`;
    }
    return '';
  }

  private _arrayRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (Array.isArray(value)) {
        if (
          value.length === 0 ||
          value.every((v) => GosuValidate.required(v))
        ) {
          return { required: true };
        }
      } else if (GosuValidate.required(value)) {
        return { required: true };
      }
      return null;
    };
  }

  private _selectBannedOptionValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedValue = control.value;
      const options = this.payload.bannedOptions;

      if (options && Array.isArray(options) && options.length > 0) {
        if (Array.isArray(selectedValue)) {
          if (selectedValue.some((v) => options.includes(v))) {
            return { selectBannedOption: true };
          }
        } else if (options.includes(selectedValue)) {
          return { selectBannedOption: true };
        }
      }
      return null;
    };
  }
}

export function urlValidator(
  control: AbstractControl,
): ValidationErrors | null {
  const value = control.value;
  if (!value) return null;
  if (isCode(value)) return null;

  try {
    new URL(value);
    return null;
  } catch (error) {
    return { uri: true };
  }
}

export function isCode(value: string): boolean {
  let pattern = new RegExp(/\$\{.*\}/g);
  return pattern.test(value);
}
