<svg:text
  class="name"
  [attr.x]="2 * em"
  [attr.y]="0.5 * em - (height - em) / 2"
  [style.font-size.px]="em"
  alignment-baseline="text-before-edge"
  text-anchor="start"
  [attr.fill]="node.color"
  [style.font-size.px]="node.fontSize"
  [style.font-weight]="
    flowService.selected.indexOf(node) !== -1 ? 'bold' : 'normal'
  "
>
  <tspan x="0" dy="0">
    {{ node?.display }}
  </tspan>

  <tspan
    x="0"
    dy="1.5em"
    [style.font-size.em]="0.8"
    *ngFor="let text of node.text.split('\n')"
  >
    {{ text }}
  </tspan>
</svg:text>
