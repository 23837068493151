import { Injectable, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FlexChineseService implements PipeTransform {
  public transform(value: string, isType?: boolean): string {
    if (value == 'promotionId') return $localize`推廣通路`;
    else if (value == 'scheme') return $localize`連結類型(僅支援LINE)`;
    else if (isType) return TYPE_NAME[value] || value;
    return PROPERTY_NAME[value] || value;
  }
}

export const PROPERTY_NAME = {
  type: $localize`類型`,
  direction: $localize`方向`,
  size: $localize`大小`,
  label: $localize`按鈕文字`,
  data: $localize`回傳內容`,
  displayText: $localize`訊息內容`,
  text: $localize`文字內容`,
  uri: $localize`網址`,
  altUri: $localize`電腦版網址`,
  mode: $localize`時間格式`,
  initial: $localize`預設日期`,
  max: $localize`最大值`,
  min: $localize`最小值`,
  backgroundColor: $localize`背景色`,
  separator: $localize`顯示分隔線`,
  separatorColor: $localize`分隔線顏色`,
  layout: $localize`呈現方式`,
  position: $localize`定位`,
  flex: $localize`彈性係數`,
  spacing: $localize`間隔`,
  margin: $localize`外距`,
  width: $localize`寬度`,
  height: $localize`高度`,
  borderWidth: $localize`邊框粗細`,
  borderColor: $localize`邊框顏色`,
  cornerRadius: $localize`圓角半徑`,
  offsetTop: $localize`上方偏移`,
  offsetBottom: $localize`下方偏移`,
  offsetStart: $localize`前方偏移`,
  offsetEnd: $localize`後方偏移`,
  paddingAll: $localize`內距`,
  paddingTop: $localize`上方內距`,
  paddingBottom: $localize`下方內距`,
  paddingStart: $localize`前方內距`,
  paddingEnd: $localize`後方內距`,
  color: $localize`顏色`,
  weight: $localize`字重`,
  style: $localize`樣式`,
  decoration: $localize`裝飾線`,
  align: $localize`水平對齊`,
  gravity: $localize`垂直對齊`,
  wrap: $localize`換行`,
  maxLines: $localize`最大行數`,
  url: $localize`網址`,
  aspectRatio: $localize`長寬比`,
  aspectMode: $localize`填滿方式`,
  action: $localize`動作`,
  justifyContent: $localize`順向軸對齊`,
  alignItems: $localize`交錯軸對齊`,
  angle: $localize`角度`,
  startColor: $localize`起始色`,
  endColor: $localize`終點色`,
  centerColor: $localize`中間色`,
  centerPosition: $localize`中間色位置`,
  animated: $localize`動畫`,
  adjustMode: $localize`文字尺寸`,
  previewUrl: $localize`預覽圖片`,
};

export const TYPE_NAME = {
  carousel: $localize`多頁訊息`,
  bubble: $localize`訊息`,
  header: $localize`標頭`,
  hero: $localize`主視覺`,
  body: $localize`內容`,
  footer: $localize`結尾頁`,
  box: $localize`容器`,
  image: $localize`圖片`,
  video: $localize`影片`,
  text: $localize`文字`,
  button: $localize`按鈕`,
  filler: $localize`填充`,
  spacer: $localize`空格`,
  separator: $localize`分隔線`,
  icon: $localize`圖標`,
  span: $localize`行內文字`,
};
