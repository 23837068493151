import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { LegacyAppearanceDirective } from '../../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { TranslateService } from '../../../../../service/translate.service';

@Component({
  selector: 'gosu-config-form',
  templateUrl: './config-form.component.html',
  styleUrls: ['./config-form.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MatFormField,
    MatLabel,
    MatInput,
    NgIf,
    MatError,
    MatIconButton,
    MatSuffix,
    MatIcon,
    MatSelect,
    MatOption,
    MatCheckbox,
    MatHint,
    MatButton,
    LegacyAppearanceDirective,
  ],
})
export class ConfigFormComponent {
  public name = new FormControl('', [Validators.required]);

  public key = new FormControl('', [
    Validators.required,
    this._onlyAlphaOrNumberOrUnderline.bind(this),
    this._startWithoutNumber.bind(this),
  ]);

  public type = new FormControl('string', [Validators.required]);

  public default = new FormControl('');
  public required = new FormControl(false);
  public hidden = new FormControl(false);
  public placeholder = new FormControl('');
  public info = new FormControl('');
  public disabled = new FormControl('');
  public enum = new FormControl('');
  public accept = new FormControl('');
  public multiple = new FormControl(false);

  public form = new FormGroup({
    name: this.name,
    key: this.key,
    type: this.type,
    default: this.default,
    required: this.required,
    hidden: this.hidden,
    placeholder: this.placeholder,
    info: this.info,
    disabled: this.disabled,
    enum: this.enum,
    accept: this.accept,
    multiple: this.multiple,
  });

  public constructor(
    private _matDialogRef: MatDialogRef<any>,
    public translateService: TranslateService,
  ) {}

  public _onlyAlphaOrNumberOrUnderline(ctrl: FormControl) {
    if (/\W/.test(ctrl.value)) {
      return { onlyAlphaOrNumberOrUnderline: true };
    }
    return false;
  }

  public _startWithoutNumber(ctrl: FormControl) {
    if (/^[0-9]/.test(ctrl.value)) {
      return { startWithoutNumber: true };
    }
    return null;
  }

  public submit() {
    if (this.form.invalid) {
      return;
    }

    const raw = this.form.getRawValue();

    this._matDialogRef.close(raw);
  }

  public lastTranslateName = '';

  public async translateKey() {
    let translated = await this.translateService.translateKey(this.name.value);
    this.key.setValue(translated);
  }
}
