import { FlagRule } from '../flag-rule.class';
import { FromJsonOption } from '../rule';

export class TemplateRule extends FlagRule {
  public keywords: string[] = [$localize`範本`];

  public constructor() {
    super('TEMPLATE');
  }

  public toString() {
    return $localize`範本`;
  }

  public static schema: string = 'template';

  public static async fromShortCode(code: string, option: FromJsonOption) {
    return new TemplateRule();
  }

  public toJSON(json: any = {}) {
    json.class = this.class;
    json.type = this.type;
    json.id = -1;
    return json;
  }
}
