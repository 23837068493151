<mat-card>
  <mat-card-header>
    <mat-card-title i18n>偏移</mat-card-title>
  </mat-card-header>
  <mat-divider> </mat-divider>
  <mat-card-content [formGroup]="formGroup">
    <div class="label" *ngFor="let offset of offsets">
      <div class="title">{{ offset.name | cht }}</div>
      <programmable
        [title]="offset.name | cht"
        [value]="formGroup.value[offset.name]"
        (valueChange)="
          formGroup.controls[offset.name].setValue($event);
          formValueChange.emit(formGroup.value);
          findError()
        "
      >
        <mat-form-field
          [class.mat-form-field-invalid]="offset.error"
          legacyAppearance
        >
          <input
            matInput
            color="primary"
            [formControlName]="offset.name"
            [matAutocomplete]="auto"
            (change)="formValueChange.emit(formGroup.value); findError()"
            (focus)="flexEditorService.isInputFocus$.next(true)"
            (blur)="flexEditorService.isInputFocus$.next(false)"
          />
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="
              formValueChange.emit(formGroup.value); findError()
            "
          >
            <mat-option
              *ngFor="let option of offset.autoComplete"
              [value]="option.value"
            >
              {{ option.text || option.value }}
            </mat-option>
          </mat-autocomplete>
          <mat-hint>
            <span [class.mat-error]="offset.error">
              {{ offset.error || offset.hint }}
            </span>
          </mat-hint>
        </mat-form-field>
      </programmable>
    </div>
  </mat-card-content>
</mat-card>
