import { Content } from '@ay/bot';
import { Message } from '../../base/base.message';

export class CouponRecordMessage extends Message<Content.CouponRecord> {
  public type = 'coupon';

  public toString() {
    return $localize`一張優惠券: ${this.content.title}`;
  }
}
