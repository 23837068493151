import { Component, Input } from '@angular/core';
import { FlexEditorService } from '../../../editor/editor.service';
import { RegularizedFlex } from '../../../regularized-flex.class';
import { FlexBubbleComponent } from '../bubble/bubble.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'flex-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    standalone: true,
    imports: [NgFor, FlexBubbleComponent],
})
export class FlexCarouselComponent {
  @Input()
  public data: RegularizedFlex;

  @Input()
  public flex: RegularizedFlex;

  public constructor(public readonly flexEditorService: FlexEditorService) {}
}
