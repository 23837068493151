import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import unescape from 'lodash/unescape';
import { BaseComponent } from '../base/base.component';
import { TextMessage } from './text.message';
import { Nl2brPipe } from '../../pipe/nl2br.pipe';
import { EscapePipe } from '../../pipe/escape.pipe';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'ms-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['package', 'mode', 'message'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[style.margin-right.px]': "mode == 'EDIT' ? 24 : 0",
    },
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        ExtendedModule,
        EscapePipe,
        Nl2brPipe,
    ],
})
export class TextComponent extends BaseComponent<TextMessage> {
  @ViewChild('textarea')
  public textarea: ElementRef<HTMLDivElement>;

  public constructor(protected readonly changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  public mousedown($event: MouseEvent) {
    this.package.inTextEditMode = true;
  }

  public focus($event: Event) {
    this.changeDetectorRef.markForCheck();
  }

  public blur($event: Event) {
    let content = this.message.content;
    content.content = unescape(this.textarea.nativeElement.innerHTML);
    this.package.inTextEditMode = false;
    this.changeDetectorRef.markForCheck();
  }

  public stop($event: MouseEvent) {
    $event.stopPropagation();
  }
}
