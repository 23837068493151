<ng-container *ngIf="data.contents && data.contents.length; else text">
  <p #p>
    <ng-container *ngFor="let content of $any(data.contents)">
      <flex-span
        [data]="content"
        [bubble]="bubble"
        [flex]="flex"
        [level]="level + 1"
        [class.active]="content.isSelect"
        (click)="
          $event.stopPropagation(); flexEditorService.selected$.next(content)
        "
      >
      </flex-span>
    </ng-container>
  </p>
</ng-container>

<ng-template #text>
  <p #p [innerHTML]="dataText"></p>
</ng-template>
