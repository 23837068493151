import { ApplicationRef, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  BotDto,
  FlowConnectionDto,
  FlowDto,
  FlowModel,
  ListFlowDto,
  PackageFetchForSendNode,
  PackageModel,
  TemplateDto,
  TemplateModel,
} from '@ay-gosu/server-shared';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { Map } from '@ay/util';
import Bluebird, { delay } from 'bluebird';
import { Dictionary } from 'lodash';
import isEqual from 'lodash/isEqual';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  firstValueFrom,
} from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';
import { BasicDialog } from '../../dialog/basic';
import { PackageFactoryService } from '../../message/package-factory.service';
import { SaveReminder } from '../../save-reminder';
import { BotService } from '../../service/bot.service';
import { CouponService } from '../../service/coupon.service';
import { FilterService } from '../../service/filter.service';
import { FlowListService } from '../../service/flow-list.service';
import { KeywordService } from '../../service/keyword.service';
import { PageService } from '../../service/page.service';
import { ProfileService } from '../../service/profile.service';
import { PropertyConfigService } from '../../service/property-config.service';
import { TagService } from '../../service/tag.service';
import { TokenService } from '../../service/token.service';
import { ChartComponent } from './chart/chart.component';
import { DeleteCommand } from './command/delete';
import { Invoker } from './command/invoker';
import { BlueprintConnection, Connection } from './connection/class';
import { generateNode } from './flow.util';
import { BlueprintNode } from './node/blueprint/class';
import { Node } from './node/class';
import { SendNode } from './node/send/class';

@Injectable({
  providedIn: 'root',
})
export class FlowService {
  public disableAction: boolean = false;
  public loadingStatus: string = '';
  public error = null;

  // #region isModify: boolean
  private _isModify: boolean = false;

  public get isModify(): boolean {
    return this._isModify;
  }

  public set isModify(status: boolean) {
    this._isModify = !!status;
    this.saveReminder.isActive = this._isModify;
  }
  // #endregion

  // #region debug: boolean
  public debug$ = new BehaviorSubject<boolean>(false);
  public get debug() {
    return this.debug$.value;
  }
  public set debug(flag) {
    if (flag) {
      window.localStorage.setItem('isDebug', 'true');
    } else {
      window.localStorage.setItem('isDebug', 'false');
    }
    this.debug$.next(flag);
  }
  // #endregion

  public checkErrorNodes() {
    if (!this.nodeList) return;
    this.nodeList.forEach((node) => {
      node.checkError();
    });
  }

  private _subscribeDebug$ = this.debug$.subscribe(() => {
    this.checkErrorNodes();
  });

  public oldNode: any;
  public em: BehaviorSubject<number> = new BehaviorSubject(16);
  public chart: ChartComponent = null;
  public selected: (Node | Connection)[] = [];
  public openedForm: Node = null;

  public blueprintConnection: BlueprintConnection = null;
  public blueprintNode: BlueprintNode = null;

  public flowId$ = new BehaviorSubject<number>(null);

  public flow$ = combineLatest([this.flowId$, this.flowListService.list$]).pipe(
    map(([flowId, flows]) => {
      if (!flowId || !(flows instanceof Array)) return null;
      return flows.find((flow) => flow.id == flowId);
    }),
    shareReplay(1),
  );

  public connectionList: Connection[] = [];
  public oriConnectionList: Connection[] = [];
  public nodeList: Node[] = [];
  public oriNodeList: Node[] = [];
  public templates$: Observable<TemplateDto[]> =
    this.tokenService.account$.pipe(
      mergeMap(() => {
        return TemplateModel.list().catch((err) => {
          console.error('Error TemplateModel.list:', { err });
          return [];
        });
      }),
    );

  public oriSelectedBotIds: number[] = [];
  public selectedBots: BotDto[] = [];

  public checkModify(): boolean {
    let isModify =
      !isEqual(
        this.selectedBots.map((bot) => bot.id),
        this.oriSelectedBotIds,
      ) ||
      !isEqual(this.nodeList, this.oriNodeList) ||
      this.oriConnectionList.length !== this.connectionList.length ||
      this.connectionList.reduce(
        (p, c) => p || this.oriConnectionList.indexOf(c) === -1,
        false,
      );

    this.isModify = this.nodeList.reduce(
      (prev, node) => prev || node.checkModify(),
      isModify,
    );

    return this.isModify;
  }

  public bots$ = this.botService.all$.pipe(
    map((bots) => bots.filter((bot) => !bot.disabled)),
  );

  public constructor(
    public applicationRef: ApplicationRef,
    public saveReminder: SaveReminder,
    public botService: BotService,
    public tokenService: TokenService,
    public keywordService: KeywordService,
    public tagService: TagService,
    public propertyConfigService: PropertyConfigService,
    public filterService: FilterService,
    public matConnectedDialog: MatConnectedDialog,
    public couponService: CouponService,
    public pageService: PageService,
    public profileService: ProfileService,
    public invoker: Invoker,
    public basicDialog: BasicDialog,
    public messageFactory: PackageFactoryService,
    public matSnackBar: MatSnackBar,
    public flowListService: FlowListService,
  ) {
    setTimeout(() => {
      if (window.localStorage.getItem('isDebug') === null) {
        this.debug = true;
      } else {
        this.debug = window.localStorage.getItem('isDebug') === 'true';
      }
    }, 1000);
  }

  public async deploy(): Promise<boolean> {
    let flowId = this.flowId$.getValue();
    if (!flowId) return;
    await FlowModel.deploy(flowId).catch((err) => {
      console.error('Error FlowModel.deploy:', { flowId, err });
      return false;
    });
  }

  public async getFlow(): Promise<ListFlowDto> {
    return await firstValueFrom(this.flow$);
  }

  public async getFlows(): Promise<ListFlowDto[]> {
    return firstValueFrom(this.flowListService.list$);
  }

  public async getTemplates(): Promise<TemplateDto[]> {
    return firstValueFrom(this.templates$);
  }

  public async deleteFlow(): Promise<boolean> {
    let flowId = this.flowId$.getValue();
    if (!flowId) return;
    let res = await FlowModel.delete(flowId).catch((err) => {
      console.error('Error FlowModel.delete:', { flowId, err });
      return false;
    });
    if (res) {
      this.flowListService.reload$.next(null);
      await delay(100);
    }
    return res;
  }

  public async createFlow(
    name: string = $localize`未命名的流程`,
  ): Promise<number> {
    let id = await FlowModel.create(name).catch((err) => {
      console.error('Error FlowModel.create', { name, err });
      return null;
    });
    if (id) {
      this.flowListService.reload$.next(null);
      await delay(100);
    }
    return id;
  }

  public async renameFlow(newName: string): Promise<boolean> {
    let flowId = this.flowId$.getValue();
    let res = await FlowModel.rename(flowId, newName).catch((err) => {
      console.error('Error FlowModel.rename', { flowId, newName, err });
      return false;
    });
    if (res) {
      this.flowListService.reload$.next(null);
      await delay(100);
    }
    return res;
  }

  private async _saveAllRecords() {
    let sendNodes: SendNode[] = this.nodeList.filter(
      (node) => node instanceof SendNode,
    ) as SendNode[];

    const maxCount = sendNodes.length;
    let count = 0;
    this.loadingStatus = $localize`正在儲存訊息內容 (0/${maxCount})`;
    return Bluebird.map(
      sendNodes,
      async (node) => {
        await node.saveRecords().catch((err) => {
          console.error('Error node.saveRecords:', { node, err });
        });
        count++;
        this.loadingStatus = $localize`正在儲存訊息內容 (${count}/${maxCount})`;
        this.applicationRef.tick();
      },
      { concurrency: 3 },
    );
  }

  public async saveFlow(): Promise<boolean> {
    if (this.disableAction) {
      return;
    }

    try {
      console.time('saveFlow');
      this.disableAction = true;

      console.time('saveAllRecords');
      this.loadingStatus = $localize`正在儲存訊息內容`;
      await this._saveAllRecords();
      console.timeEnd('saveAllRecords');

      const flowId = this.flowId$.getValue();
      const botIds = this.selectedBots.map((bot) => bot.id);
      const nodeDatas = this.nodeList.map((node) => node.toJSON());
      const connectionData = this.connectionList.map((connection) => ({
        from: this.nodeList.indexOf(connection.start.node),
        to: this.nodeList.indexOf(connection.end.node),
        fromJunctionIdx: connection.start.node.nodeComponent.junctions.indexOf(
          connection.start,
        ),
        toJunctionIdx: connection.end.node.nodeComponent.junctions.indexOf(
          connection.end,
        ),
      }));

      console.time('update');
      this.loadingStatus = $localize`正在儲存流程資料`;
      await FlowModel.update(flowId, botIds, nodeDatas, connectionData);
      console.timeEnd('update');

      console.time('deploy');
      this.loadingStatus = $localize`正在把服務部署到多台伺服器`;
      await this.deploy();
      console.timeEnd('deploy');

      console.time('loadFlow');
      this.loadingStatus = $localize`正在重新讀取流程資料`;
      await this.loadFlow();
      console.timeEnd('loadFlow');

      this.disableAction = false;
      this.isModify = false;
      console.timeEnd('saveFlow');
      this.flowListService.reload$.next(null);
      return true;
    } catch (error) {
      console.error($localize`儲存流程時發生錯誤:`, { error });
      this.flowListService.reload$.next(null);
      return false;
    }
  }

  private _reset() {
    this.isModify = false;
    this.selectedBots = [];
    this.oriSelectedBotIds = [];
    this.nodeList = [];
    this.oriNodeList = [];
    this.connectionList = [];
    this.oriConnectionList = [];
  }

  public destroy() {
    this.flowId$.next(null);
    this._reset();
  }

  public async loadFlow(): Promise<boolean> {
    try {
      if (this.chart) {
        this.chart.showGrid = false;
        this.chart.showMinimap = false;
      }
      this.disableAction = true;
      this._reset();

      let flowId = this.flowId$.getValue();
      if (!flowId) {
        return;
      }

      let flow: FlowDto;
      try {
        this.loadingStatus = $localize`正在讀取流程資料`;
        console.time($localize`讀取流程資料`);
        flow = await FlowModel.get(flowId);
        console.timeEnd($localize`讀取流程資料`);
      } catch (error) {
        if (error.message == '查無此流程') {
          throw new Error($localize`流程不存在或不屬於該組織`);
        }
        console.error(error);
        throw new Error($localize`讀取流程資料發生錯誤`);
      }

      let allBots: BotDto[] = [];
      try {
        this.loadingStatus = $localize`正在讀取機器人清單🤖`;
        console.time($localize`讀取機器人清單`);
        allBots = await firstValueFrom(this.botService.all$);
        console.timeEnd($localize`讀取機器人清單`);
      } catch (error) {
        console.error(error);
        throw new Error($localize`讀取機器人清單時發生錯誤`);
      }

      try {
        this.loadingStatus = $localize`正在生成節點與連結線`;
        await this.generateNodeAndConnections(flow);
      } catch (error) {
        console.error(error);
        throw new Error($localize`生成節點與連結線時發生錯誤`);
      }

      try {
        this.loadingStatus = $localize`正在記錄原始資料狀態$localize`;
        this.createOriginalStatus(flow, allBots);
      } catch (error) {
        console.error(error);
        throw new Error($localize`記錄流程原始資料時發生錯誤`);
      }

      // package
      this.loadingStatus = $localize`正在讀取訊息資料`;
      console.time($localize`讀取訊息資料`);
      await this.loadSendNodePackages();
      console.timeEnd($localize`讀取訊息資料`);

      // 等待流程清單載入，載入完畢時才會有 chart 可以用來顯示流程，不然畫面會有問題
      this.loadingStatus = $localize`正在讀取流程清單`;
      console.time($localize`讀取流程清單`);
      await firstValueFrom(this.flowListService.list$);
      console.timeEnd($localize`讀取流程清單`);
      this.loadingStatus = $localize`正在更新畫面`;

      this.disableAction = false;
      this.loadingStatus = $localize`正在更新畫面`;
      this.tick();

      this.recalcChartInfo();
      this.updateNodeView();
      this.tick();
      setTimeout(() => this.chart?.loadPreference(), 1000);
      this.checkErrorNodes();
    } catch (error) {
      this.error = error.message;
    }
  }

  private recalcChartInfo() {
    if (this.chart) {
      this.chart.recalcDisplayRange();
      this.chart.render();
      this.chart.detectionPadding();
    }
  }

  private updateNodeView() {
    this.nodeList.map((node) => {
      if (node.nodeComponent === undefined) {
        return;
      }
      node.nodeComponent.updateTransform();
      node.nodeComponent.updateSize();
    });
  }

  private async generateNodeAndConnections(flow: FlowDto) {
    this.nodeList = await Bluebird.map(
      flow.nodes,
      (node) => generateNode(node, this),
      { concurrency: 3 },
    );
    let nodeMap: Map<Node> = {};
    this.nodeList.forEach((node) => (nodeMap[node.id] = node));

    this.tick();

    this.connectionList = flow.connections
      .map((connection) => this._newConnection(nodeMap, connection))
      .filter((connection) => !!connection);
  }

  private createOriginalStatus(flow: FlowDto, allBots: BotDto[]) {
    const allowBotIds = allBots.map((bot) => bot.id);

    const botIds = flow.enabledBotIds.filter((botId) =>
      allowBotIds.includes(botId),
    );

    this.oriSelectedBotIds = botIds.slice();
    this.selectedBots = botIds
      .map((botId) =>
        allBots.filter((bot) => !bot.disabled).find((bot) => bot.id === botId),
      )
      .filter((bot) => !!bot);

    this.oriNodeList = this.nodeList.slice();
    this.oriConnectionList = this.connectionList.slice();
  }

  private _newConnection(nodeMap: Map<Node>, connection: FlowConnectionDto) {
    try {
      let { fromId, toId } = connection;
      let fromNode = nodeMap[fromId];
      let toNode = nodeMap[toId];

      if (
        fromNode.nodeComponent === undefined ||
        fromNode.nodeComponent.junctions[connection.fromJunctionIdx] ===
          undefined ||
        toNode.nodeComponent === undefined ||
        toNode.nodeComponent.junctions[connection.toJunctionIdx] === undefined
      ) {
        return;
      }

      return new Connection(
        fromNode.nodeComponent.junctions[connection.fromJunctionIdx],
        toNode.nodeComponent.junctions[connection.toJunctionIdx],
        fromNode.color,
      );
    } catch (err) {
      console.error('Error _newConnection:', err);
    }
    return;
  }

  protected async loadSendNodePackages() {
    let sendNodes: SendNode[];
    let needLoadRecordNodes: SendNode[];
    let forSendNodes: Dictionary<PackageFetchForSendNode>;

    try {
      sendNodes = this.nodeList.filter(
        (node) => node instanceof SendNode,
      ) as SendNode[];

      needLoadRecordNodes = sendNodes.filter(
        (node) => node.packageId && !node.fromOldPackage,
      );

      const packageIds = needLoadRecordNodes.map((node) => node.packageId);

      let loaded = 0;
      const max = packageIds.length;

      this.loadingStatus = $localize`正在讀取訊息資料 (${loaded}/${max}筆)`;
      forSendNodes = await PackageModel.fetchForSendNode(packageIds);
      loaded += Object.keys(forSendNodes).length;
      this.loadingStatus = $localize`正在讀取訊息資料 (${loaded}/${max}筆)`;
      console.info($localize`正在讀取訊息資料 (${loaded}/${max}筆)`);
      this.applicationRef.tick();
    } catch (error) {
      console.error(error);
      throw new Error($localize`讀取訊息資料時發生錯誤`);
    }

    try {
      let total = needLoadRecordNodes.length;
      this.loadingStatus = $localize`正在解壓縮訊息資料 (0/${total}筆)`;

      for (let i = 0; i < needLoadRecordNodes.length; i++) {
        const node = needLoadRecordNodes[i];
        try {
          const forSend = forSendNodes[node.packageId];
          node.package = await this.messageFactory.createForFlow(forSend);
          this.loadingStatus = $localize`正在解壓縮訊息資料 (${
            i + 1
          }/${total}筆)`;
          console.info($localize`正在解壓縮訊息資料 (${i + 1}/${total}筆)`);
        } catch (error) {
          this.matSnackBar.open($localize`解壓縮發送節點時發生異常`);
          node.error = error;
        }
      }
    } catch (error) {
      console.error(error);
      throw new Error($localize`解壓縮訊息資料時發生錯誤`);
    }
  }

  public tick() {
    try {
      this.applicationRef.tick();
    } catch (error) {}
  }

  public async deleteSelected() {
    this.invoker.do(new DeleteCommand(this, this.selected));
  }
}
