<mat-form-field [style.width.%]="100" legacyAppearance>
  <mat-label i18n>群組編號</mat-label>
  <input
    matInput
    matTooltip="選填，要查詢的成員清單的群組編號，留空則使用當前執行流程的 profile"
    i18n-matTooltip="
      選填，要查詢的成員清單的群組編號，留空則使用當前執行流程的 profile
    "
    [(ngModel)]="node.targetId"
  />
</mat-form-field>

<mat-form-field [style.width.%]="100" legacyAppearance>
  <mat-label i18n>回傳變數</mat-label>
  <input
    matInput
    [(ngModel)]="node.responseVar"
    matTooltip="篩選結果將存入 tmp 下，回傳型態為物件陣列"
    i18n-matTooltip="篩選結果將存入 tmp 下，回傳型態為物件陣列"
  />
</mat-form-field>

<div fxLayout="row ">
  <mat-checkbox
    fxFlex="1 0 0"
    [(ngModel)]="node.includeInfo"
    matTooltip="回傳的每一個元素包含平台類型(platformType)、類型(type)、狀態(status)、照片(picture)、個人狀態(message)、封鎖時間(unfollowedAt)"
    i18n-matTooltip="
      回傳的每一個元素包含平台類型(platformType)、類型(type)、狀態(status)、照片(picture)、個人狀態(message)、封鎖時間(unfollowedAt)
    "
    i18n
  >
    額外資訊
  </mat-checkbox>

  <mat-checkbox
    fxFlex="1 0 0"
    [(ngModel)]="node.includeTag"
    matTooltip="回傳的每一個元素包含標籤陣列(tags)，其每一個元素都由 id, name 組成"
    i18n-matTooltip="
      回傳的每一個元素包含標籤陣列(tags)，其每一個元素都由 id, name 組成
    "
    i18n
  >
    標籤
  </mat-checkbox>

  <mat-checkbox
    fxFlex="1 0 0"
    [(ngModel)]="node.includeProperty"
    matTooltip="回傳的每一個元素包含屬性陣列(properties)，其每一個元素都由 key, value 組成"
    i18n-matTooltip="
      回傳的每一個元素包含屬性陣列(properties)，其每一個元素都由 key, value 組成
    "
    i18n
  >
    屬性
  </mat-checkbox>
</div>
