import { Injectable } from '@angular/core';
import {
  ExchangeModel,
  UseExchangeFlowAndNodeIdsDto,
} from '@ay-gosu/server-shared';
import { BehaviorSubject, ReplaySubject, combineLatest, of } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  mergeMap,
  shareReplay,
} from 'rxjs/operators';
import { BasicDialog } from '../dialog/basic';
import { BypassSecurityService } from './bypass-security.service';
import { TokenService } from './token.service';

@Injectable({ providedIn: 'root' })
export class ExchangeService {
  public id$ = new ReplaySubject<number>(1);

  public reload$ = new BehaviorSubject(1);

  public response$ = combineLatest([this.token.account$, this.reload$]).pipe(
    mergeMap(([account]) =>
      account ? ExchangeModel.list(1000, 1) : of({ data: [], total: 0 }),
    ),
    shareReplay(),
  );

  public list$ = this.response$.pipe(map((res) => res.data));

  public updateSelected$: BehaviorSubject<void> = new BehaviorSubject(null);

  public selected$ = combineLatest([
    this.id$.pipe(distinctUntilChanged()),
    this.token.account$,
    this.updateSelected$,
  ]).pipe(
    mergeMap(([id]) => ExchangeModel.fetch(id).catch((error) => null)),
    shareReplay(),
  );

  public methods$ = this.selected$.pipe(map((exchange) => exchange?.methods));

  public constructor(
    private token: TokenService,
    private _basicDialog: BasicDialog,
    private _bypassSecurityService: BypassSecurityService,
  ) {}

  public async checkExchangeUsedInFlow(
    exchangeId: number,
    methodIds?: number[],
  ): Promise<boolean> {
    let flows: UseExchangeFlowAndNodeIdsDto[] = [];
    if (methodIds) {
      flows = await ExchangeModel.useExchangeFlowAndNodeIds(
        exchangeId,
        methodIds,
      );
    } else {
      flows = await ExchangeModel.useExchangeFlowAndNodeIds(exchangeId);
    }
    if (flows && flows.length > 0) {
      const flowWord = $localize`流程`;
      const nodeWord = $localize`節點`;
      const content = flows
        .map(
          (flow) => `
            <div style="margin-bottom:10px">
              <div style="margin-bottom:5px"><i class="mi">call_split</i>${flowWord}#${flow.id}:</div>
              <div>${
                flow.nodeIds
                  ? flow.nodeIds
                      .map(
                        (nodeId) =>
                          `<a href="./flow/${flow.id}?nodeId=${nodeId}" target="_blank"> ${nodeWord}#${nodeId} <i class="mi" style="vertical-align:middle;color:#FFC107">open_in_new</i> </a>`,
                      )
                      .join('、')
                  : ''
              }</div>
            </div>
          `,
        )
        .join('');
      let htmlContent = this._bypassSecurityService.bypassSecurityHtml(
        `<div style="max-height:300px;overflow-y:auto;">${content}</div>`,
      ) as string;

      const res = await this._basicDialog.confirm(
        $localize`可能導致「以下」流程執行異常，確定要刪除嗎？`,
        {
          htmlContent,
        },
      );
      if (!res) return false;
    }
    return true;
  }
}
