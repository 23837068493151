import { ProfileDto } from '@ay-gosu/server-shared';
import { RuleOp } from '@ay-gosu/types';
import { ensureTheElementIsWhitelisted } from '@ay/util';
import { ReadableError } from '../../../../../util/readable-error';
import { FromJsonOption, Rule } from '../rule';

export class ProfileRule extends Rule {
  public static op: RuleOp[] = ['EQUAL'];

  public constructor(public profile: ProfileDto) {
    super('PROFILE');
    this.op = 'EQUAL';
  }

  public afterTypeChanged(isUserEvent = false) {
    this.op = ensureTheElementIsWhitelisted(this.op, ...ProfileRule.op);
  }

  public async fromJSON(json: any, option: FromJsonOption) {
    await super.fromJSON(json, option);
    const profile = await option.profileService.get(json.profileId);
    this.profile = profile;
  }

  public toRuleObject(json: any = {}) {
    super.toRuleObject(json);
    json.profileId = this.profile.id;
    return json;
  }

  public similar(keyword: string) {
    return (
      this.profile.name.toString().indexOf(keyword) !== -1 ||
      this.profile.id.toString().indexOf(keyword) !== -1
    );
  }

  public toString() {
    switch (this.op) {
      case 'EQUAL':
        return `${this.profile.name}`;
    }
  }

  public static schema: string = 'profile';

  public toShortCode(): string {
    return `${this.profile.id}`;
  }

  public static async fromShortCode(code: string, option: FromJsonOption) {
    const profileId = parseInt(code);

    const profile = await option.profileService.get(profileId);

    if (profile === undefined) {
      throw new ReadableError($localize`未知的使用者編號${code}`);
    }

    return new ProfileRule(profile);
  }

  public checkError(): boolean {
    return false;
  }
}
