export const LINE_URL_SCHEME = {
  camera: {
    text: $localize`開啟相機`,
    value: 'https://line.me/R/nv/camera/',
  },
  location: {
    text: $localize`開啟地圖`,
    value: 'https://line.me/R/nv/location/',
  },
  lineOut: {
    text: $localize`開啟 Line Out`,
    value: 'https://line.me/R/calls',
  },
  shareAccount: {
    text: $localize`分享機器人`,
    value: 'https://line.me/R/nv/recommendOA/${to.bot.property.botId}',
  },
};
