import { GosuValidate } from '../../../../components/gosu-validator/gosu-validator.class';
import { ParamConfig } from '../../../../components/params-form/params-form.component';
import { Node } from '../class';

export class EventNode extends Node {
  public icon = 'arrow_forward';
  public ref = '';
  public _color = '#368858';
  public isSpecial: boolean;
  public specialCount: number;
  public specialTimeout: string;
  public specialMode: 'concurrent' | 'monopolize' = 'concurrent';
  public specialTarget: 'profile' | 'company' | 'bot' | 'record' = 'profile';
  public extraProperties = [
    'isSpecial',
    'specialCount',
    'specialTimeout',
    'specialTarget',
    'specialMode',
  ];

  public display = '';

  public async getDisplay() {
    if (this.name !== '起始點') return this.name;
    return this.getEventDisplay();
  }

  public getEventDisplay() {
    return '';
  }

  public bannedEvents = [
    'RemoveRatingsNode',
    'RemoveLikePageNode',
    'LeaveBeaconEventNode',
  ];

  public checkError(): void {
    this.hasError = false;
    const isCheckError = window.localStorage.getItem('isDebug') === 'true';

    if (isCheckError) {
      if (this._checkError(this)) {
        this.hasError = true;
      }
    }
  }

  private _checkError = (node: Node) => {
    if (GosuValidate.bannedOptions(node.type, this.bannedEvents)) {
      return true;
    }
    return false;
  };
}

export class UntreatedEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`當訊息未被處理時`;
  }
}

export class MessageEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`收到任何訊息`;
  }
}
export class TextMessageEventNode extends MessageEventNode {
  public getEventDisplay() {
    return $localize`收到文字`;
  }
}
export class ImageMessageEventNode extends MessageEventNode {
  public getEventDisplay() {
    return $localize`收到圖片`;
  }
}
export class VideoMessageEventNode extends MessageEventNode {
  public getEventDisplay() {
    return $localize`收到影片`;
  }
}
export class AudioMessageEventNode extends MessageEventNode {
  public getEventDisplay() {
    return $localize`收到聲音`;
  }
}
export class LocationMessageEventNode extends MessageEventNode {
  public getEventDisplay() {
    return $localize`收到位置資訊`;
  }
}
export class JoinEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`加入群組`;
  }
}
export class LeaveEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`離開群組`;
  }
}
export class FollowEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`加入好友`;
  }
}
export class UnfollowEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`封鎖好友`;
  }
}

export class PostbackEventNode extends EventNode {
  public getEventDisplay() {
    return 'Postback';
  }
}
export class AccountLinkEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`成功鏈結帳號`;
  }
}
export class AddIGCommentNode extends EventNode {
  public getEventDisplay() {
    return $localize`IG 使用者回應文章`;
  }
}
export class AddPostEditorsNode extends EventNode {
  public getEventDisplay() {
    return $localize`小編發文`;
  }
}
export class RemovePostEditorsNode extends EventNode {
  public getEventDisplay() {
    return $localize`小編刪除貼文`;
  }
}
export class AddPostCustomerNode extends EventNode {
  public getEventDisplay() {
    return $localize`使用者發文`;
  }
}
export class RemovePostCustomerNode extends EventNode {
  public getEventDisplay() {
    return $localize`使用者刪除貼文`;
  }
}
export class AddLikePageNode extends EventNode {
  public getEventDisplay() {
    return $localize`收到讚`;
  }
}
export class RemoveLikePageNode extends EventNode {
  public getEventDisplay() {
    return $localize`讚被收回(不再支援)`;
  }
}
export class AddRatingsNode extends EventNode {
  public getEventDisplay() {
    return $localize`使用者新增評論`;
  }
}
export class RemoveRatingsNode extends EventNode {
  public getEventDisplay() {
    return $localize`使用者刪除評論(不再支援)`;
  }
}
export class AddCommentNode extends EventNode {
  public getEventDisplay() {
    return $localize`使用者回應文章`;
  }
}
export class RemoveCommentNode extends EventNode {
  public getEventDisplay() {
    return $localize`使用者刪除回應`;
  }
}
export class AddReactionNode extends EventNode {
  public getEventDisplay() {
    return $localize`使用者對貼文按讚`;
  }
}
export class EditReactionNode extends EventNode {
  public getEventDisplay() {
    return $localize`使用者編輯貼文的按讚`;
  }
}
export class EditedPostCustomerNode extends EventNode {
  public getEventDisplay() {
    return $localize`使用者編輯貼文`;
  }
}
export class EditedPostEditorsNode extends EventNode {
  public getEventDisplay() {
    return $localize`小編編輯文章`;
  }
}
export class RemoveReactionNode extends EventNode {
  public getEventDisplay() {
    return $localize`使用者收回貼文的按讚`;
  }
}
export class MemberJoinedEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`群組有新成員`;
  }
}
export class MemberLeftEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`群組成員退出`;
  }
}
export class UseCouponEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`使用優惠券`;
  }
}
export class ReceiveCouponEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`收到優惠券`;
  }
}

export class EnterBeaconEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`進入 Beacon 範圍`;
  }
}

export class ClickBeaconBannerEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`點擊 Beacon Banner`;
  }
}

export class StayBeaconEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`待在 Beacon 範圍`;
  }
}

export class LeaveBeaconEventNode extends EventNode {
  public getEventDisplay() {
    return $localize`離開 Beacon 範圍`;
  }
}

export class MMEEventNode extends EventNode {
  public getEventDisplay() {
    return 'M.ME ' + this['ref'];
  }

  public readonly extraProperties = [
    'isSpecial',
    'specialCount',
    'specialTimeout',
    'specialTarget',
    'specialMode',
    'ref',
  ];
}

export class IGMEEventNode extends EventNode {
  public getEventDisplay() {
    return 'IG.ME ' + this['ref'];
  }

  public readonly extraProperties = [
    'isSpecial',
    'specialCount',
    'specialTimeout',
    'specialTarget',
    'specialMode',
    'ref',
  ];
}

export class ExchangeEventNode extends EventNode {
  public exchangeId = '';
  public ticketId = '';
  public readonly extraProperties = ['exchangeId', 'ticketId'];

  public getEventDisplay() {
    return $localize`當使用者成功兌換獎品`;
  }
}

export class WebhookEventNode extends EventNode {
  public path: string = '';
  public method: string = 'get';
  public responseVar: string = '';
  public profileVar: string = '';
  public recordVar: string = '';
  public isAutoReply: boolean = true;
  public isOAuth: boolean = false;
  public whitelist: string = '';
  public generateForm: boolean = false;
  public formConfig: ParamConfig[] = [];
  public readonly extraProperties = [
    'isSpecial',
    'specialCount',
    'specialTimeout',
    'specialTarget',
    'specialMode',
    'path',
    'method',
    'isOAuth',
    'whitelist',
    'responseVar',
    'profileVar',
    'recordVar',
    'isAutoReply',
    'generateForm',
    'formConfig',
  ];

  public getEventDisplay() {
    return '/' + this['path'];
  }
}

export class SurveyCakeEventNode extends EventNode {
  public integrateDirectus: boolean;
  public surveyCakeUrl: string;
  public hashKey: string;
  public ivKey: string;

  public getEventDisplay() {
    let svid = 'SurveyCake';
    if (this.surveyCakeUrl) {
      svid = this.surveyCakeUrl.replace('https://www.surveycake.com/s/', '');
    }
    return $localize`填完 ${svid} 表單`;
  }

  public readonly extraProperties = [
    'integrateDirectus',
    'surveyCakeUrl',
    'hashKey',
    'ivKey',
  ];
}

export class AddThroughPromotionEventNode extends EventNode {
  public promotionChannelId: number;
  public subPromotionChannelId: number;
  public readonly extraProperties = [
    'promotionChannelId',
    'subPromotionChannelId',
  ];

  public getEventDisplay() {
    return $localize`透過推廣通路加入好友`;
  }
}

export class SuccessInviteFriendThroughPromotionEventNode extends EventNode {
  public promotionChannelId: number;
  public readonly extraProperties = ['promotionChannelId'];

  public getEventDisplay() {
    return $localize`透過推廣通路邀請好友`;
  }
}
