import { Component, ElementRef } from '@angular/core';
import { FormComponent } from '../form.component';
import { AwaitNode } from './class';

@Component({
    selector: 'flow-await-form',
    templateUrl: './form.component.html',
    standalone: true,
})
export class AwaitFormComponent extends FormComponent<AwaitNode> {
  public width = 400;

  public constructor(public elementRef: ElementRef) {
    super(elementRef);
  }
}
