import { Clipboard } from '@angular/cdk/clipboard';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { AsyncPipe, NgIf } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { Content } from '@ay/bot';
import cloneDeep from 'lodash/cloneDeep';
import { Subject } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { EditorComponent } from '../../../components/ngx-monaco-editor/editor.component';
import { MonacoEditorLoaderService } from '../../../components/ngx-monaco-editor/monaco-editor-loader.service';
import { BasicDialog } from '../../../dialog/basic';
import { MatTooltip } from '../../../material/tooltip/tooltip';
import { FlexCheckService } from '../../../service/flex-check.service';
import { Package } from '../../package.class';
import { FlexPickerComponent } from '../picker/picker.component';
import { FlexPreviewComponent } from '../preview/preview.component';
import { RegularizedFlex } from '../regularized-flex.class';
import { FlexEditorClipBoardService } from './clipboard.service';
import { FlexComponentDialogComponent } from './component-dialog/component-dialog.component';
import { FlexEditorService } from './editor.service';
import { FlexFormComponent } from './form/form.component';
import { FlexTreePickComponent } from './tree-pick/tree-pick.component';

@Component({
  selector: 'ms-flex-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexPreviewComponent,
    MatDivider,
    CdkScrollable,
    MatTooltip,
    MatIconButton,
    MatIcon,
    FlexTreePickComponent,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    FlexFormComponent,
    MatButton,
    MatProgressSpinner,
    EditorComponent,
    FormsModule,
    FlexModule,
    AsyncPipe,
  ],
})
export class FlexEditorComponent implements OnDestroy {
  public enableMonaco$ = this._monacoEditorLoaderService.enableMonaco$;

  public expandTree = true;
  public autoScroll = true;

  public editorOptions = {
    theme: 'vs-dark',
    language: 'json',
    minimap: { enabled: false },
    snippetSuggestions: 'auto',
  };

  protected jsonDialogRef: MatDialogRef<any, any>;

  public get hasError() {
    let error = document.querySelector('tree-pick li.error');
    if (error) return true;
    return false;
  }

  @ViewChild('jsonDialog')
  public jsonDialog: TemplateRef<any>;

  public json: string = '';

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    public readonly flexEditorService: FlexEditorService,
    public readonly flexEditorClipBoardService: FlexEditorClipBoardService,
    private readonly _matSnackBar: MatSnackBar,
    private readonly _clipboard: Clipboard,
    private readonly _basicDialog: BasicDialog,
    private readonly _matDialogRef: MatDialogRef<
      FlexEditorComponent,
      RegularizedFlex
    >,
    private readonly _matConnectedDialog: MatConnectedDialog,
    @Inject(MAT_DIALOG_DATA)
    data: { flex: RegularizedFlex; package: Package },
    private readonly _monacoEditorLoaderService: MonacoEditorLoaderService,
    private readonly _flexCheckService: FlexCheckService,
  ) {
    this.flexEditorService.regularizedFlex = data.flex;
    this.flexEditorService.package = data.package;
    this._matDialogRef
      .afterClosed()
      .subscribe(() => this.flexEditorService.reset());

    this.flexEditorService.editorDialogRef = this._matDialogRef;
    this.flexEditorService.selected$
      .pipe(
        tap((select) => (this.expandTree = !!select)),
        tap((select) => !!select),
        delay(100),
        takeUntil(this._destroy$),
      )
      .subscribe(() => this.scrollToActive());
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public get screenWidth() {
    return document.body.clientWidth;
  }

  public get canUseComponent() {
    let select = this.flexEditorService.selected$.value;
    if (!select) return null;
    return this.flexEditorService.findChildTypes(select)?.includes('box');
  }

  public openTemplates() {
    this.flexEditorService.selected$.next(null);

    let dialogRef = this._matConnectedDialog.open(FlexPickerComponent);

    dialogRef.afterClosed().subscribe((flex) => {
      if (!flex) return;
      this.flexEditorService.beforeChange$.next();
      this.flexEditorService.regularizedFlex =
        RegularizedFlex.fromContentFlex(flex);
      this.flexEditorService.afterChange$.next();
    });
  }

  public openComponents() {
    let dialogRef = this._matConnectedDialog.open(
      FlexComponentDialogComponent,
      {
        width: '80vw',
        maxWidth: '960px',
      },
    );

    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result) return;
      this.flexEditorClipBoardService.addContent(result);
    });
  }

  public async rename() {
    this.flexEditorService.selected$.next(null);
    let name = await this._basicDialog.rename(
      this.flexEditorService.regularizedFlex.content.altText,
    );
    if (!name) return;
    this.flexEditorService.beforeChange$.next();
    this.flexEditorService.regularizedFlex.content.altText = name;
    this.flexEditorService.afterChange$.next();
  }

  public copyJson() {
    this._clipboard.copy(this.json);
    this._matSnackBar.open($localize`複製完成`, 'Close', { duration: 500 });
    this.closeJsonDialog();
  }

  public changeJson(json: string) {
    this.flexEditorService.beforeChange$.next();
    const content = JSON.parse(json);
    const flex = new Content.Flex(content);
    const valid = this._flexCheckService.checkFlexMessage(content);
    if (!valid.valid) {
      this._basicDialog.error(valid.message);
      return;
    }
    this.flexEditorService.regularizedFlex =
      RegularizedFlex.fromContentFlex(flex);
    this.flexEditorService.afterChange$.next();
    this.closeJsonDialog();
  }

  public openJsonDialog() {
    const flex = this.flexEditorService.regularizedFlex.toContentFlex();
    this.json = JSON.stringify(flex.content, null, 2);
    this.jsonDialogRef = this._matConnectedDialog.open(this.jsonDialog);
  }

  public closeJsonDialog() {
    this.jsonDialogRef.close();
    this.jsonDialogRef = null;
  }

  public close() {
    this.flexEditorService.selected$.next(null);
    setTimeout(() => {
      this._matDialogRef.close();
    }, 1);
  }

  public save() {
    if (!this._checkFlexHasBody(this.flexEditorService.regularizedFlex)) {
      this._basicDialog.error($localize`內容至少要有一個容器`);
      return;
    }
    this.flexEditorService.selected$.next(null);
    setTimeout(() => {
      let value = cloneDeep(this.flexEditorService.regularizedFlex);
      this._matDialogRef.close(value);
    }, 1);
  }

  private _checkFlexHasBody(flex: RegularizedFlex) {
    switch (flex?.content?.contents?.type) {
      case 'bubble':
        if (flex?.content?.contents?.body?.contents?.length === 0) return false;
        break;
      case 'carousel':
        for (let bubble of flex?.content?.contents?.contents) {
          if (bubble?.body?.contents?.length === 0) return false;
        }
        break;
    }
    return true;
  }

  protected scrollToActive() {
    let actives = document.querySelectorAll('ms-flex-editor .active');

    actives.forEach((active) => {
      if (active.nodeName == 'LI' && !this.autoScroll) {
        return;
      }

      active.scrollIntoView({
        block: 'end',
        inline: 'center',
        behavior: 'smooth',
      });
    });
  }
}
