import { NgIf } from '@angular/common';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TagDto } from '@ay-gosu/server-shared';
import { delay } from 'bluebird';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, mergeMap, shareReplay, takeUntil } from 'rxjs/operators';
import { validateName } from '../../../../util/validators/validate-name';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTreePicker } from '../../../material/tree-picker/tree-picker';
import {
  MatTreePickerNode,
  MatTreePickerNodeDef,
} from '../../../material/tree-picker/tree-picker-node';
import { MatTreePickerTrigger } from '../../../material/tree-picker/tree-picker-trigger';
import { TagService, TagTargetType } from '../../../service/tag.service';

@Component({
  selector: 'dl-create-tag',
  templateUrl: './create-tag.component.html',
  styleUrls: ['./create-tag.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    NgIf,
    MatInput,
    MatError,
    MatCheckbox,
    MatButton,
    MatTreePickerTrigger,
    MatTreePicker,
    MatTreePickerNodeDef,
    MatTreePickerNode,
    LegacyAppearanceDirective,
  ],
})
export class CreateTagComponent implements OnDestroy, OnInit {
  public name = new FormControl('', Validators.required);

  public form = new FormGroup({ name: this.name });

  public tagNames: string[] = [];

  //#region parentTag
  private _parentTag: TagDto = null;

  public parentTag$ = new Subject<TagDto>();

  public get parentTag(): TagDto {
    return this._parentTag;
  }

  public set parentTag(parentTag: TagDto) {
    if (this._parentTag === parentTag) return;
    this._parentTag = parentTag;
    this.parentTag$.next(this._parentTag);
  }
  //#endregion parentTag

  //#region @Input() targetType
  private _targetType: TagTargetType = 'profile';

  public targetType$ = new BehaviorSubject<TagTargetType>('profile');

  public get targetType(): TagTargetType {
    return this._targetType;
  }

  @Input()
  public set targetType(targetType: TagTargetType) {
    if (this._targetType === targetType) return;
    this._targetType = targetType;
    this.targetType$.next(targetType);
  }
  //#endregion @Two-way() targetType

  public isChild: boolean = false;

  public tags$ = this.targetType$.pipe(
    mergeMap((targetType) => this._tagService.getTagTreeByType(targetType)),
    map((tags) => this._tagService.toTreePickerNodes(tags)),
    shareReplay(1),
  );

  @ViewChild(MatTreePickerTrigger)
  public trigger: MatTreePickerTrigger<any>;

  private readonly _destroy$ = new Subject<void>();

  private _changeTagNameWhenChanged = this.tags$
    .pipe(takeUntil(this._destroy$))
    .subscribe((tag) => {
      if (tag) {
        this.tagNames = this.extractNames(tag);
      } else {
        this.tagNames = [];
      }
    });

  private _changeIsChildWhenParentTagChanged = this.parentTag$
    .pipe(takeUntil(this._destroy$))
    .subscribe((parentTag) => {
      if (parentTag) this.isChild = true;
      else this.isChild = false;
    });

  public constructor(
    private _tagService: TagService,
    @Optional()
    private _matDialogRef: MatDialogRef<CreateTagComponent>,
  ) {}

  public ngOnInit(): void {
    this.name.addValidators(validateName(this.tagNames));
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public extractNames(tags: TagDto[]) {
    let tagNames = [];

    tags.forEach((tag) => {
      tagNames.push(tag.name);
      if (tag.children && tag.children.length > 0) {
        tagNames = tagNames.concat(this.extractNames(tag.children));
      }
    });

    return tagNames;
  }

  public selectTag(tag: TagDto) {
    this.parentTag = tag;
    this.trigger.closePanel();
    this.isChild = true;
  }

  public async afterIsChildChange(isChild: boolean) {
    if (isChild) {
      await delay(1);
      this.trigger.open();
    } else {
      this.parentTag = null;
    }
  }

  public async submit() {
    if (this.form.invalid) {
      return;
    }
    let tag = new TagDto();
    tag.name = this.name.value;
    this.setDefaultColumn(tag);
    if (this.isChild) {
      tag.tagId = this.parentTag.id;
    }
    let id = await this._tagService.create(tag);
    if (this._matDialogRef) {
      this._matDialogRef.close({ id });
    }
  }

  public setDefaultColumn(tag: TagDto) {
    try {
      this._setDefaultTagMap[this.targetType](tag);
    } catch (error) {
      console.error(error);
    }
  }

  private _setDefaultTagMap = {
    profile: this._setProfileDefault.bind(this),
    package: this._setPackageDefault.bind(this),
    keyword: this._setKeywordDefault.bind(this),
  };

  private _setProfileDefault(tag: TagDto) {
    tag.applyToProfile = true;
    tag.showOnProfileList = true;
    tag.showOnProfileDetail = true;
  }

  private _setPackageDefault(tag: TagDto) {
    tag.applyToPackage = true;
    tag.showOnPackageList = true;
    tag.showOnPackageDetail = true;
  }

  private _setKeywordDefault(tag: TagDto) {
    tag.applyToKeyword = true;
  }
}
