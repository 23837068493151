import { Observable } from 'rxjs';
import { FLEX_PROP_OPTIONS } from './options';
import { flexTooltip, videoUrlTooltip } from './tooltips';

export interface FormControl {
  [key: string]: FormCard;
}

export interface FormCard {
  properties?: FormProperty[];
  hasAction?: boolean;
  hasOffset?: boolean;
  hasPadding?: boolean;
  hasStyle?: boolean;
  hasBackground?: boolean;
}

export interface FormProperty {
  name: string;
  value?: string;
  disabled?: boolean;
  options?: FormOption[];
  asyncOptions?: Observable<FormOption[]>;
  hint?: string;
  tooltip?: string;
  inputTooltip?: string;
  error?: string;
  isNumber?: boolean;
  autoComplete?: FormOption[];
  imagePicker?: boolean;
}

export interface FormOption {
  text: string;
  value: string | boolean;
  disabled?: boolean;
}

export const FORM_CONTROLS: FormControl = {
  carousel: {
    properties: [{ name: 'type', value: 'carousel', disabled: true }],
  },
  bubble: {
    properties: [
      { name: 'type', value: 'bubble', disabled: true },
      { name: 'direction', options: FLEX_PROP_OPTIONS.BubbleDirection },
      { name: 'size', options: FLEX_PROP_OPTIONS.BubbleSize },
    ],
    hasAction: true,
  },
  header: {
    properties: [{ name: 'type', value: 'header', disabled: true }],
    hasStyle: true,
  },
  hero: {
    properties: [{ name: 'type', value: 'hero', disabled: true }],
    hasStyle: true,
  },
  body: {
    properties: [{ name: 'type', value: 'body', disabled: true }],
    hasStyle: true,
  },
  footer: {
    properties: [{ name: 'type', value: 'footer', disabled: true }],
    hasStyle: true,
  },
  box: {
    properties: [
      { name: 'type', value: 'box', disabled: true },
      { name: 'layout', options: FLEX_PROP_OPTIONS.BoxLayout },
      { name: 'position', options: FLEX_PROP_OPTIONS.Position },
      {
        name: 'flex',
        isNumber: true,
        hint: $localize`彈性係數`,
        tooltip: flexTooltip,
      },
      {
        name: 'spacing',
        hint: 'ex: 5px',
        autoComplete: FLEX_PROP_OPTIONS.Spacing,
      },
      {
        name: 'margin',
        hint: 'ex: 5px',
        autoComplete: FLEX_PROP_OPTIONS.Margin,
      },
      { name: 'width', hint: 'ex: 100px or 100%' },
      { name: 'height', hint: 'ex: 100px or 100%' },
      { name: 'backgroundColor', hint: '#RRGGBB or #RRGGBBAA' },
      {
        name: 'borderWidth',
        hint: 'ex: 5px or bold',
        autoComplete: FLEX_PROP_OPTIONS.BorderWidth,
      },
      { name: 'borderColor', hint: '#RRGGBB or #RRGGBBAA' },
      {
        name: 'cornerRadius',
        hint: 'ex: 5px',
        autoComplete: FLEX_PROP_OPTIONS.CornerRadius,
      },
      { name: 'justifyContent', options: FLEX_PROP_OPTIONS.JustifyContent },
      { name: 'alignItems', options: FLEX_PROP_OPTIONS.AlignItems },
    ],
    hasOffset: true,
    hasPadding: true,
    hasAction: true,
    hasBackground: true,
  },
  image: {
    properties: [
      { name: 'type', value: 'image', disabled: true },
      {
        name: 'flex',
        isNumber: true,
        tooltip: flexTooltip,
      },
      { name: 'position', options: FLEX_PROP_OPTIONS.Position },
      { name: 'url', imagePicker: true },
      {
        name: 'animated',
        hint: $localize`僅支援小於300KB的APNG檔案`,
        options: FLEX_PROP_OPTIONS.Animated,
      },
      {
        name: 'margin',
        hint: 'ex: 5px',
        autoComplete: FLEX_PROP_OPTIONS.Margin,
      },
      { name: 'align', options: FLEX_PROP_OPTIONS.Align },
      { name: 'gravity', options: FLEX_PROP_OPTIONS.Gravity },
      {
        name: 'size',
        hint: 'ex: 100px or 100%',
        autoComplete: FLEX_PROP_OPTIONS.ImageSize,
      },
      { name: 'aspectRatio', hint: 'ex: 1:1' },
      { name: 'aspectMode', options: FLEX_PROP_OPTIONS.AspectMode },
      { name: 'backgroundColor', hint: '#RRGGBB or #RRGGBBAA' },
    ],
    hasOffset: true,
    hasAction: true,
  },
  video: {
    properties: [
      { name: 'type', value: 'video', disabled: true },
      {
        name: 'url',
        hint: $localize`LINE 僅支援「.mp4」結尾的網址`,
        inputTooltip: videoUrlTooltip,
      },
      { name: 'previewUrl', imagePicker: true },
      { name: 'aspectRatio', hint: 'ex: 20:13' },
    ],
    hasAction: true,
  },
  text: {
    properties: [
      { name: 'type', value: 'text', disabled: true },
      {
        name: 'flex',
        isNumber: true,
        tooltip: flexTooltip,
      },
      {
        name: 'margin',
        hint: 'ex: 5px',
        autoComplete: FLEX_PROP_OPTIONS.Margin,
      },
      { name: 'text' },
      {
        name: 'size',
        hint: 'ex: 16px',
        autoComplete: FLEX_PROP_OPTIONS.TextSize,
      },
      { name: 'adjustMode', options: FLEX_PROP_OPTIONS.AdjustMode },
      { name: 'color', hint: '#RRGGBB or #RRGGBBAA' },
      { name: 'weight', options: FLEX_PROP_OPTIONS.TextWeight },
      { name: 'style', options: FLEX_PROP_OPTIONS.TextStyle },
      { name: 'decoration', options: FLEX_PROP_OPTIONS.TextDecoration },
      { name: 'position', options: FLEX_PROP_OPTIONS.Position },
      { name: 'align', options: FLEX_PROP_OPTIONS.Align },
      { name: 'gravity', options: FLEX_PROP_OPTIONS.Gravity },
      { name: 'wrap', options: FLEX_PROP_OPTIONS.Wrap },
      { name: 'maxLines', isNumber: true },
    ],
    hasOffset: true,
    hasAction: true,
  },
  button: {
    properties: [
      { name: 'type', value: 'button', disabled: true },
      { name: 'flex', isNumber: true, tooltip: flexTooltip },
      { name: 'position', options: FLEX_PROP_OPTIONS.Position },
      {
        name: 'margin',
        hint: 'ex: 5px',
        autoComplete: FLEX_PROP_OPTIONS.Margin,
      },
      { name: 'height', options: FLEX_PROP_OPTIONS.ButtonHeight },
      { name: 'style', options: FLEX_PROP_OPTIONS.ButtonStyle },
      { name: 'color', hint: '#RRGGBB or #RRGGBBAA' },
      { name: 'gravity', options: FLEX_PROP_OPTIONS.Gravity },
      { name: 'adjustMode', options: FLEX_PROP_OPTIONS.AdjustMode },
    ],
    hasOffset: true,
    hasAction: true,
  },
  filler: {
    properties: [
      { name: 'type', value: 'filler', disabled: true },
      {
        name: 'flex',
        isNumber: true,
        tooltip: flexTooltip,
      },
    ],
  },
  spacer: {
    properties: [
      { name: 'type', value: 'spacer', disabled: true },
      { name: 'size', options: FLEX_PROP_OPTIONS.SpacerSize },
    ],
  },
  separator: {
    properties: [
      { name: 'type', value: 'separator', disabled: true },
      {
        name: 'margin',
        hint: 'ex: 5px',
        autoComplete: FLEX_PROP_OPTIONS.Margin,
      },
      { name: 'color', hint: '#RRGGBB or #RRGGBBAA' },
    ],
  },
  icon: {
    properties: [
      { name: 'type', value: 'icon', disabled: true },
      { name: 'position', options: FLEX_PROP_OPTIONS.Position },
      { name: 'url', imagePicker: true },
      {
        name: 'margin',
        hint: 'ex: 5px',
        autoComplete: FLEX_PROP_OPTIONS.Margin,
      },
      {
        name: 'size',
        hint: 'ex: 16px',
        autoComplete: FLEX_PROP_OPTIONS.IconSize,
      },
      { name: 'aspectRatio', hint: 'ex: 5px' },
    ],
    hasOffset: true,
  },
  span: {
    properties: [
      { name: 'type', value: 'span', disabled: true },
      { name: 'text' },
      {
        name: 'size',
        hint: 'ex: 16px',
        autoComplete: FLEX_PROP_OPTIONS.TextSize,
      },
      { name: 'color', hint: '#RRGGBB or #RRGGBBAA' },
      { name: 'weight', options: FLEX_PROP_OPTIONS.TextWeight },
      { name: 'style', options: FLEX_PROP_OPTIONS.TextStyle },
      { name: 'decoration', options: FLEX_PROP_OPTIONS.TextDecoration },
    ],
  },
};
