import { TemplateModel } from '@ay-gosu/server-shared';
import { Card as AyBotCard, Content } from '@ay/bot';
import { ButtonFactory } from './button.factory';
import { CustomizeCard } from './customize/card.class';

export class Card extends AyBotCard {
  public loading: boolean = false;
  public templateLost = false;
  public templateName: string;
  public template: Content.Template;
  public customize = new CustomizeCard();

  public constructor() {
    super('', '', '', []);
  }

  public async fromJson(content: AyBotCard | Content.Template): Promise<void> {
    switch (content.type) {
      case 'card':
        return this.loadGeneralCard(content);

      case 'template':
        return await this.loadTemplateCard(content);

      default:
        throw $localize`卡片類型異常 ${content}`;
    }
  }

  protected loadGeneralCard(card: AyBotCard) {
    this.cardId = card.cardId;
    this.recordId = card.recordId;
    this.packageId = card.packageId;
    this.image = card.image;
    this.title = card.title;
    this.content = card.content;
    this.buttons = card.buttons.map((button) =>
      ButtonFactory.fromButton(button),
    );
  }

  protected async loadTemplateCard(content: Content.Template) {
    this.template = new Content.Template(
      'card',
      content.templateId,
      content.properties,
    );

    if (!content.preview) {
      await this.loadCardPreview();
      content.preview = new Content.Cards([this]);
    } else {
      const preview = content.preview as Content.Cards;
      const card = preview.cards[0] as AyBotCard;
      this.cardId = card.cardId;
      this.image = card.image;
      this.title = card.title;
      this.content = card.content;
      this.buttons = card.buttons.map((button) =>
        ButtonFactory.fromButton(button),
      );
      preview.cards[0] = this;
    }
    this.customize = CustomizeCard.fromJson(content.customize as any);
  }

  protected async loadCardPreview(): Promise<void> {
    const result = (await TemplateModel.exec(
      this.template.templateId,
      this.template.properties,
    )) as any as Content.Card;

    if (result === null) {
      this.templateLost = true;
      return;
    }

    if (result.type !== 'card') {
      throw $localize`圖像範本的類型${result.type}與訊息的類型${this.type}不一致`;
    }

    const card = result.cards[0] as Card;
    this.cardId = card.cardId;
    this.image = card.image;
    this.title = card.title;
    this.content = card.content;

    this.buttons = card.buttons.map((button) =>
      ButtonFactory.fromButton(button),
    );
  }

  public toJSON() {
    if (this.template) {
      return {
        ...this.template,
        customize: this.customize,
      };
    } else {
      const card = new AyBotCard(
        this.image,
        this.title,
        this.content,
        this.buttons,
      );
      card.cardId = this.cardId;
      card.recordId = this.recordId;
      card.packageId = this.packageId;
      return card;
    }
  }
}
