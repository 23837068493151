import { Pipe, PipeTransform } from '@angular/core';
const FieldTypeMap = {
  string: $localize`字串`,
  number: $localize`數字`,
  text: $localize`文案`,
  date: $localize`日期`,
  json: 'JSON',
  file: $localize`檔案`,
  tel: $localize`號碼`,
  boolean: $localize`開關`,
  dropdown: $localize`下拉選單`,
  radio: $localize`單選選項`,
  checkbox: $localize`多選選項`,
};
@Pipe({
    name: 'fieldType',
    standalone: true
})
export class FieldTypePipe implements PipeTransform {
  public transform(value: string): string {
    let str = FieldTypeMap[value];
    return str ? str : $localize`未知`;
  }
}
