import { NgIf } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { Flex } from '@ay/bot';
import { ImageTypePipe } from '../../../../../pipe/imageType.pipe';

@Component({
  selector: 'flex-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {},
  standalone: true,
  imports: [NgIf, ImageTypePipe],
})
export class FlexVideoComponent {
  @Input()
  public data: Flex.Block.Video;

  public get imageHeight() {
    if (!this.data.aspectRatio) return 100;
    let [width, height] = this.data.aspectRatio
      .split(':')
      .map((val) => parseInt(val));
    return (height / width) * 100;
  }

  @HostBinding('class')
  public get hostClass(): string {
    const list = ['MdImg'];
    return list.filter(Boolean).join(' ');
  }
}
