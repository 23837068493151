<li [class.active]="data.isSelect" [class.error]="hasError" *ngIf="data">
  <div
    class="content"
    (click)="
      $event.stopPropagation(); flexEditorService.selected$.next(this.data)
    "
  >
    <button
      mat-icon-button
      (click)="$event.stopPropagation(); data.isExpanded = !data.isExpanded"
      [ngSwitch]="data.type"
      [class.disabled]="!data.contents"
    >
      <mat-icon *ngSwitchCase="'image'">image</mat-icon>
      <mat-icon *ngSwitchCase="'video'">play_circle</mat-icon>
      <mat-icon *ngSwitchCase="'icon'">star</mat-icon>
      <mat-icon *ngSwitchCase="'filler'">fullscreen</mat-icon>
      <mat-icon *ngSwitchCase="'spacer'">space_bar</mat-icon>
      <mat-icon *ngSwitchCase="'separator'">horizontal_rule</mat-icon>
      <mat-icon *ngSwitchCase="'button'">smart_button</mat-icon>
      <mat-icon *ngSwitchCase="'span'">text_format</mat-icon>
      <mat-icon *ngSwitchCase="'text'">
        {{
          data.contents?.length
            ? data.isExpanded
              ? 'expand_more'
              : 'chevron_right'
            : 'text_format'
        }}
      </mat-icon>
      <mat-icon *ngSwitchDefault>
        {{ data.isExpanded ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
    </button>
    <span>{{ data.type | cht: true }}</span>

    <button
      class="add"
      mat-icon-button
      [matMenuTriggerFor]="menu"
      *ngIf="flexEditorService.findChildTypes(data) && data.type !== 'bubble'"
    >
      <mat-icon>add</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      @for (
        option of flexEditorService.childTypeOptions(data);
        track option.type
      ) {
        <button
          mat-menu-item
          (click)="addNew(option.type)"
          [disabled]="option.disabled"
        >
          {{ option.type | cht: true }}
        </button>
      }
    </mat-menu>

    <div class="fill"></div>
    <p *ngIf="hasError">
      <mat-icon>error</mat-icon>
      {{ hasError | cht }}
    </p>
  </div>

  <ul
    [hidden]="!data.isExpanded"
    cdkDropList
    #dropList="cdkDropList"
    [cdkDropListData]="data.contents || []"
    [cdkDropListConnectedTo]="flexEditorService.cdkDropLists"
    (cdkDropListDropped)="drop($event)"
    [cdkDropListEnterPredicate]="typePredicate()"
    (mousemove)="
      $event.stopPropagation(); flexEditorService.cdkDropTarget = dropList
    "
  >
    <ng-container *ngIf="data.isExpanded">
      <tree-content
        *ngFor="let content of data.contents"
        [data]="content"
        cdkDrag
        [cdkDragData]="content"
        [cdkDragDisabled]="data.type === 'bubble'"
        (cdkDragStarted)="flexEditorService.cdkDragData = $event.source"
        (cdkDragEnded)="flexEditorService.cdkDragData = null"
      >
      </tree-content>
    </ng-container>
  </ul>
</li>
