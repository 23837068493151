<div fxLayout="row" fxLayoutGap="30px">
  <mat-checkbox
    class="form-field"
    [(ngModel)]="node.isAsync"
    color="primary"
    i18n
  >
    {{ node.isAsync ? notText : '' }}同步模式
  </mat-checkbox>

  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label i18n>回傳變數</mat-label>
    <input matInput [(ngModel)]="node.responseVar" />
  </mat-form-field>
</div>
