import { Injectable } from '@angular/core';
import {
  FacebookPostColumnDto,
  FacebookPostModel,
} from '@ay-gosu/server-shared';
import { Map } from '@ay/util';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FacebookPostService {
  private _posts: FacebookPostColumnDto[] = [];
  public posts$ = new BehaviorSubject<FacebookPostColumnDto[]>(this._posts);

  public postNameMap$ = this.posts$.pipe(
    map((posts) => {
      let res: Map<string> = {};
      for (let post of posts) {
        res[post.postId] = `${post.story || post.name || ''} ${
          post.message || post.postId
        }`;
      }
      return res;
    }),
    shareReplay(1),
  );

  public async loadPostByIds(postIds: string[]) {
    if (!(postIds instanceof Array)) return [];
    let nameMap = await this.getPostNameMap();
    let ids = postIds.filter((postId) => !nameMap[postId]);
    if (ids.length) {
      let posts = await FacebookPostModel.getPostsByIds(ids);
      await this._addPostCache(posts);
    }
    return this._posts.filter((post) => postIds.includes(post.postId));
  }

  public async loadPostList(
    botIds: number[] = [],
    keyword: string = '',
    isPublished: boolean = true,
    page: number = 0,
  ) {
    let posts = await FacebookPostModel.getPosts(
      botIds,
      keyword,
      isPublished,
      page + 1,
      10,
    );
    this._updatePostCache(posts.data);
    return posts;
  }

  public async getPostNameMap() {
    return firstValueFrom(this.postNameMap$);
  }

  private _updatePostCache(posts: FacebookPostColumnDto[]) {
    this._posts = this._posts.map((post) => {
      let newPost = posts.find((p) => p.postId === post.postId);
      return newPost || post;
    });
    this.posts$.next(this._posts);
  }

  private async _addPostCache(posts: FacebookPostColumnDto[]) {
    let nameMap = await this.getPostNameMap();
    for (let post of posts) {
      if (!nameMap[post.postId]) this._posts.push(post);
    }
    this.posts$.next(this._posts);
  }

  public async syncFacebookPost(botIds: number[]) {
    await FacebookPostModel.syncPostsByBotIds(botIds);
  }
}
