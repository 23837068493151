<div
  class="T1"
  [ngClass]="{
    fxLTR: data.direction === 'ltr',
    fxRTL: data.direction === 'rtl'
  }"
>
  <ng-template ngFor let-content [ngForOf]="data.contents">
    <div
      *ngIf="content.contents?.length"
      [style.background-color]="
        data.styles ? data.styles[content.type]?.backgroundColor : null
      "
      [class.ExHasFooter]="
        content.type === 'body' && data.footer?.contents.length
      "
      [ngClass]="{
        t1Header: content.type === 'header',
        t1Hero: content.type === 'hero',
        t1Body: content.type === 'body',
        t1Footer: content.type === 'footer'
      }"
      [class.active]="content.isSelect"
      (click)="
        $event.stopPropagation(); flexEditorService.selected$.next(content)
      "
    >
      <ng-template ngFor let-item [ngForOf]="content.contents">
        <ng-template
          [ngIf]="content.type === 'body' && (item | isString)"
          [ngIfElse]="isNotProgrammable"
        >
          <div class="is-programmable-block" i18n>程式碼區塊</div>
        </ng-template>

        <ng-template #isNotProgrammable>
          <ng-container [ngSwitch]="item.type">
            <flex-box
              *ngSwitchCase="'box'"
              [data]="item"
              [bubble]="bubble"
              [flex]="$any(flex)"
              [style.padding.px]="0"
              [class.active]="item.isSelect"
              (click)="
                $event.stopPropagation(); flexEditorService.selected$.next(item)
              "
            >
            </flex-box>

            <flex-image
              *ngSwitchCase="'image'"
              [data]="$any(item)"
              [bubble]="bubble"
              [flex]="$any(flex)"
              [style.padding.px]="0"
              [class.active]="item.isSelect"
              (click)="
                $event.stopPropagation(); flexEditorService.selected$.next(item)
              "
            >
            </flex-image>

            <flex-video
              *ngSwitchCase="'video'"
              [data]="$any(item)"
              [style.padding.px]="0"
              [class.active]="item.isSelect"
              (click)="
                $event.stopPropagation(); flexEditorService.selected$.next(item)
              "
            >
            </flex-video>
          </ng-container>
        </ng-template>
      </ng-template>
    </div>

    <ng-container *ngIf="data.styles">
      <div
        class="MdSep"
        *ngIf="data.styles[content.type]?.separator"
        [style.border-color]="data.styles[content.type]?.separatorColor"
      ></div>
    </ng-container>
  </ng-template>
</div>
