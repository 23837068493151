import { Node } from '../class';

export class MemberNode extends Node {
  public icon = 'people';
  public targetId: string = '';
  public includeInfo = false;
  public includeTag = false;
  public includeProperty = false;
  public responseVar = 'members';
  public readonly extraProperties = [
    'targetId',
    'includeInfo',
    'includeTag',
    'includeProperty',
    'responseVar',
  ];
}
