import { RandomNode } from '../node/random';
import { SwitchNode } from '../node/switch';
import { Command } from './command';

export class AddConditionCommand extends Command {
  public constructor(
    private readonly _node: SwitchNode | RandomNode,
    private readonly _condition: any,
  ) {
    super();
  }

  public async do() {
    this._node.conditions.push(this._condition);
  }

  public async undo() {
    let findIdx = this._node.conditions.indexOf(this._condition);
    if (findIdx !== -1) {
      this._node.conditions.splice(findIdx, 1);
    }
  }
}
