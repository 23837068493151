<div class="header" i18n>
  <mat-icon>{{ 'coupon' | messageIcon }}</mat-icon>
  挑選優惠券
</div>
<div fxLayout="row">
  <div class="table-container">
    <table mat-table [dataSource]="list$ | async" class="fw">
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="vam" i18n>狀態</th>
        <td mat-cell *matCellDef="let coupon" class="vam">
          {{ coupon.status }}
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef class="vam" i18n>優惠券標題</th>
        <td mat-cell *matCellDef="let coupon" class="vam">
          {{ coupon.title }}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="vam" i18n>類別</th>
        <td mat-cell *matCellDef="let coupon" class="vam">
          {{ coupon.type | couponType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef class="vam" i18n>活動時間</th>
        <td mat-cell *matCellDef="let coupon" class="vam">
          {{ coupon.startAt | moment: 'YYYY-MM-DD HH:mm' }} ~
          {{ coupon.endAt | moment: 'YYYY-MM-DD HH:mm' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns" class="curp"
        [class.active]="selectedCoupon && selectedCoupon.id === row.id" (click)="selectedCoupon = row"></tr>
    </table>
    <mat-paginator [pageSize]="pageSize$ | async" [length]="total$ | async" (page)="page$.next($event.pageIndex)"
      showFirstLastButtons>
    </mat-paginator>
  </div>
  <cp-coupon-preview fxFlex="auto" [coupon]="selectedCoupon">
  </cp-coupon-preview>
</div>

<div class="footer">
  <button mat-raised-button color="primary" (click)="submit()" i18n>
    <mat-icon>done</mat-icon>
    確定
  </button>
</div>