// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { Content } from '@ay/bot';

export class PackageSaveForInteractiveNotification {
  public id?: number;
  public interactiveModuleId: number;
  public type:
    | 'NEARING_COMPLETION' // 將達標通知
    | 'AWARD' // 給獎通知(直接回饋)
    | 'COMPLETION' // 達標通知(轉盤、拉霸)
    | 'PRIZE'; // 遊戲獎品通知(轉盤、拉霸)

  // 當 type 為 PRIZE 時必填，
  // 依照 InteractiveModule 的 type 分別代表
  //   - InteractiveModuleSlotsPrize.id
  //   - InteractiveModulePrizeWheelPrize.id
  public prizeId?: number;

  public records: Content.Any[];
}
// d84fb778db79f453fd4aa140b1b24259c5ffdf939804da2098b81c2521a43d73
