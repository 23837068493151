export class GosuValidate {
  public static required(value: any): boolean {
    return (
      value === undefined ||
      value === null ||
      value === '' ||
      Number.isNaN(value)
    );
  }

  public static arrayRequired(value: any): boolean {
    if (Array.isArray(value)) {
      if (value.length === 0 || value.every((v) => this.required(v))) {
        return true;
      }
    } else if (this.required(value)) {
      return true;
    }
    return false;
  }

  public static bannedOptions(value: any, bannedOptions: string[]): boolean {
    return bannedOptions.includes(value);
  }
}
