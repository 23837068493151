import { Component, ElementRef, Input } from '@angular/core';
import { TagDto } from '@ay-gosu/server-shared';
import { LengthPipe } from '../../pipe/length.pipe';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '../../material/tooltip/tooltip';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'gosu-tag-short-button',
    templateUrl: './tag-short-button.component.html',
    styleUrls: ['./tag-short-button.component.scss'],
    standalone: true,
    imports: [
        MatButton,
        MatTooltip,
        MatIcon,
        NgIf,
        LengthPipe,
    ],
})
export class TagShortButtonComponent {
  @Input()
  public tags: TagDto[];

  public constructor(public elementRef: ElementRef) {}

  public get tooltip() {
    return this.tags
      .filter((tag) => tag)
      .map((tag) => tag.name)
      .join('、');
  }
}
