import { BotDto } from '@ay-gosu/server-shared';
import { RuleType } from '@ay-gosu/types';
import {
  ensureIsNumbers,
  ensurePropertyIsArray,
  ensureTheElementIsWhitelisted,
} from '@ay/util';
import { delay } from 'bluebird';
import { firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { FromJsonOption, Rule } from '../rule';
import { ruleJoin } from '../ruleJoin';

export class BotRule extends Rule<number[]> {
  public type = 'BOT' as RuleType;
  public bots: BotDto[] = [];

  public constructor(...bots: BotDto[]) {
    super('BOT');
    this.bots = bots;
    this.op = 'IN_LIST';
    this.value = this.bots.map((bot) => bot.id);
  }

  public async afterTypeChanged(isUserEvent: boolean = false) {
    ensurePropertyIsArray(this, 'value');
    this.op = ensureTheElementIsWhitelisted(this.op, 'IN_LIST');
    if (isUserEvent) {
      await delay(1);
      this.value = this.value.filter((element) => typeof element === 'number');
      this.component.bot.botTreePickerTrigger.open();
    }
  }

  public async fromJSON(json: any, option: FromJsonOption) {
    super.fromJSON(json, option);
    if (!(json.value instanceof Array)) {
      json.value = json.value.split(',');
    }

    let bots = await firstValueFrom(
      option.botService.all$.pipe(first((bot) => bot !== null)),
    );

    this.value = json.value
      .map((value) => parseInt(value, 10))
      .filter((id) => !isNaN(id));

    this.bots = this.value.map(
      (id) =>
        bots.find((bot) => bot.id === id) ||
        ({ name: $localize`未知的機器人` } as BotDto),
    );
  }

  public toRuleObject(json: any = {}) {
    super.toRuleObject(json);
    if (this.value instanceof Array) {
      json.value = this.value.join(',');
    }
    return json;
  }

  public similar(keyword: string): boolean {
    return this.bots.reduce(
      (prev, bot) => prev || bot.name.indexOf(keyword) !== -1,
      false,
    );
  }

  public toString() {
    return $localize`機器人是${ruleJoin(this.bots.map((bot) => bot.name))}`;
  }

  /** for short code */
  public static schema: string = 'bot';

  public toShortCode(): string {
    return this.bots.map((bot) => bot.id).join(',');
  }

  public static async fromShortCode(
    code: string,
    option: FromJsonOption,
  ): Promise<BotRule> {
    let botIds = ensureIsNumbers(code);
    let allBots = await firstValueFrom(
      option.botService.all$.pipe(first((bot) => bot !== null)),
    );

    let bots = allBots.filter((bot) => botIds.find((id) => bot.id === id));
    let rule = new BotRule(...bots);
    return rule;
  }

  public checkError(): boolean {
    if (this.value && this.value.length === 0) {
      return true;
    }
    return false;
  }
}
