<div
  class="empty-card"
  *ngIf="mode === 'READ' && message.content.cards.length === 0"
  i18n
>
  這則卡片訊息中沒有任何卡片
</div>

<div
  class="cards"
  [ngClass]="{ edit: mode === 'EDIT' }"
  cdkDropList
  cdkDropListOrientation="horizontal"
  cdkDropListLockAxis="x"
  [cdkDropListDisabled]="mode === 'READ'"
  (cdkDropListDropped)="drop($event)"
  [matTooltip]="message.content.alt"
>
  <div
    *ngFor="let card of message.content.cards; let index = index"
    cdkDrag
    class="card"
    [class.error]="hasError"
  >
    <ms-card
      [card]="$any(card)"
      [message]="message"
      [matContextMenu]="contextMenu"
      [matContextMenuDisable]="mode === 'READ'"
      (contextmenu)="selectedCard = $any(card)"
    >
    </ms-card>
  </div>
</div>

<mat-menu #contextMenu class="tiny-menu">
  <button mat-menu-item (click)="editCard()" i18n>
    <mat-icon>settings</mat-icon>編輯卡片
  </button>
  <button
    mat-menu-item
    (mouseenter)="insertFrontCard()"
    [matMenuTriggerFor]="cards"
    i18n
  >
    <mat-icon>add</mat-icon>新增前方卡片
  </button>
  <button
    mat-menu-item
    (mouseenter)="insertBehindCard()"
    [matMenuTriggerFor]="cards"
    i18n
  >
    <mat-icon>add</mat-icon>新增後方卡片
  </button>
  <button mat-menu-item (click)="deleteCard()" i18n>
    <mat-icon>delete</mat-icon>刪除這張卡片
  </button>
  <div>
    <button mat-button (click)="swapCardLeft()" [disabled]="isFirstCard" i18n>
      <mat-icon>arrow_back</mat-icon>左移
    </button>
    <button mat-button (click)="swapCardRight()" [disabled]="isLastCard" i18n>
      右移<mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>

  <button
    mat-menu-item
    (click)="editAlt(elementRef)"
    element-ref
    #elementRef="elementRef"
    i18n
  >
    <mat-icon>title</mat-icon>編輯替代文字
  </button>

  <button mat-menu-item (click)="deleteCards()" i18n>
    <mat-icon>delete</mat-icon>刪除整組卡片
  </button>

  <button
    mat-menu-item
    (click)="swapMessageUpward()"
    [disabled]="isFirstMessage"
    i18n
  >
    <mat-icon>arrow_upward</mat-icon>上移
  </button>
  <button
    mat-menu-item
    (click)="swapMessageDownward()"
    [disabled]="isLastMessage"
    i18n
  >
    <mat-icon>arrow_downward</mat-icon>下移
  </button>
</mat-menu>

<mat-menu #cards="matMenu" class="tiny-menu">
  <button mat-menu-item (click)="openCreator()" i18n>建立新卡片</button>
  <button mat-menu-item (click)="openTemplatePicker()" i18n>
    從圖像編輯器中挑選
  </button>
</mat-menu>
