import { Component, HostListener, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RegularizedFlex } from '../../regularized-flex.class';
import { FlexEditorService } from '../editor.service';
import { CdkDropListGroup } from '@angular/cdk/drag-drop';
import { FlexTreeContentComponent } from './content/content.component';

@Component({
    selector: 'tree-pick',
    templateUrl: './tree-pick.component.html',
    styleUrls: ['./tree-pick.component.scss'],
    standalone: true,
    imports: [FlexTreeContentComponent, CdkDropListGroup],
})
export class FlexTreePickComponent implements OnDestroy {
  private readonly _destroy$ = new Subject();

  public constructor(public readonly flexEditorService: FlexEditorService) {}

  protected selectBlockOnSelectedChanged = this.flexEditorService.selected$
    .pipe(takeUntil(this._destroy$))
    .subscribe((selected) => {
      if (!selected) return;
      this.selectBlock(selected);
    });

  public ngOnDestroy() {
    this._destroy$.complete();
  }

  protected selectBlock(selected: RegularizedFlex, data?: RegularizedFlex) {
    if (!this.flexEditorService.regularizedFlex) {
      return;
    }

    if (!data) {
      data = this.flexEditorService.regularizedFlex.content.contents;
    }

    if (selected === data) {
      return data;
    }

    if (!data.contents) {
      return;
    }

    let hasSelect = data.contents.find((content) =>
      this.selectBlock(selected, content),
    );

    if (hasSelect) {
      data.isExpanded = true;
    }

    return hasSelect;
  }

  @HostListener('click')
  public onClick() {
    this.flexEditorService.selected$.next(null);
  }
}
