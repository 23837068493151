<div class="header" i18n>請選擇要插入的元件</div>
<div class="mat-dialog-content">
  <div class="row">
    <mat-card
      class="col"
      *ngFor="let element of elements$ | async"
      (click)="selected = element.data"
      [class.active]="selected === element.data"
    >
      <img mat-card-image [src]="element.imgUrl" [alt]="element.title" />
      <mat-card-content class="title">{{ element.title }}</mat-card-content>
    </mat-card>
  </div>
</div>

<br />

<mat-divider></mat-divider>

<div class="mat-dialog-actions" align="center">
  <button mat-raised-button color="primary" [disabled]="!selected" i18n>
    確定加入
  </button>
</div>
