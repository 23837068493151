<div class="row">
  <mat-form-field [style.width.%]="100" legacyAppearance>
    <mat-label i18n>疊加器名稱</mat-label>
    <input matInput [(ngModel)]="node.stackerName" />
  </mat-form-field>
</div>

<div class="row">
  <mat-form-field [style.width.%]="100" legacyAppearance>
    <mat-label i18n>卡片疊加數量</mat-label>
    <mat-select [(ngModel)]="node.cardMergeLimit">
      <mat-option *ngFor="let item of cardMergeLimits" [value]="item">
        {{ item }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
