<mat-menu #contextMenu class="tiny-menu">
  <button mat-menu-item (click)="editCoupon()" i18n>
    <mat-icon>settings</mat-icon>編輯優惠券統計細節
  </button>

  <mat-divider> </mat-divider>

  <button mat-menu-item (click)="delete()" i18n>
    <mat-icon>delete</mat-icon> 刪除
  </button>

  <button mat-menu-item (click)="swapUpward()" [disabled]="isFirstMessage" i18n>
    <mat-icon>arrow_upward</mat-icon> 上移
  </button>

  <button
    mat-menu-item
    (click)="swapDownward()"
    [disabled]="isLastMessage"
    i18n
  >
    <mat-icon>arrow_downward</mat-icon> 下移
  </button>
</mat-menu>

<div
  class="coupon"
  [matContextMenu]="contextMenu"
  [matContextMenuDisable]="mode === 'READ'"
  *ngIf="message?.coupon?.no; else noCouponNo"
>
  <div class="T1 fxLTR" dir="ltr">
    <div class="t1Hero fxC0">
      <div class="ExCover MdImg ExFull">
        <div>
          <a style="padding-bottom: 66.225%">
            <span [style.backgroundImage]="backgroundImage$ | async"></span>
          </a>
        </div>
      </div>
    </div>
    <div class="ExHasFooter t1Body fxC0">
      <div class="MdBx vr">
        <div class="MdBx hr">
          <div class="fxC1 MdTxt fl0 ExXs">
            <p class="color-aaaaaa">
              {{ message.coupon.type | couponType }}
            </p>
          </div>
        </div>
        <div class="fxC2 fl0 MdSep ExTMd"></div>
        <div class="MdTxt fxC3 grvC ExTLg fl1 ExWrap ExWB ExLg">
          <p class="color-424242">{{ message?.coupon?.title }}</p>
        </div>
        <div class="MdTxt fxC4 grvC ExTLg fl1 ExWrap ExWB ExXs">
          <p class="color-666666" i18n>活動時間</p>
        </div>
        <div class="MdTxt fxC5 grvC fl1 ExSm ExWrap ExTSm">
          <p
            class="color-999999"
            *ngIf="message.coupon.startAt && message.coupon.endAt"
          >
            {{ message.coupon.startAt | moment: 'YYYY-MM-DD HH:mm' }} ~
            {{ message.coupon.endAt | moment: 'YYYY-MM-DD HH:mm' }}
          </p>
        </div>
        <div class="MdTxt fxC4 grvC ExTLg fl1 ExWrap ExWB ExXs">
          <p class="color-666666" i18n>使用說明</p>
        </div>
        <div class="MdTxt fxC5 grvC fl1 ExSm ExWrap ExTSm">
          <p
            class="color-999999"
            [innerHtml]="message.coupon.description | nl2br"
          ></p>
        </div>
        <div class="MdTxt fxC4 grvC ExTLg fl1 ExWrap ExWB ExXs">
          <p class="color-666666" i18n>＊此優惠券僅能使用一次</p>
        </div>
        <div class="ExTNone MdTxt fxC4 grvC fl1 ExWrap ExWB ExXs">
          <p class="color-666666" i18n>＊主辦方保有活動修改、終止之權利</p>
        </div>
        <div
          class="ExTNone MdTxt fxC4 grvC fl1 ExWrap ExWB ExXs"
          *ngIf="message.coupon.shareable && message.coupon.shareText"
        >
          <p class="color-666666" i18n>
            ＊分享優惠券不影響您使用此優惠券的權益
          </p>
        </div>
      </div>
    </div>
    <div class="t1Footer fxC0">
      <div class="MdBx hr">
        <div
          class="grvC ExBtnL fl1 MdBtn"
          *ngIf="message.coupon.shareable && message.coupon.shareText"
        >
          <a><div class="fxC6 color-4154AF" i18n>分享</div></a>
        </div>
        <div class="ExBtn1 ExLNone grvC fl1 MdBtn">
          <a class="fxC7 background-color-4154AF"><div i18n>使用</div></a>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noCouponNo>
  <div class="coupon">
    <div class="T1 fxLTR" dir="ltr" *ngIf="message.coupon; else noCoupon">
      <div class="t1Hero fxC0">
        <div class="ExCover MdImg ExFull">
          <div>
            <a style="padding-bottom: 66.225%">
              <span [style.backgroundImage]="backgroundImage$ | async"></span>
            </a>
          </div>
        </div>
      </div>
      <div class="t1Body fxC0">
        <div class="MdBx vr">
          <div class="fxC1 MdTxt grvC fl1 ExWrap ExWB ExLg">
            <p class="color-424242">{{ message?.coupon?.title }}</p>
          </div>
          <div class="fxC2 MdTxt grvC ExTLg fl1 ExWrap ExWB ExXs">
            <p class="color-666666" i18n>活動時間</p>
          </div>
          <div class="MdTxt fxC3 grvC fl1 ExSm ExWrap ExTSm">
            <p
              class="color-999999"
              *ngIf="message.coupon.startAt && message.coupon.endAt"
            >
              {{ message.coupon.startAt | moment: 'YYYY-MM-DD HH:mm' }} ~
              {{ message.coupon.endAt | moment: 'YYYY-MM-DD HH:mm' }}
            </p>
          </div>
          <div class="fxC4 fl0 MdSep ExTMd"></div>
          <div class="MdTxt ExAlgC fxC5 grvC ExTXXl color-4154AF">
            <p i18n>優惠券已全數發送完畢</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noCoupon>
  <div class="no-coupon" i18n>
    <icon icon="error"></icon>
    遺失的優惠券訊息
  </div>
</ng-template>
