import { Node } from '../class';

export type TimerType = 'DelaySeconds' | 'RelativeTime' | 'AbsoluteTime';

export class TimerNode extends Node {
  public icon = 'timer';
  public timerType: TimerType = 'DelaySeconds';
  public time: string = '1000';
  public absoluteTime: string = '';
  public relativeTime: string = '';
  public processed: boolean = true;
  public readonly extraProperties = [
    'timerType',
    'time',
    'absoluteTime',
    'relativeTime',
    'processed',
  ];
}
