<ng-container *ngIf="card && card.loading !== true; else loading">
  <ng-container *ngIf="!card.templateLost; else templateLost">
    <div class="card mat-elevation-z2">
      <div
        class="header"
        *ngIf="customize('image') as image"
        [style.background-image]="'url(' + (image | imageType) + ')'"
      ></div>

      <div class="container">
        <div class="title">{{ customize('title') | length: 20 }}</div>
        <div
          class="content"
          [innerHTML]="customize('content') | nl2br | length: 60"
        ></div>
      </div>

      <div class="buttons">
        <ng-container *ngFor="let index of buttonIndexes">
          <div
            class="button"
            [class.active]="button.isSelected"
            *ngIf="customizeButton(index); let button"
            [matTooltip]="tooltip"
          >
            {{ button.label }}
            <ng-template #tooltip>
              <ng-container [ngSwitch]="button.type">
                <ng-template ngSwitchCase="postback">
                  {{ button.type | buttonType }}
                </ng-template>
                <ng-template ngSwitchCase="message">
                  {{ button.type | buttonType }} : {{ button['text'] }}
                </ng-template>
                <ng-template ngSwitchCase="uri">
                  {{ button.type | buttonType }} : {{ button['uri'] }}
                </ng-template>
                <ng-template ngSwitchCase="next">
                  {{ button.type | buttonType }}
                </ng-template>
                <ng-template *ngSwitchDefault></ng-template>
              </ng-container>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading>
  <gosu-loading i18n>卡片繪製中請稍候</gosu-loading>
</ng-template>

<ng-template #templateLost>
  <div class="card mat-elevation-z2 template-lost" i18n>
    <mat-icon class="mat-18">search_off</mat-icon><br />
    圖像編輯器中的卡片已被刪除<br />無法預覽
  </div>
</ng-template>
