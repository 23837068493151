<div class="tags" *ngIf="tags$ | async as tags">
  <div
    class="tag"
    *ngFor="let tag of tags"
    [style.background-color]="tag.color || 'rgba(255, 255, 255, 0.3)'"
    (contextmenu)="selected = tag"
    [matContextMenu]="tagContextMenu"
  >
    {{ tag.name }}
    <mat-icon
      class="close"
      (click)="delete(tag)"
      matTooltip="刪除"
      i18n-matTooltip="刪除"
      >close</mat-icon
    >
  </div>

  <button
    mat-button
    #addTagButton
    type="button"
    class="tag add"
    (click)="addTag(addTagButton)"
  >
    <mat-icon>add</mat-icon>
    {{ title }}
  </button>
</div>

<mat-menu #tagContextMenu="matMenu" class="tiny-menu">
  <button mat-menu-item (click)="delete(selected)" i18n>刪除</button>
</mat-menu>
