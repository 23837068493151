import { AfterContentInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { GosuIconComponent } from '../../components/gosu-icon/icon.component';
import { LogoComponent } from '../../components/gosu-logo/logo.component';
import { TokenService } from '../../service/token.service';

@Component({
  templateUrl: './landing.page.html',
  styleUrls: ['./landing.page.scss'],
  standalone: true,
  imports: [GosuIconComponent, LogoComponent],
})
export class LandingPageComponent implements AfterContentInit {
  public constructor(
    private readonly _tokenService: TokenService,
    private readonly _router: Router,
  ) {}

  public async ngAfterContentInit() {
    const isLoggedIn = await this._tokenService.isLoggedIn();
    if (!isLoggedIn) {
      this._router.navigate(['login']);
    }
  }
}
