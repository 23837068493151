import { Injectable } from '@angular/core';
import { MatConnectedDialogConfig } from '@ay-gosu/ui/common/connected-dialog';
import { ExposedPromise } from '@ay/util';
import extend from 'lodash/extend';
import { DialogService } from '../../dialog/dialog.service';
import {
  ColorPickerDialog,
  ColorResponse,
} from './color-picker-dialog/color-picker-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ColorPickerService extends DialogService {
  public async pick(
    color?: string,
    config?: MatConnectedDialogConfig,
  ): Promise<ColorResponse> {
    const exposedPromise = new ExposedPromise();

    const dialogRef = this._open(
      ColorPickerDialog,
      extend({}, config),
      exposedPromise,
    );

    let instance = dialogRef.componentInstance;
    if (color) {
      instance.oriColor = color;
    }

    return exposedPromise.promise;
  }
}
