import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DMU } from '@ay/util';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { BasicDialog } from '../../../dialog/basic/basic-dialog.service';
import { ComponentsCommand } from '../command/components';
import { Invoker } from '../command/invoker';
import { MoveNodeCommand } from '../command/moveNode';
import { SelectCommand } from '../command/selectNode';
import { FlowService } from '../flow.service';
import { JunctionComponent } from '../junction/junction.component';
import { Node } from './class';
import { ConditionComponent } from './condition/condition.component';

export const host = {
  '[attr.transform]': 'transform',
  '[attr.stroke-width]': 'flowService.selected.indexOf(node) !== -1 ? 3 : 1',
};

@Component({
    template: 'abstract component, please implement first',
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['node'],
    standalone: true,
})
export class NodeComponent<T extends Node = Node>
  implements OnDestroy, AfterContentInit, AfterViewInit
{
  /** 訂閱 */
  private _openForm$: Subscription;
  private _em$: Subscription;

  public afterViewInit$ = new Subject();

  /** 記錄1 em = ? px (SVG只吃px) */
  public em = 16;

  /** 所對應的 node */
  @Input()
  public node: T;

  /** 對應的位置 */
  public transform: string;

  /** 所有的連結點，提供給其他節點移動時更新路徑使用 */
  @ViewChildren(JunctionComponent)
  public originJunctions: QueryList<JunctionComponent> = null;

  @ViewChildren(ConditionComponent)
  public conditionComponents: QueryList<ConditionComponent> = null;

  public get junctions(): JunctionComponent[] {
    const junctions = [
      ...(this.originJunctions ? this.originJunctions.toArray() : []),
      ...(this.conditionComponents
        ? this.conditionComponents.toArray().map((c) => c.junction)
        : []),
    ]
      .sort((a, b) => a.index - b.index)
      .flat();

    return junctions;
  }

  @ViewChild('start', { static: false })
  public start: JunctionComponent = null;

  @ViewChild('end', { static: false })
  public end: JunctionComponent = null;

  /** 拖曳節點時使用，拖曳的元素 */
  private element: SVGGElement = null;

  /** 節點的寬度與高度 */
  public width: number = 0;
  public height: number = 0;

  private readonly _destroy$ = new Subject<void>();

  private commands = [];

  public constructor(
    public readonly elementRef: ElementRef,
    public readonly flowService: FlowService,
    private readonly _ngZone: NgZone,
    private readonly _invoker: Invoker,
    private readonly _basicDialog: BasicDialog,
  ) {}

  public ngAfterContentInit() {
    this.node.nodeComponent = this;
    this._em$ = this.flowService.em
      .pipe(takeUntil(this._destroy$))
      .subscribe((em) => (this.em = em));
  }

  /** 元件初始完畢 */
  public ngAfterViewInit() {
    this.element = this.elementRef.nativeElement;

    // 開啟編輯表單
    this._openForm$ = fromEvent(this.element, 'dblclick')
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (event: MouseEvent) => {
        if (event.shiftKey) return;
        if (this.node.error) {
          let response = await this._basicDialog.confirm(
            $localize`這個節點有異常資料，你要重設資料嘛？`,
            { ok: $localize`重設資料` },
          );
          if (!response) return;
          await this.node.reset();
        }
        this.flowService.oldNode = this.node.toJSON();
        this.flowService.openedForm = this.node;
      });

    this.afterViewInit$.next(true);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    try {
      this._openForm$.unsubscribe();
    } catch (error) {
      console.error(error);
    }

    try {
      this._em$.unsubscribe();
    } catch (error) {
      console.error(error);
    }
  }

  /** 更新結點位置 */
  public updateTransform() {
    this.transform = `translate(${this.node.x}, ${this.node.y})`;
    this.element.setAttribute('transform', this.transform);
  }

  /** 更新節點的大小，透過 name dom 的大小推算 */
  public updateSize() {
    this._updateSize();
    this.flowService.tick();
    this.updateNearbyConnection();
  }

  /** 更新連接此節點線 */
  public updateNearbyConnection() {
    this.flowService.connectionList
      .filter(
        (connection) =>
          (connection.start && connection.start.node == this.node) ||
          (connection.end && connection.end.node == this.node),
      )
      .forEach((connection) => connection.component.updatePath());
  }

  public _updateSize() {
    let el: SVGGElement = this.elementRef.nativeElement;
    this._updateWidth(el);
    this._updateHeight(el);
  }

  private _drag$ = this.afterViewInit$
    .pipe(
      mergeMap((_) =>
        DMU(
          this.elementRef.nativeElement,
          (down) => true,
          (down) => this._onDragStart(down),
          (down, move) => this._onDrag(down, move),
          (down, up) => this._onDragEnd(down, up),
          this._ngZone,
        ),
      ),
      takeUntil(this._destroy$),
    )
    .subscribe();

  private _updateHeight(el: SVGGElement) {
    this.height = 0;
    const conditions = el.querySelectorAll('.condition');
    if (conditions.length) {
      this.height = (3 + conditions.length * 2.5) * this.em;
    } else {
      this.height = 2 * this.em;
    }
  }

  private _updateWidth(el: SVGGElement) {
    let texts = el.querySelectorAll('text');
    const maxWidth = Array.from(texts).reduce((max, text) => {
      const width = text.getBBox().width;
      if (width > max) {
        return width;
      }
      return max;
    }, 0);
    this.width = maxWidth + 3 * this.em;
  }

  @HostListener('mousedown', ['$event'])
  public select(down: MouseEvent) {
    const node = this.node;
    const increase = down.shiftKey;
    const command = new SelectCommand(this.flowService, node, increase);
    command.do();
    this.commands.push(command);
  }

  private _onDragStart(down: MouseEvent): void {
    const zoom = this.flowService.chart.zoom;

    this.flowService.selected
      .filter((node) => node instanceof Node)
      .forEach((node: Node) => {
        node.oldX = node.x * zoom;
        node.oldY = node.y * zoom;
        node.draggingPaddingX = down.pageX / zoom - node.x;
        node.draggingPaddingY = down.pageY / zoom - node.y;
      });

    down['showMinimap'] = this.flowService.chart.showMinimap;
  }

  private _onDrag(down: MouseEvent, move: MouseEvent) {
    // 因為拖曳時顯示小地圖效能不彰，所以在拖曳時暫時隱藏小地圖
    if (this.flowService.chart.showMinimap) {
      this._ngZone.run(() => (this.flowService.chart.showMinimap = false));
    }

    this.flowService.selected
      .filter((node) => node instanceof Node)
      .forEach((node: Node) => {
        const zoom = this.flowService.chart.zoom;
        const em = this.em;
        const { x, y } = this._getPosition(move, zoom, node, em);
        node.x = x;
        node.y = y;
        node.nodeComponent.updateNearbyConnection();
        node.nodeComponent.updateTransform();
      });
  }

  private _onDragEnd(down: MouseEvent, up: MouseEvent) {
    up.stopPropagation();

    this.flowService.selected
      .filter((node) => node instanceof Node)
      .forEach((node: Node) => {
        const zoom = this.flowService.chart.zoom;
        const em = this.em;
        const { x, y } = this._getPosition(up, zoom, node, em);
        const { oldX: oX, oldY: oY } = node;
        if (x === node.oldX && y === node.oldY) return;
        const command = new MoveNodeCommand(
          this.flowService,
          node,
          oX / zoom,
          oY / zoom,
          x,
          y,
        );
        this.commands.push(command);
      });

    if (this.commands.length) {
      this._invoker.do(new ComponentsCommand(this.commands));
      this.commands = [];
    }

    this.flowService.chart.showMinimap = down['showMinimap'];
  }

  private _getPosition(up: MouseEvent, zoom: number, node: Node, em: number) {
    let x = up.pageX / zoom - node.draggingPaddingX;
    let y = up.pageY / zoom - node.draggingPaddingY;

    if (!up.altKey) {
      x -= (x + em / 2) % em;
      y -= (y + em / 4) % (em / 2);
    }

    return { x, y };
  }
}
