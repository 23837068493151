import {
  ChangeDetectorRef,
  Component,
  Inject,
  forwardRef,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Content } from '@ay/bot';
import { Subject } from 'rxjs';
import { MessageAffectedComponent } from '../../message-affected.component';
import { TemplatePreviewComponent } from '../../template/template-preview/template-preview.component';
import { TemplatePropertiesEditorComponent } from '../../template/template-properties-editor/template-properties-editor.component';
import { ImageComponent } from '../image.component';
import { ImageMessage } from '../image.message';

@Component({
  selector: 'ms-image-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    TemplatePreviewComponent,
    forwardRef(() => ImageComponent),
    TemplatePropertiesEditorComponent,
    MatButton,
  ],
})
export class ImageTemplateEditorDialog extends MessageAffectedComponent<ImageMessage> {
  public notifier = new Subject<void>();

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _matDialogRef: MatDialogRef<ImageTemplateEditorDialog>,
    @Inject(MAT_DIALOG_DATA)
    message: ImageMessage,
  ) {
    super(changeDetectorRef);
    this.message = message;
  }

  public afterTemplateExec(result: Content.Image) {
    this.message.content = result;
  }

  public afterPropertyChange($event) {
    this.notifier.next();
  }

  public close() {
    this._matDialogRef.close();
  }
}
