<div [matContextMenu]="contextMenu" [matContextMenuDisable]="mode === 'READ'">
  <ms-flex-preview [data]="regularizedFlex$ | async" [canSelect]="false">
  </ms-flex-preview>
</div>

<mat-menu #contextMenu class="tiny-menu">
  <button mat-menu-item (click)="editFlex()" i18n>
    <mat-icon>settings</mat-icon>編輯 FLEX
  </button>

  <mat-divider> </mat-divider>

  <button mat-menu-item (click)="delete()" i18n>
    <mat-icon>delete</mat-icon> 刪除
  </button>

  <button mat-menu-item (click)="swapUpward()" [disabled]="isFirstMessage" i18n>
    <mat-icon>arrow_upward</mat-icon> 上移
  </button>

  <button
    mat-menu-item
    (click)="swapDownward()"
    [disabled]="isLastMessage"
    i18n
  >
    <mat-icon>arrow_downward</mat-icon> 下移
  </button>
</mat-menu>
