import { Node } from '../class';

export class ExecuteFTPNode extends Node {
  public icon = 'insert_drive_file';

  /**
   * 回傳類型
   */
  public responseType: 'json' | 'csv' | 'string' = 'json';

  /**
   * 協定
   */
  public method: 'ftp' | 'sftp' = 'ftp';

  /**
   * FTP HOST
   */
  public host: string = '';

  /**
   * FTP PORT
   */
  public port: string = '21';

  /**
   * FTP USERNAME
   */
  public username: string = '';

  /**
   * FTP PASSWORD
   */
  public password: string = '';

  /**
   * FTP PATH
   */
  public path: string = '';

  /**
   * 回傳值寫入的變數
   */
  public responseVar: string = 'data';

  //編碼
  public encoding: 'utf-8' | 'big5' = 'utf-8';

  // 快取時間
  public cacheTime: string = '0';

  public isAsync = false;

  public readonly extraProperties = [
    'responseType',
    'isAsync',
    'method',
    'host',
    'port',
    'username',
    'password',
    'path',
    'responseVar',
    'encoding',
    'cacheTime',
  ];
}
