<ng-container *ngIf="service.selected$ | async; let select">
  <mat-card *ngIf="data.properties">
    <mat-card-title color="primary">
      {{ select.type | cht: true }}
    </mat-card-title>

    <mat-divider> </mat-divider>

    <mat-card-content>
      <div class="label" *ngFor="let property of data.properties">
        <ng-template #tooltip>
          <div class="flex-tooltip-html">
            <mat-icon class="info-icon">info</mat-icon>
            <div [innerHTML]="property.tooltip"></div>
          </div>
        </ng-template>

        <ng-template #inputTooltip>
          <div class="flex-tooltip-html">
            <mat-icon class="info-icon">info</mat-icon>
            <div [innerHTML]="property.inputTooltip"></div>
          </div>
        </ng-template>

        <div class="title">
          <span>
            {{ property.name | cht: false : select.type }}
          </span>
          @if (property?.tooltip) {
            <mat-icon
              class="info-icon"
              (click)="openTooltipDialog($event, tooltip)"
              >info</mat-icon
            >
          }
        </div>

        <programmable
          [title]="property.name | cht: false : select.type"
          *ngIf="property.options; else input"
          [value]="select[property.name] || property.value"
          (valueChange)="setValue(select, $event, property.name)"
        >
          <mat-form-field legacyAppearance>
            <mat-select
              [value]="select[property.name] || property.value"
              (selectionChange)="setValue(select, $event.value, property.name)"
            >
              <mat-option
                *ngFor="let option of property.options"
                [value]="option.value"
                [disabled]="option?.disabled"
              >
                {{ option.text || option.value }}
              </mat-option>
            </mat-select>
            <mat-hint>{{ property.hint }}</mat-hint>
            @if (property?.inputTooltip) {
              <mat-icon
                class="info-icon"
                (click)="openTooltipDialog($event, inputTooltip)"
                >info</mat-icon
              >
            }
          </mat-form-field>
        </programmable>

        <ng-template #input>
          <programmable
            [title]="property.name | cht: false : select.type"
            [value]="select[property.name] || property.value"
            (valueChange)="setValue(select, $event, property.name, property)"
            [dataType]="property.isNumber ? 'number' : 'string'"
          >
            <mat-form-field
              [class.mat-form-field-invalid]="property.error"
              legacyAppearance
            >
              <input
                matInput
                color="primary"
                *ngIf="!property.name.toLocaleLowerCase().includes('color')"
                [value]="select[property.name] || property.value || ''"
                [disabled]="property.disabled"
                [matAutocomplete]="auto"
                (change)="
                  setValue(
                    select,
                    $any($event.target).value,
                    property.name,
                    property
                  );
                  fixValueWhenBlur(select, property.name, property)
                "
                (focus)="service.isInputFocus$.next(true)"
                (blur)="service.isInputFocus$.next(false)"
              />

              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="
                  setValue(select, $event.option.value, property.name, property)
                "
              >
                <mat-option
                  *ngFor="let option of property.autoComplete"
                  [value]="option.value"
                >
                  {{ option.text || option.value }}
                </mat-option>
              </mat-autocomplete>

              <gosu-color-picker
                #colorPicker
                *ngIf="property.name.toLocaleLowerCase().includes('color')"
                [value]="select[property.name]"
                (valueChange)="
                  setValue(select, $event, property.name, property)
                "
                format="hex"
                [enableAlpha]="true"
              >
              </gosu-color-picker>
              <gosu-image-picker
                #imagePicker
                [hidden]="true"
                [value]="select[property.name]"
                (valueChange)="
                  setValue(select, $event, property.name, property)
                "
                [useCode]="true"
              >
              </gosu-image-picker>
              <button
                *ngIf="property?.imagePicker"
                matSuffix
                mat-icon-button
                element-ref
                #elementRef="elementRef"
                (click)="imagePicker.pickImage(elementRef)"
              >
                <mat-icon>cloud_upload</mat-icon>
              </button>
              <span class="info">
                <span [class.mat-error]="property.error">
                  {{ property.error || property.hint }}
                </span>
                @if (property?.inputTooltip) {
                  <mat-icon
                    class="info-icon"
                    (click)="openTooltipDialog($event, inputTooltip)"
                    >info</mat-icon
                  >
                }
              </span>
            </mat-form-field>
          </programmable>
        </ng-template>
      </div>
    </mat-card-content>
  </mat-card>

  <form-style
    *ngIf="data.hasStyle"
    [formValue]="
      service.selectedParent && service.selectedParent.styles
        ? service.selectedParent.styles[select.type]
        : {}
    "
    (formValueChange)="setStyle(select, $event)"
  >
  </form-style>

  <form-offset
    *ngIf="data.hasOffset"
    [formValue]="select"
    (formValueChange)="setValue(select, $event)"
  >
  </form-offset>

  <form-padding
    *ngIf="data.hasPadding"
    [formValue]="select"
    (formValueChange)="setValue(select, $event)"
  >
  </form-padding>

  <form-background
    *ngIf="data.hasBackground"
    [formValue]="select"
    (formValueChange)="setValue(select, $event, 'background')"
  >
  </form-background>

  <form-action
    *ngIf="data.hasAction"
    [formValue]="select"
    (formValueChange)="setValue(select, $event, 'action')"
  >
  </form-action>
</ng-container>
