<div class="header w-full flex flex-row">
  <span class="flex-1">
    {{ title }}
    @if (dataType) {
      <span class="pl-2 opacity-70 text-sm">{{ dataType }}</span>
    }
  </span>

  <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
</div>

<div class="container flex-1 !p-0 w-full bg-code !overflow-visible">
  @if (enableMonaco$ | async) {
    @if (isLoading) {
      <div
        class="absolute top-0 left-0 w-full h-full flex items-center justify-center"
      >
        <gosu-progress-bar class="w-[80%]" mode="indeterminate" i18n>
          程式碼編輯器載入中，請稍候
        </gosu-progress-bar>
      </div>
    }

    <ngx-monaco-editor
      class="w-full !h-full"
      [options]="editorOptions"
      [(ngModel)]="data.code"
      (init)="onMonacoEditorInit($event)"
    >
    </ngx-monaco-editor>
  } @else {
    <textarea
      class="text-primary font-mono px-6 py-0 w-full h-full bg-code leading-6 border-none"
      [(ngModel)]="data.code"
    ></textarea>
  }
</div>

<div class="footer w-full flex flex-row !justify-end gap-3">
  <button mat-button (click)="cancel()" i18n>取消</button>
  <button mat-flat-button color="primary" (click)="submit()" i18n>確認</button>
</div>
