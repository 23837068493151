<div class="gene">
  <h3 i18n>一般節點</h3>
  <div
    class="node"
    *ngFor="let node of generals"
    [matTooltip]="tooltipTemplate"
    [matTooltipData]="{ $implicit: node }"
    [style.background-color]="node.toolbar.color"
    (click)="select(node)"
  >
    <icon [icon]="node.toolbar.icon"></icon>
    <!-- <ng-container
      *ngTemplateOutlet="iconTemplate; context: { $implicit: node }"
    >
    </ng-container> -->
  </div>
</div>

<div class="expert">
  <h3 i18n>進階節點</h3>
  <ng-container *ngFor="let node of experts">
    <div
      class="node"
      [matTooltip]="tooltipTemplate"
      [matTooltipData]="{ $implicit: node }"
      [style.background-color]="node.toolbar.color"
      (click)="select(node)"
    >
      <icon [icon]="node.toolbar.icon"></icon>
    </div>
  </ng-container>
</div>

<ng-template #tooltipTemplate let-node>
  {{ node.toolbar.name || node.name }}
  <div
    class="tag"
    [class.line]="
      node?.toolbar?.tag === 'LINE 限定' ||
      node?.toolbar?.tag === 'LINE Messaging API 限定'
    "
    [class.facebook]="node?.toolbar?.tag === 'Facebook 限定'"
    *ngIf="node?.toolbar?.tag"
  >
    {{ node?.toolbar?.tag }}
  </div>
</ng-template>
