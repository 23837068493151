import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { AfterContentInit, Component, ElementRef } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MatOption } from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle,
} from '@angular/material/datepicker';
import {
  MatFormField,
  MatHint,
  MatLabel,
  MatPrefix,
  MatSuffix,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { StatisticModel } from '@ay-gosu/server-shared';
import { map } from 'rxjs/operators';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../../material/tooltip/tooltip';
import { BotService } from '../../../../service/bot.service';
import { FlowService } from '../../flow.service';
import { FormComponent } from '../form.component';
import { GetStatisticNode } from './class';

@Component({
  selector: 'statistic-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD',
        },
        display: {
          dateInput: 'YYYY-MM-DD',
          monthYearLabel: 'YYYY MMM',
          dateA11yLabel: 'YYYY-MM-DD',
          monthYearA11yLabel: 'YYYY MMMM',
        },
      },
    },
  ],
  standalone: true,
  imports: [
    MatCheckbox,
    FormsModule,
    FlexModule,
    MatFormField,
    MatLabel,
    MatSelect,
    NgFor,
    MatOption,
    NgIf,
    MatInput,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatPrefix,
    MatDatepicker,
    MatHint,
    MatIconButton,
    MatSuffix,
    MatTooltip,
    MatIcon,
    AsyncPipe,
    LegacyAppearanceDirective,
  ],
})
export class GetStatisticFormComponent
  extends FormComponent<GetStatisticNode>
  implements AfterContentInit
{
  public configs = [];

  public options = [
    { value: 'HOUR', name: $localize`小時` },
    { value: 'DAY', name: $localize`日` },
    { value: 'WEEKDAY', name: $localize`星期` },
    { value: 'MONTH', name: $localize`月份` },
    { value: 'QUARTER', name: $localize`季` },
    { value: 'YEAR', name: $localize`年份` },
    { value: 'WEEK_OF_YEAR', name: $localize`周` },
  ];

  public bots$ = this.botService.all$.pipe(
    map((bots) => [{ id: -1, name: $localize`觸發的機器人` }, ...bots]),
  );

  public notText = $localize`非`;

  public constructor(
    public flow: FlowService,
    public elementRef: ElementRef,
    public botService: BotService,
  ) {
    super(elementRef);
  }

  public async ngAfterContentInit() {
    this.configs = await StatisticModel.getAllConfig();
  }
}
