<ng-container *ngIf="message">
  <ng-container *ngIf="type !== 'announcement'; else announcement">
    <gosu-avatar *ngIf="message.userProfileId" [id]="message.userProfileId">
    </gosu-avatar>

    <div class="content">
      <ms-factory-container #container> </ms-factory-container>

      <div class="extra-info" *ngIf="message.createdAt || message.sender">
        <span class="time" *ngIf="message.createdAt">
          <span
            [matTooltip]="message.createdAt | moment: 'YYYY/MM/DD HH:mm:ss'"
          >
            {{ message.createdAt | fromNow }}
          </span>
        </span>

        <span class="sender" *ngIf="message.sender" i18n>
          由 {{ message.sender }} 發送
        </span>

        <mat-icon
          *ngIf="
            !message.userProfileId &&
            message.createdAt &&
            message.status !== null
          "
          [matTooltip]="
            message.status ? '發送成功' : message.reason || '發送失敗'
          "
          [style.color]="message.status ? '#00B900' : 'red'"
        >
          {{ message.status ? 'done' : 'error' }}
        </mat-icon>
        <span *ngIf="message.status === null" class="fill"></span>
      </div>
    </div>
  </ng-container>

  <ng-template #announcement>
    <ms-factory-container #container> </ms-factory-container>
  </ng-template>
</ng-container>
