import { APP_BASE_HREF, AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, forwardRef, Inject } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { TemplateDto } from '@ay-gosu/server-shared';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import urlJoin from 'url-join';
import { LandingBrandComponent } from '../../../components/landing-brand/landing-brand.component';
import { LoadingComponent } from '../../../components/loading/loading.component';
import { ImageTypePipe } from '../../../pipe/imageType.pipe';
import { TemplateService } from '../../../service/template.service';
import { MessageIconPipe } from '../../message-icon.pipe';

@Component({
  selector: 'ms-image-template-picker',
  templateUrl: './template-picker.component.html',
  styleUrls: ['./template-picker.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatIcon,
    FlexModule,
    NgFor,
    LandingBrandComponent,
    LoadingComponent,
    AsyncPipe,
    ImageTypePipe,
    forwardRef(() => MessageIconPipe),
  ],
})
export class ImageTemplatePickerDialog {
  public images$: Observable<TemplateDto[]> = of(0).pipe(
    switchMap((_) => this._templateService.getList()),
    map((templates) =>
      templates.filter((template) => template.type === 'Image'),
    ),
  );

  public constructor(
    private readonly _templateService: TemplateService,
    private readonly _matDialogRef: MatDialogRef<ImageTemplatePickerDialog>,
    @Inject(APP_BASE_HREF)
    private readonly _baseHref: string,
  ) {}

  public submit(image: TemplateDto) {
    this._matDialogRef.close(image);
  }

  public createTemplate() {
    const url = urlJoin(this._baseHref, '/imaging');
    window.open(url);
    this._matDialogRef.close();
  }
}
