import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { LingtelliService } from '../../../service/lingtelli.service';

@Component({
  selector: 'dl-lingtelli-login-dialog',
  templateUrl: './lingtelli-login.dialog.html',
  styleUrls: ['./lingtelli-login.dialog.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    NgIf,
    MatHint,
    MatButton,
    LegacyAppearanceDirective,
  ],
})
export class LingtelliLoginDialog {
  public username: string = '';
  public password: string = '';
  public isLogging: boolean = false;
  public error: boolean = false;

  public constructor(
    private readonly _lingtelliService: LingtelliService,
    private readonly _matDialogRef: MatDialogRef<LingtelliLoginDialog>,
  ) {}

  public async login(): Promise<boolean> {
    if (!this.username || !this.password) return;
    this.error = false;
    this.isLogging = true;
    let res = await this._lingtelliService
      .createChatbot(this.username, this.password)
      .catch((err) => false);
    this.isLogging = false;
    if (!res) {
      this.password = '';
      this.error = true;
      return;
    }
    this._matDialogRef.close();
  }
}
