<mat-card>
  <mat-card-header>
    <mat-card-title i18n>背景</mat-card-title>
  </mat-card-header>
  <mat-divider> </mat-divider>

  <mat-card-content [formGroup]="formGroup">
    <div class="label">
      <div class="title">{{ 'type' | cht }}</div>
      <programmable
        [title]="'type' | cht"
        [value]="formGroup.value['type']"
        (valueChange)="formGroup.controls['type'].setValue($event); doChange()"
      >
        <mat-form-field legacyAppearance>
          <mat-select
            formControlName="type"
            (selectionChange)="changeBackgroundType($event.value); doChange()"
          >
            <mat-option
              *ngFor="let option of options.Background"
              [value]="option.value"
            >
              {{ option.text || option.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </programmable>
    </div>

    <div
      class="label"
      *ngFor="let property of backgroundProperties[formGroup.value.type]"
    >
      <div class="title">{{ property.name | cht }}</div>

      <programmable
        [title]="property.name | cht"
        [value]="formGroup.value[property.name]"
        (valueChange)="
          formGroup.controls[property.name].setValue($event); doChange()
        "
      >
        <mat-form-field
          [class.mat-form-field-invalid]="property.error"
          legacyAppearance
        >
          <gosu-color-picker
            #colorPicker
            *ngIf="
              property.name.toLocaleLowerCase().includes('color');
              else input
            "
            [value]="formGroup.value[property.name]"
            (valueChange)="
              formGroup.controls[property.name].setValue($event); doChange()
            "
            format="hex"
            [enableAlpha]="true"
          >
          </gosu-color-picker>
          <ng-template #input>
            <input
              matInput
              color="primary"
              [formControlName]="property.name"
              (change)="doChange()"
              (focus)="flexEditorService.isInputFocus$.next(true)"
              (blur)="flexEditorService.isInputFocus$.next(false)"
            />
          </ng-template>
          <mat-hint>
            <span [class.mat-error]="property.error">
              {{ property.error || property.hint }}
            </span>
          </mat-hint>
        </mat-form-field>
      </programmable>
    </div>
  </mat-card-content>
</mat-card>
