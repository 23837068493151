import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { firstValueFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { CouponStatisticsDialogComponent } from './coupon-statistics-dialog/coupon-statistics-dialog.component';
import { CouponMessage } from './coupon.message';
import { Nl2brPipe } from '../../pipe/nl2br.pipe';
import { MomentPipe } from '../../pipe/moment.pipe';
import { CouponTypePipe } from '../../pipe/coupon-type.pipe';
import { IconComponent } from '../../components/icon/icon.component';
import { Dir } from '@angular/cdk/bidi';
import { MatContextMenuDirective } from '../../material/context-menu';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem } from '@angular/material/menu';

@Component({
    selector: 'ms-coupon',
    templateUrl: './coupon.component.html',
    styleUrls: ['./coupon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['package', 'mode', 'message'],
    standalone: true,
    imports: [
        MatMenu,
        MatMenuItem,
        MatIcon,
        MatDivider,
        NgIf,
        MatContextMenuDirective,
        Dir,
        IconComponent,
        AsyncPipe,
        CouponTypePipe,
        MomentPipe,
        Nl2brPipe,
    ],
})
export class CouponComponent extends BaseComponent<CouponMessage> {
  public backgroundImage$ = this.message$.pipe(
    filter((message) => !!message && !!message.coupon),
    map((message) =>
      this._domSanitizer.bypassSecurityTrustStyle(
        `url(${message.coupon.image})`,
      ),
    ),
  );

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _matConnectedDialog: MatConnectedDialog,
  ) {
    super(changeDetectorRef);
  }

  public async editCoupon() {
    return firstValueFrom(
      this._matConnectedDialog
        .open(CouponStatisticsDialogComponent, { data: this.message })
        .afterClosed(),
    );
  }
}
