import {
  ChangeDetectionStrategy,
  Component,
  ViewContainerRef,
} from '@angular/core';

@Component({
    selector: 'ms-factory-container',
    template: '',
    styles: [':host{ display: inline-block; }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class FactoryContainerComponent {
  public constructor(public viewContainerRef: ViewContainerRef) {}
}
