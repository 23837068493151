@if (withCode) {
  <mat-form-field legacyAppearance class="w-full">
    @if (matLabel) {
      <mat-label>{{ title }}</mat-label>
    }

    <input matInput class="hidden" />

    <button
      mat-button
      color="primary"
      class="w-full !py-0 !px-2 !h-5"
      element-ref
      #elementRef="elementRef"
      (click)="edit(elementRef)"
      i18n
    >
      <span
        class="inline-block w-full !h-5 !text-ellipsis !overflow-hidden !whitespace-nowrap"
      >
        <mat-icon class="!w-4 !h-4 !text-base">code</mat-icon>
        {{ preview }}
      </span>
    </button>
  </mat-form-field>

  <button
    matSuffix
    mat-icon-button
    matTooltip="清空程式碼"
    i18n-matTooltip="清空程式碼"
    class="opacity-50 hover:opacity-100 ml-1"
    (click)="clear()"
    [disabled]="disabled"
  >
    <mat-icon>code_off</mat-icon>
  </button>
} @else {
  <ng-content></ng-content>

  <button
    mat-icon-button
    matTooltip="撰寫一小段程式碼"
    i18n-matTooltip="撰寫一小段程式碼"
    element-ref
    #elementRef="elementRef"
    (click)="edit(elementRef)"
    [disabled]="disabled"
    class="opacity-50 hover:opacity-100 ml-1"
  >
    <mat-icon>code</mat-icon>
  </button>
}
