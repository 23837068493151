import { AsyncPipe } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import {
  MatFormField,
  MatHint,
  MatLabel,
  MatPrefix,
  MatSuffix
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { EditorComponent } from '../../../../components/ngx-monaco-editor/editor.component';
import { MonacoEditorLoaderService } from '../../../../components/ngx-monaco-editor/monaco-editor-loader.service';
import { ProgrammableComponent } from '../../../../components/programmable/programmable.component';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../../material/tooltip/tooltip';
import { ClipboardService } from '../../../../service/clipboard.service';
import { FormComponent } from '../form.component';
import { ExecuteAPINode } from './class';

@Component({
  selector: 'flow-execute-api-form',
  templateUrl: './form.component.html',
  standalone: true,
  imports: [
    MatCheckbox,
    FormsModule,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatInput,
    MatSuffix,
    MatPrefix,
    MatHint,
    MatTabGroup,
    MatTab,
    MatIconButton,
    MatTooltip,
    MatIcon,
    MatButton,
    EditorComponent,
    AsyncPipe,
    LegacyAppearanceDirective,
    ProgrammableComponent,
  ],
})
export class ExecuteAPIFormComponent extends FormComponent<ExecuteAPINode> {
  public enableMonaco$ = this._monacoEditorLoaderService.enableMonaco$;
  public methods: string[] = ['get', 'post', 'delete', 'put'];
  public responseTypes: string[] = ['json', 'csv', 'string', 'rss'];
  public bodyTypes: string[] = ['x-www-form-urlencoded', 'raw'];
  public encodings: string[] = ['utf-8', 'big5'];
  public activeTab = 0;

  public editorOptions = {
    theme: 'vs-dark',
    language: 'json',
    minimap: { enabled: false },
    snippetSuggestions: 'auto',
  };

  public notText = $localize`非`;

  public constructor(
    public elementRef: ElementRef,
    private readonly _monacoEditorLoaderService: MonacoEditorLoaderService,
    private readonly _clipboard: ClipboardService
  ) {
    super(elementRef);
  }

  public addHeader() {
    if (this.node.headers === undefined) this.node.headers = [];
    this.node.headers.push({ key: '', value: '' });
  }

  public afterChangeBodyType(type: 'x-www-form-urlencoded' | 'raw') {
    if (type == 'raw') {
      if (typeof this.node.body != 'string') this.node.body = '';
    } else {
      if (typeof this.node.body != 'object') this.node.body = [];
    }
  }

  public addQueryString() {
    if (this.node.query === undefined) this.node.query = [];
    this.node.query.push({ key: '', value: '' });
  }

  public addBody() {
    if (this.node.body === undefined || typeof this.node.body == 'string')
      this.node.body = [];
    this.node.body.push({ key: '', value: '' });
  }

  public async removeHeader(index: number) {
    this.node.headers.splice(index, 1);
  }

  public async removeQuery(index: number) {
    this.node.query.splice(index, 1);
  }

  public async removeBody(index: number) {
    this.node.body.splice(index, 1);
  }

  public trackByIndex(index: number, item: any) {
    return index;
  }

  public copy(value: string) {
    this._clipboard.copy(value);
  }
}
