export const FLEX_PROP_OPTIONS = {
  Action: [
    { text: '', value: '', enable: true },
    { text: $localize`回傳資料`, value: 'postback', enable: true },
    { text: $localize`連結`, value: 'uri', enable: true },
    { text: $localize`訊息`, value: 'message', enable: true },
    { text: $localize`日曆`, value: 'datetimepicker', enable: true },
    {
      text: $localize`加入機器人(推廣通路)`,
      value: $localize`add-through-promotion`,
      enable: true,
    },
    {
      text: $localize`分享機器人 (推廣通路)`,
      value: 'share-message-through-promotion',
      enable: true,
    },
  ],
  Background: [
    { text: '', value: '' },
    { text: $localize`線性漸層`, value: 'linearGradient' },
  ],
  Separator: [
    { text: '', value: '' },
    { text: $localize`是`, value: true },
    { text: $localize`否`, value: false },
  ],
  DatetimePickerMode: [
    { text: $localize`日期`, value: 'date' },
    { text: $localize`時間`, value: 'time' },
    { text: $localize`日期與時間`, value: 'datetime' },
  ],
  AspectMode: [
    { text: '', value: '' },
    { text: $localize`完全覆蓋`, value: 'cover' },
    { text: $localize`最適大小`, value: 'fit' },
  ],
  ButtonHeight: [
    { text: '', value: '' },
    { text: $localize`小`, value: 'sm' },
    { text: $localize`中`, value: 'md' },
  ],
  TextStyle: [
    { text: '', value: '' },
    { text: $localize`正常`, value: 'normal' },
    { text: $localize`斜體`, value: 'italic' },
  ],
  TextDecoration: [
    { text: '', value: '' },
    { text: $localize`無`, value: 'none' },
    { text: $localize`底線`, value: 'underline' },
    { text: $localize`刪除線`, value: 'line-through' },
  ],
  TextWeight: [
    { text: '', value: '' },
    { text: $localize`正常`, value: 'regular' },
    { text: $localize`粗體`, value: 'bold' },
  ],
  ImageSize: [
    { text: '', value: '' },
    { text: '', value: 'xxs' },
    { text: '', value: 'xs' },
    { text: '', value: 'sm' },
    { text: '', value: 'md' },
    { text: '', value: 'lg' },
    { text: '', value: 'xl' },
    { text: '', value: 'xxl' },
    { text: '', value: '3xl' },
    { text: '', value: '4xl' },
    { text: '', value: '5xl' },
    { text: '', value: 'full' },
  ],
  IconSize: [
    { text: '', value: '' },
    { text: '', value: 'xxs' },
    { text: '', value: 'xs' },
    { text: '', value: 'sm' },
    { text: '', value: 'md' },
    { text: '', value: 'lg' },
    { text: '', value: 'xl' },
    { text: '', value: 'xxl' },
    { text: '', value: '3xl' },
    { text: '', value: '4xl' },
    { text: '', value: '5xl' },
  ],
  TextSize: [
    { text: '', value: '' },
    { text: '', value: 'xxs' },
    { text: '', value: 'xs' },
    { text: '', value: 'sm' },
    { text: '', value: 'md' },
    { text: '', value: 'lg' },
    { text: '', value: 'xl' },
    { text: '', value: 'xxl' },
    { text: '', value: '3xl' },
    { text: '', value: '4xl' },
    { text: '', value: '5xl' },
  ],
  BubbleDirection: [
    { text: '', value: '' },
    { text: $localize`由左至右`, value: 'ltr' },
    { text: $localize`由右至左`, value: 'rtl' },
  ],
  BubbleSize: [
    { text: '', value: '' },
    { text: '', value: 'nano' },
    { text: '', value: 'micro' },
    { text: '', value: 'kilo' },
    { text: '', value: 'mega' },
    { text: '', value: 'giga' },
  ],
  SpacerSize: [
    { text: '', value: '' },
    { text: '', value: 'xs' },
    { text: '', value: 'sm' },
    { text: '', value: 'md' },
    { text: '', value: 'lg' },
    { text: '', value: 'xl' },
    { text: '', value: 'xxl' },
  ],
  Align: [
    { text: '', value: '' },
    { text: $localize`前`, value: 'start' },
    { text: $localize`中`, value: 'center' },
    { text: $localize`後`, value: 'end' },
  ],
  Gravity: [
    { text: '', value: '' },
    { text: $localize`上`, value: 'top' },
    { text: $localize`中`, value: 'center' },
    { text: $localize`下`, value: 'bottom' },
  ],
  ButtonStyle: [
    { text: '', value: '' },
    { text: $localize`主色調`, value: 'primary' },
    { text: $localize`輔色調`, value: 'secondary' },
    { text: $localize`連結`, value: 'link' },
  ],
  BoxLayout: [
    { text: $localize`水平排列`, value: 'horizontal' },
    { text: $localize`垂直排列`, value: 'vertical' },
    { text: $localize`依據基準線`, value: 'baseline' },
  ],
  BorderWidth: [
    { text: '', value: '' },
    { text: $localize`無`, value: 'none' },
    { text: $localize`細線`, value: 'light' },
    { text: $localize`正常`, value: 'normal' },
    { text: $localize`微粗`, value: 'medium' },
    { text: $localize`略粗`, value: 'semi-bold' },
    { text: $localize`粗`, value: 'bold' },
  ],
  CornerRadius: [
    { text: '', value: '' },
    { text: '', value: 'none' },
    { text: '', value: 'xs' },
    { text: '', value: 'sm' },
    { text: '', value: 'md' },
    { text: '', value: 'lg' },
    { text: '', value: 'xl' },
    { text: '', value: 'xxl' },
  ],
  Spacing: [
    { text: '', value: '' },
    { text: '', value: 'none' },
    { text: '', value: 'xs' },
    { text: '', value: 'sm' },
    { text: '', value: 'md' },
    { text: '', value: 'lg' },
    { text: '', value: 'xl' },
    { text: '', value: 'xxl' },
  ],
  Margin: [
    { text: '', value: '' },
    { text: '', value: 'none' },
    { text: '', value: 'xs' },
    { text: '', value: 'sm' },
    { text: '', value: 'md' },
    { text: '', value: 'lg' },
    { text: '', value: 'xl' },
    { text: '', value: 'xxl' },
  ],
  Position: [
    { text: '', value: '' },
    { text: $localize`相對定位`, value: 'relative' },
    { text: $localize`絕對定位`, value: 'absolute' },
  ],
  Wrap: [
    { text: '', value: '' },
    { text: $localize`是`, value: true },
    { text: $localize`否`, value: false },
  ],
  Padding: [
    { text: '', value: '' },
    { text: '', value: 'none' },
    { text: '', value: 'xs' },
    { text: '', value: 'sm' },
    { text: '', value: 'md' },
    { text: '', value: 'lg' },
    { text: '', value: 'xl' },
    { text: '', value: 'xxl' },
  ],
  Offset: [
    { text: '', value: '' },
    { text: '', value: 'none' },
    { text: '', value: 'xs' },
    { text: '', value: 'sm' },
    { text: '', value: 'md' },
    { text: '', value: 'lg' },
    { text: '', value: 'xl' },
    { text: '', value: 'xxl' },
  ],
  JustifyContent: [
    { text: $localize`置中對齊`, value: 'center' },
    { text: $localize`置前對齊`, value: 'flex-start' },
    { text: $localize`置後對齊`, value: 'flex-end' },
    { text: $localize`分散`, value: 'space-between' },
    { text: $localize`均分`, value: 'space-around' },
    { text: $localize`等距`, value: 'space-evenly' },
  ],
  AlignItems: [
    { text: $localize`置中對齊`, value: 'center' },
    { text: $localize`置前對齊`, value: 'flex-start' },
    { text: $localize`置後對齊`, value: 'flex-end' },
  ],
  Animated: [
    { text: '', value: '' },
    { text: $localize`是`, value: true },
    { text: $localize`否`, value: false },
  ],
  AdjustMode: [
    { text: $localize`不自動調整`, value: '' },
    { text: $localize`自動調整`, value: 'shrink-to-fit' },
  ],
};
