import { Bot } from './bot';
import { Company } from './company';
import { Content } from './content';
import { Coupon } from './coupon';
import { FacebookPost } from './facebook-post';
import { InstagramPost } from './instagram-post';
import { Log } from './log';
import { QuickReply } from './quick-reply';
import { Record } from './record';
import { Target } from './target';

export type CommonEventType = 'message' | 'follow' | 'postback';

export type LineEventType =
  | 'join'
  | 'leave'
  | 'unfollow'
  | 'memberJoined'
  | 'memberLeft'
  | 'enterBeacon'
  | 'stayBeacon'
  | 'leaveBeacon'
  | 'clickBeaconBanner'
  | 'accountLink';

export type GosuEventType =
  | 'use-coupon'
  | 'receive-coupon'
  | 'exchange'
  | 'customize'
  | 'add-through-promotion'
  | 'success-invite-friend-through-promotion';

export type InstagramEventType = 'comments' | 'igme';

export type FacebookEventType =
  | 'add-comment'
  | 'remove-comment'
  | 'edited-comment'
  | 'add-like-page'
  | 'remove-like-page'
  | 'add-reaction'
  | 'edit-reaction'
  | 'remove-reaction'
  | 'add-post-customer'
  | 'remove-post-customer'
  | 'edited-post-customer'
  | 'add-post-editors'
  | 'remove-post-editors'
  | 'edited-post-editors'
  | 'add-ratings'
  | 'remove-ratings'
  | 'mme';

export type ThirdPartyEventType = 'surveyCake';

export type EventType =
  | CommonEventType
  | LineEventType
  | InstagramEventType
  | FacebookEventType
  | GosuEventType
  | ThirdPartyEventType;

export namespace Event {
  export type Any =
    | Message
    | MemberJoined
    | Join
    | Leave
    | Follow
    | Unfollow
    | MemberLeft
    | MemberJoined
    | Postback
    | EnterBeacon
    | LeaveBeacon
    | StayBeacon
    | ClickBeaconBanner
    | FacebookEvent
    | InstagramEvent
    | UseCoupon
    | AccountLink
    | ReceiveCoupon
    | MMEEvent
    | Exchange
    | Customize
    | SurveyCake
    | AddThroughPromotion
    | SuccessInviteFriendThroughPromotion;

  export class Base<FromTarget extends Target.Any = Target.Any> {
    /** 事件類型 */
    public type: EventType;

    // 在轉化短網址時所需要的資訊，為了統計用途
    public packageId?: number;

    public bot?: Bot;
    public companyId?: number;
    public company?: Company;
    public record?: Record;
    public log?: Log;

    public requestId?: string;

    public constructor(
      /** 機器人編號 */
      public botId: number,
      /** 發送來源 */
      public from: FromTarget,
      /** 訊息時間 */
      public timestamp: number = Date.now(),
    ) {}
  }

  export class Message<
    BaseContent extends Content.Any = Content.Any,
    FromTarget extends Target.Any = Target.Any,
    ToTarget extends Target.Any = Target.Any,
  > extends Base<FromTarget> {
    public type = 'message' as const;

    /** 發送任務編號 */
    public taskId?: number;

    /** 發送任務是否優先 */
    public isPriority?: boolean;

    public quickReplies?: QuickReply[];

    public constructor(
      /** 機器人編號 */
      botId: number,
      /** 發送對象 */
      public to: ToTarget,
      /** 發送來源 */
      from: FromTarget,
      /** 訊息內容 */
      public content: BaseContent,
      /** 訊息時間 */
      timestamp: number = Date.now(),
    ) {
      super(botId, from, timestamp);
    }
  }

  export class Join extends Base<Target.Line> {
    public type = 'join' as const;
  }

  export class Leave extends Base<Target.Line> {
    public type = 'leave' as const;
  }

  export class Follow<
    FromTarget extends Target.Any = Target.Any,
  > extends Base<FromTarget> {
    public type = 'follow' as const;
  }

  export class Unfollow<
    FromTarget extends Target.Any = Target.Any,
  > extends Base<FromTarget> {
    public type = 'unfollow' as const;
  }

  export class MemberLeft extends Base<Target.Line> {
    public type = 'memberLeft' as const;
  }

  export class MemberJoined extends Base<Target.Line> {
    public type = 'memberJoined' as const;
  }

  export class Postback<
    FromTarget extends Target.Any = Target.Any,
    DataType = any,
  > extends Base<FromTarget> {
    public type = 'postback' as const;

    public params: any;

    public constructor(
      /** 機器人編號 */
      botId: number,
      /** 發送來源 */
      from: FromTarget,
      /** 回傳的資料 */
      public data: DataType,
      /** 訊息時間 */
      timestamp: number = Date.now(),
    ) {
      super(botId, from, timestamp);
    }
  }

  export class EnterBeacon extends Base<Target.Line> {
    public type = 'enterBeacon' as const;

    public constructor(
      /** 機器人編號 */
      botId: number,
      /** 發送來源 */
      from: Target.Line,
      /** 硬體編號 */
      public hwid: string,
      /** Device message */
      public deviceMessage: string,
      /** 訊息時間 */
      timestamp: number = Date.now(),
    ) {
      super(botId, from, timestamp);
    }
  }

  export class LeaveBeacon extends Base<Target.Line> {
    public type = 'leaveBeacon' as const;

    public constructor(
      /** 機器人編號 */
      botId: number,
      /** 發送來源 */
      from: Target.Line,
      /** 硬體編號 */
      public hwid: string,
      /** Device message */
      public deviceMessage: string,
      /** 訊息時間 */
      timestamp: number = Date.now(),
    ) {
      super(botId, from, timestamp);
    }
  }

  export class StayBeacon extends Base<Target.Line> {
    public type = 'stayBeacon' as const;

    public constructor(
      /** 機器人編號 */
      botId: number,
      /** 發送來源 */
      from: Target.Line,
      /** 硬體編號 */
      public hwid: string,
      /** Device message */
      public deviceMessage: string,
      /** 訊息時間 */
      timestamp: number = Date.now(),
    ) {
      super(botId, from, timestamp);
    }
  }

  export class ClickBeaconBanner extends Base<Target.Line> {
    public type = 'clickBeaconBanner' as const;

    public constructor(
      /** 機器人編號 */
      botId: number,
      /** 發送來源 */
      from: Target.Line,
      /** 硬體編號 */
      public hwid: string,
      /** Device message */
      public deviceMessage: string,
      /** 訊息時間 */
      timestamp: number = Date.now(),
    ) {
      super(botId, from, timestamp);
    }
  }

  export class FacebookEvent extends Base<Target.Facebook> {
    public content?: string;
    public resources?: string[];
    public post?: FacebookPost;
    public value?: any;

    public constructor(
      /** Facebook 事件類型 */
      public type: FacebookEventType,
      /** 機器人編號 */
      botId: number,
      /** 發送來源 */
      from: Target.Facebook,
      /** 訊息時間 */
      timestamp: number = Date.now(),
    ) {
      super(botId, from, timestamp);
    }
  }

  export class InstagramEvent extends Base<Target.Instagram> {
    public content?: string;
    public resources?: string[];
    public post?: InstagramPost;
    public value?: any;

    public constructor(
      /** Instagram 事件類型 */
      public type: InstagramEventType,
      /** 機器人編號 */
      botId: number,
      /** 發送來源 */
      from: Target.Instagram,
      /** 訊息時間 */
      timestamp: number = Date.now(),
    ) {
      super(botId, from, timestamp);
    }
  }

  export class UseCoupon<T extends Target.Any = Target.Any> extends Base<T> {
    public type = 'use-coupon' as const;

    public coupon?: Coupon;

    public constructor(
      /** 機器人編號 */
      botId: number,
      /** 發送來源 */
      from: T,
      /** 訊息時間 */
      timestamp: number = Date.now(),
    ) {
      super(botId, from, timestamp);
    }
  }

  export class AccountLink extends Base<Target.Line> {
    public type = 'accountLink' as const;

    public constructor(
      /** 機器人編號 */
      botId: number,
      /** 發送來源 */
      from: Target.Line,
      /** 訊息時間 */
      timestamp: number = Date.now(),
      public nonce: string,
    ) {
      super(botId, from, timestamp);
    }
  }

  export class ReceiveCoupon<
    T extends Target.Any = Target.Any,
  > extends Base<T> {
    public type = 'receive-coupon' as const;

    public coupon?: Coupon;

    public constructor(
      /** 機器人編號 */
      botId: number,
      /** 發送來源 */
      from: T,
      /** 訊息時間 */
      timestamp: number = Date.now(),
    ) {
      super(botId, from, timestamp);
    }
  }

  // m.me 所觸發的事件
  export class MMEEvent extends Base<Target.Facebook> {
    public type = 'mme' as const;

    public constructor(
      public ref: string,
      botId: number,
      from: Target.Facebook,
      timestamp?: number,
    ) {
      super(botId, from, timestamp);
    }
  }

  // ig.me 所觸發的事件
  export class IGMEEvent extends Base<Target.Instagram> {
    public type = 'igme' as const;

    public constructor(
      public ref: string,
      botId: number,
      from: Target.Instagram,
      timestamp?: number,
    ) {
      super(botId, from, timestamp);
    }
  }

  export class Exchange<T extends Target.Any = Target.Any> extends Base<T> {
    public type = 'exchange' as const;

    public constructor(
      public exchangeId: number,
      public methodId: number,
      public ticketCodeId: number,
      public couponRecordId: number,
      botId: number,
      from: T,
      timestamp?: number,
    ) {
      super(botId, from, timestamp);
    }
  }

  export class Http<T extends Target.Any = Target.Any> extends Base<T> {
    public constructor(
      public request: HttpEventRequest,
      botId: number,
      from: T,
      timestamp?: number,
    ) {
      super(botId, from, timestamp);
    }
  }

  export class Customize<T extends Target.Any = Target.Any> extends Http<T> {
    public type = 'customize' as const;

    public constructor(
      public path: string,
      public request: HttpEventRequest,
      botId: number,
      from: T,
      timestamp?: number,
    ) {
      super(request, botId, from, timestamp);
    }
  }

  export class SurveyCake<T extends Target.Any = Target.Any> extends Http<T> {
    public type = 'surveyCake' as const;

    public constructor(
      public svid: string,
      public hash: string,
      public request: HttpEventRequest,
      botId: number,
      from: T,
      timestamp?: number,
    ) {
      super(request, botId, from, timestamp);
    }
  }

  export class AddThroughPromotion extends Base<Target.Line> {
    public type = 'add-through-promotion' as const;

    public constructor(
      public promotionChannelId: number,
      public inviterProfileId: number,
      public subPromotionId: number,
      botId: number,
      from: Target.Line,
      timestamp?: number,
    ) {
      super(botId, from, timestamp);
    }
  }

  export class SuccessInviteFriendThroughPromotion extends Base<Target.Line> {
    public type = 'success-invite-friend-through-promotion' as const;

    public constructor(
      public promotionChannelId: number,
      public beInvitedProfileId: number,
      botId: number,
      from: Target.Line,
      timestamp?: number,
    ) {
      super(botId, from, timestamp);
    }
  }
}

export type HttpEventRequest = {
  method: string;
  protocol: string;
  hostname: string;
  get: any;
  post: any;
  headers: any;
  cookies: any;
  originalUrl: string;
};
