import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { camelCase } from '@ay/util';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private cache: { [key: string]: Promise<string> } = {};

  public constructor(public httpClient: HttpClient) {}

  private async _translate(ch: string) {
    const key = environment.googleTranslateAPIKey;
    const name = encodeURI(ch);
    const res = await firstValueFrom(
      this.httpClient.get<{
        data: { translations: { translatedText: string }[] };
      }>(
        `https://translation.googleapis.com/language/translate/v2/?q=${name}&target=en&key=${key}`,
      ),
    );

    if (res.data.translations.length === 0) return '';
    return res.data.translations[0].translatedText;
  }

  public async translate(ch: string) {
    if (this.cache[ch] === undefined) {
      this.cache[ch] = this._translate(ch);
    }

    return this.cache[ch];
  }

  public async translateKey(ch: string) {
    return camelCase(await this.translate(ch));
  }
}
