import { Injectable } from '@angular/core';
import { DirectusCollectionDto, DirectusModel } from '@ay-gosu/server-shared';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class DirectusService {
  public _cache: Promise<DirectusCollectionDto[]>;

  public constructor(public tokenService: TokenService) {}

  private clearCacheOnCompanyChanged = this.tokenService.account$
    .pipe(
      map((account) => account.companyId),
      distinctUntilChanged(),
    )
    .subscribe((e) => {
      this._cache = null;
    });

  public async fetchCollections(force = false) {
    if (!this._cache || force) {
      this._cache = DirectusModel.fetchCollection();
    }

    return this._cache;
  }
}
