import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { map } from 'rxjs';
import { GosuValidatorComponent } from '../../../../components/gosu-validator/gosu-validator.component';
import { ProgrammableComponent } from '../../../../components/programmable/programmable.component';
import { TagTreePickerComponent } from '../../../../components/tag-tree-picker/tag-tree-picker.component';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../../material/tooltip/tooltip';
import { PropertyConfigService } from '../../../../service/property-config.service';
import { TagService } from '../../../../service/tag.service';
import { FlowService } from '../../flow.service';
import { FormComponent } from '../form.component';
import { FindProfileNode, ProfileType } from './class';

@Component({
  selector: 'flow-find-profile-form',
  templateUrl: './form.component.html',
  styleUrls: ['form.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatTooltip,
    FlexModule,
    NgFor,
    MatCheckbox,
    MatDivider,
    ProgrammableComponent,
    GosuValidatorComponent,
    TagTreePickerComponent,
    MatSelect,
    MatOption,
    NgIf,
    MatIconButton,
    MatIcon,
    MatButton,
    AsyncPipe,
    LegacyAppearanceDirective,
  ],
})
export class FindProfileFormComponent
  extends FormComponent<FindProfileNode>
  implements OnInit
{
  public profileTypes: {
    value: ProfileType;
    label: string;
    checked: boolean;
  }[] = [
    { value: 'User', label: $localize`使用者`, checked: false },
    { value: 'Group', label: $localize`群組`, checked: false },
    { value: 'Room', label: $localize`聊天室`, checked: false },
  ];

  public noneExistTag = $localize`此標籤已刪除`;

  public deletedTagNames: number[];

  public deletedTagsName$ = this._tagService.profile.showAllWithDeleted.list$
    .pipe(
      map((tags) => tags.filter((tag) => tag.deletedAt).map((tag) => tag.id)),
    )
    .subscribe((tags) => (this.deletedTagNames = tags));

  public configs$ = this._propertyConfigService.profile$;

  public constructor(
    public flow: FlowService,
    private readonly _tagService: TagService,
    private _propertyConfigService: PropertyConfigService,
    public elementRef: ElementRef,
  ) {
    super(elementRef);
  }

  public ngOnInit() {
    this.node.profileType.map((type) => {
      const t = this.profileTypes.find((_type) => _type.value === type);
      if (t === null) return;
      t.checked = true;
    });
  }

  public addProperty() {
    this.node.property.push({
      key: '',
      type: 'string',
      value: '',
    });
  }

  public afterProfileTypesChanged() {
    this.node.profileType = this.profileTypes
      .filter((type) => type.checked)
      .map((type) => type.value);
  }

  public removeProperty(idx: number) {
    this.node.property.splice(idx, 1);
  }
}
