<div class="header" i18n>
  <mat-icon>{{ 'coupon' | messageIcon }}</mat-icon>
  編輯優惠券統計細節
</div>

<div class="container fw">
  <div class="desc" i18n>
    明確的紀錄優惠券取得方式，GOSU 將協助你更精準的描繪使用者輪廓
  </div>
  <mat-form-field legacyAppearance>
    <mat-label i18n>取得方式</mat-label>
    <mat-select [(ngModel)]="message.content.viaType" i18n>
      <mat-option value="flow">透過服務流程、商業邏輯取得優惠券</mat-option>
      <mat-option value="command">透過輸入關鍵字取得優惠券</mat-option>
      <mat-option value="share">透過好友分享取得優惠券</mat-option>
      <mat-option value="qrcode">透過掃描 QR Code 取得優惠券</mat-option>
      <mat-option value="broadcast">群發優惠券訊息</mat-option>
      <!-- <mat-option value="privateMessage">透過 1:1 私訊個別發送優惠券</mat-option> -->
    </mat-select>
    <mat-hint i18n>
      若無適合選項，請選擇『透過服務流程、商業邏輯取得優惠券』
    </mat-hint>
  </mat-form-field>
  <mat-form-field legacyAppearance>
    <mat-label i18n>分享人編號</mat-label>
    <input matInput [(ngModel)]="message.content.shareProfileId" />
    <mat-hint i18n>
      可程式化輸入框，用於記錄優惠券的分享擴散關係，找出你的意見領袖
    </mat-hint>
  </mat-form-field>

  <mat-form-field
    *ngIf="['qrcode', 'flow'].includes(message.content.viaType)"
    legacyAppearance
  >
    <mat-label i18n>額外資訊</mat-label>
    <input matInput [(ngModel)]="message.content.info" />
    <mat-hint i18n>
      可程式化輸入框，用於紀錄優惠券取得方式是透過何種服務流程、放置何處的 QR
      Code 等資訊
    </mat-hint>
  </mat-form-field>
</div>

<div class="footer">
  <button mat-raised-button color="primary" (click)="close()" i18n>
    <mat-icon>done</mat-icon>
    確定
  </button>
</div>
