import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  MatFormField,
  MatFormFieldControl,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { isArray } from 'lodash';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LegacyAppearanceDirective } from '../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../material/tooltip/tooltip';
import { ElementRefDirective } from '../element-ref.directive';
import { EditorComponent } from '../ngx-monaco-editor/editor.component';
import { MonacoDialog } from './monaco-dialog/monaco-dialog.component';

// 參考：https://www.notion.so/anyong-fintech/09cf63f358d046c0aeda3e60cf3572bb?pvs=4
@Component({
  selector: 'programmable',
  templateUrl: './programmable.component.html',
  styleUrls: ['./programmable.component.scss'],
  standalone: true,
  imports: [
    MatIconButton,
    MatTooltip,
    MatIcon,
    MatButton,
    ElementRefDirective,
    MatFormField,
    MatInput,
    MatLabel,
    MatSuffix,
    LegacyAppearanceDirective,
    EditorComponent,
  ],
  host: {
    class: 'flex flex-row !items-center',
  },
})
export class ProgrammableComponent implements AfterViewInit, OnDestroy {
  // 如果有值，使用該值，如果沒有，從 mat-label 或 form-control 的 placeholder 取得的 title
  @Input()
  public title: string = null;

  // valueType (資料型態)
  @Input()
  public dataType: 'string' | 'number' | 'number[]' | 'string[]' = 'string';

  // value 資料內容
  private _value: string | number | (number | string)[] = '';

  @Output()
  public valueChange = new EventEmitter();

  public preview: string;

  @Input()
  public set value(value: string | number | (number | string)[]) {
    if (value === undefined || value === null) {
      value = '';
    }

    if (value === this._value) {
      return;
    }

    this._value = value;
    this._withCode = checkValueWithCode(value);

    // 如果 value 是多行，就顯示 "點擊編輯程式碼"，否則就顯示 程式碼內容
    if (value.toString().trim().split('\n').length > 1) {
      this.preview = '點擊編輯程式碼';
    } else {
      this.preview = this._valueToCode(value).toString();
    }

    this.valueChange.emit(value);
  }

  public get value() {
    return this._value;
  }

  // disabled (是否禁用)
  @Input()
  public disabled: boolean = false;

  // withCode (資料中是否含有程式碼？)
  private _withCode = false;

  public get withCode() {
    return this._withCode;
  }

  // 內層的 MatFormFieldControl 控制項， 例如 mat-input / mat-select...
  @ContentChild(MatFormFieldControl, { static: false })
  public control: MatFormFieldControl<any>;

  // 內層的 <mat-label>
  @ContentChild(MatLabel, { static: false, read: ElementRef })
  public matLabel: ElementRef<HTMLElement>;

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly _matConnectedDialog: MatConnectedDialog,
  ) {}

  public ngAfterViewInit(): void {
    this._updateDisabledAndTitleFromControl();
    this._updateTitleFromLabel();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public async edit(elementRef: ElementRef) {
    let code = this._valueToCode(this.value);

    const dialogRef = this._matConnectedDialog.open(MonacoDialog, {
      elementRef,
      offsetY: 16,
      data: { code, title: this.title, dataType: this.dataType },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    // 取消、Ｘ、點擊是窗外關閉
    if (result === undefined) return;

    this.value = this._codeToValue(result);
  }

  private _valueToCode(value: string | number | (number | string)[]): string {
    const strValue = value.toString().trim();
    if (strValue === '') {
      return '';
    }

    const inner = strValue.substring(1, strValue.length - 1);

    switch (this.dataType) {
      case 'string':
        // 純程式碼，例如: tmp.a，在程式碼編輯器中會顯示 tmp.a，但實際儲存的是 ${tmp.a}
        // 字串和程式碼混合(相加)，例如: "123" + tmp.a，在程式碼編輯器中會顯示 "123" + tmp.a，但實際儲存的是 ${"123" + tmp.a}
        if (strValue.startsWith('${') && strValue.endsWith('}')) {
          return strValue.substring(2, strValue.length - 1);
        }

        // 字串和程式碼混合(範本字串)，例如: 123${tmp.a}456，在程式碼編輯器中會顯示 `123${tmp.a}456`，實際儲存的也是 123${tmp.a}456
        if (inner.includes('${')) {
          return '`' + strValue + '`';
        }

        // 完整的字串，例如 TEST 就直接回傳 "TEST"
        return `"${strValue}"`;

      case 'number':
        // 純數字，沒有程式碼
        if (!isNaN(parseFloat(strValue))) {
          return strValue;
        }

        // 純程式碼：在程式碼編輯器中會顯示 tmp.a，但實際儲存的是 ${tmp.a}
        // 數字和程式碼混合(運算)：在程式碼編輯器中會顯示 1 + tmp.a，但實際儲存的是 ${1 + tmp.a}
        if (strValue.startsWith('${') && strValue.endsWith('}')) {
          return strValue.substring(2, strValue.length - 1);
        }

        return strValue;

      case 'number[]':
        // 1,2 => [1, 2]
        // 1,${tmp.q} => [1, tmp.q]
        if (!isArray(value)) {
          value = [value];
        }

        return (
          '[' +
          value
            .map((row) => {
              if (typeof row === 'number') {
                return row;
              }

              // 1,${tmp.q} => [1,tmp.q]
              if (row.startsWith('${') && row.endsWith('}')) {
                return row.substring(2, row.length - 1);
              }
            })
            .join(', ') +
          ']'
        );

      case 'string[]':
        if (!isArray(value)) {
          value = [value];
        }

        return (
          '[' +
          value
            .map((row) => {
              if (typeof row === 'number') {
                return row;
              }

              // 1,${tmp.q} => [1,tmp.q]
              if (row.startsWith('${') && row.endsWith('}')) {
                return row.substring(2, row.length - 1);
              }

              return `"${row}"`;
            })
            .join(', ') +
          ']'
        );
    }

    return strValue;
  }

  private _codeToValue(code: string): string | number | (number | string)[] {
    code = code.trim();
    if (code === '') {
      return '';
    }

    const start = code[0];
    const end = code[code.length - 1];
    const inner = code.substring(1, code.length - 1);
    const length = code.length;

    switch (this.dataType) {
      case 'string':
        // 完整的字串，例如: "TEST" 或 'TEST'，並且中間沒有連接符號，就直接回傳 中間的內容 TEST
        if (
          length > 2 &&
          ((start === "'" && end === "'" && !inner.includes("'")) ||
            (start === '"' && end === '"' && !inner.includes('"')))
        ) {
          return inner;
        }

        // - 字串和程式碼混合(文字範本)，在程式碼編輯器中會顯示 `123${tmp.a}456`，但實際儲存的是 123${tmp.a}456
        if (start === '`' && end === '`') {
          return inner;
        }

        // 純數字，例如: 123，就直接回傳 123
        if (parseFloat(code).toString() === code) {
          return code.toString();
        }

        return '${' + code + '}';

      case 'number':
        // 數字，例如: 123 就直接回傳 123
        if (parseFloat(code).toString() === code) {
          return parseFloat(code);
        }

        return '${' + code + '}';

      case 'number[]':
        if (code.startsWith('[') && code.endsWith(']')) {
          code = code.substring(1, code.length - 1);
        }

        return code
          .split(',')
          .map((item) => item.trim())
          .map(this._parseNumberIfPossible);

      case 'string[]':
        if (code.startsWith('[') && code.endsWith(']')) {
          code = code.substring(1, code.length - 1);
        }

        return code
          .split(',')
          .map((item) => item.trim())
          .map((item) => {
            if (start === '"' && end === '"') {
              return item.substring(2, item.length - 1);
            } else if (start === "'" && end === "'") {
              return item.substring(2, item.length - 1);
            } else if (parseFloat(item).toString() === item) {
              return parseFloat(item);
            } else {
              return '${' + item + '}';
            }
          });
    }
  }

  // 如果是數字，就轉換成數字，否則就回傳 ${item} (程式碼模式)
  private _parseNumberIfPossible(item: string): string | number {
    if (parseFloat(item).toString() === item) {
      return parseFloat(item);
    }

    return '${' + item + '}';
  }

  public clear() {
    this.value = '';
  }

  private _updateTitleFromLabel() {
    if (!this.matLabel) return;
    setTimeout(() => {
      this.title = this.title || this.matLabel.nativeElement.innerHTML;
    }, 1);
  }

  private _updateDisabledAndTitleFromControl() {
    if (!this.control) return;

    this.control.stateChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      // 如果有 label 就取 label 的 innerHTML 作為 title，否則取 placeholder
      if (!this.matLabel) {
        this.title = this.title || this.control.placeholder;
      }

      // 根據外層的 control 來設定是否為 disabled
      this.disabled = this.control.disabled;
    });
  }
}

export function checkValueWithCode(
  value: string | number | (string | number)[],
): boolean {
  if (!isArray(value)) {
    return valueWithCode(value);
  } else if (isArray(value) && value.length > 0) {
    return anyItemValueWithCode(value);
  }

  return false;
}

function anyItemValueWithCode(values: (string | number)[]): boolean {
  return values.filter((value) => valueWithCode(value)).length > 0;
}

function valueWithCode(value: string | number): boolean {
  return (
    typeof value === 'string' && value.includes('${') && value.includes('}')
  );
}
