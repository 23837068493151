import { CustomizeCardRecordContent } from '../card.type';
import { CustomizeButton } from './button.class';
import { CustomizeField } from './field.class';

export class CustomizeCard {
  public image = new CustomizeField();
  public title = new CustomizeField();
  public content = new CustomizeField();
  public buttons = [
    new CustomizeButton(),
    new CustomizeButton(),
    new CustomizeButton(),
    new CustomizeButton(),
  ];

  public toJSON() {
    return {
      image: this.image.toJSON(),
      title: this.title.toJSON(),
      content: this.content.toJSON(),
      buttons: this.buttons.map((button) => button.toJSON()),
    };
  }

  public static fromJson(json: CustomizeCardRecordContent) {
    const customize = new CustomizeCard();

    customize.image.fromJson(json.image);
    customize.title.fromJson(json.title);
    customize.content.fromJson(json.content);
    if (json.buttons) {
      customize.buttons.map((button, index) =>
        button.fromJson(json.buttons[index]),
      );
    }

    return customize;
  }
}
