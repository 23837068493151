import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { BypassSecurityService } from '../../../service/bypass-security.service';
import { StaticService } from '../../../service/static.service';
import { ProgressBarComponent } from '../../../components/progress-bar/progress-bar.component';
import { MatButton } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'dl-image-uploader',
    templateUrl: './uploader.dialog.html',
    styleUrls: ['./uploader.dialog.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatButton,
        ProgressBarComponent,
        AsyncPipe,
    ],
})
export class ImageUploaderDialog implements AfterViewInit, OnDestroy {
  public state: 'init' | 'uploading' | 'uploaded' = 'init';

  public safeUrl: SafeUrl;

  public progress$: Subject<number>;

  //#region file
  private _file: File = null;

  public file$ = new BehaviorSubject<File>(null);

  public get file(): File {
    return this._file;
  }

  public set file(file: File) {
    if (this._file === file) return;
    this._file = file;
    this.file$.next(this._file);
  }
  //#endregion file

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly _staticService: StaticService,
    private readonly _matDialogRef: MatDialogRef<ImageUploaderDialog>,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _bypassSecurityService: BypassSecurityService,
  ) {}

  public ngAfterViewInit(): void {
    this._loadFileToImage();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public async cancel() {
    this._matDialogRef.close();
  }

  public async submit() {
    this.state = 'uploading';
    let upload = await this._staticService.uploadFile(this._file);
    this.progress$ = upload.progress;
    this._changeDetectorRef.markForCheck();
    let response = await firstValueFrom(
      upload.uploadSubject.pipe(first((event) => event?.status == 'all-done')),
    );

    this._matDialogRef.close(response.result);
  }

  private _loadFileToImage() {
    this.file$
      .pipe(
        takeUntil(this._destroy$),
        filter((file) => !!file),
      )
      .subscribe((file) => {
        let reader = new FileReader();
        reader.onload = (event) => this._onFileReaderLoad(reader);
        reader.readAsDataURL(this.file);
      });
  }

  private _onFileReaderLoad(reader: FileReader) {
    if (reader.readyState === FileReader.DONE) {
      this._onFileReaderLoadDone(reader);
    }
  }

  private _onFileReaderLoadDone(reader: FileReader) {
    this.safeUrl = this._bypassSecurityService.bypassSecurityUrl(
      reader.result as string,
      [BypassSecurityService.isBypassImageFile],
    ) as SafeUrl;
    this._changeDetectorRef.markForCheck();
  }
}
