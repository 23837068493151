import { MatPaginatorIntl } from '@angular/material/paginator';

const dutchRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) {
    return $localize`沒有資料`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  const endIndex =
    startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

  return $localize`資料總數: ${length}, 本頁範圍: ${
    startIndex + 1
  } ~ ${endIndex}`;
};

export function getDutchPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = $localize`每頁資料筆數`;
  paginatorIntl.nextPageLabel = $localize`下一頁`;
  paginatorIntl.previousPageLabel = $localize`前一頁`;
  paginatorIntl.getRangeLabel = dutchRangeLabel;

  return paginatorIntl;
}
