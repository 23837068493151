import { FlagRule } from '../flag-rule.class';
import { FromJsonOption } from '../rule';

export class DraftRule extends FlagRule {
  public keywords: string[] = [$localize`草稿`];

  public constructor() {
    super('DRAFT');
  }

  public toString() {
    return $localize`草稿`;
  }

  public static schema: string = 'draft';

  public static async fromShortCode(code: string, option: FromJsonOption) {
    return new DraftRule();
  }
}
