<div class="container">
  <ng-container *ngIf="status === 'upload'">
    <gosu-progress-bar [value]="progress$ | async" i18n>
      錄音檔上傳中，請稍候
    </gosu-progress-bar>
  </ng-container>

  <ng-container *ngIf="status === 'recording' || status === 'init'">
    <div class="bars">
      <div
        *ngFor="let bar of (chart$ | async)"
        [style.height.px]="bar * 200"
        class="bar"
      ></div>
    </div>
  </ng-container>

  <ng-container *ngIf="status === 'recorded'">
    <audio #player controls></audio>
  </ng-container>

  <div class="button-wrap">
    <button
      mat-raised-button
      *ngIf="status === 'init'"
      (click)="start()"
      color="primary"
      i18n
    >
      開始錄音
    </button>

    <button
      mat-raised-button
      *ngIf="status === 'recording'"
      (click)="stop()"
      color="primary"
      i18n
    >
      停止錄音
    </button>

    <ng-container *ngIf="status === 'recorded'">
      <button mat-raised-button (click)="upload()" color="primary" i18n>
        確定上傳
      </button>
      <button mat-raised-button (click)="restart()" i18n>重新錄音</button>
    </ng-container>
  </div>
</div>
