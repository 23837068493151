export class CustomizeField {
  public enable: boolean = false;
  public value: string = '';

  public toJSON() {
    if (!this.enable) {
      return undefined;
    }

    return this.value;
  }

  public fromJson(value: string) {
    if (!value) return;
    this.enable = true;
    this.value = value;
  }
}
