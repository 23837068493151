import { Content } from '@ay/bot';
import { Message } from '../base/base.message';

export class AudioMessage extends Message<Content.Audio> {
  public type = 'audio';

  public constructor() {
    super();
    this.content = new Content.Audio('');
  }

  public toString(): string {
    return this.content.alt || $localize`一段聲音`;
  }
}
