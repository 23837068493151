@if (enableMonaco$ | async) {
  @if (isLoading) {
    <gosu-loading class="absolute top-0 left-0 w-full h-full" i18n>
      程式碼編輯器載入中，請稍候
    </gosu-loading>
  }

  <ngx-monaco-editor
    class="h-calc(100vh-350px) p-0"
    [options]="editorOptions"
    [(ngModel)]="node.body"
    (init)="onMonacoEditorInit($event)"
  >
  </ngx-monaco-editor>
} @else {
  <textarea
    class="code-space text-primary font-mono p-6 w-full border-none bg-code leading-6"
    [(ngModel)]="node.body"
  ></textarea>
}
