<div class="LySlider">
  <div class="lyInner">
    <flex-bubble
      class="lyItem"
      *ngFor="let content of data.contents"
      [data]="content"
      [bubble]="$any(content)"
      [flex]="flex"
      [class.active]="content.isSelect"
      (click)="
        $event.stopPropagation(); flexEditorService.selected$.next(content)
      "
    >
    </flex-bubble>
  </div>
</div>
