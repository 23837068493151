import { Content } from '@ay/bot';
import { Observable } from 'rxjs';
import { Message } from '../base/base.message';
import { Package } from '../package.class';

type Mode = 'CLICK' | 'UPLOAD_FILE' | 'SUBMENU';

export class MessageToolbar {
  public enable = true;

  public label: string;
  public icon: string;
  public type: string;

  public mode: Mode;

  // mode click
  public onClick?: (pkg: Package) => Promise<Message<Content.Any>>;

  // mode upload-file
  public accept?: string;
  public tooltip?: string;
  public onUpload?: (file: FileList) => Promise<Message>;

  // mode submenu
  public submenu?: (UploadFileItem | ClickItem)[];

  public constructor(
    mode: Mode,
    option: { type: string; label: string; icon: string },
  ) {
    this.mode = mode;
    this.type = option.type;
    this.label = option.label;
    this.icon = option.icon;
  }
}

export class ClickItem extends MessageToolbar {
  public constructor(option: {
    type: string;
    label: string;
    icon: string;
    onClick: (pkg: Package) => Promise<Message<Content.Any>>;
    enable$?: Observable<boolean>;
    tooltip?: string;
  }) {
    super('CLICK', option);
    this.onClick = option.onClick;
    this.tooltip = option.tooltip;

    if (option.enable$) {
      option.enable$.subscribe((enable) => {
        this.enable = enable;
      });
    }
  }
}

export class UploadFileItem extends MessageToolbar {
  public constructor(option: {
    type: string;
    label: string;
    icon: string;
    accept: string;
    onUpload: (file: FileList) => Promise<Message>;
    tooltip?: string;
  }) {
    super('UPLOAD_FILE', option);
    this.accept = option.accept;
    this.tooltip = option.tooltip;
    this.onUpload = option.onUpload;
  }
}

export class SubmenuItem extends MessageToolbar {
  public constructor(option: {
    type: string;
    label: string;
    icon: string;
    submenu: (UploadFileItem | ClickItem)[];
    tooltip?: string;
  }) {
    super('SUBMENU', option);
    this.submenu = option.submenu;
    this.tooltip = option.tooltip;
  }
}
