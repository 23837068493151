<div class="header">
  <h3 i18n>新增屬性</h3>
</div>

<div class="container">
  <div>
    <mat-form-field legacyAppearance>
      <mat-label i18n>應用對象</mat-label>
      <mat-select [formControl]="targetTypeControl" i18n>
        <mat-option value="profile">好友</mat-option>
        <mat-option value="bot">機器人</mat-option>
        <mat-option value="company">組織</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field legacyAppearance>
      <mat-label i18n>名稱</mat-label>
      <input matInput [formControl]="nameControl" required />
      <mat-error
        *ngIf="keyControl.errors && keyControl.errors['required']"
        i18n
      >
        必填項目
      </mat-error>
    </mat-form-field>

    <mat-form-field legacyAppearance>
      <mat-label i18n>變數名稱</mat-label>
      <input matInput [formControl]="keyControl" />
      <button
        mat-icon-button
        matSuffix
        [disabled]="
          nameControl.value === lastTranslatedName || nameControl.value === ''
        "
        (click)="translateKey()"
      >
        <mat-icon>g_translate</mat-icon>
      </button>
      <mat-error
        *ngIf="keyControl.errors && keyControl.errors['required']"
        i18n
      >
        必填項目
      </mat-error>

      <mat-error
        *ngIf="keyControl.errors && keyControl.errors['duplicateKey']"
        i18n
      >
        該變數已經使用過
      </mat-error>

      <mat-error
        *ngIf="
          keyControl.errors && keyControl.errors['onlyAlphaOrNumberOrUnderline']
        "
        i18n
      >
        只能由英文/數字或下底線組成
      </mat-error>

      <mat-error
        *ngIf="keyControl.errors && keyControl.errors['startWithoutNumber']"
        i18n
      >
        開頭不能是數字
      </mat-error>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field legacyAppearance>
      <mat-label i18n>型態</mat-label>
      <mat-select [formControl]="dataTypeControl">
        <mat-option value="string" i18n>文字</mat-option>
        <mat-option value="number" i18n>數字</mat-option>
        <mat-option value="list" i18n>列表</mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="targetTypeControl.value === 'profile'">
      <mat-checkbox
        [(ngModel)]="displayInList"
        value="true"
        color="primary"
        i18n
      >
        顯示於列表
      </mat-checkbox>

      <mat-checkbox
        [(ngModel)]="displayInDetail"
        value="true"
        color="primary"
        i18n
      >
        顯示詳細資料
      </mat-checkbox>
    </ng-container>
  </div>
</div>

<div class="footer tar">
  <button
    mat-button
    color="primary"
    (click)="submit()"
    [disabled]="group.invalid"
    i18n
  >
    新增
  </button>
</div>
