import Bluebird from 'bluebird';

const cache: Record<string, Promise<string>> = {};

export async function loadDefaultDefinitelyFromCdn(ts: any) {
  const urls = [
    'https://cdn.jsdelivr.net/npm/@types/lodash@4.17/index.d.ts',
    'https://cdn.jsdelivr.net/npm/@types/numeral@2.0/index.d.ts',
    {
      url: 'https://cdn.jsdelivr.net/npm/@types/node@20/crypto.d.ts',
      suffix: 'declare const nodeCrypto: typeof import("crypto");',
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/moment@2.30/moment.d.ts',
      suffix: 'export as namespace moment;',
    },
  ];

  for (const url of urls) {
    await loadDefinitelyFromCdn(ts, url).catch((error) => {
      console.error('載入 DefinitelyTyped 發生異常', error);
    });
    console.log('載入 DefinitelyTyped 成功', url);
  }
}

export async function loadDefinitelyFromCdn(
  ts: any,
  option: string | { postfix?: string; url: string; suffix?: string },
) {
  let url = '';
  let postfix = '';
  let suffix = '';
  if (option instanceof Object) {
    url = option.url;
    suffix = option.suffix ?? '';
    postfix = option.postfix ?? '';
  } else {
    url = option;
  }
  if (!cache[url]) {
    cache[url] = fetch(url).then((res) =>
      res
        .text()
        .then(
          (text) =>
            (postfix ? postfix + '\n' : '') +
            text +
            (suffix ? '\n' + suffix : ''),
        ),
    );
  }

  const source = await cache[url];

  ts.addExtraLib(source, url);

  // parse /// <reference path="*****" />
  await Bluebird.map(
    source.split('\n'),
    (line) => parseTypeScriptTripleSlash(line, url, ts),
    { concurrency: 3 },
  );
}

export async function parseTypeScriptTripleSlash(
  line: string,
  url: string,
  ts: any,
) {
  if (!line.startsWith('///')) return;
  const command = line.substring(3); // 去掉 ///
  const parser = new DOMParser();
  const doc = parser.parseFromString(command, 'application/xml');

  for (const child of Array.from(doc.children)) {
    if (child.tagName.toLocaleLowerCase() !== 'reference') return;
    const filename = child.getAttribute('path');
    const childUrl = new URL(filename, url);
    await loadDefinitelyFromCdn(ts, childUrl.toString()).catch((error) => {
      console.error('載入 DefinitelyTyped 發生異常', error);
    });
  }
}
