import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import cloneDeep from 'lodash/cloneDeep';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';
import { RegularizedFlex } from '../../../regularized-flex.class';
import { FlexChinesePipe } from '../../chinese.pipe';
import { FlexChineseService } from '../../chinese.service';
import { FlexEditorClipBoardService } from '../../clipboard.service';
import { CHILD_TYPES, FlexEditorService } from '../../editor.service';
import { ERROR_MESSAGE } from '../../error';
import { NEW_CONTENT } from './new-content';

@Component({
  selector: 'tree-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatIconButton,
    NgSwitch,
    NgSwitchCase,
    MatIcon,
    NgSwitchDefault,
    MatMenuTrigger,
    MatMenu,
    NgFor,
    MatMenuItem,
    CdkDropList,
    CdkDrag,
    FlexChinesePipe,
  ],
})
export class FlexTreeContentComponent implements OnDestroy, AfterViewInit {
  @Input()
  public data: RegularizedFlex;

  @ViewChild(CdkDropList)
  public cdkDropList: CdkDropList;

  public get canDrop() {
    if (!this.flexEditorService.cdkDragData) {
      return;
    }

    return (
      CHILD_TYPES[this.data.type] &&
      CHILD_TYPES[this.data.type].includes(
        this.flexEditorService.cdkDragData.data.type,
      )
    );
  }

  public get hasError(): string {
    if (!this.data) {
      return null;
    }

    if (this.contentError) {
      return this.contentError;
    }

    if (this.positionError) {
      return this.positionError;
    }

    let errFunction = ERROR_MESSAGE[this.data.type];
    if (!errFunction) {
      return null;
    }

    let errorMessage = errFunction(this.data);
    if (!errorMessage) {
      return;
    }

    let message = Object.keys(errorMessage).find((val) => errorMessage[val]);
    if (!message) {
      return;
    }

    return this._flexChineseService.transform(message) + $localize`發生錯誤`;
  }

  protected get contentError() {
    let isEmpty = false;
    let overContent = false;
    let msg = '';

    switch (true) {
      case this.data.type == 'box':
        let isRightContents = this.data.contents.every((data) =>
          this.flexEditorService.findChildTypes(this.data).includes(data.type),
        );
        if (!isRightContents) msg = $localize`內容有不允許的物件`;
        break;

      case this.data.type == 'bubble':
        isEmpty = this.data.contents.every((data) => !data.contents?.length);
        if (isEmpty) msg = $localize`至少要有一個項目有內容`;
        break;

      case this.data.type == 'carousel':
        isEmpty = this.data.contents.every((data) => !data.contents?.length);
        if (isEmpty) msg = $localize`內容至少要有一則訊息`;
        overContent = this.data.contents.length > 12;
        if (overContent) msg = $localize`內容不可超過12則訊息`;
        break;

      case CHILD_TYPES.bubble.includes(this.data.type):
        overContent = this.data.contents.length > 1;
        if (overContent) msg = $localize`最多只能放置一項內容`;
        break;
    }
    return msg;
  }

  protected get positionError() {
    let isBubbleChild = CHILD_TYPES.bubble.includes(this.data.type);
    let child = this.data?.contents ? this.data.contents[0] : null;
    if (!isBubbleChild || !child) return;
    let isAbs = child.position == 'absolute' && child.type == 'box';
    if (isAbs) return $localize`第一層容器不可使用絕對定位`;
  }

  private readonly _destroy$ = new Subject();

  private readonly _updateIsSelectedWhenSelectedUpdate =
    this.flexEditorService.selected$
      .pipe(
        delay(1),
        filter(() => !!this.data),
        takeUntil(this._destroy$),
      )
      .subscribe((selected) => (this.data.isSelect = selected == this.data));

  public constructor(
    public readonly flexEditorService: FlexEditorService,
    private readonly _flexClipBoardService: FlexEditorClipBoardService,
    private readonly _flexChineseService: FlexChineseService,
  ) {}

  public ngAfterViewInit(): void {
    this.flexEditorService.cdkDropLists.unshift(this.cdkDropList);
    this.flexEditorService.updateCdkDropList$.pipe(take(1)).subscribe(() => {
      this.flexEditorService.cdkDropLists.unshift(this.cdkDropList);
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.complete();
  }

  public addNew(type: string) {
    this._flexClipBoardService.addContent(cloneDeep(NEW_CONTENT[type]) as any);
  }

  public drop(event: CdkDragDrop<RegularizedFlex[]>) {
    let noChange =
      event.previousContainer === event.container &&
      event.previousIndex === event.currentIndex;
    if (noChange) return;

    this.flexEditorService.beforeChange$.next();
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.flexEditorService.selected$.next(
      event.container.data[event.currentIndex],
    );
    this.flexEditorService.afterChange$.next();
    this.flexEditorService.updateCdkDropList$.next();
  }

  public typePredicate() {
    let canDrop = this.canDrop;
    let target = this.flexEditorService.cdkDropTarget;
    return function (drag: CdkDrag, drop: CdkDropList) {
      if (!drag.data) return;
      if (!drop.data || !drop.data.length) return canDrop;
      if (target == drop) return canDrop;
      return;
    };
  }
}
