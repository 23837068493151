import { Position } from '../flow.util';
import { JunctionComponent } from '../junction/junction.component';
import { ConnectionComponent } from './connection.component';

export class Connection {
  public component: ConnectionComponent;
  public constructor(
    public start?: JunctionComponent,
    public end?: JunctionComponent,
    public color?: string,
  ) {}
}

export class BlueprintConnection extends Connection implements Position {
  public x?: number;
  public y?: number;
}
