<ng-container *ngFor="let content of $any(data.contents); index as idx">
  <ng-container [ngSwitch]="content['type']">
    <flex-box
      *ngSwitchCase="'box'"
      [data]="$any(content)"
      [bubble]="bubble"
      [flex]="flex"
      [level]="level + 1"
      [layout]="data.layout"
      [index]="idx"
      [class.active]="$any(content).isSelect"
      (click)="
        $event.stopPropagation();
        flexEditorService.selected$.next($any(content))
      "
    >
    </flex-box>
    <flex-text
      *ngSwitchCase="'text'"
      [data]="$any(content)"
      [bubble]="bubble"
      [flex]="flex"
      [level]="level + 1"
      [layout]="data.layout"
      [index]="idx"
      [class.active]="$any(content).isSelect"
      (click)="
        $event.stopPropagation();
        flexEditorService.selected$.next($any(content))
      "
    >
    </flex-text>
    <flex-filler
      *ngSwitchCase="'filler'"
      [data]="$any(content)"
      [bubble]="bubble"
      [flex]="flex"
      [level]="level + 1"
      [class.active]="$any(content).isSelect"
      (click)="
        $event.stopPropagation();
        flexEditorService.selected$.next($any(content))
      "
    >
    </flex-filler>
    <flex-image
      *ngSwitchCase="'image'"
      [data]="$any(content)"
      [bubble]="bubble"
      [flex]="flex"
      [level]="level + 1"
      [layout]="data.layout"
      [index]="idx"
      [class.active]="$any(content).isSelect"
      (click)="
        $event.stopPropagation();
        flexEditorService.selected$.next($any(content))
      "
    >
    </flex-image>
    <flex-icon
      *ngSwitchCase="'icon'"
      [data]="$any(content)"
      [bubble]="bubble"
      [flex]="flex"
      [level]="level + 1"
      [layout]="data.layout"
      [index]="idx"
      [class.active]="$any(content).isSelect"
      (click)="
        $event.stopPropagation();
        flexEditorService.selected$.next($any(content))
      "
    >
    </flex-icon>
    <flex-spacer
      *ngSwitchCase="'spacer'"
      [data]="$any(content)"
      [bubble]="bubble"
      [flex]="flex"
      [level]="level + 1"
      [class.active]="$any(content).isSelect"
      (click)="
        $event.stopPropagation();
        flexEditorService.selected$.next($any(content))
      "
    >
    </flex-spacer>
    <flex-button
      *ngSwitchCase="'button'"
      [data]="$any(content)"
      [bubble]="bubble"
      [flex]="flex"
      [level]="level + 1"
      [layout]="data.layout"
      [index]="idx"
      [class.active]="$any(content).isSelect"
      (click)="
        $event.stopPropagation();
        flexEditorService.selected$.next($any(content))
      "
    >
    </flex-button>
    <flex-separator
      *ngSwitchCase="'separator'"
      [data]="$any(content)"
      [bubble]="bubble"
      [flex]="flex"
      [level]="level + 1"
      [layout]="data.layout"
      [index]="idx"
      [class.active]="$any(content).isSelect"
      (click)="
        $event.stopPropagation();
        flexEditorService.selected$.next($any(content))
      "
    >
    </flex-separator>
    <p *ngSwitchCase="false"></p>
    <p *ngSwitchDefault></p>
  </ng-container>
</ng-container>
