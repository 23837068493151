<div class="container">
  <ng-container *ngIf="state === 'init'">
    <video #player controls [src]="url$ | async"></video>
    <div class="button-wrap">
      <button mat-button class="cancel-button" (click)="cancel()" i18n>
        取消
      </button>
      <button mat-raised-button color="primary" (click)="submit()" i18n>
        確定上傳
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="state === 'uploading'">
    <gosu-progress-bar [value]="progress$ | async" i18n>
      影片上傳中，請稍候
    </gosu-progress-bar>
  </ng-container>
</div>
