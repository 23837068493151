import { Injectable } from '@angular/core';
import { CouponDto, CouponModel } from '@ay-gosu/server-shared';
import { CouponRecordViaType } from '@ay/bot';
import moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PreloadService } from './preload.service';
import { TokenService } from './token.service';

export type CouponColumnWithStatus = CouponDto & {
  status?: CouponStatusType;
  no?: string;
};

export type CouponStatusType =
  | '已結束'
  | '開放使用中'
  | '開放領取中'
  | '預約中'
  | '草稿';

export interface CouponOverviewReport {
  date?: string;
  sendCount?: number;
  useCount?: number;
}

export interface CouponViaReport {
  viaType?: CouponRecordViaType;
  viaName?: string;
  count?: number;
  percent?: number;
}

@Injectable({
  providedIn: 'root',
})
export class CouponService extends PreloadService<CouponColumnWithStatus> {
  public constructor(protected _tokenService: TokenService) {
    super(_tokenService);
  }

  public async create(data: CouponDto) {
    let item = await CouponModel.create(data);
    this.afterCreate(item.id);
    return item;
  }

  public async update(data: CouponDto): Promise<boolean> {
    let res = await CouponModel.update(data);
    this.afterUpdate(data.id);
    return res;
  }

  protected async load(): Promise<CouponColumnWithStatus[]> {
    let list: CouponColumnWithStatus[] = await CouponModel.list();

    for (let coupon of list) {
      coupon.status = this._getPublishStatus(coupon);
    }

    return list;
  }

  protected async get(id: number): Promise<CouponColumnWithStatus> {
    let coupon: CouponColumnWithStatus = await CouponModel.get(id);
    coupon.status = this._getPublishStatus(coupon);
    return coupon;
  }

  public async delete(id: number): Promise<boolean> {
    let res = await CouponModel.delete(id);
    if (res) this.afterDelete(id);
    return res;
  }

  public async outputDetail(couponId: number) {
    return CouponModel.outputDetail(couponId);
  }

  public getById(id: number): Observable<CouponColumnWithStatus> {
    return this.all$.pipe(
      filter((items) => items instanceof Array),
      map((items) => items.find((bot) => bot.id === id)),
    );
  }

  private _getPublishStatus(coupon: CouponDto): CouponStatusType {
    let now = moment();
    let publish = moment(coupon.publishAt);
    let start = moment(coupon.startAt);
    let end = moment(coupon.endAt);
    if (coupon.isDraft) {
      return '草稿';
    } else if (publish.isAfter(now)) {
      return '預約中';
    } else if (now.isBetween(publish, start)) {
      return '開放領取中';
    } else if (now.isBetween(start, end)) {
      return '開放使用中';
    } else if (end.isBefore(now)) {
      return '已結束';
    }
  }

  public async publish(couponId: number, date?: Date) {
    await CouponModel.publish(couponId, date);
    return this.afterUpdate(couponId);
  }

  public async cancelPublish(couponId: number) {
    await CouponModel.cancelPublish(couponId);
    return this.afterUpdate(couponId);
  }

  public async getCouponOverviewReport(
    couponId: number,
  ): Promise<CouponOverviewReport> {
    return CouponModel.getOverviewReport(couponId);
  }

  public async getCouponActivityReport(
    couponId: number,
  ): Promise<CouponOverviewReport[]> {
    return CouponModel.getCouponActivityReport(couponId);
  }

  public async recalculateCouponReport(couponId: number) {
    return CouponModel.recalculateCouponReport(couponId);
  }

  public couponViaTypeMap = {
    command: $localize`關鍵字觸發`,
    share: $localize`好友分享`,
    qrcode: $localize`掃描 QR Code`,
    broadcast: $localize`群發`,
    flow: $localize`透過流程`,
    privateMessage: $localize`1:1 私訊`,
  };

  public async getCouponViaReport(
    couponId: number,
    onlyUsed: boolean,
  ): Promise<any[]> {
    let data: any[] = await CouponModel.getCouponViaReport(couponId, onlyUsed);
    let total = data.reduce((prev, curr) => (prev += curr.count), 0);
    for (let d of data) {
      d.percent = Math.round((d.count / total) * 1000) / 10;
      d.viaName = this.couponViaTypeMap[d.viaType];
    }
    return data;
  }

  public async getViaTopInfo(
    couponId: number,
    viaType: CouponRecordViaType,
    onlyUsed: boolean,
  ): Promise<any> {
    return CouponModel.getCouponViaTopInfo(couponId, viaType, onlyUsed);
  }
}
