import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { Button } from '@ay/bot';
import { BaseComponent } from '../../base/base.component';
import { CardsMessage } from '../cards.message';
import { Card } from './card.class';
import { ButtonTypePipe } from '../../../pipe/button-type.pipe';
import { Nl2brPipe } from '../../../pipe/nl2br.pipe';
import { LengthPipe } from '../../../pipe/length.pipe';
import { ImageTypePipe } from '../../../pipe/imageType.pipe';
import { MatIcon } from '@angular/material/icon';
import { LoadingComponent } from '../../../components/loading/loading.component';
import { MatTooltip } from '../../../material/tooltip/tooltip';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
    selector: 'ms-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['package', 'mode', 'message'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        MatTooltip,
        NgSwitch,
        NgSwitchCase,
        NgSwitchDefault,
        LoadingComponent,
        MatIcon,
        ImageTypePipe,
        LengthPipe,
        Nl2brPipe,
        ButtonTypePipe,
    ],
})
export class CardComponent extends BaseComponent<CardsMessage> {
  public buttonIndexes = [0, 1, 2, 3];

  @Input()
  public card: Card;

  public constructor(protected readonly changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  public customize(key: 'content' | 'title' | 'image') {
    if (this.card.template && this.card.customize[key].enable) {
      return this.card.customize[key].value;
    } else {
      return this.card[key];
    }
  }

  public customizeButton(index: number): Button.Base {
    if (!this.card.buttons) {
      this.card.buttons = [];
    }

    if (this.card.template && this.card.customize.buttons[index].enable) {
      return this.card.customize.buttons[index];
    } else {
      return this.card.buttons[index];
    }
  }
}
