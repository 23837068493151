import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  ApplicationRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelActionRow,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatList, MatListItem } from '@angular/material/list';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import {
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';
import { LingtelliGroupDto } from '@ay-gosu/server-shared';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import sample from 'lodash/sample';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  combineLatest,
  firstValueFrom,
} from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  mergeMap,
  shareReplay,
  take,
  takeUntil,
} from 'rxjs/operators';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { Condition } from '../../../pages/flow/node/switch/class';
import { getDutchPaginatorIntl } from '../../../paginator';
import { EnsureTextLengthPipe } from '../../../pipe/ensure-text-length.pipe';
import { LingtelliService } from '../../../service/lingtelli.service';
import { DeleteConfirmDialog } from '../../basic/delete-confirm/delete-confirm.dialog';
import { PromptDialog } from '../../basic/prompt/prompt.dialog';
import { LingtelliLoginDialog } from '../lingtelli-login-dialog/lingtelli-login.dialog';

export interface LingtelliDialogData {
  condition: Condition;
}

@Component({
  selector: 'dl-lingtelli-manager-dialog',
  templateUrl: './lingtelli-manager.dialog.html',
  styleUrls: ['./lingtelli-manager.dialog.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatButton,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    NgFor,
    MatOption,
    MatInput,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
    MatList,
    MatListItem,
    MatExpansionPanelActionRow,
    MatPaginator,
    AsyncPipe,
    EnsureTextLengthPipe,
    LegacyAppearanceDirective,
  ],
  providers: [{ provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }],
})
export class LingtelliManagerDialog implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();
  private _snakeBarRef: MatSnackBarRef<SimpleSnackBar>;
  private _trainSubscription: Subscription;
  public expandedId: number;
  public noChatbot$ = this.lingtelliService.list$.pipe(
    map((list) => list.length == 0),
    shareReplay(1),
  );

  public lingtelliId$ = new BehaviorSubject<number>(null);

  public get lingtelliId(): number {
    return this.lingtelliId$.getValue();
  }

  public set lingtelliId(val: number) {
    if (typeof val !== 'number') return;
    this.lingtelliId$.next(val);
  }

  public keyword$ = new BehaviorSubject<string>('');

  public get keyword(): string {
    return this.keyword$.getValue();
  }

  public set keyword(val: string) {
    if (typeof val !== 'string') return;
    val = val.trim();
    if (val && this.page$.getValue() > 1) {
      this.page$.next(1);
      this._appRef.tick();
    }
    this.keyword$.next(val);
  }

  public page$ = new BehaviorSubject<number>(1);

  public groupFrame$ = combineLatest([
    this.lingtelliId$.pipe(filter((id) => !!id)),
    this.keyword$,
    this.page$,
  ]).pipe(
    debounceTime(333),
    mergeMap(([id, keyword, page]) =>
      this.lingtelliService.getGroups(id, keyword, page).catch((err) => null),
    ),
    filter((e) => !!e),
    shareReplay(1),
  );

  public verifyAccessToken$ = combineLatest(
    this.lingtelliId$,
    this.lingtelliService.list$,
  ).pipe(
    map(([id, list]) => id),
    mergeMap((id) => this.lingtelliService.verifyAccessToken(id)),
    shareReplay(1),
  );

  public groups$ = this.groupFrame$.pipe(
    map((group) => group.results),
    shareReplay(1),
  );

  public total$ = this.groupFrame$.pipe(
    map((d) => d.count),
    shareReplay(1),
  );

  public constructor(
    private readonly _dialogRef: MatDialogRef<LingtelliManagerDialog>,
    @Inject(MAT_DIALOG_DATA)
    private readonly _data: LingtelliDialogData,
    public readonly lingtelliService: LingtelliService,
    private readonly _matConnectedDialog: MatConnectedDialog,
    private readonly _appRef: ApplicationRef,
    private readonly _snakeRef: MatSnackBar,
  ) {}

  public async ngOnInit() {
    let noChatbot = await firstValueFrom(this.noChatbot$);
    if (noChatbot) await this.openLoginDialog();
    let list = await firstValueFrom(this.lingtelliService.list$);
    if (list && list[0]) this.lingtelliId = list[0].id;
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    if (this._snakeBarRef) {
      this._snakeBarRef.dismiss();
    }
  }

  public openLoginDialog() {
    return firstValueFrom(
      this._matConnectedDialog
        .open(LingtelliLoginDialog, {
          panelClass: 'custom-dialog-container',
        })
        .afterClosed(),
    );
  }

  public async createGroup(elementRef: ElementRef): Promise<any> {
    let question = await this.getQuestion(elementRef);
    if (!question) return false;
    return this.lingtelliService
      .createGroup(this.lingtelliId, question)
      .then((r) => {
        if (r) {
          this.page$.next(1);
          this._notifyTrain();
        }
        return r;
      });
  }

  private async _notifyTrain() {
    let lingtelliId = this.lingtelliId;
    if (this._snakeBarRef) {
      this._snakeBarRef.dismiss();
      this._snakeBarRef = null;
    }
    if (this._trainSubscription) {
      this._trainSubscription.unsubscribe();
      this._trainSubscription = null;
    }
    this._snakeBarRef = this._snakeRef.open(
      $localize`操作完畢後請點擊`,
      $localize`訓練語意資料庫`,
      {
        duration: 5000,
      },
    );
    this._trainSubscription = this._snakeBarRef
      .onAction()
      .pipe(takeUntil(this._destroy$), take(1))
      .subscribe((e) => this.trainChatbot(lingtelliId));
  }

  public async deleteGroup(group: number): Promise<boolean> {
    let opened = this._matConnectedDialog.open(DeleteConfirmDialog);
    opened.componentInstance.name = $localize`問題群組`;
    let check = await firstValueFrom(opened.afterClosed());
    if (!check) return;
    return this.lingtelliService
      .deleteGroup(this.lingtelliId, group)
      .then((r) => {
        if (r) {
          this.page$.next(this.page$.getValue());
          this._notifyTrain();
        }
        return r;
      });
  }

  public async createQuestion(
    group: number,
    elementRef: ElementRef,
  ): Promise<boolean> {
    if (!group) return false;
    let question = await this.getQuestion(elementRef);
    if (!question) return false;
    return this.lingtelliService
      .createQuestion(this.lingtelliId, group, question)
      .then((r) => {
        if (r) {
          this.page$.next(1);
          this._notifyTrain();
        }
        return r;
      });
  }

  public async getQuestion(
    elementRef: ElementRef,
    defaultVal?: string,
  ): Promise<string> {
    const dialogRef = this._matConnectedDialog.open(PromptDialog, {
      elementRef,
      panelClass: 'dialog-container-p0',
      minWidth: '26em',
    });

    const prompt = dialogRef.componentInstance;

    prompt.title = $localize`請輸入問題`;
    prompt.value =
      defaultVal ||
      sample([
        $localize`今天天氣如何`,
        $localize`今天會下雨嗎`,
        $localize`天空會放晴嗎`,
        $localize`今天天氣好嗎`,
        $localize`今天會是好天氣嗎`,
        $localize`氣象狀況如何`,
        $localize`今天要帶雨衣嗎`,
        $localize`今天出門要帶雨傘嗎`,
        $localize`今天氣溫幾度`,
        $localize`今天會冷嗎`,
        $localize`今天會熱嗎`,
        $localize`今天會回暖嗎`,
        $localize`今天有下雨的可能嗎`,
      ]);

    const text = await firstValueFrom(dialogRef.afterClosed());
    if (typeof text === 'string') return text.trim();
    return '';
  }

  public selectGroup(data: LingtelliGroupDto) {
    if (!data) return;
    this._data.condition.lingtelliId = this.lingtelliId;
    this._data.condition.lingtelliGroupId = data.group;
    this._data.condition.lingtelliAnswer = data.answer[0].content;
    this._data.condition.lingtelliDisplay = data.question[0].content;
    this._dialogRef.close(true);
  }

  public async deleteQuestion(questionId: number) {
    let opened = this._matConnectedDialog.open(DeleteConfirmDialog);
    opened.componentInstance.name = $localize`問題`;
    let check = await firstValueFrom(opened.afterClosed());
    if (!check) return;
    return this.lingtelliService
      .deleteQuestion(this.lingtelliId, questionId)
      .then((r) => {
        if (r) {
          this.page$.next(this.page$.getValue());
          this._notifyTrain();
        }
        return r;
      });
  }

  public async updateQuestion(
    elementRef: ElementRef,
    ori: string,
    questionId: number,
  ) {
    if (!questionId) return false;
    let question = await this.getQuestion(elementRef, ori);
    if (!question) return false;
    return this.lingtelliService
      .updateQuestion(this.lingtelliId, questionId, question)
      .then((r) => {
        if (r) {
          this.page$.next(this.page$.getValue());
          this._notifyTrain();
        }
        return r;
      });
  }

  public async trainChatbot(id: number = this.lingtelliId) {
    if (!id) return false;
    this.lingtelliService.trainChatbot(id).then((r) => {
      if (r) this._snakeRef.open($localize`語意資料庫訓練完成`);
    });
  }
}
