<div class="header" i18n>編輯欄位</div>

<div class="container">
  <form [formGroup]="form">
    <div fxLayout="row" fxLayoutGap="15px">
      <mat-form-field legacyAppearance>
        <mat-label i18n>欄位名稱</mat-label>
        <input matInput formControlName="name" />
        <mat-error *ngIf="name.errors && name.errors['required']" i18n>
          必填項目
        </mat-error>
      </mat-form-field>

      <mat-form-field legacyAppearance>
        <mat-label i18n>變數名稱</mat-label>
        <input matInput formControlName="key" />
        <button
          mat-icon-button
          [disabled]="lastTranslateName === name.value || name.value === ''"
          (click)="translateKey()"
          matSuffix
        >
          <mat-icon>g_translate</mat-icon>
        </button>
        <mat-error *ngIf="key.errors && key.errors['required']" i18n>
          必填項目
        </mat-error>

        <mat-error *ngIf="key.errors && key.errors['duplicateKey']" i18n>
          該變數已經使用過
        </mat-error>

        <mat-error
          *ngIf="key.errors && key.errors['onlyAlphaOrNumberOrUnderline']"
          i18n
        >
          只能由英文/數字或下底線組成
        </mat-error>
      </mat-form-field>

      <mat-form-field legacyAppearance>
        <mat-label i18n>資料型態</mat-label>
        <mat-select formControlName="type" i18n>
          <mat-option value="string">字串</mat-option>
          <mat-option value="number">數字</mat-option>
          <mat-option value="text">文案</mat-option>
          <mat-option value="date">日期</mat-option>
          <mat-option value="json">JSON</mat-option>
          <mat-option value="file">檔案</mat-option>
          <mat-option value="tel">號碼</mat-option>
          <mat-option value="boolean">開關</mat-option>
          <mat-option value="dropdown">下拉選單</mat-option>
          <mat-option value="radio">單選選項</mat-option>
          <mat-option value="checkbox">多選選項</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row">
      <mat-form-field fxFlex="1 0 0" legacyAppearance>
        <mat-label i18n>預設值</mat-label>
        <input matInput formControlName="default" />
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="15px">
      <mat-checkbox
        class="form-field"
        fxFlex="1 0 0"
        formControlName="required"
        i18n
        >必填欄位</mat-checkbox
      >
      <mat-checkbox
        class="form-field"
        fxFlex="1 0 0"
        formControlName="hidden"
        i18n
        >隱藏欄位</mat-checkbox
      >
      <mat-checkbox
        class="form-field"
        fxFlex="1 0 0"
        formControlName="disabled"
        i18n
        >禁止編輯</mat-checkbox
      >
    </div>

    <div fxLayout="row" fxLayoutGap="15px">
      <mat-form-field fxFlex="1 0 0" legacyAppearance>
        <mat-label i18n>輸入框提示</mat-label>
        <input matInput formControlName="placeholder" />
      </mat-form-field>

      <mat-form-field fxFlex="1 0 0" legacyAppearance>
        <mat-label i18n>欄位提示</mat-label>
        <input matInput formControlName="info" />
      </mat-form-field>
    </div>

    <div fxLayout="row">
      <mat-form-field
        legacyAppearance
        fxFlex="1 0 0"
        *ngIf="['dropdown', 'radio', 'checkbox'].includes(type.value)"
      >
        <mat-label i18n>選項</mat-label>
        <textarea matInput formControlName="enum"></textarea>
        <mat-hint i18n>透過逗點分隔</mat-hint>
      </mat-form-field>
    </div>

    <div *ngIf="type.value === 'file'" fxLayout="row" fxLayoutGap="15px">
      <mat-checkbox
        class="form-field"
        fxFlex="1 0 0"
        formControlName="multiple"
        i18n
        >檔案可多選</mat-checkbox
      >
      <mat-form-field fxFlex="3 0 0" legacyAppearance>
        <mat-label i18n>可接收檔案類型</mat-label>
        <input matInput formControlName="accept" />
      </mat-form-field>
    </div>
  </form>
</div>

<div class="footer tar">
  <button mat-button [disabled]="form.invalid" (click)="submit()" i18n>
    確定
  </button>
</div>
