import { NgFor } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardTitle,
} from '@angular/material/card';
import { MatOption } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ProgrammableComponent } from '../../../../../components/programmable/programmable.component';
import { LegacyAppearanceDirective } from '../../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { RegularizedFlex } from '../../../regularized-flex.class';
import { FlexChinesePipe } from '../../chinese.pipe';
import { FlexEditorService } from '../../editor.service';
import { ERROR_MESSAGE } from '../../error';
import { FormProperty } from '../form';
import { FLEX_PROP_OPTIONS } from '../options';

@Component({
  selector: 'form-offset',
  templateUrl: './offset.component.html',
  styleUrls: ['./offset.component.scss'],
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatDivider,
    MatCardContent,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    ProgrammableComponent,
    MatFormField,
    MatInput,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOption,
    MatHint,
    FlexChinesePipe,
    LegacyAppearanceDirective,
  ],
})
export class FlexFormOffsetComponent implements OnChanges {
  @Input()
  public formValue: RegularizedFlex;

  @Output()
  public formValueChange = new EventEmitter();

  public offsets: FormProperty[] = [
    {
      name: 'offsetTop',
      hint: 'ex: 100px or 100%',
      autoComplete: FLEX_PROP_OPTIONS.Offset,
    },
    {
      name: 'offsetBottom',
      hint: 'ex: 100px or 100%',
      autoComplete: FLEX_PROP_OPTIONS.Offset,
    },
    {
      name: 'offsetStart',
      hint: 'ex: 100px or 100%',
      autoComplete: FLEX_PROP_OPTIONS.Offset,
    },
    {
      name: 'offsetEnd',
      hint: 'ex: 100px or 100%',
      autoComplete: FLEX_PROP_OPTIONS.Offset,
    },
  ];

  public formGroup: FormGroup = new FormGroup({
    offsetTop: new FormControl(),
    offsetBottom: new FormControl(),
    offsetStart: new FormControl(),
    offsetEnd: new FormControl(),
  });

  public errorFunction: (data: RegularizedFlex) => {};

  public constructor(public flexEditorService: FlexEditorService) {}

  public ngOnChanges(): void {
    if (this.formValue)
      this.formGroup = new FormGroup({
        offsetTop: new FormControl(this.formValue['offsetTop']),
        offsetBottom: new FormControl(this.formValue['offsetBottom']),
        offsetStart: new FormControl(this.formValue['offsetStart']),
        offsetEnd: new FormControl(this.formValue['offsetEnd']),
      });
    this.findError();
  }

  public findError() {
    this.errorFunction = ERROR_MESSAGE[this.formValue.type];
    this.offsets.forEach((offset) => {
      let hasError = this.errorFunction(this.formValue);
      if (hasError) offset.error = hasError[offset.name];
      else delete offset.error;
    });
  }
}
