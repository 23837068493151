import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardTitle,
} from '@angular/material/card';
import { MatOption } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { PromotionChannelModel } from '@ay-gosu/server-shared';
import cloneDeep from 'lodash/cloneDeep';
import { firstValueFrom, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DatetimePickerComponent } from '../../../../../components/datetime-picker/datetime-picker.component';
import { ProgrammableComponent } from '../../../../../components/programmable/programmable.component';
import { LegacyAppearanceDirective } from '../../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { LINE_URL_SCHEME } from '../../../../line-url-scheme';
import { PackageFactoryService } from '../../../../package-factory.service';
import { RegularizedFlex } from '../../../regularized-flex.class';
import { FlexChinesePipe } from '../../chinese.pipe';
import { FlexEditorService } from '../../editor.service';
import { ERROR_MESSAGE } from '../../error';
import { FormProperty } from '../form';
import { FLEX_PROP_OPTIONS } from '../options';

@Component({
  selector: 'form-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatDivider,
    MatCardContent,
    FormsModule,
    ReactiveFormsModule,
    ProgrammableComponent,
    MatFormField,
    MatSelect,
    NgFor,
    NgIf,
    MatOption,
    DatetimePickerComponent,
    MatInput,
    MatHint,
    AsyncPipe,
    FlexChinesePipe,
    LegacyAppearanceDirective,
  ],
})
export class FlexFormActionComponent implements OnChanges {
  @Input()
  public formValue: RegularizedFlex;

  @Output()
  public formValueChange = new EventEmitter();

  public options = FLEX_PROP_OPTIONS;

  public formGroup: FormGroup = new FormGroup({
    type: new FormControl(''),
  });

  public errorFunction: (data: RegularizedFlex) => {};

  public promotionOptions$ = of([]).pipe(
    mergeMap((_) =>
      PromotionChannelModel.list(1, 100).then((promotions) =>
        promotions.data
          .filter((promotion) => promotion.type === 'SHARE_MESSAGE')
          .map((promotion) => ({
            value: promotion.id.toString(),
            text: promotion.name,
          })),
      ),
    ),
  );

  public actionProperties: { [key: string]: FormProperty[] } = {
    postback: [{ name: 'label' }, { name: 'data' }, { name: 'displayText' }],
    uri: [{ name: 'label' }, { name: 'uri' }, { name: 'altUri' }],
    message: [{ name: 'label' }, { name: 'text' }],
    datetimepicker: [
      { name: 'label' },
      { name: 'data' },
      { name: 'mode', options: FLEX_PROP_OPTIONS.DatetimePickerMode },
      { name: 'initial', hint: 'format' },
      { name: 'max', hint: 'format' },
      { name: 'min', hint: 'format' },
    ],
    'add-through-promotion': [{ name: 'label' }],
    'share-message-through-promotion': [
      { name: 'label' },
      { name: 'promotionId', asyncOptions: this.promotionOptions$ },
    ],
  };

  public get hint(): string {
    let mode = this.formGroup.value.mode;
    switch (mode) {
      case 'date':
        return 'YYYY-MM-DD';

      case 'time':
        return 'HH:mm';

      default:
        return 'YYYY-MM-DDtHH:mm';
    }
  }

  public constructor(
    public flexEditorService: FlexEditorService,
    private readonly _packageFactoryService: PackageFactoryService,
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    let type = this.formValue?.action?.type;
    this.changeActionType(type, true);
    this.findError();
  }

  public selectChange$ = this.flexEditorService.selected$.subscribe(
    (selected) => {
      this.toggleActionOptions(selected);
    },
  );

  private async toggleActionOptions(selected?: RegularizedFlex) {
    const pkg = this.flexEditorService.package;

    const enabledPromotionChannel = await firstValueFrom(
      this._packageFactoryService.enablePromotionChannel$,
    );

    const types = {
      postback: pkg.enabledActions.postback,
      uri: pkg.enabledActions.uri,
      message: pkg.enabledActions.message,
      datetimepicker: pkg.enabledActions.dateTimePicker,
      'add-through-promotion':
        pkg.enabledActions.addThroughPromotion && enabledPromotionChannel,
      'share-message-through-promotion':
        pkg.enabledActions.shareMessageThroughPromotion &&
        enabledPromotionChannel,
    };

    if (selected?.type === 'button') {
      types[''] = false;
    } else {
      types[''] = true;
    }

    if (selected?.type === 'video') {
      for (let type in types) {
        types[type] = false;
      }
      types[''] = true;
      types['uri'] = true;
    }

    for (let type in types) {
      const enable = types[type];
      this.toggleActionOption(type, enable);
    }
  }

  private toggleActionOption(value: string, enable: boolean) {
    const option = this.options.Action.find((item) => item.value === value);
    option.enable = enable;
  }

  public async changeActionType(type: string, init = false) {
    if (!this.actionProperties[type]) {
      this.formGroup = new FormGroup({
        type: new FormControl(type),
      });
      return;
    } else {
      const select = await firstValueFrom(this.flexEditorService.selected$);
      if (select.type === 'video') {
        this.actionProperties[type] = this.actionProperties[type].filter(
          (property) => property.name !== 'altUri',
        );
      }
    }

    let typeControl = { type: new FormControl(type) };
    let controls = this.actionProperties[type].map((property) => {
      let key = property.name;
      let value = '';

      if (key == 'mode') {
        value = 'date';
      }

      if (key == 'scheme') {
        let schemeKey = Object.keys(LINE_URL_SCHEME).find(
          (key) => LINE_URL_SCHEME[key].value == this.formValue?.action?.uri,
        );
        value = schemeKey || 'others';
      }

      let hasValue =
        this.formValue &&
        this.formValue['action'] &&
        this.formValue['action'][key];

      if (hasValue) value = this.formValue['action'][key];
      if (key == 'altUri') {
        return {
          altUri: new FormGroup({
            desktop: new FormControl(value['desktop']),
          }),
        };
      } else {
        return { [key]: new FormControl(value) };
      }
    });

    this.formGroup = new FormGroup(Object.assign(typeControl, ...controls));
  }

  public doChange() {
    Object.keys(this.formGroup.value).forEach((key) => {
      if (!this.formGroup.value[key]) delete this.formGroup.value[key];
    });

    let values = cloneDeep(this.formGroup.value);
    delete values.scheme;
    this.formValueChange.emit(values);
    this.findError();
  }

  public changeUrlType(type: string) {
    let scheme = LINE_URL_SCHEME[type];
    if (!scheme) this.formGroup.controls.uri.setValue('');
    else this.formGroup.controls.uri.setValue(scheme.value);
  }

  public resetTimes() {
    if (this.formGroup.controls.initial) {
      this.formGroup.controls.initial.setValue(null);
    }
    if (this.formGroup.controls.max) {
      this.formGroup.controls.max.setValue(null);
    }
    if (this.formGroup.controls.min) {
      this.formGroup.controls.min.setValue(null);
    }
  }

  protected findError() {
    if (!this.actionProperties[this.formGroup.value.type])
      this.formGroup.controls.type.setValue('');

    if (!this.formGroup.value.type) return;
    this.errorFunction = ERROR_MESSAGE[this.formValue.type];

    this.actionProperties[this.formGroup.value.type].forEach((property) => {
      let hasError = this.errorFunction(this.formValue)['action'];
      if (hasError) property.error = hasError[property.name];
      else delete property.error;
    });
  }
}
