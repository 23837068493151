import { Subject } from 'rxjs';
import { AudioMeter } from './audio-meter.class';

export class Recorder {
  protected chunks = [];
  public recorder: MediaRecorder;
  public onDataAvailableEvent: Function;

  public meter: AudioMeter;
  public stop$ = new Subject<void>();

  /* outer readonly isRunning */
  private _isRunning = false;

  public get isRunning() {
    return this._isRunning;
  }

  public getBlob() {
    return new Blob(this.chunks, { type: this.mimeType });
  }

  public constructor(public stream: MediaStream, public mimeType: string) {
    this.recorder = new MediaRecorder(stream, { mimeType });
    this.meter = new AudioMeter(stream);
    this.recorder.ondataavailable = (event) => {
      if (event.data && event.data.size > 0) {
        this.chunks.push(event.data);
        if (typeof this.onDataAvailableEvent == 'function') {
          this.onDataAvailableEvent(event);
        }
      }
    };
  }

  public download() {
    let url = window.URL.createObjectURL(this.getBlob());
    let a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'video.mkv';
    document.body.appendChild(a);
    a.click();

    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100);
  }

  public start() {
    if (this._isRunning) return;
    this._isRunning = true;
    this.recorder.start(333);
  }

  public stop() {
    if (!this._isRunning) return;
    this._isRunning = false;
    this.recorder.stop();
    this.stopStream();
    this.stop$.next();
  }

  protected stopStream() {
    this.stream.getAudioTracks().forEach((track) => track.stop());
    this.stream.getVideoTracks().forEach((track) => track.stop());
  }
}
