<div class="header" i18n>編輯<span>{{ quickReply.label }}</span></div>

<div class="container">
  <div fxLayout="row" fxLayoutAlign="center center">
    <button
      mat-stroked-button
      (click)="uploadIcon()"
      class="icon-uploader"
      matTooltip="圖示，建議尺寸 24 x 24，檔案限制 1MB 以內"
      i18n-matTooltip="圖示，建議尺寸 24 x 24，檔案限制 1MB 以內"
    >
      <img [src]="quickReply.icon" *ngIf="quickReply.icon" />
    </button>

    <mat-form-field class="type" legacyAppearance>
      <mat-label i18n>按鈕類型</mat-label>
      <mat-select
        [(ngModel)]="quickReply.type"
        (ngModelChange)="afterChanged()"
      >
        <mat-select-trigger>
          {{ quickReply.type | quickReplyType }}
        </mat-select-trigger>

        <mat-option *ngFor="let type of filterType()" [value]="type">
          {{ type | quickReplyType }}
          <gosu-platform [platforms]="typePlatforms[type]"></gosu-platform>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="tip" *ngIf="['next'].includes(quickReply.type)" i18n>
    在等待使用者回應的過程中，變數資料將會重置。<br />
    如有需要保留的資料，可以透過設定屬性結點將資料儲存
  </div>

  <mat-form-field
    *ngIf="!['email', 'phone'].includes(quickReply.type)"
    legacyAppearance
  >
    <mat-label i18n>按鈕顯示文字</mat-label>
    <input
      matInput
      [(ngModel)]="quickReply.label"
      (ngModelChange)="afterChanged()"
    />
    <mat-hint i18n>必填，20字以內</mat-hint>
  </mat-form-field>

  <mat-form-field *ngIf="quickReply.type === 'message'" legacyAppearance>
    <mat-label i18n>指令內容</mat-label>
    <input
      matInput
      [(ngModel)]="quickReply.text"
      (ngModelChange)="afterChanged()"
    />
    <mat-hint i18n>必填，LINE 300 字內；Facebook 1000 字內</mat-hint>
  </mat-form-field>

  <mat-form-field
    *ngIf="['postback', 'next'].includes(quickReply.type)"
    legacyAppearance
  >
    <mat-label i18n>使用者示意文字</mat-label>
    <input
      matInput
      [(ngModel)]="quickReply.text"
      (ngModelChange)="afterChanged()"
    />
    <mat-hint i18n>選填，LINE 限定，300 字內</mat-hint>
  </mat-form-field>

  <mat-form-field *ngIf="quickReply.type === 'postback'" legacyAppearance>
    <mat-label i18n>回傳內容</mat-label>
    <input
      matInput
      [(ngModel)]="quickReply.data"
      (ngModelChange)="afterChanged()"
    />
    <mat-hint i18n>必填，LINE 限制 300 字內；Facebook 限制 1000 字內</mat-hint>
  </mat-form-field>
</div>
