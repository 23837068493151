import { APP_BASE_HREF, AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Inject,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { TemplateDto } from '@ay-gosu/server-shared';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import urlJoin from 'url-join';
import { LandingBrandComponent } from '../../../components/landing-brand/landing-brand.component';
import { LoadingComponent } from '../../../components/loading/loading.component';
import { ImageTypePipe } from '../../../pipe/imageType.pipe';
import { TemplateService } from '../../../service/template.service';
import { MessageIconPipe } from '../../message-icon.pipe';
@Component({
  selector: 'ms-poster-template-picker-dialog',
  templateUrl: './template-picker.dialog.html',
  styleUrls: ['./template-picker.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatIcon,
    FlexModule,
    NgFor,
    LandingBrandComponent,
    LoadingComponent,
    AsyncPipe,
    ImageTypePipe,
    forwardRef(() => MessageIconPipe),
  ],
})
export class PosterTemplatePickerDialog {
  public templates$: Observable<TemplateDto[]> = of(0).pipe(
    switchMap((_) => this.templateService.getList()),
    map((templates) =>
      templates
        .filter((template) => template.type === 'Poster')
        .filter((template) => template),
    ),
  );

  public constructor(
    protected templateService: TemplateService,
    protected matDialogRef: MatDialogRef<PosterTemplatePickerDialog>,
    @Inject(APP_BASE_HREF)
    private readonly _baseHref: string,
  ) {}

  public submit(poster: TemplateDto) {
    this.matDialogRef.close(poster);
  }

  public createPoster() {
    const url = urlJoin(this._baseHref, '/imaging');
    window.open(url);
    this.matDialogRef.close();
  }
}
