import { AsyncPipe } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { ExecutorDTS } from '../../../pages/flow/definition/executor';
import { EditorComponent } from '../../ngx-monaco-editor/editor.component';
import { MonacoEditorLoaderService } from '../../ngx-monaco-editor/monaco-editor-loader.service';
import { ProgressBarComponent } from '../../progress-bar/progress-bar.component';

@Component({
  selector: 'gosu-monaco-dialog',
  templateUrl: './monaco-dialog.component.html',
  standalone: true,
  imports: [
    MatIconButton,
    MatIcon,
    ProgressBarComponent,
    EditorComponent,
    FormsModule,
    MatButton,
    AsyncPipe,
  ],
  host: {
    class: '!flex flex-col z-10 w-[600px] h-[400px] bg-code',
  },
})
export class MonacoDialog {
  public enableMonaco$ = this._monacoEditorLoaderService.enableMonaco$;

  public title = $localize`程式碼區塊`;

  public dataType = `string`;

  public isLoading = true;

  public editorOptions = {
    theme: 'vs-dark',
    language: 'typescript',
    minimap: { enabled: false },
    snippetSuggestions: 'auto',
  };

  public constructor(
    private readonly _matDialogRef: MatDialogRef<any>,
    private readonly _monacoEditorLoaderService: MonacoEditorLoaderService,
    @Inject(MAT_DIALOG_DATA)
    public data: { code: string; title: string; dataType: string },
  ) {
    this.title = data.title;
    this.dataType = data.dataType;
  }

  public async onMonacoEditorInit(editor): Promise<void> {
    try {
      this.isLoading = false;

      const monaco = (window as any).monaco;
      const ts = monaco.languages.typescript.typescriptDefaults;
      ts.setExtraLibs([]);

      const define = this.toDefine(this.variable);
      ts.addExtraLib(ExecutorDTS, `Executor.d.ts`);
      ts.addExtraLib(define, `prop.d.ts`);

      window['editor'] = editor;
    } catch (error) {
      console.error(error);
    }
  }

  protected toDefine(json: any, isRoot = true) {
    let prefix = '';
    if (isRoot) {
      prefix = 'declare var ';
    }
    return Object.keys(json)
      .filter((key) => !key.startsWith('$'))
      .map((key) => {
        const type = typeof json[key];
        if (type === 'object') {
          return `
/** ${json['$' + key]} */\n
${prefix}${key}: {
  ${this.toDefine(json[key], false)}
};`;
        } else {
          return `
/** ${json['$' + key]} */
${prefix}${key}: ${type};
`;
        }
      })
      .join('\n');
  }

  @Input()
  public variable = {};

  public submit() {
    this._matDialogRef.close(this.data.code);
  }

  public cancel() {
    this._matDialogRef.close(undefined);
  }
}
