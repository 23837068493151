import { Button } from '@ay/bot';

export class ButtonFactory {
  public static fromButton(data: Button.Any) {
    let button: Button.Any;

    switch (data.type) {
      case 'next':
        button = new Button.Next(data.label, data.text, data.data);
        break;

      case 'postback':
        button = new Button.Postback(data.label, data.data, data.text);
        break;

      case 'message':
        button = new Button.Message(data.label, data.text);
        break;

      case 'uri':
        button = new Button.Uri(data.label, data.uri);
        break;

      case 'add-through-promotion':
        button = new Button.AddThroughPromotion(data.label);
        break;

      case 'share-message-through-promotion':
        button = new Button.ShareMessageThroughPromotion(
          data.label,
          data.promotionId,
        );
        break;

      default:
        if (data.type.includes('$')) {
          button = new Button.Base(null, data.label) as any;
          button['data'] = data['data'];
          button['text'] = data['text'];
          button['uri'] = data['uri'];
          break;
        }
        throw new Error($localize`未知的按鈕類型 ${data.type}`);
    }

    button.buttonId = data.buttonId;
    button.cardId = data.cardId;
    button.recordId = data.recordId;
    button.packageId = data.packageId;
    return button;
  }
}
