import { Component, Input } from '@angular/core';
import { Content } from '@ay/bot';
import { ReplaySubject } from 'rxjs';
import { AffectedComponent } from './affected.component';
import { Message } from './base/base.message';

@Component({
  selector: 'message-affected-component',
  template: '',
})
export class MessageAffectedComponent<
  T extends Message<Content.Any> = Message<Content.Any>,
> extends AffectedComponent<T> {
  //#region message
  private _message: T = null;

  public message$ = new ReplaySubject<T>(1);

  public get message(): T {
    return this._message;
  }

  @Input()
  public set message(message: T) {
    if (this._message === message) return;
    this._message = message;
    this.message$.next(message);
    this.affecter$.next(message);
  }
  //#endregion message
}
