import {
  Component,
  forwardRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { BaseEditor } from './base-editor';
import { NGX_MONACO_EDITOR_CONFIG, NgxMonacoEditorConfig } from './config';
import { MonacoEditorLoaderService } from './monaco-editor-loader.service';
import { NgxEditorModel } from './types';

declare var monaco;

@Component({
  selector: 'ngx-monaco-editor',
  template: '<div class="editor-container" #editorContainer></div>',
  styles: [
    `
      :host {
        display: block;
        height: 200px;
        padding: 24px;
      }
      .editor-container {
        width: 100%;
        height: 98%;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    },
    { provide: NGX_MONACO_EDITOR_CONFIG, useValue: {} },
    MonacoEditorLoaderService,
  ],
  standalone: true,
})
export class EditorComponent
  extends BaseEditor
  implements ControlValueAccessor, OnDestroy
{
  private _value: string = '';

  public propagateChange = (_: any) => {};

  public onTouched = () => {};

  @Input()
  public set options(options: any) {
    this._options = Object.assign(
      {},
      this.editorConfig.defaultOptions,
      options,
    );
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(options);
    }
  }

  public get options(): any {
    return this._options;
  }

  @Input()
  public set model(model: NgxEditorModel) {
    this.options.model = model;
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(this.options);
    }
  }

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly _ngZone: NgZone,
    @Inject(NGX_MONACO_EDITOR_CONFIG)
    protected readonly editorConfig: NgxMonacoEditorConfig,
    protected readonly loaderService: MonacoEditorLoaderService,
  ) {
    super(editorConfig, loaderService);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._destroy$.next();
    this._destroy$.complete();
  }

  public writeValue(value: any): void {
    this._value = value || '';
    // Fix for value change while dispose in process.
    setTimeout(() => {
      if (this._editor && !this.options.model) {
        this._editor.setValue(this._value);
      }
    });
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  protected initMonaco(options: any): void {
    const hasModel = !!options.model;

    if (hasModel) {
      const model = monaco.editor.getModel(options.model.uri || '');
      if (model) {
        options.model = model;
        options.model.setValue(this._value);
      } else {
        options.model = monaco.editor.createModel(
          options.model.value,
          options.model.language,
          options.model.uri,
        );
      }
    }

    this._editor = monaco.editor.create(
      this.editorContainer.nativeElement,
      options,
    );

    if (!hasModel) {
      this._editor.setValue(this._value);
    }

    this._editor.onDidChangeModelContent((e: any) => {
      const value = this._editor.getValue();

      // value is not propagated to parent when executing outside zone.
      this._ngZone.run(() => {
        this.propagateChange(value);
        this._value = value;
      });
    });

    this._editor.onDidBlurEditorWidget(() => {
      this.onTouched();
    });

    // refresh layout on resize event.
    if (this._windowResizeSubscription) {
      this._windowResizeSubscription.unsubscribe();
    }
    this._windowResizeSubscription = fromEvent(window, 'resize')
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this._editor.layout());
    this.init.emit(this._editor);
  }
}
