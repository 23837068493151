import { Connection } from '../connection/class';
import { FlowService } from '../flow.service';
import { Node } from '../node/class';
import { Command } from './command';

export class DeleteCommand extends Command {
  public constructor(
    private readonly _flowService: FlowService,
    private readonly _items: (Node | Connection)[],
  ) {
    super();
  }

  protected relations: {
    connection: Connection;
    start: Node;
    startIndex: number;
    end: Node;
    endIndex: number;
  }[] = [];

  protected nodes: Node[];
  protected connections: Connection[];

  public async do() {
    this.nodes = this._items.filter((item) => item instanceof Node) as Node[];
    this.connections = this.readConnectionWithinNodes(this._items, this.nodes);
    this.relations = this.connections.map((connection) =>
      this.connectionToRelation(connection),
    );

    this._flowService.nodeList = this._flowService.nodeList.filter(
      (node) => this.nodes.indexOf(node) === -1,
    );

    this._flowService.connectionList = this._flowService.connectionList.filter(
      (connection) => this.connections.indexOf(connection) === -1,
    );

    this._flowService.selected.splice(0, this._flowService.selected.length);

    this._flowService.checkModify();

    if (!this._flowService.nodeList.length) {
      this._flowService.chart.scrollX = 0;
      this._flowService.chart.scrollY = 0;
      this._flowService.chart.zoom = 1;
      this._flowService.chart.render();
    }
  }

  public async undo() {
    this._flowService.nodeList = this._flowService.nodeList.concat(this.nodes);

    this._flowService.tick();

    this.nodes.map((node) => {
      node.nodeComponent.updateTransform();
      node.nodeComponent.updateSize();
    });

    this._flowService.connectionList = this._flowService.connectionList.concat(
      this.connections,
    );

    this.relations.map((relation) => {
      const startJunctions = relation.start.nodeComponent.junctions.sort(
        (a, b) => a.index - b.index,
      );

      const endJunctions = relation.end.nodeComponent.junctions.sort(
        (a, b) => a.index - b.index,
      );

      relation.connection.start = startJunctions[relation.startIndex];

      relation.connection.end = endJunctions[relation.endIndex];
    });

    this._flowService.checkModify();
  }

  protected readConnectionWithinNodes(
    items: (Node | Connection)[],
    nodes: Node[],
  ) {
    return items
      .filter((item) => item instanceof Connection)
      .concat(this.getConnectionsInTheNodes(nodes)) as Connection[];
  }

  protected getConnectionsInTheNodes(nodes: Node[]) {
    return this._flowService.connectionList.filter(
      (connection) =>
        nodes.indexOf(connection?.start?.node) !== -1 ||
        nodes.indexOf(connection?.end?.node) !== -1,
    );
  }

  protected connectionToRelation(connection: Connection) {
    const endJunctions = connection?.end?.node?.nodeComponent.junctions.sort(
      (a, b) => a.index - b.index,
    );

    const startJunctions = connection.start.node.nodeComponent.junctions.sort(
      (a, b) => a.index - b.index,
    );

    return {
      connection,
      start: connection.start.node,
      startIndex: startJunctions.indexOf(connection.start),
      end: connection.end.node,
      endIndex: endJunctions.indexOf(connection.end),
    };
  }
}
