import { ButtonType } from '@ay/bot';
import {
  CustomizeButtonRecordContent,
  CustomizeMessageButtonRecordContent,
  CustomizeUriButtonRecordContent,
} from '../card.type';

export class CustomizeButton {
  public enable: boolean = false;
  public label: string = '';
  public type: ButtonType = 'message';
  public message: string = '';
  public uri: string = '';

  public fromJson(json: CustomizeButtonRecordContent) {
    if (!json) return;

    switch (json.type) {
      case 'message':
        return this.fromMessageButtonJson(json);

      case 'uri':
        return this.fromUriButtonJson(json);
    }
  }

  public toJSON(): CustomizeButtonRecordContent {
    if (!this.enable) {
      return undefined;
    }

    switch (this.type) {
      case 'message':
        return this.toMessageButtonJson();

      case 'uri':
        return this.toUriButtonJson();
    }
  }

  protected fromUriButtonJson(json: CustomizeUriButtonRecordContent) {
    this.enable = true;
    this.type = 'uri';
    this.label = json.label;
    this.uri = json.uri;
  }

  protected toUriButtonJson(): CustomizeUriButtonRecordContent {
    return {
      type: 'uri',
      label: this.label,
      uri: this.uri,
    };
  }

  protected fromMessageButtonJson(json: CustomizeMessageButtonRecordContent) {
    this.enable = true;
    this.type = 'message';
    this.message = json.message;
    this.label = json.label;
  }

  protected toMessageButtonJson(): CustomizeMessageButtonRecordContent {
    return {
      type: 'message',
      label: this.label,
      message: this.message,
    };
  }
}
