import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { StaticService } from '../../../service/static.service';
import { RecorderFactory } from '../../media/recorder-factory.class';
import { Recorder } from '../../media/recorder.class';
import { MatButton } from '@angular/material/button';
import { ProgressBarComponent } from '../../../components/progress-bar/progress-bar.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'ms-video-recorder-dialog',
    templateUrl: './recorder.dialog.html',
    styleUrls: ['./recorder.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        ProgressBarComponent,
        MatButton,
        AsyncPipe,
    ],
})
export class VideoRecorderDialog implements OnInit, OnDestroy {
  public status: 'init' | 'recording' | 'recorded' | 'upload' | 'uploaded' =
    'init';

  @ViewChild('video')
  public video: ElementRef<HTMLVideoElement>;

  public recorder: Recorder;
  public progress$: Subject<number>;

  public constructor(
    private readonly _staticService: StaticService,
    private readonly _matDialogRef: MatDialogRef<VideoRecorderDialog>,
  ) {}

  public async ngOnInit() {
    this.status = 'init';
    await this.start();
  }

  public ngOnDestroy(): void {
    try {
      this.recorder.stop();
      this.video.nativeElement.pause();
    } catch (error) {}
  }

  public async initRecorder() {
    if (this.recorder) {
      this.recorder.stop();
    }

    this.recorder = await RecorderFactory.create(
      {
        audio: true,
        video: {
          width: 1280,
          height: 720,
          frameRate: 15,
        },
      },
      'video/webm;codecs=H264',
    );
  }

  public async start() {
    await this.initRecorder();

    this.status = 'recording';
    this.recorder.start();

    this.video.nativeElement.controls = false;
    this.video.nativeElement.srcObject = this.recorder.stream;
    this.video.nativeElement.play();
  }

  public async stop() {
    this.recorder.stop();
    this.status = 'recorded';
    this.video.nativeElement.src = URL.createObjectURL(this.recorder.getBlob());
    this.video.nativeElement.srcObject = null;
    this.video.nativeElement.controls = true;
  }

  public async restart() {
    this.start();
  }

  public async upload() {
    this.status = 'upload';
    let blob = this.recorder.getBlob();
    let task = await this._staticService.uploadBlob(blob, 'audio.webm');
    this.progress$ = task.progress;
    let response = await firstValueFrom(
      task.uploadSubject.pipe(first((event) => event?.status == 'all-done')),
    );

    this._matDialogRef.close(response.result);
  }
}
