<button
  class="tag-short-button"
  mat-flat-button
  [style.background-color]="
    tags.length && tags[0] ? tags[0].color : 'transparent'
  "
  [matTooltip]="tooltip"
>
  <mat-icon>bookmark</mat-icon>

  <ng-container *ngIf="tags.length === 1 && tags[0] as tag">
    {{ tag.name }}
  </ng-container>

  <ng-container *ngIf="tags.length > 1" i18n>
    {{ tags.length }} 個分類
  </ng-container>

  <ng-container *ngIf="tags.length === 0" i18n> 沒有分類 </ng-container>
</button>
