import { Connection } from '../connection/class';
import { FlowService } from '../flow.service';
import { Node } from '../node/class';
import { Command } from './command';

export class AddConnectionCommand extends Command {
  private _oldSelected: (Node | Connection)[];

  public constructor(
    private readonly _flowService: FlowService,
    private readonly _connection: Connection,
  ) {
    super();
    this._oldSelected = _flowService.selected.slice();
  }

  public async do() {
    this._flowService.connectionList.push(this._connection);
    this._flowService.selected = [this._connection];
  }

  public async undo() {
    let index = this._flowService.connectionList.indexOf(this._connection);
    if (index !== -1) {
      this._flowService.connectionList.splice(index, 1);
      this._flowService.selected = this._oldSelected;
    }
  }
}
