import { Content } from '@ay/bot';
import { Message } from '../base/base.message';

export class VideoMessage extends Message<Content.Video> {
  public type = 'video';

  public constructor() {
    super();
    this.content = new Content.Video('');
  }

  public toString() {
    return this.content.alt || $localize`一段影片`;
  }
}
