import { NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { FlexEditorService } from '../editor/editor.service';
import { RegularizedFlex } from '../regularized-flex.class';
import { FlexBubbleComponent } from './content/bubble/bubble.component';
import { FlexCarouselComponent } from './content/carousel/carousel.component';

@Component({
  selector: 'ms-flex-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, FlexBubbleComponent, FlexCarouselComponent],
})
export class FlexPreviewComponent {
  @Input()
  public data: RegularizedFlex;

  @Input()
  public canSelect = true;

  public constructor(public readonly editorService: FlexEditorService) {}

  @HostListener('click')
  public onClick() {
    this.editorService.selected$.next(null);
  }
}
