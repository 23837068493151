import { Pipe, PipeTransform } from '@angular/core';
import { NodeColumn } from '@ay-gosu/server-shared';
import { Connection } from './connection/class';
import { FlowService } from './flow.service';
import { Nodes } from './node/';
import { Node } from './node/class';

export interface Position {
  x?: number;
  y?: number;
}

export interface Class<T> extends Function {
  new (...args: any[]): T;
}

/** 產生節點 */
export async function generateNode(
  data: NodeColumn,
  service: FlowService,
): Promise<Node>;
export async function generateNode(
  type: string,
  service: FlowService,
): Promise<Node>;
export async function generateNode(
  arg: string | NodeColumn,
  service: FlowService,
): Promise<Node> {
  let node: Node;
  if (typeof arg === 'string') {
    node = await generateNodeFromType(arg);
  } else {
    node = await generateNodeFromServerNode(arg);
  }
  node.flowService = service;
  await node.afterUpdated();
  return node;
}

async function generateNodeFromType(type: string): Promise<Node> {
  let node: Node;
  if (Nodes[type] === undefined) {
    console.error(Nodes);
    throw $localize`不支援的節點類型` + type;
  }
  node = new Nodes[type]();
  return node;
}

async function generateNodeFromServerNode(data: NodeColumn): Promise<Node> {
  let node = await generateNodeFromType(data.type);
  node.fromJSON(data);
  return node;
}

@Pipe({
    name: 'deleteSelected',
    standalone: true,
})
export class DeleteSelectedPipe implements PipeTransform {
  public transform(selected: (Node | Connection)[]) {
    let hasNode = selected.find((item) => item instanceof Node);
    let hasConnection = selected.find((item) => item instanceof Connection);
    let res = '';
    if (hasNode && hasConnection) res = $localize`刪除所選擇的節點及連結線`;
    else if (hasNode) res = $localize`刪除所選擇的節點`;
    else if (hasConnection) res = $localize`刪除所選擇的連結線`;
    else res = '';
    if (selected.length > 1) {
      res += $localize`(共${selected.length}個)`;
    }
    return res;
  }
}
