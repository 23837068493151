import { Routes } from '@angular/router';
import { MarketPlaceGuard } from '../market-place.guard';
import { LandingPageComponent } from './pages/landing/landing.page';
import { SaveReminder } from './save-reminder';
import { VerifyToken } from './verify-token';
import { VerifyTokenAndAuth } from './verify-token-and-auth';

export const routes: Routes = [
  {
    path: 'policy',
    loadChildren: () => import('./pages/policy/policy.routes'),
  },
  {
    path: 'sso',
    loadChildren: () => import('./pages/sso/sso.routes'),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.routes'),
  },
  {
    path: 'market-place',
    loadChildren: () => import('./pages/market-place/market-place.routes'),
    canActivate: [MarketPlaceGuard],
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.routes'),
    canActivate: [VerifyToken],
    data: { disableAuthCheck: true },
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.routes'),
  },
  {
    path: 'broadcast',
    loadChildren: () => import('./pages/broadcast/broadcast.routes'),
    canActivate: [VerifyTokenAndAuth],
    canDeactivate: [SaveReminder],
  },
  {
    path: 'private-message',
    loadChildren: () =>
      import('./pages/private-message/private-message.routes'),
    canActivate: [VerifyTokenAndAuth],
    canDeactivate: [SaveReminder],
  },
  {
    path: 'friends',
    loadChildren: () => import('./pages/friends/friends.routes'),
    canActivate: [VerifyTokenAndAuth],
    canDeactivate: [SaveReminder],
  },
  {
    path: 'filter',
    loadChildren: () => import('./pages/filter/filter.routes'),
    canActivate: [VerifyTokenAndAuth],
    canDeactivate: [SaveReminder],
  },

  {
    path: 'tag',
    loadChildren: () => import('./pages/tag/tag.routes'),
    canActivate: [VerifyTokenAndAuth],
    canDeactivate: [SaveReminder],
  },

  {
    path: 'property',
    loadChildren: () => import('./pages/property/property.routes'),
    canActivate: [VerifyTokenAndAuth],
    canDeactivate: [SaveReminder],
  },

  {
    path: 'flow',
    loadChildren: () => import('./pages/flow/flow.routes'),
    canActivate: [VerifyTokenAndAuth],
    canDeactivate: [SaveReminder],
  },

  {
    path: 'resource',
    loadChildren: () => import('./pages/resource/resource.routes'),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'keyword',
    loadChildren: () => import('./pages/keyword/keyword.routes'),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'imaging',
    loadChildren: () => import('./pages/imaging/imaging.routes'),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'richmenu',
    loadChildren: () => import('./pages/rich-menu/rich-menu.routes'),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'bot',
    loadChildren: () => import('./pages/bot/bot.routes'),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.routes'),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'company',
    loadChildren: () => import('./pages/company/company.routes'),
    canActivate: [VerifyToken],
  },

  {
    path: 'chart',
    loadChildren: () => import('./pages/chart/chart.routes'),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'dynamic-form',
    redirectTo: 'form',
  },
  {
    path: 'form',
    loadChildren: () => import('./pages/dynamic-form/dynamic-form.routes'),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'coupon',
    loadChildren: () => import('./pages/coupon/coupon.routes'),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'exchange',
    loadChildren: () => import('./pages/exchange/exchange.routes'),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'ticket',
    loadChildren: () => import('./pages/ticket-event/ticket-event.routes'),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'directus',
    loadComponent: () => import('./pages/directus/directus.component'),
    canActivate: [VerifyTokenAndAuth],
  },
  {
    path: 'log',
    loadChildren: () => import('./pages/log/log.routes'),
    canActivate: [VerifyTokenAndAuth],
  },
  {
    path: 'store',
    loadChildren: () => import('./pages/store/store.routes'),
    canActivate: [VerifyTokenAndAuth],
  },
  {
    path: 'promotion-channel',
    loadChildren: () =>
      import('./pages/promotion-channel/promotion-channel.routes'),
    canActivate: [VerifyTokenAndAuth],
  },
  {
    path: 'embedded-private-message',
    loadChildren: () =>
      import(
        './pages/embedded-private-message/embedded-private-message.routes'
      ),
  },
  {
    path: 'data-source',
    loadChildren: () => import('./pages/data-source/data-source.routes'),
    canActivate: [VerifyTokenAndAuth],
  },
  {
    path: 'interactive',
    loadChildren: () => import('./pages/interactive/interactive.routes'),
    canActivate: [VerifyTokenAndAuth],
  },
  {
    path: '**',
    component: LandingPageComponent,
    canActivate: [VerifyToken],
  },
];
