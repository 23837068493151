<div class="container full">
  <div fxLayout="row" fxLayoutGap="15px">
    <mat-checkbox
      class="form-field"
      fxFlex="0 0 140px"
      [(ngModel)]="node.processed"
      value="true"
      color="primary"
      i18n
    >
      標示為已處理
    </mat-checkbox>
  </div>
  <div fxLayout="row" fxLayoutGap="15px" class="row">
    <mat-form-field class="action-selector" legacyAppearance>
      <mat-label i18n>操作行為</mat-label>
      <mat-select [(ngModel)]="node.action">
        <mat-option *ngFor="let action of actions" [value]="action.value">
          {{ action.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="1 1 auto" legacyAppearance>
      <mat-label i18n>設定對象</mat-label>
      <mat-select
        [(ngModel)]="userType"
        (ngModelChange)="node.profileId = $event == 'profileId' ? '' : $event"
      >
        <mat-option value i18n>觸發事件的使用者</mat-option>
        <mat-option value="profileId" i18n>特定的使用者</mat-option>
        <mat-option value="*" i18n>所有的使用者</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      fxFlex="1 0 0"
      *ngIf="userType === 'profileId'"
      legacyAppearance
    >
      <mat-label i18n>使用者編號</mat-label>
      <mat-chip-grid #chipList>
        <mat-chip-row
          *ngFor="let profileId of profileIds"
          selectable="true"
          removable="true"
          (removed)="removeProfileId(profileId)"
        >
          {{ profileId }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>

        <input
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addProfileId($event)"
        />
      </mat-chip-grid>

      <mat-hint i18n>
        留空代表觸發者; 逗點分隔使用者編號; * 代表全部所有人
      </mat-hint>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutGap="15px" class="row">
    <mat-form-field
      class="bot-selector"
      *ngIf="node.action === 'link'"
      legacyAppearance
    >
      <mat-label i18n>LINE 機器人</mat-label>
      <mat-select [(ngModel)]="node.botId" (ngModelChange)="getRichMenuList()">
        <mat-option *ngFor="let botId of botIds$ | async" [value]="botId">
          {{ botId | bot }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="node.botId && node.action === 'link'">
      <mat-form-field
        fxFlex="1 0 0"
        class="richmenu-selector"
        *ngIf="!isCodeMode"
        legacyAppearance
      >
        <mat-label i18n>圖文選單</mat-label>
        <mat-select
          [(ngModel)]="node.richMenuId"
          (ngModelChange)="setNodeRichMenu()"
        >
          <mat-option
            class="menu"
            *ngFor="let menu of richMenus"
            [value]="menu.richMenuId"
          >
            <div class="flex items-center">
              <img
                class="preview fl"
                [src]="getRichMenuImageUrl(menu.richMenuId)"
              />
              <div>
                <div class="title">{{ menu.name }}</div>
                <div class="subtitle">{{ menu.chatBarText }}</div>
                <div class="hint">
                  (<span i18n> {{ menu.areas.length }} 個按鈕， </span>
                  <span>
                    <ng-container *ngIf="menu.selected; else hidden" i18n>
                      預設展開
                    </ng-container>
                    <ng-template #hidden i18n>預設隱藏</ng-template> </span
                  >)
                </div>
              </div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        mat-icon-button
        class="curp code-icon"
        *ngIf="!isCodeMode"
        (click)="node.richMenuId = '${  }'"
      >
        <mat-icon class="mat-18">code</mat-icon>
      </button>

      <mat-form-field
        *ngIf="isCodeMode"
        class="fw input-color"
        legacyAppearance
      >
        <mat-label i18n>圖文選單</mat-label>
        <input matInput [(ngModel)]="node.richMenuId" />
      </mat-form-field>
    </ng-container>
  </div>

  <img
    *ngIf="
      node.botId && node.action === 'link' && node.richMenuId && !isCodeMode
    "
    class="preview"
    [src]="getRichMenuImageUrl(node.richMenuId)"
  />
</div>
