import { Node } from '../class';

export class RandomCondition {
  public weight: string = '1';
  public name: string = $localize`機率`;
  public color: string = '#333';
  public constructor() {}
}

export class RandomNode extends Node {
  public icon = 'donut_small';
  /** 隨機取出的數量 */
  public amount = '1';
  /** 允許取出重複的結果 */
  public repeatable = false;
  public conditions: RandomCondition[] = [];
  public readonly extraProperties = ['amount', 'repeatable', 'conditions'];

  public constructor() {
    super();
    this._create();
  }

  public _create() {
    let condition = new RandomCondition();
    condition.name = $localize`情況 1`;
    let color = '#ECEFF1';

    condition.color = color;

    this.conditions = [condition];
  }
}
