import { delay } from 'bluebird';
import { firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { GosuValidate } from '../../../../components/gosu-validator/gosu-validator.class';
import { UnknownPropertyError } from '../../../../service/property-config.service';
import { OpMap } from '../op';
import { FromJsonOption, Rule } from '../rule';

export class PropertyRule<
  VALUE_TYPE = string | number | Date | (string | number | Date)[],
> extends Rule<VALUE_TYPE> {
  public constructor() {
    super('PROPERTY');
  }

  public async afterTypeChanged(isUserEvent = false) {
    if (isUserEvent) {
      await delay(1);
      if (this.component.property?.propertyTrigger) {
        this.component.property.propertyTrigger.openMenu();
      }
    }
  }

  public async fromJSON(json: any, option: FromJsonOption) {
    super.fromJSON(json, option);
    let key = json.key;

    let properties = await firstValueFrom(
      option.propertyConfigService.all$.pipe(
        first((properties) => properties !== null),
      ),
    );

    this.property = properties.find((property) => property.key === key);

    if (this.property === undefined) {
      throw new UnknownPropertyError(key, properties);
    }

    if (this.op == 'BETWEEN') {
      this.value = json.value;
      this.value2 = json.value2;
    } else if (this.op === 'IN_LIST' || this.op === 'ALL_IN_LIST') {
      this.value = json.value.split(',');
    } else {
      this.value = json.value;
      this.value2 = 0;
    }
  }

  public toRuleObject(json: any = {}) {
    super.toRuleObject(json);
    json.key = this.property.key;
    if (this.op == 'BETWEEN') {
      json.value = this.value;
      json.value2 = this.value2;
    } else if (this.op === 'IN_LIST' || this.op === 'ALL_IN_LIST') {
      json.value = this.value.toString();
    } else {
      json.value = this.value;
    }
    return json;
  }

  public static schema: string = 'property';

  public toString(): string {
    throw new Error('Method not implemented.');
  }

  public toShortCode(): string {
    switch (this.op) {
      case 'BETWEEN':
        return `${this.property.key},${OpMap[this.op]},${this.value},${
          this.value2
        }`;

      case 'EXISTS':
      case 'NOT_EXISTS':
        return `${this.property.key},${OpMap[this.op]}`;

      default:
        return `${this.property.key},${OpMap[this.op]},${this.value}`;
    }
  }

  public static async fromShortCode(code: string, option: FromJsonOption) {
    let [key, op, value, value2] = code.split(',');
    if (op === 'IN_LIST' || op === 'ALL_IN_LIST') {
      value = code.split(',').slice(2).join(',');
    }

    let rule = new this();
    rule.fromJSON({ key, op: OpMap[op], value, value2 }, option);
    return rule;
  }

  public checkError(): boolean {
    if (this.op === 'EXISTS' || this.op === 'NOT_EXISTS') {
      return false;
    }
    //列表
    if (
      this.property.dataType === 'list' &&
      GosuValidate.arrayRequired(this.property.key)
    ) {
      return true;
    }
    if (
      this.property.dataType === 'list' &&
      GosuValidate.arrayRequired(this.value)
    ) {
      return true;
    }
    //數字
    if (
      this.property.dataType === 'number' &&
      this.op === 'BETWEEN' &&
      GosuValidate.required(this.value2)
    ) {
      return true;
    }
    if (
      this.property.dataType === 'number' &&
      GosuValidate.required(this.property.key)
    ) {
      return true;
    }
    if (
      this.property.dataType === 'number' &&
      GosuValidate.required(this.value)
    ) {
      return true;
    }
    //字串
    if (
      this.property.dataType === 'string' &&
      GosuValidate.required(this.property.key)
    ) {
      return true;
    }
    if (
      this.property.dataType === 'string' &&
      GosuValidate.required(this.value)
    ) {
      return true;
    }
    return false;
  }
}
