import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[inputWithRemind]',
  standalone: true,
})
export class InputWithRemindDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  public ngOnInit() {
    const formField = this.el.nativeElement;
    this.renderer.addClass(formField, 'mat-form-field-with-error');
  }
}
