<div class="container">
  <ng-container [ngSwitch]="type">
    <ng-template ngSwitchCase="success">
      <mat-icon>done</mat-icon>
    </ng-template>
    <ng-template ngSwitchCase="failure">
      <mat-icon class="failure">close</mat-icon>
    </ng-template>
    <ng-template ngSwitchCase="alert">
      <mat-icon class="alert">priority_high</mat-icon>
    </ng-template>
    <ng-template ngSwitchCase="warning">
      <mat-icon class="warning">warning</mat-icon>
    </ng-template>
  </ng-container>

  <h3 [class.small]="type === 'alert' || type === 'warning'">{{ title }}</h3>

  <div class="content" [innerHTML]="content"></div>

  <div class="buttons" [class.isVertical]="secondButton?.isVertical">
    <button mat-raised-button color="primary" (click)="close()">
      <div class="button-text" [class.isIconLeft]="isIconLeft">
        <span> {{ confirm }} </span>
        <mat-icon *ngIf="icon">{{icon}}</mat-icon>
      </div>
    </button>

    <button
      *ngIf="secondButton"
      class="secondButton"
      mat-raised-button
      color="primary"
      (click)="secondButton.click()"
    >
      <div class="button-text" [class.isIconLeft]="secondButton.isIconLeft">
        <span> {{ secondButton.text }} </span>
        <mat-icon *ngIf="secondButton.icon">{{secondButton.icon}}</mat-icon>
      </div>
    </button>
  </div>
</div>
