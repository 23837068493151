import { Map } from '@ay/util';
import { PageService } from '../../../service/page.service';
import { Class } from '../flow.util';
import { Node } from './class';
import { FormComponent } from './form.component';
import { NodeComponent } from './node.component';

export type Config = {
  name: string;
  type: string;
  node: Class<Node>;
  nodeComponent: Class<NodeComponent>;
  formComponent: Class<FormComponent>;
  toolbar?: {
    name?: string;
    icon: string;
    color: string;
    expert?: boolean;
    hidden?: boolean;
    hiddenFn?: HiddenFunction;
    tag?: string;
  };
};
type HiddenFunction = (pageService: PageService) => Promise<boolean>;

export let configs: Config[] = [];
export let Nodes: Map<Class<Node>> = {};
export let NodeComponents: Map<Class<NodeComponent>> = {};
export let FormComponents: Map<Class<FormComponent>> = {};
export let DynamicNodeComponentList = [];
export let DynamicFormComponentList = [];

export function registerNode(..._configs: Config[]) {
  _configs.map((config) => {
    configs.push(config);
    if (DynamicNodeComponentList.indexOf(config.nodeComponent) == -1) {
      DynamicNodeComponentList.push(config.nodeComponent);
    }
    if (DynamicFormComponentList.indexOf(config.formComponent) == -1) {
      DynamicFormComponentList.push(config.formComponent);
    }
    Nodes[config.type] = config.node;
    NodeComponents[config.type] = config.nodeComponent;
    FormComponents[config.type] = config.formComponent;
  });
}
