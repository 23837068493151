import { RuleOp } from '@ay-gosu/types';
import {
  ensurePropertyIsArray,
  ensureTheElementIsWhitelisted,
  ensureTheElementsAreWhitelisted,
} from '@ay/util';
import { delay } from 'bluebird';
import { PlatformType } from '../../../../service/platform.service';
import { FromJsonOption, Rule } from '../rule';
import { ruleJoin } from '../ruleJoin';

export class PlatformRule extends Rule<PlatformType[]> {
  public op: RuleOp = 'IN_LIST';

  public constructor(...value: PlatformType[]) {
    super('PLATFORM');
    this.value = value;
  }

  public async afterTypeChanged(isUserEvent = false) {
    ensurePropertyIsArray(this, 'value');
    this.op = ensureTheElementIsWhitelisted(this.op, 'IN_LIST');

    this.value = ensureTheElementsAreWhitelisted(
      this.value,
      'Facebook',
      'Line',
      'Instagram',
      'MDBS',
      'WebChat',
    );

    if (isUserEvent) {
      await delay(1);
      this.component.platform.platformTrigger.open();
    }
  }

  public async fromJSON(json: any, option: FromJsonOption) {
    await super.fromJSON(json, option);
    this.value = json.value.split(',').filter((row: string) => row != '');
  }

  public toRuleObject(json: any = {}) {
    json = super.toRuleObject(json);
    json.value = this.value.join(',');
    return json;
  }

  public similar(keyword: string): boolean {
    return this.value.join(',').toLowerCase().indexOf(keyword) !== -1;
  }

  public toString() {
    return $localize`社群平台是${ruleJoin(this.value)}`;
  }

  public static schema: string = 'platform';

  public toShortCode(): string {
    return `${this.value.join(',')}`;
  }

  public static async fromShortCode(code: string, option: FromJsonOption) {
    const type = ensureTheElementsAreWhitelisted(
      code.split(','),
      'Facebook',
      'Instagram',
      'LineMessagingAPI',
      'LineModuleChannel',
      'Line',
      'MDBS',
      'WebChat',
    );

    return new PlatformRule(...(type as PlatformType[]));
  }

  public checkError(): boolean {
    if (this.value && this.value.length === 0) {
      return true;
    }
    return false;
  }
}
