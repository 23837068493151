import { Component, forwardRef } from '@angular/core';
import { host, NodeComponent } from '../node.component';
import { DirectusNode } from './class';
import { ConditionComponent } from '../condition/condition.component';
import { NgIf } from '@angular/common';
import { JunctionComponent } from '../../junction/junction.component';

@Component({
    selector: ':svg:g.exchange.node',
    templateUrl: './node.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['node'],
    providers: [
        {
            provide: NodeComponent,
            useExisting: forwardRef(() => DirectusNodeComponent),
        },
    ],
    standalone: true,
    imports: [
        JunctionComponent,
        NgIf,
        ConditionComponent,
    ],
})
export class DirectusNodeComponent extends NodeComponent<DirectusNode> {}
