import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { Content } from '@ay/bot';
import { delay } from 'bluebird';
import { firstValueFrom } from 'rxjs';
import { EmptyResponseError } from '../../../util/empty-response-error';
import { BasicDialog } from '../../dialog/basic';
import { BaseComponent } from '../base/base.component';
import { Card } from './card/card.class';
import { CardsMessageService } from './cards-message.service';
import { CardsMessage } from './cards.message';
import { CardTemplatePickerDialog } from './template-picker/template-picker.dialog';
import { ElementRefDirective } from '../../components/element-ref.directive';
import { MatDivider } from '@angular/material/divider';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatContextMenuDirective } from '../../material/context-menu';
import { CardComponent } from './card/card.component';
import { MatTooltip } from '../../material/tooltip/tooltip';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'ms-cards',
    templateUrl: './cards.component.html',
    styleUrls: ['./cards.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['package', 'mode', 'message'],
    standalone: true,
    imports: [
        NgIf,
        CdkDropList,
        NgClass,
        ExtendedModule,
        MatTooltip,
        NgFor,
        CdkDrag,
        CardComponent,
        MatContextMenuDirective,
        MatMenu,
        MatMenuItem,
        MatIcon,
        MatMenuTrigger,
        MatButton,
        MatDivider,
        ElementRefDirective,
    ],
})
export class CardsComponent
  extends BaseComponent<CardsMessage>
  implements AfterViewInit
{
  public selectedCard: Card;

  public get cardIndex() {
    try {
      return this.message.content.cards.indexOf(this.selectedCard);
    } catch (error) {
      return 0;
    }
  }

  public get messageIndex() {
    return this.package.messages.indexOf(this.message);
  }

  public get isFirstCard() {
    return this.cardIndex === 0;
  }

  public get isLastCard() {
    if (this.message.content.cards === undefined) return false;
    return this.cardIndex == this.message.content.cards.length - 1;
  }

  public get isFirstMessage() {
    return this.messageIndex === 0;
  }

  public get isLastMessage() {
    return this.messageIndex == this.package.messages.length - 1;
  }

  @Input()
  public mode: 'READ' | 'EDIT' = 'EDIT';

  public insertDirection: 'FRONT' | 'BEHIND';

  @HostListener('wheel', ['$event'])
  public onWheel($event: WheelEvent) {
    let element = this._elementRef.nativeElement;
    if (
      ($event.deltaY > 0 &&
        element.scrollLeft + element.clientWidth < element.scrollWidth) ||
      ($event.deltaY < 0 && element.scrollLeft > 0)
    ) {
      element.scrollLeft += $event.deltaY;
      $event.stopPropagation();
      $event.stopImmediatePropagation();
      $event.preventDefault();
    }
  }

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _matConnectedDialog: MatConnectedDialog,
    private readonly _cardsMessageService: CardsMessageService,
    private readonly _basicDialog: BasicDialog,
  ) {
    super(changeDetectorRef);
  }

  public ngAfterViewInit() {
    this.checkError();
  }

  public hasError: boolean = false;

  public checkError() {
    this.hasError = this.package.checkError();
  }

  public async swap(origin: number, destination: number) {
    await delay(1);
    let cards = this.message.content.cards;
    let tmp = cards[destination];
    cards[destination] = cards[origin];
    cards[origin] = tmp;
  }

  public trackByCard(index: number, card: Card) {
    return (card as any).cardId || index;
  }

  public drop(event: CdkDragDrop<any>) {
    moveItemInArray(
      this.message.content.cards,
      event.previousIndex,
      event.currentIndex,
    );
  }

  public swapCardLeft() {
    let index = this.cardIndex;
    this.swap(index, index - 1);
  }

  public swapCardRight() {
    let index = this.cardIndex;
    this.swap(index, index + 1);
  }

  public swapMessageUpward() {
    let index = this.messageIndex;
    this.package.swap(index, index - 1);
  }

  public swapMessageDownward() {
    let index = this.messageIndex;
    this.package.swap(index, index + 1);
  }

  public deleteCard() {
    let index = this.cardIndex;
    this.message.content.cards.splice(index, 1);
    this.changeDetectorRef.markForCheck();

    if (this.message.content.cards.length === 0) {
      this.package.messages.splice(this.messageIndex, 1);
      this.package.changed();
    }
  }

  public async deleteCards() {
    this.package.messages.splice(this.messageIndex, 1);
    this.package.changed();
  }

  public async insertFrontCard() {
    this.insertDirection = 'FRONT';
  }

  public async insertBehindCard() {
    this.insertDirection = 'BEHIND';
  }

  public async pickCards(): Promise<Card[]> {
    try {
      let dialog = this._matConnectedDialog.open(CardTemplatePickerDialog);
      let cards = await firstValueFrom(dialog.afterClosed());
      return cards;
    } catch (error) {}
  }

  public async editCard() {
    try {
      await this._cardsMessageService.openEditor(
        this.selectedCard,
        this.message,
        this.package,
      );
      this.message.changed();
      this.checkError();
    } catch (error) {}
  }

  public async openCreator(): Promise<void> {
    try {
      let message = await this._cardsMessageService.createFromCreator(
        this.package,
      );
      const content = message.content as Content.Cards;
      this.insertCard(content.cards as Card[]);
      this.checkError();
    } catch (error) {
      if (error instanceof EmptyResponseError) return;
      throw error;
    }
  }

  public async openTemplatePicker(): Promise<void> {
    try {
      const message =
        await this._cardsMessageService.createFromTemplatePicker();
      const content = message.content as Content.Cards;
      this.insertCard(content.cards as Card[]);
    } catch (error) {
      if (error instanceof EmptyResponseError) return;
      throw error;
    }
  }

  public insertCard(cards: Card[]) {
    if (this.insertDirection === 'FRONT') {
      this.message.content.cards.splice(this.cardIndex, 0, ...cards);
    } else {
      this.message.content.cards.splice(this.cardIndex + 1, 0, ...cards);
    }
    this.changeDetectorRef.markForCheck();
  }

  public async editAlt(elementRef: ElementRef) {
    const name = await this._basicDialog.rename(this.message.content.alt, {
      elementRef,
    });
    this.message.content.alt = name;
  }
}
