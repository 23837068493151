import { Component, Input, forwardRef } from '@angular/core';
import { PromiseLock } from '@ay/util';
import cloneDeep from 'lodash/cloneDeep';
import {
  CouponService,
  CouponColumnWithStatus as CouponWithStatus,
} from '../../../service/coupon.service';
import { AnnouncementMessage } from '../../announcement/announcement.message';
import { PackageFactoryService } from '../../package-factory.service';
import { Package } from '../../package.class';
import { PreviewComponent } from '../../preview/preview.component';
import { TextMessage } from '../../text/text.message';
import { CouponMessage } from '../coupon.message';

@Component({
  selector: 'cp-coupon-preview',
  templateUrl: './coupon-preview.component.html',
  styleUrls: ['./coupon-preview.component.scss'],
  standalone: true,
  imports: [forwardRef(() => PreviewComponent)],
})
export class CouponPreviewComponent {
  public lock = new PromiseLock();
  public package: Package;

  @Input()
  public set coupon(data: CouponWithStatus) {
    this.setCoupon(data);
  }

  public constructor(
    private readonly _couponService: CouponService,
    private readonly _packageFactoryService: PackageFactoryService,
  ) {}

  public async setCoupon(data: CouponWithStatus) {
    if (!data) {
      return;
    }

    const unlock = await this.lock.lock();

    if (!data.no) {
      data.no = 'FAKE01';
    }

    if (this.package === undefined) {
      this.package = await this._packageFactoryService.createForPreview();
    }

    this.package.messages.splice(0, this.package.messages.length);

    const couponMessage = new CouponMessage(this._couponService);
    couponMessage.coupon = data;
    this.package.add(couponMessage);

    if (data.shareable && data.shareText) {
      const announcement = new AnnouncementMessage();
      announcement.content.content = $localize`分享給好友後，好友會收到以下訊息`;
      this.package.add(announcement);

      const message = new TextMessage();
      message.content.content = `${data.shareText}\n\nhttps://api.gosu.bar/coupon/share/FAKE01`;
      this.package.add(message);
    }

    if (data.isLimited) {
      const announcement = new AnnouncementMessage();
      announcement.content.content = $localize`當優惠券用發放完畢時，會出現以下訊息`;
      this.package.add(announcement);

      let coupon = cloneDeep(data);
      delete coupon.no;
      const message = new CouponMessage(this._couponService);
      message.coupon = coupon;
      this.package.add(message);
    }

    unlock.resolve();
  }
}
