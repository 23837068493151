import { NgFor, NgIf, NgSwitch } from '@angular/common';
import { AfterContentInit, Component, ElementRef } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { Map } from '@ay/util';
import { firstValueFrom } from 'rxjs';
import { GosuValidatorComponent } from '../../../../components/gosu-validator/gosu-validator.component';
import { CreatePropertyConfigDialog } from '../../../../dialog/create-property-config';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../../material/tooltip/tooltip';
import { PropertyConfigService } from '../../../../service/property-config.service';
import { FlowService } from '../../flow.service';
import { FormComponent } from '../form.component';
import { SetterNode } from './class';
import { CommandComponent } from './command/command.component';

@Component({
  selector: 'flow-setter-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    MatOption,
    NgIf,
    MatInput,
    MatTooltip,
    GosuValidatorComponent,
    MatCheckbox,
    NgFor,
    CommandComponent,
    NgSwitch,
    MatButton,
    MatIcon,
    LegacyAppearanceDirective,
  ],
})
export class SetterFormComponent
  extends FormComponent<SetterNode>
  implements AfterContentInit
{
  public targetMap: Map<string> = {
    profile: $localize`使用者`,
    bot: $localize`機器人`,
    company: $localize`組織`,
    record: $localize`聊天記錄`,
  };

  public categoryText = $localize`類別`;

  public indexText = $localize`編號`;

  public showIdInput = false;

  public notText = $localize`非`;

  public constructor(
    public flow: FlowService,
    public elementRef: ElementRef,
    public _propertyConfigService: PropertyConfigService,
    private _matConnectedDialog: MatConnectedDialog,
  ) {
    super(elementRef);
  }

  public ngOnDestroy() {
    this.node.checkError();
  }

  public afterTargetChange() {
    if (this.node.target == 'company') {
      this.node.item = 'property';
    }
    this.node.commands = [];
    this.addCommand();
  }

  public ngAfterContentInit() {
    this._formatMigration();

    if (!this.node.commands.length) {
      this.addCommand();
    }
    this.showIdInput = !!this.node.targetId;
  }

  private _formatMigration() {
    if (this.node.item) {
      this.node.commands.push({
        item: this.node.item,
        key: this.node.key,
        action: this.node.action,
        tagIds:
          this.node.tagIds instanceof Array ? this.node.tagIds.slice(0) : [],
        value: this.node.value,
        reason: '',
      });
    }

    this.node.item = null;
    this.node.key = null;
    this.node.action = null;
    this.node.tagIds = null;
    this.node.value = null;
    this.node.variableTime = null;
  }

  public deleteCommand(idx) {
    this.node.commands.splice(idx, 1);
  }

  public addCommand() {
    this.node.commands.push({
      item: 'property',
      key: '',
      action: 'set',
      tagIds: [],
      value: '',
      reason: '',
    });
  }

  public async addPropertyConfig() {
    const dialogRef = this._matConnectedDialog.open(CreatePropertyConfigDialog);
    const key = await firstValueFrom(dialogRef.afterClosed());
    if (key) {
      this.node.commands.push({
        item: 'property',
        key,
        action: 'set',
        tagIds: [],
        value: '',
        reason: '',
      });
    }
  }
}
