import { Injectable } from '@angular/core';
import { TemplateDto, TemplatePropertyDto } from '@ay-gosu/server-shared';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { Area, Content } from '@ay/bot';
import { firstValueFrom } from 'rxjs';
import { Package } from '../package.class';
import { PosterCreatorDialog } from './creator/creator.dialog';
import { PosterMessage } from './poster.message';
import { PosterTemplateEditorDialog } from './template-editor/template-editor.dialog';
import { PosterTemplatePickerDialog } from './template-picker/template-picker.dialog';

@Injectable({
  providedIn: 'root',
})
export class PosterMessageService {
  public constructor(protected matConnectedDialog: MatConnectedDialog) {}

  public async createFromEditor(pkg: Package): Promise<PosterMessage> {
    const poster = new Content.Poster('', $localize`一張海報`, [
      new Area.Message($localize`訊息內容`, 0, 0, 10000, 10000),
    ]);

    const message = new PosterMessage();
    await message.loadFromContent(poster);

    const dialogRef = this.matConnectedDialog.open(PosterCreatorDialog, {
      data: { poster, message, package: pkg },
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (!response) return null;

    return message;
  }

  public async createFromTemplatePicker(): Promise<PosterMessage> {
    const dialogRef = this.matConnectedDialog.open(PosterTemplatePickerDialog);

    const template: TemplateDto = await firstValueFrom(dialogRef.afterClosed());

    if (template == null) {
      return null;
    }

    const content = new Content.Template(
      'poster',
      template.id,
      this.convertTemplateProperties(template.properties),
    );

    const message = new PosterMessage();
    await message.loadFromContent(content);

    return message;
  }

  public async openEditor(message: PosterMessage, pkg: Package) {
    if (message.template) {
      this.openTemplateEditor(message);
    } else {
      this.openGeneralEditor(message, pkg);
    }
  }

  public async openTemplateEditor(message: PosterMessage) {
    this.matConnectedDialog.open(PosterTemplateEditorDialog, {
      data: message,
    });
  }

  public async openGeneralEditor(message: PosterMessage, pkg: Package) {
    this.matConnectedDialog.open(PosterCreatorDialog, {
      data: { poster: message.content, message, package: pkg },
    });
  }

  protected convertTemplateProperties(
    properties: TemplatePropertyDto[],
  ): { key: string; name: string; default: string }[] {
    return properties.map((property) => ({
      key: property.key,
      name: property.name,
      default: property.default,
    }));
  }
}
