import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { LocationMessage } from './location.message';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'ms-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['package', 'mode', 'message'],
    standalone: true,
    imports: [AsyncPipe],
})
export class LocationComponent extends BaseComponent<LocationMessage> {
  public src$ = this.message$.pipe(
    map((message) => {
      const q = `${message.content.latitude},${message.content.longitude}`;
      return this._domSanitizer.bypassSecurityTrustResourceUrl(
        `https://maps.google.com.tw/maps?f=q&hl=zh-TW&geocode=&q=${q}&z=16&output=embed&t=`,
      );
    }),
  );

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _domSanitizer: DomSanitizer,
  ) {
    super(changeDetectorRef);
  }
}
