import { FilterModel } from '@ay-gosu/server-shared';
import { FromJsonOption, Rule } from '../rule/rule';

export class GroupRule extends Rule {
  public constructor(public id, public name) {
    super('GROUP');
  }

  public similar(keyword: string) {
    return this.name.indexOf(keyword) !== -1;
  }

  public isEqual(rule: Rule): boolean {
    return rule.type == 'GROUP' && this.id == rule.id;
  }

  public static schema: string = 'group';

  public toString(): string {
    return '';
  }

  public toShortCode(): string {
    return this.id;
  }

  public afterTypeChanged(isUserEvent?: boolean) {}

  public static async fromShortCode(
    code: string,
    option: FromJsonOption,
  ): Promise<GroupRule> {
    const list = await FilterModel.getList();
    const groupId = parseInt(code, 10);
    const group = list.find((group) => group.id === groupId);
    if (group === undefined) return undefined;
    const rule = new GroupRule(groupId, group.name);
    return rule;
  }

  public checkError(): boolean {
    return false;
  }
}
