<div class="header" i18n>
  <p>編輯 Flex</p>
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="main" *ngIf="flexEditorService.regularizedFlex; else loading">
  <div class="viewer-pane">
    <ms-flex-preview
      [data]="flexEditorService.regularizedFlex"
    ></ms-flex-preview>
  </div>

  <mat-divider [vertical]="true"></mat-divider>

  <div
    class="control-pane"
    cdk-scrollable
    (mouseenter)="autoScroll = false"
    (mouseleave)="autoScroll = true"
  >
    <div class="control-btns">
      <p (click)="rename()" matTooltip="替代文字" i18n-matTooltip="替代文字">
        {{ flexEditorService.regularizedFlex.content.altText }}
      </p>
      <div class="f1"></div>
      <button
        mat-icon-button
        matTooltip="元件庫"
        i18n-matTooltip="元件庫"
        (click)="openComponents()"
        [disabled]="!canUseComponent"
      >
        <mat-icon>folder_special</mat-icon>
      </button>

      <button
        mat-icon-button
        (mousedown)="flexEditorClipBoardService.clipboard$.next('cut')"
        [disabled]="!flexEditorClipBoardService.canDelete"
        matTooltip="剪下 ctrl X"
        i18n-matTooltip="剪下 ctrl X"
      >
        <mat-icon>content_cut</mat-icon>
      </button>
      <button
        mat-icon-button
        (mousedown)="flexEditorClipBoardService.clipboard$.next('copy')"
        [disabled]="!flexEditorClipBoardService.canCopy"
        matTooltip="複製 ctrl+C"
        i18n-matTooltip="複製 ctrl+C"
      >
        <mat-icon>content_copy</mat-icon>
      </button>
      <button
        mat-icon-button
        (mousedown)="flexEditorClipBoardService.clipboard$.next('paste')"
        [disabled]="!flexEditorClipBoardService.canPaste"
        matTooltip="貼上 ctrl+V"
        i18n-matTooltip="貼上 ctrl+V"
      >
        <mat-icon>content_paste</mat-icon>
      </button>
      <button
        mat-icon-button
        (mousedown)="flexEditorClipBoardService.clipboard$.next('delete')"
        [disabled]="!flexEditorClipBoardService.canDelete"
        matTooltip="刪除 delete"
        i18n-matTooltip="刪除 delete"
      >
        <mat-icon>delete_outline</mat-icon>
      </button>

      <mat-divider [vertical]="true"></mat-divider>
      <button
        mat-icon-button
        [disabled]="!flexEditorService.undoStep.length"
        (click)="flexEditorService.undo()"
        matTooltip="復原 ctrl+Z"
        i18n-matTooltip="復原 ctrl+Z"
      >
        <mat-icon>undo</mat-icon>
      </button>
      <button
        mat-icon-button
        [disabled]="!flexEditorService.redoStep.length"
        (click)="flexEditorService.redo()"
        matTooltip="重做 ctrl+shift+Z"
        i18n-matTooltip="重做 ctrl+shift+Z"
      >
        <mat-icon>redo</mat-icon>
      </button>
    </div>

    <div *ngIf="screenWidth > 1440" class="content tree-pane" cdk-scrollable>
      <tree-pick
        #tree
        (mouseenter)="autoScroll = false"
        (mouseleave)="autoScroll = true"
      ></tree-pick>
    </div>

    <mat-expansion-panel
      *ngIf="screenWidth <= 1440"
      hideToggle
      [expanded]="expandTree"
      (closed)="expandTree = false"
      class="control-tree"
      cdk-scrollable
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n> 圖層結構 </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="content">
        <tree-pick #tree></tree-pick>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      *ngIf="screenWidth <= 1440"
      hideToggle
      [expanded]="true"
      class="control-prop"
    >
      <mat-expansion-panel-header>
        <mat-panel-title i18n> 屬性設定 </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="content">
        <flex-form></flex-form>
      </div>
    </mat-expansion-panel>
  </div>
  <mat-divider [vertical]="true"></mat-divider>

  <div *ngIf="screenWidth > 1440" class="prop-pane">
    <div class="content">
      <flex-form></flex-form>
    </div>
  </div>
</div>
<mat-divider class="full-divider"></mat-divider>
<div class="mat-dialog-actions">
  <button
    mat-stroked-button
    (click)="openJsonDialog()"
    i18n
    [matTooltip]="infoText"
  >
    <mat-icon>code</mat-icon>
    檢視 JSON
  </button>

  <button mat-stroked-button color="primary" (click)="openTemplates()" i18n>
    <mat-icon>dashboard</mat-icon>
    更換樣板
  </button>
  <div class="f1"></div>
  <button mat-stroked-button color="warn" (click)="close()" i18n>
    <mat-icon>clear</mat-icon>
    取消
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="save()"
    [disabled]="hasError"
    i18n
  >
    <mat-icon>check</mat-icon>
    確定
  </button>
</div>

<ng-template #infoText>
  <p style="line-height: 1.5" i18n>
    為協助您統計FLEX訊息的已讀數據，GOSU BAR
    系統會在FLEX程式碼的「body」內進行埋點，<br />
    故請勿刪除json內的「body」區塊；如您想設計僅有標題與圖片的訊息格式，請改用「海報」。
  </p>
</ng-template>

<ng-template #loading>
  <div class="main">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #jsonDialog>
  <div class="mat-dialog-content">
    <ng-container *ngIf="enableMonaco$ | async; else textarea">
      <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="json">
      </ngx-monaco-editor>
    </ng-container>

    <ng-template #textarea>
      <textarea class="code-space" [(ngModel)]="json"></textarea>
    </ng-template>
  </div>

  <div class="mat-dialog-actions" align="center" fxLayout="row">
    <div class="right">
      <button mat-stroked-button (click)="copyJson()" i18n>
        <mat-icon>content_copy</mat-icon>
        複製
      </button>

      <button
        mat-raised-button
        color="primary"
        cdkFocusInitial
        (click)="changeJson(json)"
        i18n
      >
        <mat-icon>done</mat-icon>
        確定修改
      </button>
    </div>
  </div>
</ng-template>
