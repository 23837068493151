import { FlowService } from '../flow.service';
import { Node } from '../node/class';
import { Command } from './command';

export class MoveNodeCommand extends Command {
  public constructor(
    private readonly _flowService: FlowService,
    private readonly _node: Node,
    private readonly _oldX: number,
    private readonly _oldY: number,
    private readonly _newX: number,
    private readonly _newY: number,
  ) {
    super();
  }

  public async do() {
    this._node.x = this._newX;
    this._node.y = this._newY;
    this._node.nodeComponent.updateTransform();
    this._node.nodeComponent.updateNearbyConnection();
    this._flowService.checkModify();
    let isSelected = this._flowService.selected.includes(this._node);
    if (!isSelected) this._flowService.selected.push(this._node);
  }

  public async undo() {
    this._node.x = this._oldX;
    this._node.y = this._oldY;
    this._node.nodeComponent.updateTransform();
    this._node.nodeComponent.updateNearbyConnection();
    this._flowService.checkModify();
    let isSelected = this._flowService.selected.includes(this._node);
    if (!isSelected) this._flowService.selected.push(this._node);
  }
}
