const group = {
  $expanded: false,
  $id: $localize`群組編號`,
  id: 8,
  $name: $localize`群組名稱`,
  name: $localize`未命名的群組`,
  $companyId: $localize`組織編號`,
  companyId: 1,
  $botId: $localize`機器人編號`,
  botId: 4,
  $platformType: $localize`平台類型(Line/Facebook)`,
  platformType: 'Line',
  $type: $localize`使用者類型 (Group/Room)`,
  type: 'User',
  $property: $localize`屬性`,
  property: {},
  $tag: $localize`標籤`,
  tag: {},
};

const profile = {
  $expanded: false,
  $id: $localize`使用者編號`,
  id: 3,
  $name: $localize`使用者名稱`,
  name: $localize`陳小明`,
  $companyId: $localize`組織編號`,
  companyId: 1,
  $botId: $localize`機器人編號`,
  botId: 4,
  $platformType: $localize`平台類型(Line/Facebook)`,
  platformType: 'Line',
  $type: $localize`使用者類型 (User/Group/Room)`,
  type: 'User',
  $picture: $localize`大頭貼照片網址`,
  picture: 'https://www.abc.com/avater.png',
  $message: $localize`使用者心情狀態`,
  message: $localize`葉子的離去 是風的追求 還是樹的不挽留`,
  $property: $localize`屬性`,
  property: {},
  $tag: $localize`標籤`,
  tag: {},
};

const bot = {
  $expanded: false,
  $id: $localize`機器人編號`,
  id: 3,
  $name: $localize`機器人名稱`,
  name: $localize`智能客服小i`,
  $companyId: $localize`組織編號`,
  companyId: 1,
  $secret: $localize`密鑰`,
  secret: '********************',
  $token: $localize`權杖`,
  token:
    '********************************************************************************',
  $property: $localize`屬性`,
  property: {},
};

const company = {
  $expanded: false,
  $id: $localize`組織編號`,
  id: 0,
  $name: $localize`組織名稱`,
  name: '',
  $property: $localize`屬性`,
  property: {},
};

const exchange = {
  id: 3,
  name: $localize`母親節兌換活動`,
  picture: '',
  content: $localize`積分集點換LINE POINT，200積分兌換15點LINE POINT，白鑽會員加碼再送 10 點`,
};

const request = {
  $get: $localize`Query String 傳入的資料`,
  get: { q: 'test' },
  $post: $localize`Body 傳入的資料`,
  post: { p: 'test' },
  $headers: $localize`標頭檔`,
  headers: {
    '$user-agent': $localize`瀏覽器資訊`,
    'user-agent':
      'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.149 Safari/537.36',
  },
};

const from = { $expanded: false, profile, bot, company };

// 'runner',
// 'id',
// 'from',
// 'to',
// 'record',
// 'postback',
// 'request',
// 'keywords',
// 'tmp',
// 'post',
// 'coupon',
// 'moment',
// 'numeral',
// 'console',
// '_',
// 'crypto',
// 'θcoin',
// 'θaddCoin',
// 'θconsumeCoin',
// 'result',
// 'profile',
// 'property',
// 'tag',
// 'name',
// 'picture',
// 'profileId',
// 'cache',
// 'ExhibitionEvent',
// 'HashId',
// 'exchange',
// 'exchangeMethod',
// 'code',
// 'ticketCode',
// 'ticket',

const FacebookAddCommentRecord = {
  $id: $localize`訊息編號`,
  id: 28121898,
  $type: $localize`訊息類型`,
  type: 'add-comment',
  $companyId: $localize`組織編號`,
  companyId: 8,
  $content: $localize`留言內容`,
  content: $localize`回應文章檢測`,
  $value: $localize`Facebook訊息原始資料`,
  value:
    '{"from":{"id"2225278000878797","name":"鄭宇凱"},"post":{"type":"status","status_type":"mobile_status_update","is_published":true,"updated_time":"2020-03-27T11:23:07+0000","permalink_url":"https://www.facebook.com/permalink.php?story_fbid=788683341655859&id=455384624985734","promotion_status":"inactive","id":"455384624985734_788683341655859"},"message":"回應文章檢測","post_id":"455384624985734_788683341655859","comment_id":"788683341655859_788690621655131","created_time":1585308186,"item":"comment","parent_id":"455384624985734_788683341655859","verb":"add"}',
  $postId: $localize`Facebook文章編號`,
  postId: '455384624985734_788683341655859',
  $createdAt: $localize`資料建立時間`,
  createdAt: '2020-03-27T11:23:09.933Z',
};

const FacebookAddLikeRecord = {
  $id: $localize`訊息編號`,
  id: 28124758,
  $type: $localize`訊息類型`,
  type: 'add-reaction',
  $companyId: $localize`組織編號`,
  companyId: 8,
  $content: $localize`心情類型`,
  content: 'like',
  $value: $localize`Facebook訊息原始資料`,
  value: $localize`{"from":{"id"2225278000878797","name鄭宇凱"},"post_id"455384624985734_788694711654722","created_time":1585311138,"item"reaction","parent_id"455384624985734_788694711654722","reaction_type"like","verb"add"}`,
  $postId: $localize`Facebook文章編號`,
  postId: '455384624985734_788694711654722',
  $createdAt: $localize`資料建立時間`,
  createdAt: '2020-03-27T12:12:20.782Z',
};

const FacebookPost = {
  $id: $localize`文章編號`,
  id: 62317,
  $botId: $localize`機器人編號`,
  botId: 657,
  $accountId: $localize`GOSU管理者編號`,
  accountId: null,
  $postId: $localize`Facebook文章編號`,
  postId: '455384624985734_788694711654722',
  $message: $localize`貼文內容`,
  message: $localize`貼文內容😃`,
  $picture: $localize`貼文圖片網址`,
  picture:
    'https://scontent.xx.fbcdn.net/v/t15.5256-10/83043087_322418805380750_7579574425020792832_n.jpg?_nc_cat=109&_nc_sid=ad6a45&_nc_ohc=TwGx0Emy5p4AX8NDxjV&_nc_ht=scontent.xx&oh=336c2b1f324df1619f9453af652d02e6&oe=5EA5280A',
  $link: $localize`貼文夾帶網址或影片連結`,
  link: 'https://www.facebook.com/455384624985734/videos/322418702047427/',
  $type: $localize`貼文類型`,
  type: 'video',
  $scheduledPublishTime: $localize`預約公開時間`,
  scheduledPublishTime: null,
  $name: $localize`貼文夾帶網址或影片連結的名稱`,
  name: null,
  $story: $localize`貼文的情境`,
  story: $localize`聽說讀寫覺得開心──在安永金融科技股份有限公司。`,
  $isPublished: $localize`貼文是否已公開`,
  isPublished: true,
  $createdAt: $localize`資料建立時間`,
  createdAt: '2020-03-27T11:31:32.000Z',
  $updatedAt: $localize`資料更新時間`,
  updatedAt: '2020-03-27T11:31:45.000Z',
};

export const demo = {
  expansion: {
    $profileId: $localize`使用者編號`,
    profileId: 3,
    $name: $localize`使用者名稱`,
    name: $localize`王小明`,
    $picture: $localize`大頭貼照片網址`,
    picture: 'https://www.abc.com/avater.png',
    $tag: $localize`使用者的標籤`,
    tag: profile.tag,
    $property: $localize`使用者的屬性`,
    property: profile.property,
    from,
  },
  group,
  profile,
  bot,
  company,
  from,
  exchange,
  request,
  fb: {
    addComment: {
      record: FacebookAddCommentRecord,
      post: FacebookPost,
    },
    addReaction: {
      record: FacebookAddLikeRecord,
      post: FacebookPost,
    },
  },
};
