import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ElementRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoadingComponent } from '../../../../components/loading/loading.component';
import { EditorComponent } from '../../../../components/ngx-monaco-editor/editor.component';
import { MonacoEditorLoaderService } from '../../../../components/ngx-monaco-editor/monaco-editor-loader.service';
import { loadDefaultDefinitelyFromCdn } from '../../../../definition/load-definitely-from-cdn';
import { ExecutorDTS } from '../../definition/executor';
import { ExhibitionEventDTS } from '../../definition/exhibition-event';
import { FormComponent } from '../form.component';
import { FuncNode } from './class';

@Component({
  selector: 'flow-func-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [LoadingComponent, EditorComponent, FormsModule, AsyncPipe],
  host: {
    class: 'w-full block bg-code py-6 px-0',
  },
})
export class FuncFormComponent
  extends FormComponent<FuncNode>
  implements AfterViewInit
{
  public width = 1200;
  public enableMonaco$ = this._monacoEditorLoaderService.enableMonaco$;
  public isLoading = true;

  public editorOptions = {
    theme: 'vs-dark',
    language: 'typescript',
    minimap: { enabled: false },
    snippetSuggestions: 'auto',
    automaticLayout: true,
  };

  public constructor(
    public elementRef: ElementRef,
    public applicationRef: ApplicationRef,
    private readonly _monacoEditorLoaderService: MonacoEditorLoaderService,
  ) {
    super(elementRef);
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    setTimeout(() => {
      this.dynamic.disablePadding = true;
    });
  }

  public async onMonacoEditorInit(editor) {
    try {
      this.isLoading = false;

      this.applicationRef.tick();

      const monaco = (window as any).monaco;
      const ts = monaco.languages.typescript.typescriptDefaults;
      ts.setExtraLibs([]);
      ts.addExtraLib(ExecutorDTS, 'Executor.d.ts');
      ts.addExtraLib(ExhibitionEventDTS, 'ExhibitionEvent.d.ts');
      await loadDefaultDefinitelyFromCdn(ts);
      window['editor'] = editor;
    } catch (error) {
      console.error(error);
    }
  }
}
