<mat-checkbox
  [style.width.em]="10"
  [style.padding-top.px]="18"
  [(ngModel)]="node.isAsync"
  color="primary"
  i18n
>
  {{ node.isAsync ? notText : '' }}同步模式
</mat-checkbox>

<div fxLayout="row" fxLayoutGap="15px">
  <mat-form-field fxFlex="calc(75% - 15px)" legacyAppearance>
    <mat-label i18n>報表</mat-label>
    <mat-select [(ngModel)]="node.configId">
      <mat-option *ngFor="let config of configs" [value]="config.id">
        {{ config.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field fxFlex="calc(25% - 15px)" legacyAppearance>
    <mat-label i18n>機器人</mat-label>
    <mat-select [(ngModel)]="node.botIds" name="bot" multiple>
      <mat-option *ngFor="let bot of bots$ | async" [value]="bot.id">
        {{ bot.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div fxLayout="row" fxLayoutGap="15px">
  <mat-form-field
    legacyAppearance
    fxFlex="2 0 0"
    *ngIf="!node.isProgrammableStart; else isProgrammableStart"
  >
    <mat-label i18n>起始日</mat-label>
    <input
      matInput
      [(ngModel)]="node.start"
      [matDatepicker]="startDatepicker"
    />
    <mat-datepicker-toggle matPrefix [for]="startDatepicker">
    </mat-datepicker-toggle>
    <mat-datepicker #startDatepicker></mat-datepicker>
    <mat-hint i18n>請依照格式輸入 YYYY-MM-DD</mat-hint>

    <button
      mat-icon-button
      matSuffix
      (click)="node.isProgrammableStart = !node.isProgrammableStart"
      matTooltip="開啟可程式化"
      i18n-matTooltip="開啟可程式化"
    >
      <mat-icon>code</mat-icon>
    </button>
  </mat-form-field>

  <ng-template #isProgrammableStart>
    <mat-form-field fxFlex="2 0 0" legacyAppearance>
      <mat-label i18n>起始日</mat-label>
      <input matInput [(ngModel)]="node.start" />

      <button
        mat-icon-button
        matSuffix
        (click)="node.isProgrammableStart = !node.isProgrammableStart"
        matTooltip="關閉可程式化"
        i18n-matTooltip="關閉可程式化"
      >
        <mat-icon>code</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <mat-form-field
    legacyAppearance
    fxFlex="2 0 0"
    *ngIf="!node.isProgrammableEnd; else isProgrammableEnd"
  >
    <mat-label i18n>迄止日</mat-label>
    <input matInput [(ngModel)]="node.end" [matDatepicker]="endDatepicker" />
    <mat-datepicker-toggle matPrefix [for]="endDatepicker">
    </mat-datepicker-toggle>
    <mat-datepicker #endDatepicker></mat-datepicker>
    <mat-hint i18n>請依照格式輸入 YYYY-MM-DD</mat-hint>

    <button
      mat-icon-button
      matSuffix
      (click)="node.isProgrammableEnd = !node.isProgrammableEnd"
      matTooltip="開啟可程式化"
      i18n-matTooltip="開啟可程式化"
    >
      <mat-icon>code</mat-icon>
    </button>
  </mat-form-field>

  <ng-template #isProgrammableEnd>
    <mat-form-field fxFlex="2 0 0" legacyAppearance>
      <mat-label i18n>迄止日</mat-label>
      <input matInput [(ngModel)]="node.end" />

      <button
        mat-icon-button
        matSuffix
        (click)="node.isProgrammableEnd = !node.isProgrammableEnd"
        matTooltip="關閉可程式化"
        i18n-matTooltip="關閉可程式化"
      >
        <mat-icon>code</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label i18n>時間分類辦法</mat-label>
    <mat-select [(ngModel)]="node.timeGroupWay">
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{ option.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div fxLayout="row" fxLayoutGap="15px" [style.margin-top.em]="1">
  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label i18n>包含使用者編號</mat-label>
    <mat-select [(ngModel)]="node.withDetail" i18n>
      <mat-option value="true">列出所有編號</mat-option>
      <mat-option value="false">只產生統計人數</mat-option>
    </mat-select>
    <mat-hint i18n>人數相關報表</mat-hint>
  </mat-form-field>

  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label i18n>回傳變數</mat-label>
    <input matInput [(ngModel)]="node.responseVar" />
    <mat-hint i18n>資料將會儲存於變數 tmp.{{ node.responseVar }} 中</mat-hint>
  </mat-form-field>
</div>
