// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { InteractiveDto } from './interactive.dto';

export class DirectRewardDto extends InteractiveDto {
  /** 活動類型 */
  public type = 'DIRECT_REWARD' as const;

  // 獎品編號
  public ticketId?: number;

  /*
   * 每人領取點數數量上限
   * 如果獎品類型是 回饋金百分比、LINE Point 百分比才需要填寫
   * 其他獎品類型填寫時無意義
   */
  public limitPointsPerProfile?: number;
}
// 2d2f7df341a17eb31c777b57b6a76965173bfb7b29f3f1193eecc6f2c353ce94
