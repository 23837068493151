<ng-container *ngIf="!message.templateLost; else templateLost">
  <div
    class="poster"
    [style.background-image]="
      'url(' + (message.content.content | imageType) + ')'
    "
    [style.height.px]="height$ | async"
    [matTooltip]="message.content.alt"
    [matContextMenu]="contextMenu"
    [matContextMenuDisable]="mode === 'READ'"
    #poster
  >
    <ng-container *ngIf="scale$ | async as scale">
      <div
        class="area"
        [class.active]="area.isSelected"
        *ngFor="let area of message.content.areas"
        [style.top.px]="area.y * scale"
        [style.left.px]="area.x * scale"
        [style.width.px]="area.width * scale"
        [style.height.px]="area.height * scale"
        [matTooltip]="(area.type | areaType) + ' : ' + area['content']"
      ></div>
    </ng-container>
  </div>
</ng-container>

<mat-menu #contextMenu class="tiny-menu">
  <button mat-menu-item (click)="editPoster()" i18n>
    <mat-icon>settings</mat-icon>編輯海報細節
  </button>

  <button
    *ngIf="!message.template"
    mat-menu-item
    (click)="editAlt(elementRef)"
    element-ref
    #elementRef="elementRef"
    i18n
  >
    <mat-icon>title</mat-icon>編輯替代文字
  </button>

  <mat-divider> </mat-divider>

  <button mat-menu-item (click)="delete()" i18n>
    <mat-icon>delete</mat-icon> 刪除
  </button>

  <button mat-menu-item (click)="swapUpward()" [disabled]="isFirstMessage" i18n>
    <mat-icon>arrow_upward</mat-icon> 上移
  </button>

  <button
    mat-menu-item
    (click)="swapDownward()"
    [disabled]="isLastMessage"
    i18n
  >
    <mat-icon>arrow_downward</mat-icon> 下移
  </button>
</mat-menu>

<ng-template #templateLost>
  <div class="mat-elevation-z2 template-lost" i18n>
    <mat-icon class="mat-18">search_off</mat-icon><br />
    圖像編輯器中的海報已被刪除<br />無法預覽
  </div>
</ng-template>
