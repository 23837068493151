import { SelectionModel } from '@angular/cdk/collections';
import { AsyncPipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { SchedulerModel } from '@ay-gosu/server-shared';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  mergeMap,
  shareReplay,
  takeUntil,
} from 'rxjs/operators';
import { BasicDialog } from '../../../dialog/basic';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { getDutchPaginatorIntl } from '../../../paginator';
import { MomentPipe } from '../../../pipe/moment.pipe';

export interface ScheduleTaskManagerData {
  nodeId: number;
}

@Component({
  selector: 'app-schedule-task-manager',
  templateUrl: './schedule-task-manager.component.html',
  styleUrls: ['./schedule-task-manager.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatButton,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCheckbox,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatPaginator,
    AsyncPipe,
    MomentPipe,
    LegacyAppearanceDirective,
  ],
  providers: [{ provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }],
})
export class ScheduleTaskManagerComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();
  public dataSource = new MatTableDataSource<any>([]);
  public keyword$ = new BehaviorSubject<string>('');
  public get keyword(): string {
    return this.keyword$.getValue();
  }
  public set keyword(val: string) {
    this.keyword$.next(val);
  }
  public nodeId$ = new BehaviorSubject<number>(null);
  public page$ = new BehaviorSubject<number>(1);
  public data$ = combineLatest([
    this.nodeId$.pipe(filter((nodeId) => typeof nodeId === 'number')),
    this.page$,
    this.keyword$,
  ]).pipe(
    debounceTime(333),
    mergeMap(async ([nodeId, page, keyword]) => {
      let list = await SchedulerModel.list(
        { gosuNodeId: nodeId, filter: keyword },
        page,
        10,
      );
      this.dataSource.data = list.data;
      return list;
    }),
    shareReplay(1),
  );

  public total$: Observable<number> = this.data$.pipe(
    map((list) => list.total),
    shareReplay(1),
  );

  private subscribeData = this.data$
    .pipe(takeUntil(this._destroy$))
    .subscribe();

  public constructor(
    public dialogRef: MatDialogRef<ScheduleTaskManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ScheduleTaskManagerData,
    private readonly _basicDialog: BasicDialog,
  ) {}

  public ngOnInit() {
    this.nodeId$.next(this.data.nodeId);
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public displayedColumns: string[] = ['select', 'profileId', 'title', 'date'];
  public selection = new SelectionModel<any>(true, []);

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  public async deleteAll() {
    await this._basicDialog.delete();
    let nodeId = this.nodeId$.getValue();
    await SchedulerModel.deleteByNodeId(nodeId);
    this.page$.next(1);
  }

  public async deleteByProfileIds() {
    await this._basicDialog.delete();
    let nodeId = this.nodeId$.getValue();
    let profileIds = this.selection.selected.map((task) => task.gosuProfileId);
    await Promise.all(
      await profileIds.map(async (id) => {
        return SchedulerModel.deleteByNodeIdAndProfileId(nodeId, id);
      }),
    );
    this.page$.next(this.page$.getValue());
    return;
  }
}
