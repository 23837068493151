import { NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { Flex } from '@ay/bot';
import {
  calcOffsetBottom,
  calcOffsetEnd,
  calcOffsetStart,
  calcOffsetTop,
} from '../../../flex.util';

@Component({
  selector: 'flex-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[style.flex]': "data.flex + ' ' + data.flex + ' auto'",
    '[style.top]': 'offsetTop',
    '[style.left]': 'offsetStart',
    '[style.right]': 'offsetEnd',
    '[style.bottom]': 'offsetBottom',
    '[style.maxWidth]': "maxWidth + 'px'",
    '[style.marginTop]': "layout == 'vertical'? margin:null",
    '[style.marginLeft]': "layout != 'vertical'? margin:null",
  },
  standalone: true,
  imports: [NgClass, ExtendedModule],
})
export class FlexButtonComponent {
  @Input()
  public data: Flex.Block.Button;

  @Input()
  public bubble: Flex.Content.Bubble;

  @Input()
  public flex: Flex.Message;

  @Input()
  public level: number = 0;

  @Input()
  public layout: Flex.BoxLayout;

  @Input()
  public index: number;

  protected positionClass = {
    absolute: 'ExAbs',
  };

  protected offsetTopClass = {
    none: 'ExTNone',
    xs: 'ExTXs',
    sm: 'ExTSm',
    md: 'ExTMd',
    xl: 'ExTXl',
    xxl: 'ExTXXl',
  };

  protected offsetBottomClass = {
    none: 'ExBNone',
    xs: 'ExBXs',
    sm: 'ExBSm',
    md: 'ExBMd',
    xl: 'ExBXl',
    xxl: 'ExBXXl',
  };

  protected offsetStartClass = {
    none: 'ExLNone',
    xs: 'ExLXs',
    sm: 'ExLSm',
    md: 'ExLMd',
    xl: 'ExLXl',
    xxl: 'ExLXXl',
  };

  protected offsetEndClass = {
    none: 'ExRNone',
    xs: 'ExRXs',
    sm: 'ExRSm',
    md: 'ExRMd',
    xl: 'ExRXl',
    xxl: 'ExRXXl',
  };

  protected get marginClass() {
    return {
      none: this.layout == 'vertical' ? 'ExMgnTNone' : 'ExMgnLNone',
      xs: this.layout == 'vertical' ? 'ExMgnTXs' : 'ExMgnLXs',
      sm: this.layout == 'vertical' ? 'ExMgnTSm' : 'ExMgnLSm',
      md: this.layout == 'vertical' ? 'ExMgnTMd' : 'ExMgnLMd',
      lg: this.layout == 'vertical' ? 'ExMgnTLg' : 'ExMgnLLg',
      xl: this.layout == 'vertical' ? 'ExMgnTXl' : 'ExMgnLXl',
      xxl: this.layout == 'vertical' ? 'ExMgnTXXl' : 'ExMgnLXXl',
    };
  }

  public get margin() {
    if (!this.index || this.marginClass[this.data.margin]) return null;
    return this.data.margin;
  }

  public get offsetTop() {
    return calcOffsetTop(this.data.offsetTop, this._elementRef.nativeElement);
  }

  public get offsetBottom() {
    return calcOffsetBottom(
      this.data.offsetBottom,
      this._elementRef.nativeElement,
    );
  }

  public get offsetStart() {
    return calcOffsetStart(
      this.data.offsetStart,
      this._elementRef.nativeElement,
    );
  }

  public get offsetEnd() {
    return calcOffsetEnd(this.data.offsetEnd, this._elementRef.nativeElement);
  }

  protected heightClass = {
    sm: 'ExSm',
    md: '',
  };

  protected styleClass = {
    link: 'ExBtnL',
    primary: 'ExBtn1',
    secondary: 'ExBtn2',
  };

  protected gravityClass = {
    bottom: 'grvB',
    center: 'grvC',
  };

  protected flexClass = ['fl0', 'fl1', 'fl2'];

  public get maxWidth() {
    let el = this._elementRef.nativeElement as HTMLElement;
    let isAbs = el.classList.contains('ExAbs');
    if (!isAbs) return;

    let parent = el.parentElement;
    let styles = getComputedStyle(parent);

    while (styles.position != 'relative') {
      parent = parent.parentElement;
      styles = getComputedStyle(parent);
    }

    return parent.offsetWidth - parseInt(styles.padding) * 2;
  }

  @ViewChild('font')
  public font: ElementRef;

  public get fontSize() {
    let label = '';
    if (this.data.hasOwnProperty('action')) {
      label = this.data.action['label'];
    }
    const buttonWidth = 230;
    const fontWidth = this.font?.nativeElement.offsetWidth;
    if (!label || !this.data.adjustMode || !fontWidth) return;
    let fontSize = parseInt(this.font.nativeElement.style.fontSize || '16');
    return fontWidth > buttonWidth ? fontSize - 1 : fontSize;
  }

  public constructor(private readonly _elementRef: ElementRef) {}

  @HostBinding('class')
  public get hostClass(): string {
    const list = ['MdBtn'];
    if (!!this.index) list.push(this.marginClass[this.data.margin]);
    list.push(this.positionClass[this.data.position]);
    list.push(this.heightClass[this.data.height]);
    list.push(this.styleClass[this.data.style || 'link']);
    list.push(this.gravityClass[this.data.gravity]);
    list.push(this.flexClass[this.data.flex]);
    list.push(this.offsetTopClass[this.data.offsetTop]);
    list.push(this.offsetBottomClass[this.data.offsetBottom]);
    list.push(this.offsetStartClass[this.data.offsetStart]);
    list.push(this.offsetEndClass[this.data.offsetEnd]);
    return list.filter(Boolean).join(' ');
  }
}
