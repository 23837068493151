<ng-container *ngIf="templates$ | async; let templates; else: loading">
  <ng-container *ngIf="templates.length; else empty">
    <div class="header" i18n>
      <mat-icon>{{ "poster" | messageIcon }}</mat-icon>
      請選擇海報
    </div>

    <div
      class="container"
      fxLayout="row wrap"
      fxLayoutAlign="space-between start"
    >
      <div
        matRipple
        class="poster mat-elevation-z2"
        fxFlex="0 0 260px"
        *ngFor="let template of templates"
        (click)="submit(template)"
      >
        <div
          class="image"
          [style.background-image]="'url(' + (template.picture | imageType) + ')'"
        ></div>
        <div class="label">{{ template.name }}</div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #empty>
  <div class="container">
    <gosu-landing-brand
      image="./assets/poster-landing.png"
      title="在圖像編輯器中建立海報範本"
      i18n-title="在圖像編輯器中建立海報範本"
      description="透過圖像編輯器的功能，您可以預先建立海報範本。這樣一來在建立訊息時就可以統一訊息發送的風格，並提升企業形象及發送效率"
      i18n-description="透過圖像編輯器的功能，您可以預先建立海報範本。這樣一來在建立訊息時就可以統一訊息發送的風格，並提升企業形象及發送效率"
      callToActionLabel="立即建立"
      i18n-callToActionLabel="立即建立"
      callToActionIcon="add"
      (callToAction)="createPoster()"
    >
    </gosu-landing-brand>
  </div>
</ng-template>

<ng-template #loading>
  <div class="container">
    <gosu-loading i18n>海報讀取中，請稍候</gosu-loading>
  </div>
</ng-template>
