<div class="customize-wrap">
  <mat-checkbox [(ngModel)]="card.customize.image.enable" color="primary" i18n>
    圖片
  </mat-checkbox>

  <gosu-image-picker
    *ngIf="card.customize.image.enable"
    [(value)]="card.customize.image.value"
    [useCode]="true"
    (imageChange)="onChanged()"
  >
  </gosu-image-picker>
</div>

<div class="customize-wrap">
  <mat-checkbox
    color="primary"
    [(ngModel)]="card.customize.title.enable"
    (ngModelChange)="onChanged()"
    i18n
  >
    標題
  </mat-checkbox>

  <mat-form-field
    floatLabel="auto"
    *ngIf="card.customize.title.enable"
    legacyAppearance
  >
    <mat-label i18n>{{ card.title }}</mat-label>
    <input
      matInput
      type="text"
      [(ngModel)]="card.customize.title.value"
      (ngModelChange)="onChanged()"
    />
  </mat-form-field>
</div>

<div class="customize-wrap">
  <mat-checkbox
    color="primary"
    [(ngModel)]="card.customize.content.enable"
    (ngModelChange)="onChanged()"
    i18n
  >
    內容
  </mat-checkbox>

  <mat-form-field
    floatLabel="auto"
    *ngIf="card.customize.content.enable"
    legacyAppearance
  >
    <mat-label i18n>{{ card.content }}</mat-label>
    <textarea
      rows="3"
      matInput
      [(ngModel)]="card.customize.content.value"
      (ngModelChange)="onChanged()"
    ></textarea>
  </mat-form-field>
</div>

<ng-container *ngFor="let button of card.customize.buttons; let index = index">
  <div class="customize-wrap">
    <mat-checkbox
      color="primary"
      [(ngModel)]="button.enable"
      (ngModelChange)="onChanged()"
      i18n
    >
      按鈕 {{ index + 1 }}
      {{ button.label || card.buttons[index]?.label }}
    </mat-checkbox>

    <ng-container *ngIf="button.enable">
      <mat-form-field floatLabel="auto" class="select" legacyAppearance>
        <mat-label i18n>類型</mat-label>
        <mat-select [(ngModel)]="button.type" (ngModelChange)="onChanged()">
          <mat-option *ngIf="package.enabledActions.uri" value="uri" i18n>
            連結 / 檔案
          </mat-option>

          <mat-option
            *ngIf="package.enabledActions.message"
            value="message"
            i18n
          >
            說話
          </mat-option>

          <ng-container
            *ngIf="packageFactoryService.enablePromotionChannel$ | async"
          >
            <mat-option
              *ngIf="package.enabledActions.shareMessageThroughPromotion"
              value="share-message-through-promotion"
              i18n
            >
              分享機器人 (推廣通路)
            </mat-option>

            <mat-option
              *ngIf="package.enabledActions.addThroughPromotion"
              value="add-through-promotion"
              i18n
            >
              加入機器人 (推廣通路)
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-form-field legacyAppearance>
        <mat-label i18n>按鈕文字</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="button.label"
          (ngModelChange)="onChanged()"
        />
      </mat-form-field>

      <ng-container *ngIf="button.type === 'uri'">
        <mat-form-field legacyAppearance>
          <mat-label i18n>連結</mat-label>
          <input matInput type="text" [(ngModel)]="button.uri" />
        </mat-form-field>
        <div class="tar">
          <button mat-button (file)="uploadFile($any(button), $event)" i18n>
            上傳檔案
          </button>
        </div>
      </ng-container>

      <mat-form-field *ngIf="button.type === 'message'" legacyAppearance>
        <mat-label i18n>訊息內容</mat-label>
        <input matInput type="text" [(ngModel)]="button.message" />
      </mat-form-field>

      <mat-form-field
        *ngIf="button.type === 'share-message-through-promotion'"
        legacyAppearance
      >
        <mat-label i18n>推廣通路</mat-label>
        <mat-select
          [(ngModel)]="button['promotionId']"
          (ngModelChange)="onChanged()"
        >
          <mat-option
            *ngFor="let promotion of promotions$ | async"
            [value]="promotion.id"
          >
            {{ promotion.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
</ng-container>

<ng-template #uploadProgressDialog>
  <div class="container">
    <div class="upload-progress">
      <h2 i18n>檔案上傳中，請稍後</h2>
      <mat-progress-bar
        mode="determinate"
        [value]="uploadProgress"
        color="primary"
      >
      </mat-progress-bar>
    </div>
  </div>
</ng-template>
