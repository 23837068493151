import { Connection } from '../connection/class';
import { FlowService } from '../flow.service';
import { Node } from '../node/class';
import { Command } from './command';

export class SelectCommand extends Command {
  private oldSelected: (Node | Connection)[];

  public constructor(
    private readonly _flowService: FlowService,
    private readonly _node: Node | Connection,
    private readonly _increase: boolean = false,
    private readonly _isAll: boolean = false,
  ) {
    super();
    this.oldSelected = _flowService.selected.slice();
  }

  public async do() {
    let selected = this._flowService.selected;
    let idx = selected.indexOf(this._node);
    this.changeNodeZIndex(idx);

    if (this._increase) {
      if (idx > -1) selected.splice(idx, 1);
      else selected.push(this._node);
    } else if (idx === -1) {
      selected = [this._node];
    }

    this._flowService.selected = selected.filter((e) => e);
  }

  public async undo() {
    this._flowService.selected = this.oldSelected;
  }

  protected changeNodeZIndex(idx: number) {
    if (this._node instanceof Node && !this._isAll) {
      let nodeList = this._flowService.nodeList;
      let zIdx = nodeList.indexOf(this._node);
      nodeList.splice(zIdx, 1);
      if (idx > -1 && this._increase)
        this._flowService.nodeList.unshift(this._node);
      else this._flowService.nodeList.push(this._node);
    }
  }
}
