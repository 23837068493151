<div fxLayout="row" fxLayoutGap="15px">
  <mat-form-field fxFlex="1 0 200px" legacyAppearance>
    <mat-label i18n>類型</mat-label>
    <mat-select
      [(ngModel)]="node.timerType"
      (ngModelChange)="onTimerTypeChanged()"
    >
      <mat-option [value]="'DelaySeconds'" i18n> 等待 </mat-option>
      <mat-option [value]="'RelativeTime'" i18n> 相對時間 </mat-option>
      <mat-option [value]="'AbsoluteTime'" i18n> 絕對時間 </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="node.timerType !== 'DelaySeconds'">
    <mat-checkbox
      class="form-field"
      fxFlex="0 0 140px"
      [(ngModel)]="node.processed"
      value="true"
      color="primary"
      i18n
    >
      標示為已處理
    </mat-checkbox>

    <div fxFlex="0 0 140px">
      <button
        mat-button
        color="primary"
        (click)="openDialog()"
        class="form-field"
        i18n
      >
        管理預約中的任務
      </button>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="node.timerType === 'DelaySeconds'">
  <mat-form-field [style.width.%]="100" legacyAppearance>
    <mat-label i18n>延遲時間</mat-label>
    <input matInput [(ngModel)]="node.time" required />
    <span matSuffix i18n>毫秒</span>
    <mat-hint i18n>1000毫秒 = 1秒，不得超過一分鐘</mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="node.timerType === 'AbsoluteTime'">
  <programmable
    [(value)]="node.absoluteTime"
    dataType="string"
    [style.width.%]="100"
  >
    <mat-form-field legacyAppearance>
      <mat-label i18n>絕對時間</mat-label>
      <input matInput [(ngModel)]="node.absoluteTime" required />
      <mat-hint i18n
        >可程式化輸入框，格式範例：<br />
        {{ now }}</mat-hint
      >
    </mat-form-field>
  </programmable>

  <mat-list dense>
    <mat-divider></mat-divider>
    <h3 matSubheader i18n>範例：</h3>
    <mat-list-item
      class="curp"
      (click)="node.absoluteTime = exampleDate.chirstmas"
    >
      <button
        mat-stroked-button
        color="primary"
        matTooltipPosition="after"
        [matTooltip]="exampleDate.chirstmas"
        i18n
      >
        聖誕節
      </button>
    </mat-list-item>
    <mat-list-item
      class="curp"
      (click)="node.absoluteTime = exampleDate.newYear"
    >
      <button
        mat-stroked-button
        color="primary"
        matTooltipPosition="after"
        [matTooltip]="exampleDate.newYear"
        i18n
      >
        跨年前十分鐘
      </button>
    </mat-list-item>
    <mat-list-item
      class="curp"
      (click)="node.absoluteTime = exampleDate.motherDay"
    >
      <button
        mat-stroked-button
        color="primary"
        matTooltipPosition="after"
        [matTooltip]="exampleDate.motherDay"
        i18n
      >
        母親節 (五月的第二個禮拜天)
      </button>
    </mat-list-item>
  </mat-list>
</ng-container>

<ng-container *ngIf="node.timerType === 'RelativeTime'">
  <programmable
    [(value)]="node.relativeTime"
    dataType="string"
    [style.width.%]="100"
  >
    <mat-form-field legacyAppearance>
      <mat-label i18n>相對時間</mat-label>
      <input matInput [(ngModel)]="node.relativeTime" required />
      <mat-hint i18n
        >可程式化輸入框，時間敘述辨識結果：{{
          relativeTimePreview(node.relativeTime)
        }}</mat-hint
      >
    </mat-form-field>
  </programmable>

  <mat-list dense>
    <mat-divider></mat-divider>
    <h3 matSubheader i18n>範例：</h3>
    <mat-list-item class="curp" (click)="node.relativeTime = '五分鐘後'">
      <button
        mat-stroked-button
        color="primary"
        matTooltipPosition="after"
        matTooltip="五分鐘後"
        i18n-matTooltip="五分鐘後"
        i18n
      >
        五分鐘後
      </button>
    </mat-list-item>
    <mat-list-item class="curp" (click)="node.relativeTime = '明天下午五點'">
      <button
        mat-stroked-button
        color="primary"
        matTooltipPosition="after"
        matTooltip="明天下午五點"
        i18n-matTooltip="明天下午五點"
        i18n
      >
        明天下午五點
      </button>
    </mat-list-item>
    <mat-list-item class="curp" (click)="node.relativeTime = '下週一上午十點'">
      <button
        mat-stroked-button
        color="primary"
        matTooltipPosition="after"
        matTooltip="下週一上午十點"
        i18n-matTooltip="下週一上午十點"
        i18n
      >
        下週一上午十點
      </button>
    </mat-list-item>
    <mat-list-item
      class="curp"
      (click)="
        node.relativeTime =
          '${moment().isBefore(12,\'HH\')?moment().add(6,\'hour\').format(\'YYYY-MM-DD HH:mm:ss\'):moment().add(24,\'hour\').format(\'YYYY-MM-DD HH:mm:ss\')}'
      "
    >
      <button
        mat-stroked-button
        color="primary"
        matTooltip="${moment().isBefore(12,'HH')?moment().add(6,'hour').format('YYYY-MM-DD HH:mm:ss'):moment().add(24,'hour').format('YYYY-MM-DD HH:mm:ss')}"
        i18n
      >
        中午前是6小時後，過了中午則是24小時後
      </button>
    </mat-list-item>
  </mat-list>
</ng-container>
