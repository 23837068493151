import { DirectusFieldDto } from '@ay-gosu/server-shared';
import { Node } from '../class';

export class DirectusNode extends Node {
  public icon = '/assets/directus.svg';

  public itemId: string = '';
  public isAsync: boolean = false;
  public action: string = 'READ';
  public collection: string = '';
  public fields: string[] = [];
  public sort: string = '';
  public limit: string = '30';
  public offset: string = '0';
  public single: boolean = false;
  public status: string = 'published';
  public q: string = '';
  public filter: Filter[] = [];
  public body: { [key: string]: string } = {};
  public enable: { [key: string]: boolean } = {};
  public responseVar: string = 'data';

  public readonly extraProperties = [];

  public async getDisplay() {
    switch (this.action) {
      case 'READ':
        return $localize`讀取` + this.collection;
      case 'CREATE':
        return $localize`新增` + this.collection;
      case 'UPDATE':
        return $localize`更新` + this.collection;
      case 'DELETE':
        return $localize`刪除` + this.collection;
      default:
        return $localize`內容管理`;
    }
  }

  public toJSON() {
    const data: any = {
      isAsync: this.isAsync,
      collection: this.collection,
      action: this.action,
      responseVar: this.responseVar,
    };

    switch (this.action) {
      case 'READ':
        data.fields = this.fields;
        data.sort = this.sort;
        data.limit = this.limit;
        data.offset = this.offset;
        data.single = this.single;
        data.q = this.q;
        data.status = this.status;
        data.filter = this.filter.map((filter) => ({
          field: filter.field,
          op: filter.op,
          value: filter.value,
        }));

        break;

      case 'UPDATE':
        data.itemId = this.itemId;

      case 'CREATE':
        data.body = {};
        data.enable = this.enable;
        for (let key in this.enable) {
          if (this.enable[key]) {
            data.body[key] = this.body[key];
          }
        }
        break;

      case 'DELETE':
        data.itemId = this.itemId;
        break;
    }

    return {
      id: this.id,
      name: this.name,
      color: this.color,
      type: this.type,
      x: this.x,
      y: this.y,
      data: JSON.stringify(data),
    };
  }
}

export interface Filter {
  field: string;
  op: string;
  value: string;
  _field?: DirectusFieldDto;
}
