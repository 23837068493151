import { Component, Input } from '@angular/core';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'gosu-platform',
    templateUrl: './platform.component.html',
    styleUrls: ['./platform.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgClass,
    ],
})
export class PlatformComponent {
  @Input()
  public platforms = ['FACEBOOK', 'LINE'];

  public allPlatforms = ['FACEBOOK', 'LINE'];
}
