<div class="container">
  <form [formGroup]="form">
    <mat-form-field legacyAppearance>
      <mat-label>
        <ng-container i18n *ngIf="targetType === 'profile'">
          標籤名稱
        </ng-container>
        <ng-container i18n *ngIf="targetType === 'package'">
          分類名稱
        </ng-container>
      </mat-label>

      <input matInput formControlName="name" />

      <mat-error *ngIf="name.errors">
        <ng-container i18n *ngIf="name.errors['required']">
          必填欄位
        </ng-container>

        <ng-container *ngIf="name.errors['validateName']">
          <ng-container i18n *ngIf="targetType === 'profile'">
            標籤名稱重複
          </ng-container>
          <ng-container i18n *ngIf="targetType === 'package'">
            分類名稱重複
          </ng-container>
        </ng-container>
      </mat-error>
    </mat-form-field>
  </form>
  <br />

  <mat-checkbox
    [(ngModel)]="isChild"
    color="primary"
    (ngModelChange)="afterIsChildChange($event)"
  >
    <ng-container i18n *ngIf="targetType === 'profile'">
      於下方標籤底下建立子標籤：
    </ng-container>
    <ng-container i18n *ngIf="targetType === 'package'">
      於下方分類底下建立子分類：
    </ng-container>
  </mat-checkbox>

  <button mat-stroked-button [matTreePicker]="tagTreePicker">
    <ng-container *ngIf="parentTag; else pleaseChooseI18n">
      {{ parentTag.name }}
    </ng-container>
    <ng-template i18n #pleaseChooseI18n> 請選擇 </ng-template>
  </button>

  <mat-tree-picker #tagTreePicker [dataSource]="tags$" side="left">
    <mat-tree-picker-node
      *matTreePickerNodeDef="let tag"
      (click)="selectTag(tag)"
    >
      {{ tag.name }}
    </mat-tree-picker-node>
  </mat-tree-picker>
  <div class="tar">
    <button mat-flat-button color="primary" (click)="submit()">
      <ng-container i18n *ngIf="targetType === 'profile'">
        建立標籤
      </ng-container>
      <ng-container i18n *ngIf="targetType === 'package'">
        建立分類
      </ng-container>
    </button>
  </div>
</div>
