import {
  FilterModel,
  GroupColumn,
  GroupObject,
  GroupRelation,
} from '@ay-gosu/server-shared';
import { RuleType } from '@ay-gosu/types';
import { removeInArray } from '@ay/util';
import { firstValueFrom } from 'rxjs';
import { ReadableError } from '../../../util/readable-error';
import { RelationPipe } from '../../pipe/relation.pipe';
import { GroupRule } from './group/rule.class';
import { PropertyRule } from './rule/property/property.class';
import { FromJsonOption, Rule } from './rule/rule';
import {
  PropertyRuleClassMap,
  RuleClass,
  RuleClassMap,
  RuleFactory,
} from './rule/rule.factory';
import { Bracket, CanCreateShortCode, ShortCodeDecoder } from './shortcode';

export const NOT_EXIST_ID = -1;

export class Group extends Rule implements CanCreateShortCode {
  public afterTypeChanged(isUserEvent?: boolean) {
    throw new Error('Method not implemented.');
  }

  public static schema: string = 'GROUP';
  public class: 'GROUP' = 'GROUP';
  public id = NOT_EXIST_ID;
  public name: string;
  public relation: GroupRelation;
  public rules: (Rule | Group)[] = [];

  public constructor(relation: GroupRelation, name: string = null) {
    super('GROUP');
    this.relation = relation;
    this.name = name;
  }

  public addRule(type: RuleType) {
    let rule = RuleFactory.create(type);
    this.rules.push(rule);
  }

  public addGroup(relation: GroupRelation) {
    this.rules.push(new Group(relation));
  }

  public remove(item: Rule | Group) {
    try {
      removeInArray(this.rules, item);
    } catch (error) {
      throw new ReadableError($localize`找不到要刪除的條件`, item);
    }
  }

  public static async fromJSON(
    json: any,
    option: FromJsonOption,
  ): Promise<Group> {
    if (json === null) return new Group('AND', $localize`不篩選`);
    let group = new Group(json.relation, json.name);

    if (json.id) {
      group.id = json.id;
    }

    if (json.rules) {
      let rulePromises = json.rules.map((rule) =>
        Group.itemFromJSON(rule, option),
      ) as Promise<Rule>[];
      let rules = await Promise.all(rulePromises);
      rules.map((rule) => group.rules.push(rule));
    }

    return group;
  }

  public toShortCode(): string {
    if (this.id !== NOT_EXIST_ID) {
      return `GROUP:${this.id}`;
    } else {
      if (!this.rules) {
        return '';
      }
      return `${this.relation}(${this.rules
        .map((rule) => {
          let type = rule['constructor']['schema'];
          let code = rule.toShortCode();
          if (code === '') return type;
          else return `${type}:${code}`;
        })
        .join(';')})`;
    }
  }

  public static async fromShortCode(code: string, option: FromJsonOption) {
    let decoder = new ShortCodeDecoder();
    let bracket = decoder.decode(code);
    if (bracket === undefined) {
      return new GroupRule(parseInt(code, 10), '');
    }
    return Group.fromShortCodeBracket(bracket, option);
  }

  public static async fromShortCodeBracket(
    bracket: Bracket,
    option: FromJsonOption,
  ) {
    let [relation, ...rules] = bracket.lines;
    let group = new Group(relation as GroupRelation);
    let Rules = Object.values(RuleClassMap);
    group.rules = await Promise.all(
      rules.map(async (shortCode) => {
        if (typeof shortCode === 'string') {
          let idx = shortCode.indexOf(':');
          let type: string = '';
          let code: string = '';

          if (idx == -1) {
            type = shortCode;
          } else {
            type = shortCode.substring(0, idx);
            code = shortCode.substring(idx + 1);
          }

          let Rule = Rules.find((_class) => _class.schema === type);
          if (Rule === undefined && type == 'group') Rule = Group;
          if (Rule === PropertyRule) {
            Rule = await Group.convertTypePropertyRuleClass(option, code, Rule);
          }
          const rule = await Rule.fromShortCode(code, option);
          RuleFactory.ensureType(rule);
          rule.afterTypeChanged(false);
          return rule;
        } else if (shortCode === undefined) {
        } else {
          return Group.fromShortCodeBracket(shortCode, option);
        }
      }),
    );

    return group;
  }

  private static async convertTypePropertyRuleClass(
    option: FromJsonOption,
    code: string,
    Rule: RuleClass,
  ) {
    const configs = await firstValueFrom(option.propertyConfigService.profile$);
    let [key] = code.split(',');
    const config = configs.find((config) => config.key === key);
    Rule = PropertyRuleClassMap[config.dataType];
    if (Rule === undefined) {
      throw new ReadableError(
        $localize`未定義的屬性類型'${config.dataType}'`,
        config,
      );
    }
    return Rule;
  }

  public static async itemFromJSON(
    item: Group | Rule,
    option: FromJsonOption,
  ): Promise<Rule | Group> {
    if (item.type === 'GROUP') item.class = 'GROUP';
    switch (item.class) {
      case 'GROUP':
        if (!item['relation'] && !item['rules']) {
          item = (await FilterModel.getDetail(item['id'])) as any;
        }
        return Group.fromJSON(item, option);

      case 'RULE':
        return RuleFactory.fromJSON(item, option);

      default:
        throw new ReadableError(
          $localize`未定義的類型'${(item as any).class}'`,
          item,
        );
    }
  }

  public getRuleCount() {
    let count = 0;
    count = this.rules.reduce((prev, curr) => {
      if (curr instanceof Group) return prev + curr.getRuleCount();
      else return prev + 1;
    }, 0);
    return count;
  }

  public toggleRelation() {
    if (this.relation === 'OR') {
      this.relation = 'AND';
    } else {
      this.relation = 'OR';
    }
  }

  public toString() {
    let pipe = new RelationPipe();
    if (this.name && this.name !== '未命名訊息') {
      return this.name;
    }

    return this.rules
      .map((rule) => rule.toString())
      .join(pipe.transform(this.relation));
  }

  public isEqual(compared: Group | Rule) {
    if (!(compared instanceof Group)) return false;
    return (
      this.relation === compared.relation &&
      this.rules.length === compared.rules.length &&
      this.rules.reduce(
        (prev, rule) =>
          compared.rules.find((founded) => rule.isEqual(founded as any)) &&
          prev,
        true,
      )
    );
  }

  public toGroupObject(): GroupObject {
    return {
      class: 'GROUP',
      id: this.id,
      name: this.name,
      relation: this.relation,
      rules: this.rules.map((rule) => {
        if (rule instanceof Group) return rule.toGroupObject();
        else return rule.toRuleObject();
      }),
    };
  }

  public checkError(): boolean {
    return false;
  }
}

export class UnknownFilterError extends ReadableError {
  public constructor(id: number, groups: GroupColumn) {
    super($localize`未知的篩選群組'${id}'`, { id, groups });
  }
}
