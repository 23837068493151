import { Content } from '@ay/bot';
import { Message } from '../base/base.message';

export class TextMessage extends Message<Content.Text> {
  public type = 'text';

  public constructor() {
    super();
    this.content = new Content.Text('');
  }

  public toString() {
    return this.content.content;
  }
}
