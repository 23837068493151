import {
  APP_BASE_HREF,
  PlatformLocation,
  registerLocaleData,
} from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import zhHant from '@angular/common/locales/zh-Hant';
import {
  APP_INITIALIZER,
  enableProdMode,
  ErrorHandler,
  LOCALE_ID,
} from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  provideMomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorIntl,
} from '@angular/material/paginator';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import 'moment/locale/zh-tw';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routing';
import { GosuErrorHandler } from './app/error-handler';
import { MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from './app/material/tooltip';
import { MAT_TREE_PICKER_SCROLL_STRATEGY_FACTORY_PROVIDER } from './app/material/tree-picker/scroll-strategy';
import { registeNodes } from './app/pages/flow/register';
import { getDutchPaginatorIntl } from './app/paginator';
import { PartnerProvider } from './app/provider';
import { ConnectionService } from './app/service/connection.service';
import { GTM_ID } from './app/service/google-tag-manager.service';
import { I18nService } from './app/service/i18n.service';
import { TokenService } from './app/service/token.service';
import { environment } from './environments/environment';

registerLocaleData(zhHant);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(),

    TokenService,
    ConnectionService,
    I18nService,

    {
      provide: LOCALE_ID,
      useValue: 'zh-Hant',
    },
    {
      provide: GTM_ID,
      useValue: environment.gtmId,
    },
    {
      provide: ErrorHandler,
      useClass: GosuErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        registeNodes();
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    PartnerProvider.landing,
    PartnerProvider.copyright,

    // Material
    provideMomentDateAdapter(),
    MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_TREE_PICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'zh-tw',
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY/MM/DD',
        },
        display: {
          dateInput: 'YYYY/MM/DD',
          monthLabel: 'MMM',
          monthYearLabel: 'YYYY年 MMM',
          dateA11yLabel: 'YYYY/MM/DD',
          monthYearA11yLabel: 'YYYY年 MMMM',
        },
      },
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (platformLocation: PlatformLocation) =>
        platformLocation.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
      },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 2500 },
    },
    {
      provide: MatPaginatorIntl,
      useValue: getDutchPaginatorIntl(),
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: { formFieldAppearance: 'fill' },
    },
    { provide: 'MESSAGE_CONFIG', useValue: { enableNextButton: true } },
  ],
}).catch((err) => console.error(err));
