import { DatetimePickerMode, Flex } from '@ay/bot';
import moment from 'moment';
import { RegularizedFlex } from '../regularized-flex.class';
import { FLEX_PROP_OPTIONS } from './form/options';

export const ERROR_MESSAGE = {
  carousel,
  bubble,
  header,
  hero,
  body,
  footer,
  box,
  image,
  video,
  text,
  button,
  filler,
  spacer,
  separator,
  span,
  icon,
};

function filterEmpty(error) {
  Object.keys(error).forEach((key) => {
    if (!error[key]) delete error[key];
  });
}

function carousel(data: Flex.Content.Carousel) {
  let contents = !data.contents.length
    ? $localize`沒有訊息`
    : data.contents.length > 12
      ? $localize`超過12則訊息`
      : null;
  let error = { contents };
  filterEmpty(error);
  return error;
}

function bubble(data: RegularizedFlex) {
  let contents = !data.contents.length ? $localize`沒有訊息` : null;
  let action = inValidAction(data.type, data.action);
  let hasActionError = Object.values(action).find((value) => !!value);
  if (!hasActionError) action = null;
  let error = { contents, action };
  filterEmpty(error);
  return error;
}

function header(data: RegularizedFlex) {
  if (data === undefined) return;
  let backgroundColor = !validColor(data.backgroundColor)
    ? $localize`錯誤的顏色格式`
    : null;
  let separatorColor = !validColor(data.separatorColor)
    ? $localize`錯誤的顏色格式`
    : null;
  let error = { backgroundColor, separatorColor };
  filterEmpty(error);
  return error;
}

function hero(data: RegularizedFlex) {
  if (data === undefined) return;
  let backgroundColor = !validColor(data.backgroundColor)
    ? $localize`錯誤的顏色格式`
    : null;
  let separatorColor = !validColor(data.separatorColor)
    ? $localize`錯誤的顏色格式`
    : null;
  let error = { backgroundColor, separatorColor };
  filterEmpty(error);
  return error;
}

function body(data: RegularizedFlex) {
  if (data === undefined) return;
  let backgroundColor = !validColor(data.backgroundColor)
    ? $localize`錯誤的顏色格式`
    : null;
  let separatorColor = !validColor(data.separatorColor)
    ? $localize`錯誤的顏色格式`
    : null;
  let error = { backgroundColor, separatorColor };
  filterEmpty(error);
  return error;
}

function footer(data: RegularizedFlex) {
  if (data === undefined) return;
  let backgroundColor = !validColor(data.backgroundColor)
    ? $localize`錯誤的顏色格式`
    : null;
  let separatorColor = !validColor(data.separatorColor)
    ? $localize`錯誤的顏色格式`
    : null;
  let error = { backgroundColor, separatorColor };
  filterEmpty(error);
  return error;
}

function box(data: RegularizedFlex) {
  let margin = !validSize(data.margin, 'Margin')
    ? $localize`錯誤的外距格式`
    : null;
  let offsetTop = !validPosition(data.offsetTop, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let offsetBottom = !validPosition(data.offsetBottom, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let offsetStart = !validPosition(data.offsetStart, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let offsetEnd = !validPosition(data.offsetEnd, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;

  let paddingAll = !validPosition(data.paddingAll, 'Padding')
    ? $localize`錯誤的內距格式`
    : null;
  let paddingTop = !validPosition(data.paddingTop, 'Padding')
    ? $localize`錯誤的內距格式`
    : null;
  let paddingBottom = !validPosition(data.paddingBottom, 'Padding')
    ? $localize`錯誤的內距格式`
    : null;
  let paddingStart = !validPosition(data.paddingStart, 'Padding')
    ? $localize`錯誤的內距格式`
    : null;
  let paddingEnd = !validPosition(data.paddingEnd, 'Padding')
    ? $localize`錯誤的內距格式`
    : null;

  let action = inValidAction(data.type, data.action);
  let hasActionError = Object.values(action).find((value) => !!value);
  if (!hasActionError) action = null;

  let background = inValidBackground(data.background);
  let hasBackgroundError = Object.values(background).find((value) => !!value);
  if (!hasBackgroundError) background = null;

  let flex = !validValue(data.flex) ? $localize`錯誤的數值` : null;
  let width = !validPosition(data.width) ? $localize`錯誤的數值` : null;
  let height = !validPosition(data.height) ? $localize`錯誤的數值` : null;
  let backgroundColor = !validColor(data.backgroundColor)
    ? $localize`錯誤的顏色格式`
    : null;
  let borderWidth = !validValue(data.borderWidth, 'px')
    ? $localize`錯誤的數值`
    : null;
  let borderColor = !validColor(data.borderColor)
    ? $localize`錯誤的顏色格式`
    : null;
  let cornerRadius = !validValue(data.cornerRadius, 'px')
    ? $localize`錯誤的數值`
    : null;
  let error = {
    margin,
    offsetTop,
    offsetBottom,
    offsetStart,
    offsetEnd,
    paddingAll,
    paddingTop,
    paddingBottom,
    paddingStart,
    paddingEnd,
    background,
    action,
    flex,
    width,
    height,
    backgroundColor,
    borderWidth,
    borderColor,
    cornerRadius,
  };
  filterEmpty(error);
  return error;
}

function image(data: Flex.Block.Image | RegularizedFlex) {
  let margin = !validSize(data.margin, 'Margin')
    ? $localize`錯誤的外距格式`
    : null;
  let url = !data.url
    ? $localize`網址不可為空`
    : !validURL(data.url)
      ? $localize`錯誤的網址格式`
      : null;
  let backgroundColor = !validColor(data.backgroundColor)
    ? $localize`錯誤的顏色格式`
    : null;
  let size = !validPosition(data.size, 'ImageSize')
    ? $localize`錯誤的尺寸格式`
    : null;
  let offsetTop = !validPosition(data.offsetTop, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let offsetBottom = !validPosition(data.offsetBottom, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let offsetStart = !validPosition(data.offsetStart, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let offsetEnd = !validPosition(data.offsetEnd, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let action = inValidAction(data.type, data.action);
  let hasActionError = Object.values(action).find((value) => !!value);
  if (!hasActionError) action = null;
  let flex = !validValue(data.flex) ? $localize`錯誤的數值` : null;
  let aspectRatio = !validValue(data.aspectRatio, ':\\d{1,}')
    ? $localize`錯誤的數值`
    : null;
  let error = {
    margin,
    url,
    size,
    backgroundColor,
    offsetTop,
    offsetBottom,
    offsetStart,
    offsetEnd,
    action,
    flex,
    aspectRatio,
  };
  filterEmpty(error);
  return error;
}

function video(data: Flex.Block.Video | RegularizedFlex) {
  let url = null;
  if (!data.url) {
    url = $localize`網址不可為空`;
  } else if (!validURL(data.url)) {
    url = $localize`錯誤的網址格式`;
  }
  let previewUrl = !validURL(data.previewUrl)
    ? $localize`錯誤的網址格式`
    : null;
  let aspectRatio = !validValue(data.aspectRatio, ':\\d{1,}')
    ? $localize`錯誤的數值`
    : null;
  let error = { url, previewUrl, aspectRatio };
  filterEmpty(error);
  return error;
}

function text(data: Flex.Block.Text | RegularizedFlex) {
  let margin = !validSize(data.margin, 'Margin')
    ? $localize`錯誤的外距格式`
    : null;
  let size = !validSize(data.size, 'TextSize')
    ? $localize`錯誤的尺寸格式`
    : null;
  let text =
    !data.text && !data.contents?.length ? $localize`文字內容不可為空` : null;
  let offsetTop = !validPosition(data.offsetTop, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let offsetBottom = !validPosition(data.offsetBottom, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let offsetStart = !validPosition(data.offsetStart, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let offsetEnd = !validPosition(data.offsetEnd, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let action = inValidAction(data.type, data.action);
  let hasActionError = Object.values(action).find((value) => !!value);
  if (!hasActionError) action = null;
  let flex = !validValue(data.flex) ? $localize`錯誤的數值` : null;
  let color = !validColor(data.color) ? $localize`錯誤的顏色格式` : null;
  let maxLines = !validValue(data.maxLines) ? $localize`錯誤的數值` : null;
  let error = {
    margin,
    size,
    text,
    offsetTop,
    offsetBottom,
    offsetStart,
    offsetEnd,
    action,
    flex,
    color,
    maxLines,
  };
  filterEmpty(error);
  return error;
}

function button(data: Flex.Block.Button | RegularizedFlex) {
  let margin = !validSize(data.margin, 'Margin')
    ? $localize`錯誤的外距格式`
    : null;
  let offsetTop = !validPosition(data.offsetTop, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let offsetBottom = !validPosition(data.offsetBottom, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let offsetStart = !validPosition(data.offsetStart, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let offsetEnd = !validPosition(data.offsetEnd, 'Offset')
    ? $localize`錯誤的偏移格式`
    : null;
  let action = inValidAction(data.type, data.action);
  let hasActionError = Object.values(action).find((value) => !!value);
  if (!hasActionError) action = null;
  let flex = !validValue(data.flex) ? $localize`錯誤的數值` : null;
  let color = !validColor(data.color) ? $localize`錯誤的顏色格式` : null;
  let error = {
    margin,
    offsetTop,
    offsetBottom,
    offsetStart,
    offsetEnd,
    action,
    flex,
    color,
  };
  filterEmpty(error);
  return error;
}

function filler(data: Flex.Block.Filler | RegularizedFlex) {
  let flex = !validValue(data.flex) ? $localize`錯誤的數值` : null;
  let error = { flex };
  filterEmpty(error);
  return error;
}

function spacer(data: Flex.Block.Spacer | RegularizedFlex) {
  let error = {};
  filterEmpty(error);
  return error;
}

function separator(data: Flex.Block.Separator | RegularizedFlex) {
  let margin = !validSize(data.margin, 'Margin')
    ? $localize`錯誤的外距格式`
    : null;
  let color = !validColor(data.color) ? $localize`錯誤的顏色格式` : null;
  let error = { margin, color };
  return error;
}

function span(data: Flex.Block.Span | RegularizedFlex) {
  let size = !validSize(data.size, 'SpanSize')
    ? $localize`錯誤的尺寸格式`
    : null;
  let text = !data.text ? $localize`文字內容不可為空` : null;
  let color = !validColor(data.color) ? $localize`錯誤的顏色格式` : null;
  let error = { size, text, color };
  filterEmpty(error);
  return error;
}

function icon(data: Flex.Block.Icon | RegularizedFlex) {
  let margin = !validSize(data.margin, 'Margin')
    ? $localize`錯誤的外距格式`
    : null;
  let size = !validSize(data.size, 'IconSize')
    ? $localize`錯誤的尺寸格式`
    : null;
  let url = !validURL(data.url) ? $localize`錯誤的網址格式` : null;
  let aspectRatio = !validValue(data.aspectRatio, ':\\d{1,}')
    ? $localize`錯誤的數值`
    : null;
  let error = { margin, size, url, aspectRatio };
  filterEmpty(error);
  return error;
}

//----------------------------------offset、padding、action----------------------------------//

function validSize(value: string, propName?: string) {
  if (!value || isCode(value)) return true;
  let validKeyword = true;
  if (propName)
    validKeyword = FLEX_PROP_OPTIONS[propName]
      .map((padding) => padding.value)
      .includes(value);

  return validValue(value, 'px') || validKeyword;
}

function validPosition(value: string, propName?: string) {
  if (!value || isCode(value)) return true;

  let validKeyword = true;
  if (propName)
    validKeyword = FLEX_PROP_OPTIONS[propName]
      .map((padding) => padding.value)
      .includes(value);

  return validValue(value, 'px') || validValue(value, '%') || validKeyword;
}

function inValidBackground(background: Flex.BackgroundObject.LinearGradient) {
  if (!background?.type) return {};
  switch (background.type) {
    case 'linearGradient':
      let angle = !background.angle
        ? $localize`角度不可為空`
        : !background.angle.includes('deg')
          ? $localize`錯誤的角度格式`
          : null;
      let startColor = !background.startColor
        ? $localize`起始色不可為空`
        : !validColor(background.startColor)
          ? $localize`錯誤的顏色格式`
          : null;
      let endColor = !background.endColor
        ? $localize`終點色不可為空`
        : !validColor(background.endColor)
          ? $localize`錯誤的顏色格式`
          : null;
      let centerColor = !validColor(background.centerColor)
        ? $localize`錯誤的顏色格式`
        : null;
      let centerPosition = !validValue(background.centerPosition, '%')
        ? $localize`錯誤的數值`
        : null;
      return { angle, startColor, endColor, centerColor, centerPosition };
  }
}

function inValidAction(
  dataType: string,
  action:
    | Flex.ActionObject.URI
    | Flex.ActionObject.Message
    | Flex.ActionObject.Postback
    | Flex.ActionObject.DatetimePicker,
) {
  if (!action?.type) return {};
  let label =
    dataType == 'button' && !action.label
      ? $localize`按鈕文字不可為空`
      : action.label?.length > 40
        ? $localize`按鈕文字不可超過40個字`
        : null;
  let data: string;

  switch (action.type) {
    case 'uri':
      let uri = !action.uri
        ? $localize`網址不可為空`
        : !validURL(action.uri)
          ? $localize`錯誤的網址格式`
          : null;
      let altUriDesktop = !validURL(action['altUri.desktop'])
        ? $localize`錯誤的網址格式`
        : null;
      return { label, uri, 'altUri.desktop': altUriDesktop };
    case 'message':
      let text = !action.text ? $localize`內文不可為空` : null;
      return { label, text };
    case 'postback':
      data = !action.data ? $localize`回傳內容不可為空` : null;
      return { label, data };
    case 'datetimepicker':
      data = !action.data ? $localize`回傳內容不可為空` : null;
      let mode = !action.mode ? $localize`時間格式不可為空` : null;
      let initial = !validTime(action.mode, action.initial)
        ? $localize`錯誤的時間格式`
        : null;
      let max = !validTime(action.mode, action.max)
        ? $localize`錯誤的時間格式`
        : null;
      let min = !validTime(action.mode, action.min)
        ? $localize`錯誤的時間格式`
        : null;
      return { label, data, mode, initial, max, min };
    default:
      return {};
  }
}

//----------------------------------合理的字串格式----------------------------------//

function isCode(value: string): boolean {
  let pattern = new RegExp(/\$\{.*\}/g);
  return pattern.test(value);
}

function validValue(value: string, unit?: string) {
  if (!value || isCode(value)) return true;
  let pattern = new RegExp(`^(\\-|\\+)?\\d+(\\.\\d+)?${unit || ''}$`);
  return pattern.test(value);
}

function validColor(color: string): boolean {
  if (!color || isCode(color)) return true;
  let pattern = new RegExp(
    '(^#[0-9a-fA-F]{6}[0-9a-fA-F]{2}\\b)|(^#[0-9a-fA-F]{6}\\b)',
  );
  return pattern.test(color);
}

function validURL(value: string) {
  if (!value || isCode(value)) return true;
  try {
    new URL(value);
    return true;
  } catch (error) {
    return false;
  }
}

function validTime(mode: DatetimePickerMode, time: string) {
  if (!time || !mode || isCode(mode) || isCode(time)) return true;
  switch (mode) {
    case 'date':
      return moment(time, 'YYYY-MM-DD', true).isValid();
    case 'time':
      return moment(time, 'HH:mm', true).isValid();
    case 'datetime':
      return moment(time, 'YYYY-MM-DDtHH:mm', true).isValid();
    default:
      return false;
  }
}
