import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { Flex } from '@ay/bot';
import { FlexEditorService } from '../../../editor/editor.service';
import { IsStringPipe } from '../../../is-string.pipe';
import { RegularizedFlex } from '../../../regularized-flex.class';
import { FlexBoxComponent } from '../../block/box/box.component';
import { FlexImageComponent } from '../../block/image/image.component';
import { FlexVideoComponent } from '../../block/video/video.component';

@Component({
  selector: 'flex-bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ExtendedModule,
    NgFor,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    FlexBoxComponent,
    FlexImageComponent,
    FlexVideoComponent,
    IsStringPipe,
  ],
})
export class FlexBubbleComponent {
  @Input()
  public data: RegularizedFlex;

  @Input()
  public bubble: Flex.Content.Bubble;

  @Input()
  public flex: RegularizedFlex;

  protected sizeClass = {
    giga: 'LyGi',
    kilo: 'LyKi',
    mega: 'LyMe',
    micro: 'LyMi',
    nano: 'LyNa',
    undefined: 'LyMe',
  };

  public constructor(public flexEditorService: FlexEditorService) {}

  @HostBinding('class')
  public get hostClass(): string {
    const list = [];
    list.push(this.sizeClass[this.data.size]);
    return list.filter(Boolean).join(' ');
  }
}
