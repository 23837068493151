import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MessageIconPipe } from '../../message-icon.pipe';
import { CouponMessage } from '../coupon.message';

@Component({
  selector: 'ms-coupon-statistics-dialog',
  templateUrl: './coupon-statistics-dialog.component.html',
  styleUrls: ['./coupon-statistics-dialog.component.scss'],
  standalone: true,
  imports: [
    MatIcon,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    MatOption,
    MatHint,
    MatInput,
    NgIf,
    MatButton,
    MessageIconPipe,
    LegacyAppearanceDirective,
  ],
})
export class CouponStatisticsDialogComponent {
  public constructor(
    protected matDialogRef: MatDialogRef<CouponStatisticsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public message: CouponMessage,
  ) {}

  public close(): void {
    this.matDialogRef.close();
  }
}
