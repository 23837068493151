import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { JunctionComponent } from '../../junction/junction.component';
import { host, NodeComponent } from '../node.component';
import { EventNode } from './class';

@Component({
  selector: ':svg:g.event.node',
  templateUrl: './node.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['node'],
  standalone: true,
  imports: [JunctionComponent, NgIf],
})
export class EventNodeComponent extends NodeComponent<EventNode> {}
