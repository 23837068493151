import { Injectable } from '@angular/core';
import { StaticModel } from '@ay-gosu/server-shared';
import { UploadResponse } from '@ay-nestjs/share-client';
import { limitRange } from '@ay/util';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { bufferTime, filter, map } from 'rxjs/operators';
export { UploadResponse };
export type UploadStatusType = 'uploading' | 'success' | 'abort' | 'fail';

export interface UploadAction<Result = any> {
  tempSrc?: any;
  resultUrl?: string;
  file: File;
  progress?: BehaviorSubject<number>;
  uploadSubject?: BehaviorSubject<UploadResponse<Result>>;
  abort?: Function;
  status?: BehaviorSubject<UploadStatusType>;
}

const COMPRESS_MAX_WIDTH = 600;

@Injectable({
  providedIn: 'root',
})
export class StaticService {
  /** 驗證 url 是否合法 */
  public verifyUri(uri: string): boolean {
    return /^(http|https)\:\/\/.+\..+/i.test(uri);
  }

  /** 驗證 http url 是否合法 */
  public verifyHttpUri(uri: string): boolean {
    return /^http\:\/\/.+\..+/i.test(uri);
  }

  /** 驗證 http url 是否合法 */
  public verifyHttpsUri(uri: string): boolean {
    return /^https\:\/\/.+\..+/i.test(uri);
  }

  /** 上傳檔案 */
  public async uploadFile(
    file: File,
    notFileReader = false,
  ): Promise<UploadAction> {
    let tempSrc = null;
    if (!file) {
      throw new Error($localize`沒有選擇任何檔案`);
    }

    if (!notFileReader) {
      tempSrc = await this.readFilePromise(file);
      if (!tempSrc) {
        throw new Error($localize`無法讀取檔案`);
      }
    }

    let res: UploadAction = {
      tempSrc,
      file,
      uploadSubject: new BehaviorSubject<UploadResponse>(null),
      progress: new BehaviorSubject<number>(0),
      status: new BehaviorSubject<UploadStatusType>('uploading'),
    };

    let subscription: Subscription = (
      StaticModel.upload(file.name, file) as any as Observable<
        UploadResponse<string>
      >
    )
      .pipe(
        bufferTime(333),
        filter((buffer) => buffer.length != 0),
        map((buffer) => buffer[buffer.length - 1]),
      )
      .subscribe({
        next: (e) => {
          switch (e.status) {
            case 'all-done':
              res.resultUrl = e.result;
              break;
            case 'processing':
              res.progress.next(e.percentage);
              break;
          }
          res.uploadSubject.next(e);
        },
        error: (err) => {
          res.status.next('fail');
          console.error('Error upload file:', err);
          res.progress.error(err);
          res.status.error(err);
          res.uploadSubject.error(err);
          subscription.unsubscribe();
        },
        complete: () => {
          res.status.next('success');
          res.progress.next(100);
          res.progress.complete();
          res.status.complete();
          res.uploadSubject.complete();
          subscription.unsubscribe();
        },
      });
    res.abort = () => {
      if (res.status.getValue() != 'uploading') return;
      res.status.next('abort');
      subscription.unsubscribe();
    };
    return res;
  }

  /** 使用 file reader 讀取檔案，以 promise 回應 */
  public async readFilePromise(file: File): Promise<any> {
    return new Promise<UploadAction>((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as any);
      };
      reader.onerror = () => {
        console.error($localize`載入檔案失敗, ${file}`);
        resolve(null);
      };
      reader.readAsDataURL(file);
    });
  }

  public async uriToFilePromise(uri: string) {
    return new Promise<File>((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open('GET', uri, true);
      request.responseType = 'blob';
      request.onload = () => {
        let reader = new FileReader();
        reader.readAsDataURL(request.response);
        reader.onload = (e) => {
          let data: any = e.target;
          resolve(new File([data.result], uri));
        };
        reader.onerror = (e) => {
          reject(e);
        };
      };
      request.send();
    });
  }

  public async readImagePromise(file: File) {
    let readerSrc = await this.readFilePromise(file);
    return new Promise<any>((resolve, reject) => {
      let image = new Image();
      image.onload = () => {
        resolve(image);
      };
      image.src = readerSrc;
    });
  }

  public filterFileList(
    files: FileList,
    filterFn: (file: File, idx?: number) => boolean,
  ): File[] {
    let res: File[] = [];
    for (let idx = 0; idx < files.length; idx++) {
      let file = files[idx];
      if (filterFn(file, idx)) {
        res.push(file);
      }
    }
    return res;
  }

  public findFileList(
    files: FileList,
    findFn: (file: File, idx?: number) => boolean,
  ): File {
    let res: File[] = [];
    for (let idx = 0; idx < files.length; idx++) {
      let file = files[idx];
      if (findFn(file, idx)) {
        return file;
      }
    }
    return null;
  }

  /** 載入圖片連結，以 promise 回應 */
  public async readImageUrlPromise(uri: string): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      if (!this.verifyUri(uri)) {
        console.error($localize`非法的圖片連結, ${uri}`);
        resolve(null);
        return;
      }
      let previewImg = new Image();
      previewImg.onload = () => {
        resolve(previewImg);
      };
      previewImg.onerror = () => {
        console.error($localize`載入圖片連結失敗, ${uri}`);
        resolve(null);
      };
      previewImg.src = uri;
    });
  }

  private dataUriToBlob(uri: string): Blob {
    var binary = atob(uri.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
  }

  public compressImageToBuffer(
    img: HTMLImageElement,
    encoderOption: number = 0.7,
  ): Blob {
    let resizeRate = 1;
    if (COMPRESS_MAX_WIDTH < img.width) {
      resizeRate = COMPRESS_MAX_WIDTH / img.width;
    }
    let canvas = document.createElement('canvas');
    canvas.width = Math.round(img.width * resizeRate);
    canvas.height = Math.round(img.height * resizeRate);
    let ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    let compressed = canvas.toDataURL(
      'image/jpeg',
      limitRange(0.01, encoderOption, 1),
    );
    return this.dataUriToBlob(compressed);
  }

  public blobToFile(blob: Blob, fileName: string) {
    return new File([blob], fileName);
  }

  public async uploadBlob(blob: Blob, fileName: string) {
    return this.uploadFile(this.blobToFile(blob, fileName), true);
  }
}
