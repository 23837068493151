<div fxLayout="row" fxLayoutGap="15px">
  <mat-checkbox
    class="form-field"
    fxFlex="0 0 140px"
    [(ngModel)]="node.isAsync"
    color="primary"
    i18n
  >
    {{ node.isAsync ? notText : '' }}同步模式
  </mat-checkbox>

  <!-- <mat-checkbox
    class="form-field"
    fxFlex="0 0 140px"
    [(ngModel)]="node.processed"
    value="true"
    color="primary"
  >
    標示為已處理
  </mat-checkbox> -->
</div>

<div fxLayout="row" fxLayoutGap="15px">
  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label>Method</mat-label>
    <mat-select [(ngModel)]="node.method">
      <mat-option value="ftp">FTP</mat-option>
      <mat-option value="sftp">SFTP</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field fxFlex="4 0 0" legacyAppearance>
    <mat-label>Host</mat-label>
    <input matInput [(ngModel)]="node.host" />
  </mat-form-field>

  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label>Port</mat-label>
    <input matInput [(ngModel)]="node.port" />
  </mat-form-field>
</div>

<div fxLayout="row" fxLayoutGap="15px">
  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label>Path</mat-label>
    <input matInput [(ngModel)]="node.path" />
  </mat-form-field>
</div>

<div fxLayout="row" fxLayoutGap="15px">
  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label>Username</mat-label>
    <input matInput [(ngModel)]="node.username" />
  </mat-form-field>

  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label>Password</mat-label>
    <input matInput [(ngModel)]="node.password" />
  </mat-form-field>
</div>

<div fxLayout="row" fxLayoutGap="15px">
  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label i18n>回傳變數</mat-label>
    <input matInput [(ngModel)]="node.responseVar" />
  </mat-form-field>

  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label i18n>型態</mat-label>
    <mat-select [(ngModel)]="node.responseType" name="item" i18n>
      <mat-option value="json">JSON</mat-option>
      <mat-option value="csv">CSV</mat-option>
      <mat-option value="tsv">TSV</mat-option>
      <mat-option value="string">純文字</mat-option>
      <mat-option value="rss">XML / RSS</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label i18n>編碼</mat-label>
    <mat-select [(ngModel)]="node.encoding" name="item">
      <mat-option value="utf-8">UTF 8</mat-option>
      <mat-option value="big5">BIG 5</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field fxFlex="1.2 0 0" legacyAppearance>
    <mat-label i18n>快取時間</mat-label>
    <input matInput [(ngModel)]="node.cacheTime" />
    <span matSuffix class="whitespace-nowrap align-text-bottom" i18n>毫秒</span>
    <mat-hint i18n>0 為不使用快取，最多為 60 秒</mat-hint>
  </mat-form-field>
</div>
