import { Button } from './button';

export class Card {
  public type = 'card' as const;
  // 在轉化短網址時所需要的資訊，為了統計用途
  public cardId?: number;
  public recordId?: number;
  public packageId?: number;

  public constructor(
    // 圖片網址
    public image: string,
    // 標題
    public title: string,
    // 內容
    public content: string,
    // 按鈕
    public buttons: Button.Any[],
  ) {
    this.type = 'card';
  }
}
