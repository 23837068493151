export const HEX_ALPHA_NUM_TO_CODE = {
  100: 'FF',
  99: 'FC',
  98: 'FA',
  97: 'F7',
  96: 'F5',
  95: 'F2',
  94: 'F0',
  93: 'ED',
  92: 'EB',
  91: 'E8',
  90: 'E6',
  89: 'E3',
  88: 'E0',
  87: 'DE',
  86: 'DB',
  85: 'D9',
  84: 'D6',
  83: 'D4',
  82: 'D1',
  81: 'CF',
  80: 'CC',
  79: 'C9',
  78: 'C7',
  77: 'C4',
  76: 'C2',
  75: 'BF',
  74: 'BD',
  73: 'BA',
  72: 'B8',
  71: 'B5',
  70: 'B3',
  69: 'B0',
  68: 'AD',
  67: 'AB',
  66: 'A8',
  65: 'A6',
  64: 'A3',
  63: 'A1',
  62: '9E',
  61: '9C',
  60: '99',
  59: '96',
  58: '94',
  57: '91',
  56: '8F',
  55: '8C',
  54: '8A',
  53: '87',
  52: '85',
  51: '82',
  50: '80',
  49: '7D',
  48: '7A',
  47: '78',
  46: '75',
  45: '73',
  44: '70',
  43: '6E',
  42: '6B',
  41: '69',
  40: '66',
  39: '63',
  38: '61',
  37: '5E',
  36: '5C',
  35: '59',
  34: '57',
  33: '54',
  32: '52',
  31: '4F',
  30: '4D',
  29: '4A',
  28: '47',
  27: '45',
  26: '42',
  25: '40',
  24: '3D',
  23: '3B',
  22: '38',
  21: '36',
  20: '33',
  19: '30',
  18: '2E',
  17: '2B',
  16: '29',
  15: '26',
  14: '24',
  13: '21',
  12: '1F',
  11: '1C',
  10: '1A',
  9: '17',
  8: '14',
  7: '12',
  6: '0F',
  5: '0D',
  4: '0A',
  3: '08',
  2: '05',
  1: '03',
  0: '00',
};

export const HEX_ALPHA_CODE_TO_NUM = {
  FF: 100,
  FC: 99,
  FA: 98,
  F7: 97,
  F5: 96,
  F2: 95,
  F0: 94,
  ED: 93,
  EB: 92,
  E8: 91,
  E6: 90,
  E3: 89,
  E0: 88,
  DE: 87,
  DB: 86,
  D9: 85,
  D6: 84,
  D4: 83,
  D1: 82,
  CF: 81,
  CC: 80,
  C9: 79,
  C7: 78,
  C4: 77,
  C2: 76,
  BF: 75,
  BD: 74,
  BA: 73,
  B8: 72,
  B5: 71,
  B3: 70,
  B0: 69,
  AD: 68,
  AB: 67,
  A8: 66,
  A6: 65,
  A3: 64,
  A1: 63,
  '9E': 62,
  '9C': 61,
  99: 60,
  96: 59,
  94: 58,
  91: 57,
  '8F': 56,
  '8C': 55,
  '8A': 54,
  87: 53,
  85: 52,
  82: 51,
  80: 50,
  '7D': 49,
  '7A': 48,
  78: 47,
  75: 46,
  73: 45,
  70: 44,
  '6E': 43,
  '6B': 42,
  69: 41,
  66: 40,
  63: 39,
  61: 38,
  '5E': 37,
  '5C': 36,
  59: 35,
  57: 34,
  54: 33,
  52: 32,
  '4F': 31,
  '4D': 30,
  '4A': 29,
  47: 28,
  45: 27,
  42: 26,
  40: 25,
  '3D': 24,
  '3B': 23,
  38: 22,
  36: 21,
  33: 20,
  30: 19,
  '2E': 18,
  '2B': 17,
  29: 16,
  26: 15,
  24: 14,
  21: 13,
  '1F': 12,
  '1C': 11,
  '1A': 10,
  17: 9,
  14: 8,
  12: 7,
  '0F': 6,
  '0D': 5,
  '0A': 4,
  '08': 3,
  '05': 2,
  '03': 1,
  '00': 0,
};
