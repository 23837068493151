import { NgFor, NgIf } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { Button, Card, Content } from '@ay/bot';
import { ignoreError } from '@ay/util';
import Bluebird from 'bluebird';
import flatten from 'lodash/flatten';
import * as uuid from 'uuid';
import { EmptyResponseError } from '../../../../../util/empty-response-error';
import { TagShortButtonComponent } from '../../../../components/tag-short-button/tag-short-button.component';
import { TagDialog } from '../../../../dialog/tag';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../../material/tooltip/tooltip';
import { PackageFactoryService } from '../../../../message/package-factory.service';
import { PreviewComponent } from '../../../../message/preview/preview.component';
import { SubmenuComponent } from '../../../../message/submenu/submenu.component';
import { ToolbarComponent } from '../../../../message/toolbar/toolbar.component';
import { FlowService } from '../../flow.service';
import { FormComponent } from '../form.component';
import { SendNode } from './class';

@Component({
  selector: 'flow-send-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatTooltip,
    MatCheckbox,
    NgIf,
    TagShortButtonComponent,
    MatSelect,
    NgFor,
    MatOption,
    SubmenuComponent,
    PreviewComponent,
    ToolbarComponent,
    MatButton,
    LegacyAppearanceDirective,
  ],
})
export class SendFormComponent
  extends FormComponent
  implements AfterContentInit, OnDestroy
{
  @Input()
  public node: SendNode;

  public cardMergeLimits: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  public constructor(
    public readonly flowService: FlowService,
    public readonly elementRef: ElementRef,
    private readonly _tagDialog: TagDialog,
    private readonly _packageFactory: PackageFactoryService,
  ) {
    super(elementRef);
  }

  public async ngAfterContentInit() {
    if (!this.node.package) {
      this.node.package = await this._packageFactory.createForFlow();
    }

    if (this.node.stackerName) {
      this.node.useStacker = true;
    }
  }

  public ngOnDestroy() {
    this._recalcJunctions();
    this.node.checkError();
  }

  public async addTag(elementRef: ElementRef) {
    try {
      await this._tagDialog
        .add('package', { elementRef, tags: this.node.package.tags })
        .catch(ignoreError(EmptyResponseError));
    } catch (error) {
      if (error instanceof EmptyResponseError) return;
      throw error;
    }
  }

  public afterUseStackerChanged() {
    if (this.node.useStacker) {
      this.node.stackerName = 'default';
    } else {
      this.node.stackerName = '';
    }
  }

  private async _recalcJunctions() {
    const contents = this.node.package.messages.map(
      (message) => message.content,
    );
    const nextButtons = this.filterNextButtons(contents);
    const nextQuickReplies = this.filterNextReplies();

    const usedJunction = nextButtons.map((button) => 0);
    const junctions = this.node.junctions;

    [].concat(nextButtons, nextQuickReplies).map((button) => {
      if (button.data === null) {
        button.data = uuid.v4();
      }

      let index = junctions.findIndex(
        (junction) => junction.data == button.data,
      );

      if (index === -1) {
        const junction = { text: button.text, data: button.data };
        index = junctions.push(junction) - 1;
      } else {
        junctions[index].text = button.text || button.label;
      }

      usedJunction[index] = 1;
    });

    this.node.junctions = junctions.filter((_, i) => usedJunction[i]);
    await Bluebird.delay(100);
    this.node.nodeComponent.updateSize();
  }

  protected filterNextReplies() {
    return this.node.package.quickReplies.filter(
      (reply) => reply && reply.type === 'next',
    );
  }

  protected filterNextButtons(contents: Content.Base[]) {
    const cards = flatten(
      contents.map((content) =>
        content && content.type === 'cards' ? content['cards'] : [],
      ),
    ) as Card[];
    const buttons = flatten(cards.map((card) => card.buttons));
    return buttons.filter((button) => button.type === 'next') as Button.Next[];
  }
}
