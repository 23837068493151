import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { BypassSecurityService } from '../../../service/bypass-security.service';
import { StaticService } from '../../../service/static.service';
import { AudioUploaderDialog } from '../../audio/uploader/uploader.dialog';
import { ProgressBarComponent } from '../../../components/progress-bar/progress-bar.component';
import { MatButton } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'ms-video-uploader-dialog',
    templateUrl: './uploader.dialog.html',
    styleUrls: ['./uploader.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatButton,
        ProgressBarComponent,
        AsyncPipe,
    ],
})
export class VideoUploaderDialog implements AfterViewInit, OnDestroy {
  public state: 'init' | 'uploading' | 'uploaded' = 'init';

  public url$ = new Subject<SafeUrl>();

  public progress$: Subject<number>;

  //#region file
  private _file: File = null;

  public file$ = new BehaviorSubject<File>(null);

  public get file(): File {
    return this._file;
  }

  public set file(file: File) {
    if (this._file === file) return;
    this._file = file;
    this.file$.next(this._file);
  }
  //#endregion file

  @ViewChild('player')
  public player: HTMLVideoElement;

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly _staticService: StaticService,
    private readonly _matDialogRef: MatDialogRef<AudioUploaderDialog>,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _bypassSecurityService: BypassSecurityService,
    @Inject(MAT_DIALOG_DATA)
    file: File,
  ) {
    this.file = file;
  }

  public ngAfterViewInit(): void {
    this.loadFileToPlayer();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  protected loadFileToPlayer() {
    this.file$
      .pipe(
        takeUntil(this._destroy$),
        filter((file) => !!file),
      )
      .subscribe((file) => {
        let reader = new FileReader();
        reader.onload = (event) => this.onFileReaderLoad(reader);
        reader.readAsDataURL(this.file);
      });
  }

  protected onFileReaderLoad(reader: FileReader) {
    if (reader.readyState !== FileReader.DONE) return;
    const url = this._bypassSecurityService.bypassSecurityUrl(
      reader.result.toString(),
      [BypassSecurityService.isBypassVideoFile],
    );

    this.url$.next(url);
  }

  public async submit() {
    this.state = 'uploading';
    let upload = await this._staticService.uploadFile(this._file);
    this.progress$ = upload.progress;
    this._changeDetectorRef.markForCheck();
    let response = await firstValueFrom(
      upload.uploadSubject.pipe(first((event) => event?.status == 'all-done')),
    );

    this._matDialogRef.close(response.result);
  }

  public cancel() {
    this._matDialogRef.close();
  }
}
