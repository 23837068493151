import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  public constructor(private readonly _matSnackBar: MatSnackBar) {}

  public copy(data: any) {
    try {
      data = this.stringify(data);
      this._copyTextareaContent(data);
      this._matSnackBar.open($localize`成功複製 ${data}`, '', {
        duration: 3000,
      });
    } catch (error) {
      this._matSnackBar.open($localize`複製失敗`, '', { duration: 3000 });
    }
  }

  private stringify(data: any) {
    if (typeof data == 'object') {
      return JSON.stringify(data);
    } else {
      return data;
    }
  }

  private _copyTextareaContent(data: string) {
    navigator.clipboard.writeText(data);
  }
}
