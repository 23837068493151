import { Component } from '@angular/core';
import { host, NodeComponent } from '../node.component';
import { TimerNode } from './class';
import { JunctionComponent } from '../../junction/junction.component';

@Component({
    selector: ':svg:g.timer.node',
    templateUrl: './node.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['node'],
    standalone: true,
    imports: [JunctionComponent],
})
export class TimerNodeComponent extends NodeComponent<TimerNode> {}
