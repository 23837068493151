import { Command } from './command';

export class ComponentsCommand extends Command {
  public constructor(private readonly _commands: Command[]) {
    super();
  }

  public async do() {
    this._commands.map((command) => command.do());
  }

  public async undo() {
    this._commands.reverse().map((command) => command.undo());
  }
}
