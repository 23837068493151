import { NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { FormComponent } from '../form.component';
import { LeaveNode } from './class';

@Component({
  selector: 'leave-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    FlexModule,
    MatSelect,
    FormsModule,
    MatOption,
    NgIf,
    MatLabel,
    MatInput,
    MatHint,
    LegacyAppearanceDirective,
  ],
})
export class LeaveFormComponent
  extends FormComponent<LeaveNode>
  implements AfterViewInit
{
  public width = 400;
  public showIdInput = false;

  public constructor(public readonly elementRef: ElementRef) {
    super(elementRef);
  }

  public ngAfterViewInit() {
    this.showIdInput = !!this.node.targetId;
  }
}
