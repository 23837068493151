// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { InteractiveType } from './interactive-type.type';
import { QualificationDto } from './qualification.dto';

export class InteractiveDto {
  /** 自動派獎模組編號，如果是 Fetch 時會有資料 */
  public id?: number;

  // 名稱
  public name: string;

  // 開始時間
  public startAt: string;

  // 結束時間
  public endAt: string;

  // 活動類型
  public type: InteractiveType;

  // 任務設定
  public qualifications: QualificationDto[];

  /*
   * 每人抽獎次數上限
   */
  public limitTimesPerProfile: number;

  public hasRecord?: boolean;

  public isApproved?: boolean;
}
// d98deb187e9e53e2115b9ee1d9c21a608f13c41c05e36ce6db32c5165f72dbe5
