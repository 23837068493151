export const MENU = {
  header: ['box'],
  hero: ['box', 'image'],
  body: ['box'],
  footer: ['box'],
  box: ['box', 'image', 'text', 'button', 'filler', 'separator'],
  text: ['span'],
  carousel: ['bubble'],
};

export const NEW_CONTENT = {
  box: {
    type: 'box',
    layout: 'vertical',
    isExpanded: true,
    isSelect: true,
    contents: [{ type: 'text', text: $localize`一段文字` }],
  },
  image: {
    type: 'image',
    url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png',
    isSelect: true,
  },
  video: {
    type: 'video',
    url: 'https://i.imgur.com/KjDxa7w.mp4',
    previewUrl: 'https://i.imgur.com/dParMJu.jpeg',
    altContent: {
      type: 'image',
      size: 'full',
      aspectRatio: '20:13',
      aspectMode: 'cover',
      url: 'https://i.imgur.com/dParMJu.jpeg',
    },
    aspectRatio: '20:13',
  },
  text: { type: 'text', text: $localize`一段文字`, isSelect: true },
  span: { type: 'span', text: $localize`一段文字`, isSelect: true },
  button: {
    type: 'button',
    action: {
      type: 'uri',
      label: $localize`按鈕文字`,
      uri: 'http://linecorp.com/',
    },
    isSelect: true,
  },
  icon: {
    type: 'icon',
    url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
    isSelect: true,
  },
  filler: { type: 'filler', isSelect: true },
  spacer: { type: 'spacer', isSelect: true },
  separator: { type: 'separator', isSelect: true },
  bubble: {
    type: 'bubble',
    isExpanded: true,
    isSelect: true,
    contents: [
      { type: 'header', contents: [] },
      { type: 'hero', contents: [] },
      {
        type: 'body',
        isExpanded: true,
        contents: [
          {
            type: 'box',
            layout: 'vertical',
            isExpanded: true,
            contents: [{ type: 'text', text: $localize`一段文字` }],
          },
        ],
      },
      { type: 'footer', contents: [] },
    ],
  },
};
