import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { Node } from './class';
import { DynamicFormComponent } from './dynamic/form.component';

@Component({
    template: 'abstract component',
    standalone: true,
})
export class FormComponent<T extends Node = Node>
  implements AfterContentInit, AfterViewInit, OnDestroy
{
  public width = 900;

  @Input()
  public node: T = null;

  public dynamic: DynamicFormComponent;

  protected readonly destroy$ = new Subject<void>();

  public constructor(public readonly elementRef: ElementRef<HTMLElement>) {}

  @ViewChild('footer', { static: false })
  public footer: TemplateRef<any>;

  public ngAfterViewInit(): void {
    if (this.footer) {
      setTimeout(() => (this.dynamic.footer = this.footer));
    }
  }

  public ngAfterContentInit() {
    if (this.node) {
      this.node.formComponent = this;
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
