<div class="header" i18n>
  <p>請選擇 Flex 樣板</p>
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="container">
  <div class="mat-dialog-content">
    <div class="row">
      <mat-card
        class="col"
        *ngFor="let template of templates$ | async"
        (click)="selected = template.data"
        [class.active]="selected === template.data"
      >
        <img mat-card-image [src]="template.imgUrl" [alt]="template.title" />
        <mat-card-content class="title">{{ template.title }}</mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<br />

<mat-divider></mat-divider>

<div class="mat-dialog-actions" align="center">
  <button
    mat-raised-button
    color="primary"
    (click)="submit()"
    [disabled]="!selected"
    i18n
  >
    選擇樣板
  </button>
</div>
