import { Injectable } from '@angular/core';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { Content } from '@ay/bot';
import { firstValueFrom } from 'rxjs';
import * as uuid from 'uuid';
import { EmptyResponseError } from '../../../util/empty-response-error';
import { Package } from '../package.class';
import { Card } from './card/card.class';
import { CardsMessage } from './cards.message';
import { CardCreatorDialog } from './general-editor/general-editor.dialog';
import { CardTemplateEditorDialog } from './template-editor/template-editor.dialog';
import { CardTemplatePickerDialog } from './template-picker/template-picker.dialog';

@Injectable({ providedIn: 'root' })
export class CardsMessageService {
  public constructor(protected matConnectedDialog: MatConnectedDialog) {}

  public async createFromTemplatePicker(): Promise<CardsMessage | null> {
    const dialogRef = this.matConnectedDialog.open(CardTemplatePickerDialog);

    const cards: Card[] = await firstValueFrom(dialogRef.afterClosed());

    if (cards === undefined) return null;
    const content = new Content.Cards(cards, $localize`一組卡片`);
    const message = new CardsMessage();
    await message.loadFromContent(content);
    return message;
  }

  public async createFromCreator(pkg: Package): Promise<CardsMessage> {
    let card = new Card();
    card.image = '';
    card.title = $localize`未命名卡片`;
    card.content = $localize`卡片的內容，你可以打很多很多的文字`;
    card.buttons = [];

    const message = new CardsMessage();

    const dialogRef = this.matConnectedDialog.open(CardCreatorDialog, {
      disableClose: true,
      data: { card, message, package: pkg },
    });

    card = await firstValueFrom(dialogRef.afterClosed());
    if (!card) throw new EmptyResponseError();

    this.fillNextButtonData(card);

    const content = new Content.Cards([card], $localize`一組卡片`);
    await message.loadFromContent(content);
    return message;
  }

  public async openEditor(card: Card, message: CardsMessage, pkg: Package) {
    try {
      if (card.template) {
        return this.openTemplateCardEditor(card, message, pkg);
      } else {
        return this.openGeneralCardEditor(card, message, pkg);
      }
    } catch (error) {}
  }

  protected async openTemplateCardEditor(
    card: Card,
    message: CardsMessage,
    pkg: Package,
  ) {
    const dialog = this.matConnectedDialog.open(CardTemplateEditorDialog, {
      data: { card, message, package: pkg },
    });

    await firstValueFrom(dialog.afterClosed());
  }

  protected async openGeneralCardEditor(
    card: Card,
    message: CardsMessage,
    pkg: Package,
  ) {
    const dialogRef = this.matConnectedDialog.open(CardCreatorDialog, {
      disableClose: true,
      data: { card, message, package: pkg },
    });

    await firstValueFrom(dialogRef.afterClosed());

    this.fillNextButtonData(card);
  }

  protected fillNextButtonData(card: Card) {
    card.buttons.map((button) => {
      if (button.type !== 'next') return;
      if (!button['data']) {
        button['data'] = uuid.v4();
      }
    });
  }
}
