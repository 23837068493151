<ng-container *ngIf="package">
  <div
    class="messages"
    [matContextMenu]="msSubmenu.matMenu"
    [matContextMenuDisable]="mode === 'READ'"
    [matWithoutChildren]="true"
    cdkDropList
    cdkDropListOrientation="vertical"
    cdkDropListLockAxis="y"
    [cdkDropListDisabled]="mode === 'READ'"
    (cdkDropListDropped)="messageDropped($event)"
  >
    <div
      class="message"
      *ngFor="let message of package.messages; let index = index"
      cdkDrag
      [cdkDragDisabled]="package.inTextEditMode"
      [matContextMenu]="messageContextMenu"
      [matContextMenuCustomPosition]="position"
      [matContextMenuDisable]="mode === 'READ'"
      (contextmenu)="selected = message"
    >
      <ms-factory
        [ngClass]="message?.direction"
        [message]="message"
        [package]="package"
        [mode]="mode"
      >
      </ms-factory>
      <mat-icon class="drag-indicator" *ngIf="mode === 'EDIT'">
        drag_indicator
      </mat-icon>
    </div>
  </div>

  <ms-quick-replies
    *ngIf="package.enableQuickReplies"
    [package]="package"
    [mode]="mode"
  ></ms-quick-replies>

  <div *ngIf="package.isLoading" class="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="menu" #menu>
    <mat-menu
      #messageContextMenu="matMenu"
      #menu
      class="tiny-menu"
      xPosition="before"
    >
      <button mat-menu-item (click)="delete()" i18n>
        <mat-icon>delete</mat-icon> 刪除
      </button>

      <button
        mat-menu-item
        (click)="swapUpward()"
        [disabled]="isFirstMessage"
        i18n
      >
        <mat-icon>arrow_upward</mat-icon> 上移
      </button>

      <button
        mat-menu-item
        (click)="swapDownward()"
        [disabled]="isLatestMessage"
        i18n
      >
        <mat-icon>arrow_downward</mat-icon> 下移
      </button>
    </mat-menu>
  </div>

  <ms-submenu
    [package]="package"
    [toolbars]="package.toolbars"
    #msSubmenu="msSubmenu"
  >
  </ms-submenu>
</ng-container>
