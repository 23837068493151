import { Component, HostBinding, Input } from '@angular/core';
import { Flex } from '@ay/bot';

@Component({
    selector: 'flex-spacer',
    templateUrl: './spacer.component.html',
    styleUrls: ['./spacer.component.scss'],
    standalone: true,
})
export class FlexSpacerComponent {
  @Input()
  public data: Flex.Block.Spacer;

  @Input()
  public bubble: Flex.Content.Bubble;

  @Input()
  public flex: Flex.Message;

  @Input()
  public level: number = 0;

  protected sizeClass = {
    xs: 'spcXs',
    sm: 'spcSm',
    md: 'spcMd',
    lg: 'spcLg',
    xl: 'spcXl',
    xxl: 'spcXXl',
  };

  @HostBinding('class')
  public get hostClass(): string {
    const list = ['mdBxSpacer', 'fl' + this.level];
    list.push(this.sizeClass[this.data.size]);
    return list.filter(Boolean).join(' ');
  }
}
