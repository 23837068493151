import { BehaviorSubject } from 'rxjs';
import { AudioContextFactory } from './audio-content-factory.class';

export class AudioMeter {
  public instant$ = new BehaviorSubject<number>(0);

  protected script: ScriptProcessorNode;
  protected context: AudioContext;
  protected source: MediaStreamAudioSourceNode;

  public constructor(public stream: MediaStream) {
    this.context = AudioContextFactory.create();
    this.script = this.context.createScriptProcessor(2048, 1, 1);
    this.script.addEventListener('audioprocess', (event) =>
      this.onAudioProcess(event),
    );
    this.connectToSource();
  }

  protected onAudioProcess(event) {
    const input = event.inputBuffer.getChannelData(0);
    let i = 0;
    let sum = 0.0;
    for (i = 0; i < input.length; ++i) {
      sum += input[i] * input[i];
    }

    let instant = Math.sqrt(sum / input.length);

    this.instant$.next(instant);
  }

  protected connectToSource() {
    try {
      this.source = this.context.createMediaStreamSource(this.stream);
      this.source.connect(this.script);
      this.script.connect(this.context.destination);
    } catch (e) {
      console.error(e);
    }
  }

  public stop() {
    this.source.disconnect();
    this.script.disconnect();
  }
}
