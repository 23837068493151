<mat-card>
  <mat-card-header>
    <mat-card-title i18n>樣式</mat-card-title>
  </mat-card-header>

  <mat-divider> </mat-divider>

  <mat-card-content [formGroup]="formGroup">
    <div class="label" *ngFor="let style of styles">
      <div class="title">{{ style.name | cht }}</div>

      <programmable
        [title]="style.name | cht"
        *ngIf="style.options; else input"
        [value]="formGroup.value[style.name]"
        (valueChange)="
          formGroup.controls[style.name].setValue($event);
          formValueChange.emit(formGroup.value);
          findError()
        "
      >
        <mat-form-field legacyAppearance>
          <mat-select
            [formControlName]="style.name"
            (valueChange)="
              formGroup.controls[style.name].setValue($event);
              formValueChange.emit(formGroup.value);
              findError()
            "
          >
            <mat-option
              *ngFor="let option of style.options"
              [value]="option.value"
            >
              {{ option.text || option.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </programmable>

      <ng-template #input>
        <programmable
          [title]="style.name | cht"
          [value]="formGroup.value[style.name]"
          (valueChange)="
            formGroup.controls[style.name].setValue($event);
            formValueChange.emit(formGroup.value);
            findError()
          "
        >
          <mat-form-field
            [class.mat-form-field-invalid]="style.error"
            legacyAppearance
          >
            <gosu-color-picker
              #colorPicker
              [value]="formGroup.value[style.name]"
              (valueChange)="
                formGroup.controls[style.name].setValue($event);
                formValueChange.emit(formGroup.value);
                findError()
              "
              format="hex"
              [enableAlpha]="true"
            >
            </gosu-color-picker>
            <mat-hint>
              <span [class.mat-error]="style.error">
                {{ style.error || style.hint }}
              </span>
            </mat-hint>
          </mat-form-field>
        </programmable>
      </ng-template>
    </div>
  </mat-card-content>
</mat-card>
