import { Node } from '../class';

export class SendStackerNode extends Node {
  public icon = 'question_answer';
  public stackerName = 'default';
  public isAsync = false;
  public cardMergeLimit: number = 10;
  public readonly extraProperties = [
    'stackerName',
    'isAsync',
    'cardMergeLimit',
  ];
}
