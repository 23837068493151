import { Connection } from '../connection/class';
import { FlowService } from '../flow.service';
import { Node } from '../node/class';
import { Command } from './command';

export class AddNodeCommand extends Command {
  private readonly _oldSelected: (Node | Connection)[];

  public constructor(
    private readonly _flowService: FlowService,
    private readonly _node: Node,
  ) {
    super();
    this._oldSelected = _flowService.selected.slice();
  }

  public async do() {
    if (this._node.type == null) return;
    this._flowService.nodeList.push(this._node);
    this._flowService.selected = [this._node];
    this._flowService.checkModify();
    this._flowService.tick();
    this._node.nodeComponent.updateSize();
    this._node.nodeComponent.updateTransform();
  }

  public async undo() {
    let index = this._flowService.nodeList.indexOf(this._node);
    if (index !== -1) {
      this._flowService.nodeList.splice(index, 1);
      this._flowService.selected = this._oldSelected;
    }
    this._flowService.checkModify();
  }
}
