<div class="container">
  <ng-container *ngIf="state === 'init' && safeUrl">
    <img [src]="safeUrl" />
    <div class="tar">
      <button mat-button color="warn" (click)="cancel()" i18n>取消</button>
      <button mat-raised-button color="primary" (click)="submit()" i18n>
        確定上傳
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="state === 'uploading'">
    <gosu-progress-bar [value]="progress$ | async" i18n>
      圖片上傳中，請稍候
    </gosu-progress-bar>
  </ng-container>
</div>
