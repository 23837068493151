<div>
  <mat-form-field fxFlex="1 1 auto" legacyAppearance>
    <mat-select
      [(ngModel)]="showIdInput"
      (ngModelChange)="node.targetId = null"
      i18n
    >
      <mat-option [value]="false">觸發事件的群組</mat-option>
      <mat-option [value]="true">特定的群組</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div *ngIf="showIdInput">
  <mat-form-field fxFlex="1 1 auto" legacyAppearance>
    <mat-label i18n>指定群組編號</mat-label>
    <input matInput [(ngModel)]="node.targetId" />
    <mat-hint i18n>
      選填，未指定則以當前觸發流程的使用者為主，輸入範例： 123,6432,45661
    </mat-hint>
  </mat-form-field>
</div>
