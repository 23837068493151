import { Component } from '@angular/core';
import { host, NodeComponent } from '../node.component';
import { TextNode } from './class';
import { NgFor } from '@angular/common';

@Component({
    selector: ':svg:g.text.node',
    templateUrl: './node.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['node'],
    standalone: true,
    imports: [NgFor],
})
export class TextNodeComponent extends NodeComponent<TextNode> {}
