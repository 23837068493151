<div fxLayout="row" fxLayoutGap="15px">
  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label i18n>讀取對象</mat-label>
    <mat-select [(ngModel)]="node.target" (ngModelChange)="targetChange()" i18n>
      <mat-option value="profile">使用者</mat-option>
      <mat-option value="bot">機器人</mat-option>
      <mat-option value="company">組織</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    fxFlex="3 0 0"
    *ngIf="node.target !== 'company'; else empty"
    legacyAppearance
  >
    <mat-label></mat-label>
    <mat-select
      [placeholder]="targetMap[node.target] + '類別'"
      [(ngModel)]="showIdInput"
      (ngModelChange)="node.targetId = null"
    >
      <mat-option [value]="false" i18n>
        觸發事件的{{ targetMap[node.target] }}
      </mat-option>
      <mat-option [value]="true" i18n
        >特定的{{ targetMap[node.target] }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field
    legacyAppearance
    fxFlex="3 0 0"
    *ngIf="node.target !== 'company' && showIdInput; else empty"
  >
    <mat-label></mat-label>
    <input
      matInput
      [placeholder]="targetMap[node.target] + '編號'"
      [(ngModel)]="node.targetId"
      [matTooltip]="'如果不指定，將修改執行此流程的' + targetMap[node.target]"
    />
    <gosu-validator
      [(ngModel)]="node.targetId"
      [rules]="{ required: true }"
      [offsetY]="20"
    ></gosu-validator>
  </mat-form-field>

  <ng-template #empty>
    <div fxFlex="3 0 0"></div>
  </ng-template>

  <div fxFlex="3 0 0"></div>
</div>

<div class="row">
  <div
    fxLayout="row"
    fxLayoutGap="15px"
    class="pair flex-row"
    *ngFor="let pair of node.pairs; let index = index"
  >
    <div class="no">{{ index + 1 }}.</div>

    <mat-form-field fxFlex="1 0 0" legacyAppearance>
      <mat-label i18n>屬性</mat-label>
      <mat-select
        #propSelector
        [ngModel]="pair.key"
        (ngModelChange)="afterPropertyChanged(pair, $event)"
        (openedChange)="search.value = ''"
      >
        <mat-select-trigger [attr.data-key]="pair.key">
          {{ selected(propSelector.selected) }}
        </mat-select-trigger>
        <mat-form-field appearance="fill" class="search" legacyAppearance>
          <input
            #search
            matInput
            placeholder="屬性搜尋"
            i18n-placeholder="屬性搜尋"
            autocomplete="off"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <mat-option
          *ngFor="let config of filterList(search.value)"
          [value]="config.key"
          [attr.data-key]="config.key"
        >
          {{ config.name }}
        </mat-option>
      </mat-select>
      <gosu-validator
        [(ngModel)]="pair.key"
        [rules]="{ required: true }"
        [offsetY]="20"
      ></gosu-validator>
    </mat-form-field>

    <mat-form-field fxFlex="1 0 0" legacyAppearance>
      <mat-label i18n>回傳變數</mat-label>
      <input matInput [(ngModel)]="pair.responseVar" />
      <gosu-validator
        [(ngModel)]="pair.responseVar"
        [rules]="{ required: true }"
        [offsetY]="20"
      ></gosu-validator>
    </mat-form-field>

    <button
      mat-icon-button
      class="remove"
      (click)="removePair(index)"
      matTooltip="刪除此讀取動作"
      i18n-matTooltip="刪除此讀取動作"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<ng-template #footer>
  <button mat-button (click)="addPair()" i18n>
    <mat-icon>add</mat-icon>
    新增讀取動作
  </button>

  <button mat-button (click)="addPropertyConfig()" i18n>
    <mat-icon>assignment</mat-icon>
    新增屬性
  </button>

  <button
    mat-button
    color="primary"
    class="add-condition-btn"
    (click)="dynamic.submit()"
    i18n
  >
    確定
  </button>
</ng-template>
