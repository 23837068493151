import { RuleOp } from '@ay-gosu/types';
import { Rule } from './rule';

export abstract class FlagRule extends Rule {
  public static op: RuleOp[] = ['HAVE'];

  public op = 'HAVE' as RuleOp;

  public abstract keywords: string[];

  public afterTypeChanged(isUserEvent?: boolean) {}

  public similar(keyword: string): boolean {
    return this.keywords.reduce(
      (prev, current) => prev || current.includes(keyword),
      false,
    );
  }

  public toShortCode(): string {
    return '';
  }

  public checkError(): boolean {
    return false;
  }
}
