<svg:g *ngIf="!disabled">
  <svg:circle class="sensing" [attr.cx]="dx" [attr.cy]="dy" r="20"></svg:circle>
  <svg:circle
    [ngClass]="{ dark: dark }"
    [attr.cx]="dx"
    [attr.cy]="dy"
    [attr.stroke]="color"
    r="5"
  ></svg:circle>
  <svg:circle
    class="solid"
    [attr.cx]="dx"
    [attr.cy]="dy"
    [attr.fill]="color"
    r="0"
  ></svg:circle>
</svg:g>
