<form class="container">
  <p class="title" i18n>登入語智網進行語意資料庫同步</p>
  <mat-form-field appearance="outline" class="fw">
    <mat-label i18n>帳號</mat-label>
    <input matInput [(ngModel)]="username" name="lingtelli-username" />
  </mat-form-field>

  <mat-form-field appearance="outline" class="fw">
    <mat-label i18n>密碼</mat-label>
    <input
      matInput
      type="password"
      [(ngModel)]="password"
      name="lingtelli-password"
    />
    <mat-hint *ngIf="error" i18n>帳號或密碼錯誤</mat-hint>
  </mat-form-field>

  <button
    mat-raised-button
    class="fr login-button"
    color="primary"
    [disabled]="!username || !password || isLogging"
    (click)="login()"
    i18n
  >
    登入
  </button>
</form>
