<div>
  <a [style.padding-bottom.%]="imageHeight">
    <span
      *ngIf="data.url"
      [style.background-image]="'url(' + (data.url | imageType) + ')'"
      [style.background-color]="data['backgroundColor']"
    >
    </span>
  </a>
</div>
