import { Content } from '@ay/bot';
import { Message } from '../base/base.message';

export class LocationMessage extends Message<Content.Location> {
  public type = 'location';

  public toString() {
    throw this.content.title || $localize`一個地點`;
  }
}
