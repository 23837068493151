import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[file]',
  standalone: true,
})
export class FileDirective implements OnInit, OnDestroy {
  private _input: HTMLInputElement;

  //#region @Two-way() multiple
  private _multiple: boolean = false;

  public get multiple(): boolean {
    return this._multiple;
  }

  public multiple$ = new BehaviorSubject(this._multiple);

  @Input()
  public set multiple(multiple: boolean) {
    if (this._multiple === multiple) return;
    this._multiple = coerceBooleanProperty(multiple);
  }
  //#endregion @Two-way() multiple

  //#region accept
  private _accept: string = 'audio/*, video/*, image/*, application/*, text/*';

  public accept$ = new BehaviorSubject<string>(this._accept);

  public get accept(): string {
    return this._accept;
  }

  @Input()
  public set accept(accept: string) {
    if (this._accept === accept) return;
    this._accept = accept;
    this.accept$.next(this._accept);
  }
  //#endregion accept

  //#region disable
  private _disable: boolean = false;

  public get disable(): boolean {
    return this._disable;
  }

  public set disable(disable: boolean) {
    if (this._disable === disable) return;
    this._disable = coerceBooleanProperty(disable);
  }
  //#endregion disable

  //#region @Two-way() file
  private _file: FileList = null;

  @Output('file')
  public fileChange = new EventEmitter<FileList>();

  public get file(): FileList {
    return this._file;
  }

  public set file(file: FileList) {
    if (this._file === file) return;
    this._file = file;
    this.fileChange.emit(this._file);
  }
  //#endregion @Two-way() file

  private readonly _destroy$ = new Subject<void>();

  public constructor(private readonly _renderer2: Renderer2) {}

  public ngOnInit(): void {
    this.createFileInput();
    this._updateMultipleAttribute();
    this._updateAcceptAttribute();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public createFileInput() {
    this._input = document.createElement('INPUT') as HTMLInputElement;
    this._input.setAttribute('type', 'file');
    this._renderer2.listen(this._input, 'change', (event) => {
      this.file = this._input.files;
    });
  }

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent) {
    if (this.disable) {
      event.preventDefault();
      return false;
    }
    this._input.value = '';
    this._input.click();
  }

  private _updateMultipleAttribute() {
    this.multiple$
      .pipe(takeUntil(this._destroy$))
      .subscribe((multiple) => this._updateAttribute('multiple', multiple));
  }

  private _updateAcceptAttribute() {
    this.accept$
      .pipe(takeUntil(this._destroy$))
      .subscribe((accept) => this._updateAttribute('accept', accept));
  }

  private _updateAttribute(attribute: string, value: any) {
    try {
      if (value) {
        this._input.setAttribute(attribute, value);
      } else {
        this._input.removeAttribute(attribute);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
