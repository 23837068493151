import { RuleOp } from '@ay-gosu/types';
import { ProfileType } from '@ay/bot';
import {
  ensurePropertyIsArray,
  ensureTheElementsAreWhitelisted,
} from '@ay/util';
import {
  ProfileTypeMap,
  ProfileTypePipe,
  ProfileTypes,
} from '../../../../pipe/profile-type.pipe';
import { FromJsonOption, Rule } from '../rule';
import { ruleJoin } from '../ruleJoin';

export class ProfileTypeRule extends Rule<ProfileType[]> {
  public op = 'IN_LIST' as RuleOp;

  public constructor(...value: ProfileType[]) {
    super('PROFILE_TYPE');
    this.value = value;
  }

  public afterTypeChanged(isUserEvent?: boolean) {}

  public similar(keyword: string): boolean {
    return this.value.reduce(
      (prev, type) => prev || ProfileTypeMap[type].indexOf(keyword) !== -1,
      false,
    );
  }

  public async fromJSON(json: any, option: FromJsonOption) {
    await super.fromJSON(json, option);
    ensurePropertyIsArray(json, 'value');
    this.value = ensureTheElementsAreWhitelisted(
      json.value,
      ...ProfileTypes,
    ) as ProfileType[];
  }

  public toRuleObject(json: any = {}) {
    super.toRuleObject(json);
    json.value = this.value.join(',');
    return json;
  }

  public toString() {
    let pipe = new ProfileTypePipe();
    return $localize`帳戶類型是${ruleJoin(
      this.value.map((type) => pipe.transform(type)),
    )}`;
  }

  public static schema: string = 'profile-type';

  public toShortCode(): string {
    return `${this.value.join(',')}`;
  }

  public static async fromShortCode(code: string, option: FromJsonOption) {
    let type = ensureTheElementsAreWhitelisted(
      code.split(','),
      ...ProfileTypes,
    );
    let rule = new ProfileTypeRule(...(type as ProfileType[]));
    return rule;
  }

  public checkError(): boolean {
    return false;
  }
}
