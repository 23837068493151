<section class="json-viewer">
  <section
    *ngFor="let segment of segments"
    [ngClass]="['segment', 'segment-type-' + segment.type]"
  >
    <section
      (click)="toggle(segment, $event)"
      [ngClass]="{
        'segment-main': true,
        expandable: isExpandable(segment),
        expanded: segment.expanded
      }"
    >
      <div *ngIf="isExpandable(segment)" class="toggler">
        <mat-icon>arrow_right</mat-icon>
      </div>
      <button
        class="copy-button"
        mat-icon-button
        (click)="copy(segment, $event)"
        *ngIf="withCopyButton"
      >
        <icon icon="fa-copy"></icon>
      </button>
      <span class="segment-key" [matTooltip]="segment.document">
        {{ segment.key }}
      </span>
      <span class="segment-separator">: </span>
      <span
        *ngIf="!segment.expanded || !isExpandable(segment)"
        class="segment-value"
        [matTooltip]="segment.document"
      >
        {{ segment.description }}
      </span>
    </section>
    <section *ngIf="segment.expanded && isExpandable(segment)" class="children">
      <gosu-json-viewer
        [json]="segment.value"
        [expanded]="expanded"
        [expandedDeep]="expandedDeep - 1"
        [path]="(path ? path + '.' : '') + segment.key"
        [withCopyButton]="withCopyButton"
      >
      </gosu-json-viewer>
    </section>
  </section>
</section>
