import { RecordDto, TemplateModel } from '@ay-gosu/server-shared';
import { Area, Content } from '@ay/bot';
import { Message } from '../base/base.message';

export class PosterMessage extends Message<Content.Poster> {
  public templateName: string;
  public template: Content.Template;

  public type = 'poster';
  public templateLost = false;

  public constructor() {
    super();
    this.content = new Content.Poster('', $localize`一張海報`);
  }

  public async loadFromContent(content: Content.Poster | Content.Template) {
    if (content.type === 'poster') {
      content['__proto__'] = Content.Poster.prototype;
      this.content = content;
    } else {
      await this.loadFromTemplate(content);
    }
  }

  public toString() {
    return this.content.alt || $localize`一張海報`;
  }

  public toJSON() {
    if (this.template) {
      return this.template;
    } else {
      return this.content;
    }
  }

  protected async loadFromTemplate(content: Content.Template) {
    this.template = content;

    if (!content.preview) {
      await this.previewTemplate(content);
    } else {
      this.content = content.preview as Content.Poster;
    }

    this.template.preview = this.content;
  }

  protected async previewTemplate(content: any) {
    const result: Content.Poster = (await TemplateModel.exec(
      content.templateId,
      content.properties,
    )) as any;

    if (result === null) {
      this.templateLost = true;
      return;
    }

    if (result.type !== 'poster') {
      throw $localize`圖像範本的類型${result.type}與訊息的類型${this.type}不一致`;
    }

    this.content = result;
  }

  protected loadFromGeneralRecord(content: any, record: RecordDto) {
    this.content.content = content.preview.content;
    this.content.areas = record.poster.areas.map((data) =>
      this.convertAreaAyBot(data as any),
    );
    this.content.alt = content.preview.alt;
  }

  protected convertAreaAyBot(data: Area.Any) {
    const { type, x, y, width, height, content } = data as any;

    switch (type) {
      case 'uri':
        return new Area.Uri(content, x, y, width, height);

      case 'message':
        return new Area.Uri(content, x, y, width, height);

      default:
        throw $localize`未知按鈕區塊類型${type}`;
    }
  }
}
