import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { MatTooltip } from '../../material/tooltip/tooltip';
import { ClipboardService } from '../../service/clipboard.service';
import { IconComponent } from '../icon/icon.component';

export interface Segment {
  key: string;
  value: any;
  type: undefined | string;
  description: string;
  document: string;
  expanded: boolean;
}

@Component({
  selector: 'gosu-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    NgClass,
    NgIf,
    MatIcon,
    MatIconButton,
    IconComponent,
    MatTooltip,
  ],
})
export class JsonViewerComponent implements OnChanges {
  @Input()
  public withCopyButton = true;

  @Input()
  public json: any;

  @Input()
  public path = '';

  @Input()
  public expandedDeep = 0;

  @Input()
  public expanded = false;

  public faCopy = faCopy;

  public constructor(public clipboard: ClipboardService) {}

  public segments: Segment[] = [];

  public ngOnChanges() {
    this.segments = [];

    if (typeof this.json === 'object') {
      Object.keys(this.json).forEach((key) => {
        if (key.startsWith('$')) return;
        this.segments.push(
          this._parseKeyValue(key, this.json[key], this.json['$' + key]),
        );
      });
    } else {
      this.segments.push(
        this._parseKeyValue(`(${typeof this.json})`, this.json),
      );
    }
  }

  public isExpandable(segment: Segment) {
    return segment.type === 'object' || segment.type === 'array';
  }

  public toggle(segment: Segment, $event: MouseEvent) {
    if (this.isExpandable(segment)) {
      segment.expanded = !segment.expanded;
    }
    $event.stopPropagation();
  }

  public copy(segment: Segment, $event: MouseEvent) {
    this.clipboard.copy((this.path ? this.path + '.' : '') + segment.key);
    $event.stopPropagation();
  }

  private _parseKeyValue(key: any, value: any, document: string = ''): Segment {
    const segment: Segment = {
      key: key,
      value: value,
      type: undefined,
      description: '' + value,
      document,
      expanded: this.expandedDeep > 0,
    };

    switch (typeof segment.value) {
      case 'number': {
        segment.type = 'number';
        break;
      }
      case 'boolean': {
        segment.type = 'boolean';
        break;
      }
      case 'function': {
        segment.type = 'function';
        break;
      }
      case 'string': {
        segment.type = 'string';
        segment.description = '"' + segment.value + '"';
        break;
      }
      case 'undefined': {
        segment.type = 'undefined';
        segment.description = 'undefined';
        break;
      }
      case 'object': {
        // yea, null is object
        if (segment.value === null) {
          segment.type = 'null';
          segment.description = 'null';
        } else if (Array.isArray(segment.value)) {
          segment.type = 'array';
          segment.description =
            '[' + segment.value.length + '] ' + JSON.stringify(segment.value);
          if (segment.description.length > 100) {
            segment.description = segment.description.substr(0, 97) + '...';
          }
        } else if (segment.value instanceof Date) {
          segment.type = 'date';
        } else {
          segment.type = 'object';
          segment.expanded =
            value['$expanded'] !== undefined
              ? value['$expanded']
              : segment.expanded;
          segment.description = JSON.stringify(
            segment.value,
            (key, value) => {
              if (key.startsWith('$')) return undefined;
              return value;
            },
            2,
          );
          if (segment.description.length > 100) {
            segment.description = segment.description.substr(0, 97) + '...';
          }
        }
        break;
      }
    }

    return segment;
  }
}
