import { Node } from '../class';

export type Pair = {
  key: string;
  responseVar: string;
};

export class GetAccountLinkUrlNode extends Node {
  public icon = 'insert_link';

  public isAsync = false;

  public responseVar: string = 'accountLinkUrl';

  public readonly extraProperties = ['responseVar', 'isAsync'];
}
