import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PageService } from '../../../../service/page.service';
import {
  AccountLinkEventNode,
  AddCommentNode,
  AddIGCommentNode,
  AddLikePageNode,
  AddPostCustomerNode,
  AddPostEditorsNode,
  AddRatingsNode,
  AddReactionNode,
  AddThroughPromotionEventNode,
  AudioMessageEventNode,
  ClickBeaconBannerEventNode,
  EditReactionNode,
  EditedPostCustomerNode,
  EditedPostEditorsNode,
  EnterBeaconEventNode,
  EventNode,
  ExchangeEventNode,
  FollowEventNode,
  IGMEEventNode,
  ImageMessageEventNode,
  JoinEventNode,
  LeaveBeaconEventNode,
  LeaveEventNode,
  LocationMessageEventNode,
  MMEEventNode,
  MemberJoinedEventNode,
  MemberLeftEventNode,
  MessageEventNode,
  PostbackEventNode,
  ReceiveCouponEventNode,
  RemoveCommentNode,
  RemoveLikePageNode,
  RemovePostCustomerNode,
  RemovePostEditorsNode,
  RemoveRatingsNode,
  RemoveReactionNode,
  StayBeaconEventNode,
  SuccessInviteFriendThroughPromotionEventNode,
  SurveyCakeEventNode,
  TextMessageEventNode,
  UnfollowEventNode,
  UntreatedEventNode,
  UseCouponEventNode,
  VideoMessageEventNode,
  WebhookEventNode,
} from './class';
import { demo } from './demo';

export type Event = {
  type: string;
  class: typeof EventNode;
  label: string;
  icon?: string;
  platforms?: string[];
  demo?: any;
  enable$?: Observable<boolean>;
};

@Injectable({
  providedIn: 'root',
})
export class EventNodeService {
  public enablePromotionChannel$ = this._pageService.list$.pipe(
    map((list) =>
      Boolean(list.find((page) => page.path == '/promotion-channel')),
    ),
    shareReplay(),
  );

  public events: Event[] = [
    {
      type: 'WebhookEventNode',
      class: WebhookEventNode,
      icon: 'http',
      label: $localize`自訂事件(開放對外API)`,
      demo: {
        ...demo.expansion,
        request: demo.request,
      },
    },
    {
      type: 'FollowEventNode',
      class: FollowEventNode,
      icon: 'person_add',
      label: $localize`加入好友、成為粉絲`,
      demo: demo.expansion,
    },
    {
      type: 'UnfollowEventNode',
      class: UnfollowEventNode,
      label: $localize`封鎖好友`,
      icon: 'person_remove',
      platforms: ['LINE'],
      demo: demo.expansion,
    },
    {
      type: 'JoinEventNode',
      class: JoinEventNode,
      icon: 'group_add',
      label: $localize`機器人加入群組`,
      platforms: ['LINE'],
      demo: {
        ...demo.expansion,
        from: {
          user: demo.profile,
          profile: demo.group,
          group: demo.group,
          bot: demo.bot,
          company: demo.company,
        },
      },
    },
    {
      type: 'LeaveEventNode',
      class: LeaveEventNode,
      label: $localize`機器人離開群組`,
      icon: 'exit_to_app',
      platforms: ['LINE'],
      demo: {
        ...demo.expansion,
        from: {
          user: demo.profile,
          profile: demo.group,
          group: demo.group,
          bot: demo.bot,
          company: demo.company,
        },
      },
    },
    {
      type: 'AccountLinkEventNode',
      class: AccountLinkEventNode,
      label: $localize`成功鏈結帳號`,
      icon: 'link',
      platforms: ['LINE'],
      demo: {
        ...demo.expansion,
        profile: demo.profile,
        from: demo.from,
        $result: $localize`執行結果`,
        result: {
          $nonce: 'nonce',
          nonce: 'NONCE',
          $result: 'ok',
          result: 'ok',
        },
      },
    },
    {
      type: 'PostbackEventNode',
      class: PostbackEventNode,
      icon: 'attach_email',
      label: $localize`Postback 事件(接收回傳的資訊)`,
      demo: {
        ...demo.expansion,
        $postback: $localize`Postback 傳回物件`,
        postback: $localize`回傳內容(如果是 JSON 會自動解析)`,
        $params: $localize`回傳日期/時間資料（當按鈕動作類型是 日歷 時提供）`,
        params: {
          $date: $localize`回傳日期（當時間格式是 日期 時提供）`,
          date: '2021-04-13',
          $time: $localize`回傳時間（當時間格式是 時間 時提供）`,
          time: '17:42',
          $datetime: $localize`回傳日期&時間（當時間格式是 日期與時間 時提供）`,
          datetime: '2021-04-13T17:42',
        },
      },
    },
    {
      type: 'MessageEventNode',
      class: MessageEventNode,
      label: $localize`接收到任意訊息`,
      icon: 'chat_bubble',
      demo: {
        ...demo.expansion,
        $record: $localize`訊息內容（依照類型提供不同的內容格式）`,
        record: {
          $id: $localize`編號`,
          id: 123,
          $type: $localize`類型`,
          type: $localize`text`,
          $content: $localize`文字內容`,
          content: $localize`你好！`,
        },
      },
    },
    {
      type: 'TextMessageEventNode',
      class: TextMessageEventNode,
      label: $localize`接收到文字訊息`,
      icon: 'title',
      demo: {
        ...demo.expansion,
        profile: demo.profile,
        from: demo.from,
        $record: $localize`訊息內容`,
        record: {
          $id: $localize`編號`,
          id: 123,
          $type: $localize`類型`,
          type: 'text',
          $content: $localize`文字內容`,
          content: $localize`你好！`,
        },
      },
    },
    {
      type: 'ImageMessageEventNode',
      class: ImageMessageEventNode,
      label: $localize`接收到圖片`,
      icon: 'insert_photo',
      demo: {
        ...demo.expansion,
        profile: demo.profile,
        from: demo.from,
        $record: $localize`訊息內容`,
        record: {
          $id: $localize`編號`,
          id: 123,
          $type: $localize`類型`,
          type: 'text',
          $content: $localize`圖片網址`,
          content: 'https://www.abc.com/assets.png',
        },
      },
    },
    {
      type: 'VideoMessageEventNode',
      class: VideoMessageEventNode,
      label: $localize`接收到影片`,
      icon: 'videocam',
    },
    {
      type: 'AudioMessageEventNode',
      class: AudioMessageEventNode,
      label: $localize`接收到聲音`,
      icon: 'graphic_eq',
    },
    {
      type: 'LocationMessageEventNode',
      class: LocationMessageEventNode,
      icon: 'location_on',
      label: $localize`接收到位置資訊`,
      demo: {
        ...demo.expansion,
        profile: demo.profile,
        from: demo.from,
        $record: $localize`訊息內容`,
        record: {
          $id: $localize`編號`,
          id: 123,
          $type: $localize`類型`,
          type: 'text',
          $content: $localize`地點名稱`,
          content: $localize`安永金融科技`,
          $latitude: $localize`緯度`,
          latitude: 25.0666605,
          $longitude: $localize`經度`,
          longitude: 121.5236849,
        },
      },
      platforms: ['LINE'],
    },
    {
      type: 'EnterBeaconEventNode',
      class: EnterBeaconEventNode,
      label: $localize`進入 Beacon 範圍`,
      icon: 'bluetooth',
      demo: {
        ...demo.expansion,
      },
    },
    {
      type: 'ClickBeaconBannerEventNode',
      class: ClickBeaconBannerEventNode,
      label: $localize`點擊 Beacon Banner 時`,
      icon: 'bluetooth',
      demo: {
        ...demo.expansion,
      },
    },
    {
      type: 'StayBeaconEventNode',
      class: StayBeaconEventNode,
      label: $localize`待在 Beacon 範圍內(間隔10秒(最少)觸發一次)`,
      icon: 'bluetooth',
      demo: {
        ...demo.expansion,
      },
    },
    {
      type: 'LeaveBeaconEventNode',
      class: LeaveBeaconEventNode,
      label: $localize`離開 Beacon 範圍（LINE 將於 2020 年底迄用）`,
      icon: 'bluetooth',
      demo: {
        ...demo.expansion,
      },
    },
    {
      type: 'AddIGCommentNode',
      class: AddIGCommentNode,
      label: $localize`IG 使用者回應文章`,
      icon: 'reply',
      platforms: ['IG'],
      demo: {
        ...demo.expansion,
        record: demo.fb.addComment.record,
        post: demo.fb.addComment.post,
      },
    },
    {
      type: 'AddPostEditorsNode',
      class: AddPostEditorsNode,
      label: $localize`小編在粉絲專頁發文`,
      icon: 'post_add',
      platforms: ['FB'],
    },
    {
      type: 'EditedPostEditorsNode',
      class: EditedPostEditorsNode,
      label: $localize`小編編輯粉絲專頁的文章`,
      icon: 'create',
      platforms: ['FB'],
    },
    {
      type: 'RemovePostEditorsNode',
      class: RemovePostEditorsNode,
      label: $localize`小編刪除粉絲專頁上的貼文`,
      icon: 'delete',
      platforms: ['FB'],
    },
    {
      type: 'AddPostCustomerNode',
      class: AddPostCustomerNode,
      label: $localize`使用者在粉絲專頁發文`,
      icon: 'post_add',
      platforms: ['FB'],
    },
    {
      type: 'EditedPostCustomerNode',
      class: EditedPostCustomerNode,
      label: $localize`使用者編輯粉絲專頁上的貼文`,
      icon: 'create',
      platforms: ['FB'],
    },
    {
      type: 'RemovePostCustomerNode',
      class: RemovePostCustomerNode,
      label: $localize`使用者刪除粉絲專頁上的貼文`,
      icon: 'delete',
      platforms: ['FB'],
    },
    {
      type: 'AddLikePageNode',
      class: AddLikePageNode,
      label: $localize`粉絲專頁收到讚`,
      icon: 'thumb_up',
      platforms: ['FB'],
    },
    {
      type: 'RemoveLikePageNode',
      class: RemoveLikePageNode,
      label: $localize`粉絲專頁的讚被收回(不再支援)`,
      icon: 'close',
      platforms: ['FB'],
    },
    {
      type: 'AddRatingsNode',
      class: AddRatingsNode,
      label: $localize`新增評論`,
      platforms: ['FB'],
      icon: 'add_comment',
    },
    {
      type: 'RemoveRatingsNode',
      class: RemoveRatingsNode,
      label: $localize`刪除評論(不再支援)`,
      icon: 'close',
      platforms: ['FB'],
    },
    {
      type: 'AddCommentNode',
      class: AddCommentNode,
      label: $localize`使用者回應文章`,
      icon: 'reply',
      platforms: ['FB'],
      demo: {
        ...demo.expansion,
        record: demo.fb.addComment.record,
        post: demo.fb.addComment.post,
      },
    },
    {
      type: 'RemoveCommentNode',
      class: RemoveCommentNode,
      label: $localize`使用者刪除回應`,
      icon: 'delete',
      platforms: ['FB'],
    },
    {
      type: 'AddReactionNode',
      class: AddReactionNode,
      label: $localize`使用者對某則貼文按讚或心情`,
      icon: 'thumb_up',
      platforms: ['FB'],
      demo: {
        ...demo.expansion,
        record: demo.fb.addReaction.record,
        post: demo.fb.addReaction.post,
      },
    },
    {
      type: 'EditReactionNode',
      class: EditReactionNode,
      label: $localize`使用者編輯某則貼文的按讚或心情`,
      icon: 'thumb_up',
      platforms: ['FB'],
    },
    {
      type: 'RemoveReactionNode',
      class: RemoveReactionNode,
      label: $localize`使用者收回某則貼文的按讚或心情`,
      icon: 'thumb_down',
      platforms: ['FB'],
    },
    {
      type: 'MMEEventNode',
      class: MMEEventNode,
      label: $localize`m.me 連結`,
      icon: 'fa-facebook-messenger',
      platforms: ['FB'],
    },
    {
      type: 'IGMEEventNode',
      class: IGMEEventNode,
      label: $localize`ig.me 連結`,
      icon: 'fa-instagram',
      platforms: ['IG'],
    },
    {
      type: 'MemberJoinedEventNode',
      class: MemberJoinedEventNode,
      label: $localize`群組有新成員`,
      icon: 'person_add',
      platforms: ['LINE'],
    },
    {
      type: 'MemberLeftEventNode',
      class: MemberLeftEventNode,
      label: $localize`群組成員退出`,
      icon: 'person_remove',
      platforms: ['LINE'],
    },
    {
      type: 'UseCouponEventNode',
      class: UseCouponEventNode,
      label: $localize`當使用者使用優惠券`,
      icon: 'fa-ticket-alt',
      platforms: ['LINE'],
    },
    {
      type: 'ReceiveCouponEventNode',
      class: ReceiveCouponEventNode,
      label: $localize`當使用者收到優惠券`,
      icon: 'fa-ticket-alt',
      platforms: ['LINE'],
    },
    {
      type: 'ExchangeEventNode',
      class: ExchangeEventNode,
      label: $localize`成功兌換獎品`,
      icon: 'fa-exchange-alt',
      demo: {
        $code: $localize`獎品代碼`,
        code: 'nRhelFt6oFEVwC5Vh',
        $url: $localize`網址`,
        url: 'https://points.line.me/pointcode/#/pointcode/form',
        $exchange: $localize`兌換活動`,
        exchange: demo.exchange,
        $exchangeMethod: $localize`兌換辦法`,
        exchangeMethod: { id: 8, name: $localize`白鑽會員兌換辦法` },
        $ticket: $localize`獎品`,
        ticket: { id: 3, name: 'LINE POINT', used: 8, total: 100 },
        profile: demo.profile,
        from: demo.from,
      },
    },
    {
      type: 'SurveyCakeEventNode',
      class: SurveyCakeEventNode,
      label: $localize`填完 SurveyCake 問券`,
      icon: 'poll',
      demo: {
        $surveyCake: $localize`surveyCake 回傳的資料（易於存取）`,
        surveyCake: {
          單行文字: [$localize`單行文字的回答`],
          多行文字: [
            $localize`多行文字的回答1`,
            $localize`多行文字的回答2`,
            $localize`多行文字的回答3`,
          ],
          單選題: [$localize`選項A`],
          多選題: [$localize`選項A`, $localize`選項C`],
          矩形題: {
            選項A: ['1'],
            選項B: ['2'],
            選項C: ['3'],
          },
          數字題: [5],
          數字滑桿: [24],
          項目排序: ['A', 'B', 'C'],
          星級評分: [3],
          檔案上傳: ['img_1593655710322.jpg'],
          日期: ['2020-07-07'],
        },
        $surveyCakeOriginal: $localize`surveyCake 回傳的原始資料`,
        surveyCakeOriginal: [
          {
            subject: $localize`單行文字`,
            type: 'TXTSHORT',
            sn: 0,
            answer: [$localize`單行文字`],
            otherAnswer: [],
          },
          {
            subject: $localize`多行文字`,
            type: 'TXTLONG',
            sn: 1,
            answer: [$localize`多行文字\n`],
            otherAnswer: [],
          },
          {
            subject: $localize`單選題`,
            type: 'CHOICEONE',
            sn: 2,
            answer: [$localize`選項A`],
            otherAnswer: [],
          },
          {
            subject: $localize`多選題`,
            type: 'CHOICEMULTI',
            sn: 3,
            answer: [$localize`選項A`, $localize`選項C`],
            otherAnswer: [],
          },
          {
            subject: $localize`矩形題`,
            type: 'NEST',
            sn: 4,
            answer: [],
            otherAnswer: [],
          },
          {
            subject: $localize`選項A`,
            type: 'NESTCHILD',
            sn: 5,
            answer: ['1'],
            otherAnswer: [],
          },
          {
            subject: $localize`選項B`,
            type: 'NESTCHILD',
            sn: 6,
            answer: ['2'],
            otherAnswer: [],
          },
          {
            subject: $localize`選項C`,
            type: 'NESTCHILD',
            sn: 7,
            answer: ['3'],
            otherAnswer: [],
          },
          {
            subject: $localize`數字題`,
            type: 'DIGITINPUT',
            sn: 8,
            answer: [5],
            otherAnswer: [],
          },
          {
            subject: $localize`數字滑桿`,
            type: 'DIGITSLIDE',
            sn: 9,
            answer: [24],
            otherAnswer: [],
          },
          {
            subject: $localize`項目排序`,
            type: 'ITEMSORT',
            sn: 10,
            answer: ['Ａ'],
            otherAnswer: [],
          },
          {
            subject: $localize`星級評分`,
            type: 'RATINGBAR',
            sn: 11,
            answer: [3],
            otherAnswer: [],
          },
          {
            subject: $localize`檔案上傳`,
            type: 'FILEUPLOAD',
            sn: 13,
            answer: ['img_1593655710322.jpg'],
            otherAnswer: [],
          },
          {
            subject: $localize`引言`,
            type: 'QUOTE',
            sn: 14,
            answer: [],
            otherAnswer: [],
          },
          {
            subject: $localize`分類`,
            type: 'STATEMENT',
            sn: 15,
            answer: [],
            otherAnswer: [],
          },
          {
            subject: $localize`日期`,
            type: 'DATEPICKER',
            sn: 16,
            answer: ['2020-07-07'],
            otherAnswer: [],
          },
          {
            subject: $localize`分隔線/分頁`,
            type: 'DIVIDER',
            sn: 17,
            answer: [],
            otherAnswer: [],
          },
        ],
      },
      platforms: ['SurveyCake'],
    },
    {
      type: 'AddThroughPromotionEventNode',
      class: AddThroughPromotionEventNode,
      label: $localize`透過推廣通路加入好友`,
      icon: 'account_tree',
      demo: {
        ...demo.expansion,
        $inviterProfile: $localize`邀請人(推廣通路當類型為'分享訊息'時會有)`,
        inviterProfile: demo.profile,
        $from: $localize`受邀人`,
        $beInvitedProfile: $localize`受邀人`,
        beInvitedProfile: demo.profile,
        $promotionChannel: $localize`推廣通路`,
        promotionChannel: {
          $id: $localize`編號`,
          id: 1,
          $name: $localize`推廣通路名稱`,
          name: $localize`邀請好友送點數`,
          $key: $localize`代碼`,
          key: '2021_INVITE',
          $type: $localize`類型(URL: QR Code / 連結; SHARE_MESSAGE: 分享訊息)`,
          type: 'URL',
        },
        $subPromotionChannel: $localize`推廣子通路(推廣通路當類型為'QR Code / 連結'時會有)`,
        subPromotion: {
          id: 1,
          name: $localize`南京東路店`,
          $key: $localize`代碼`,
          key: 'TPE_001',
        },
      },
      enable$: this.enablePromotionChannel$,
    },
    {
      type: 'SuccessInviteFriendThroughPromotionEventNode',
      class: SuccessInviteFriendThroughPromotionEventNode,
      label: $localize`成功透過推廣通路邀請好友`,
      icon: 'account_tree',
      demo: {
        ...demo.expansion,
        $inviterProfile: $localize`邀請人`,
        inviterProfile: demo.profile,
        $from: $localize`邀請人`,
        $beInvitedProfile: $localize`受邀人`,
        beInvitedProfile: demo.profile,
        $promotionChannel: $localize`推廣通路`,
        promotionChannel: {
          $id: $localize`編號`,
          id: 1,
          $name: $localize`推廣通路名稱`,
          name: $localize`邀請好友送點數`,
          $key: $localize`代碼`,
          key: '2021_INVITE',
          $type: $localize`類型(URL: QR Code / 連結; SHARE_MESSAGE: 分享訊息)`,
          type: 'URL',
        },
      },
      enable$: this.enablePromotionChannel$,
    },
    {
      type: 'UntreatedEventNode',
      class: UntreatedEventNode,
      label: $localize`當訊息未被處理時`,
      icon: 'last_page',
      demo: null,
    },
  ];

  public constructor(private readonly _pageService: PageService) {}
}
