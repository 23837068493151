import { Component, Input } from '@angular/core';
import { Content } from '@ay/bot';
import { MessageAffectedComponent } from '../message-affected.component';
import { Package } from '../package.class';
import { Message } from './base.message';

@Component({
  selector: 'base-component',
  template: '',
})
export class BaseComponent<
  T extends Message<Content.Any>,
> extends MessageAffectedComponent<T> {
  @Input()
  public mode: 'READ' | 'EDIT';

  @Input()
  public package?: Package;

  public get index() {
    if (this.package === undefined) return -1;
    return this.package.messages.indexOf(this.message);
  }

  public get isFirstMessage() {
    if (this.package === undefined) return false;
    return this.index === 0;
  }

  public get isLastMessage() {
    if (this.package === undefined) return false;
    return this.index === this.package.messages.length - 1;
  }

  public async delete() {
    if (this.package === undefined) return;
    this.package.messages.splice(this.index, 1);
    this.package.changed();
  }

  public swapUpward() {
    if (this.package === undefined) return;
    this.package.swap(this.index, this.index - 1);
  }

  public swapDownward() {
    if (this.package === undefined) return;
    this.package.swap(this.index, this.index + 1);
  }
}
