import { NgFor } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardTitle,
} from '@angular/material/card';
import { MatOption } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ProgrammableComponent } from '../../../../../components/programmable/programmable.component';
import { LegacyAppearanceDirective } from '../../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { RegularizedFlex } from '../../../regularized-flex.class';
import { FlexChinesePipe } from '../../chinese.pipe';
import { FlexEditorService } from '../../editor.service';
import { ERROR_MESSAGE } from '../../error';
import { FormProperty } from '../form';
import { FLEX_PROP_OPTIONS } from '../options';

@Component({
  selector: 'form-padding',
  templateUrl: './padding.component.html',
  styleUrls: ['./padding.component.scss'],
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatDivider,
    MatCardContent,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    ProgrammableComponent,
    MatFormField,
    MatInput,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOption,
    MatHint,
    FlexChinesePipe,
    LegacyAppearanceDirective,
  ],
})
export class FlexFormPaddingComponent implements OnChanges {
  @Input()
  public formValue: RegularizedFlex;

  @Output()
  public formValueChange = new EventEmitter();

  public paddings: FormProperty[] = [
    {
      name: 'paddingAll',
      hint: 'ex: 100px or 100%',
      autoComplete: FLEX_PROP_OPTIONS.Padding,
    },
    {
      name: 'paddingTop',
      hint: 'ex: 100px or 100%',
      autoComplete: FLEX_PROP_OPTIONS.Padding,
    },
    {
      name: 'paddingBottom',
      hint: 'ex: 100px or 100%',
      autoComplete: FLEX_PROP_OPTIONS.Padding,
    },
    {
      name: 'paddingStart',
      hint: 'ex: 100px or 100%',
      autoComplete: FLEX_PROP_OPTIONS.Padding,
    },
    {
      name: 'paddingEnd',
      hint: 'ex: 100px or 100%',
      autoComplete: FLEX_PROP_OPTIONS.Padding,
    },
  ];

  public formGroup: FormGroup = new FormGroup({
    paddingAll: new FormControl(),
    paddingTop: new FormControl(),
    paddingBottom: new FormControl(),
    paddingStart: new FormControl(),
    paddingEnd: new FormControl(),
  });

  public errorFunction: (data: RegularizedFlex) => {};

  public constructor(public readonly flexEditorService: FlexEditorService) {}

  public ngOnChanges(): void {
    if (this.formValue)
      this.formGroup = new FormGroup({
        paddingAll: new FormControl(this.formValue['paddingAll']),
        paddingTop: new FormControl(this.formValue['paddingTop']),
        paddingBottom: new FormControl(this.formValue['paddingBottom']),
        paddingStart: new FormControl(this.formValue['paddingStart']),
        paddingEnd: new FormControl(this.formValue['paddingEnd']),
      });
    this.findError();
  }

  public findError() {
    this.errorFunction = ERROR_MESSAGE[this.formValue.type];
    this.paddings.forEach((padding) => {
      let hasError = this.errorFunction(this.formValue);
      if (hasError) padding.error = hasError[padding.name];
      else delete padding.error;
    });
  }
}
