import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { QuickReply, QuickReplyType } from '@ay/bot';
import { firstValueFrom } from 'rxjs';
import * as uuid from 'uuid';
import { PlatformComponent } from '../../../components/platform/platform.component';
import { ImagePickerDialog } from '../../../dialog/image';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../material/tooltip/tooltip';
import { QuickReplyTypePipe } from '../../../pipe/quick-reply-type.pipe';
import { PackageAffectedComponent } from '../../package-affected.component';
import { Package } from '../../package.class';

@Component({
  selector: 'ms-quick-reply-dialog',
  templateUrl: './quick-reply.dialog.html',
  styleUrls: ['./quick-reply.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['package'],
  standalone: true,
  imports: [
    FlexModule,
    MatButton,
    MatTooltip,
    NgIf,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    MatSelectTrigger,
    NgFor,
    MatOption,
    PlatformComponent,
    MatInput,
    MatHint,
    QuickReplyTypePipe,
    LegacyAppearanceDirective,
  ],
})
export class QuickReplyDialog extends PackageAffectedComponent {
  @Input()
  public quickReply: QuickReply = { type: 'next', label: '' };

  public types: QuickReplyType[] = [
    'message',
    'location',
    'email',
    'phone',
    'camera',
    'album',
    'next',
  ];

  public typePlatforms = {
    next: ['FACEBOOK', 'LINE'],
    message: ['FACEBOOK', 'LINE'],
    postback: ['FACEBOOK', 'LINE'],
    location: ['LINE'],
    email: ['FACEBOOK'],
    phone: ['FACEBOOK'],
    camera: ['LINE'],
    album: ['LINE'],
    date: ['LINE'],
  };

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _matConnectedDialog: MatConnectedDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: { quickReply: QuickReply; package: Package },
  ) {
    super(changeDetectorRef);
    this.quickReply = data.quickReply;
    this.package = data.package;
  }

  public afterChanged() {
    this.package.changed();
    this.fillNextUuid();
  }

  public async uploadIcon() {
    const dialogRef = this._matConnectedDialog.open(ImagePickerDialog, {
      data: { url: this.quickReply.icon, useCode: true },
    });
    const res = await firstValueFrom(dialogRef.afterClosed());
    if (res === undefined) return null;
    this.quickReply.icon = res;
  }

  public fillNextUuid() {
    if (this.quickReply.type === 'next') {
      let rq = this.quickReply as QuickReply.Next;
      if (!rq.data) {
        rq.data = uuid.v4();
      }
    }
  }

  public filterType() {
    return this.package.enabledActions.next
      ? this.types
      : this.types.filter((item) => item !== 'next');
  }
}
