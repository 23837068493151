<div class="form">
  <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="center center">
    <mat-form-field fxFlex="1 0 200px" legacyAppearance>
      <mat-label i18n>指定發送對象</mat-label>
      <input
        matInput
        [(ngModel)]="node.profileIds"
        matTooltip="沒有指定時，發送給觸發者；透過逗點分隔傳送的使用者"
        i18n-matTooltip="沒有指定時，發送給觸發者；透過逗點分隔傳送的使用者"
      />
    </mat-form-field>

    <mat-checkbox
      fxFlex="0 0 140px"
      [(ngModel)]="node.useStacker"
      (ngModelChange)="afterUseStackerChanged()"
      value="true"
      color="primary"
      matTooltip="可以將相同分組的訊息疊加再一起，最後再透過「發送疊加器」發送"
      i18n-matTooltip="
        可以將相同分組的訊息疊加再一起，最後再透過「發送疊加器」發送
      "
      i18n
    >
      使用疊加器
    </mat-checkbox>

    <mat-checkbox
      fxFlex="0 0 140px"
      [(ngModel)]="node.isAsync"
      color="primary"
      i18n
    >
      <ng-container *ngIf="node.isAsync">非</ng-container>同步模式
    </mat-checkbox>

    <mat-checkbox
      fxFlex="0 0 140px"
      [(ngModel)]="node.processed"
      value="true"
      color="primary"
      i18n
    >
      標示為已處理
    </mat-checkbox>

    <gosu-tag-short-button
      *ngIf="node.package"
      #tagShortButton
      (click)="addTag(tagShortButton.elementRef)"
      [tags]="node.package.tags"
    >
    </gosu-tag-short-button>
  </div>

  <div
    class="row"
    fxLayout="row"
    fxLayoutGap="15px"
    *ngIf="node.useStacker || node.fromOldPackage"
  >
    <ng-container *ngIf="node.useStacker">
      <mat-form-field fxFlex="1 0 0" legacyAppearance>
        <mat-label i18n>疊加分組名稱</mat-label>
        <input matInput [(ngModel)]="node.stackerName" />
      </mat-form-field>

      <mat-form-field fxFlex="1 0 0" legacyAppearance>
        <mat-label i18n>疊加數量</mat-label>
        <mat-select [(ngModel)]="node.cardMergeLimit" name="item">
          <mat-option *ngFor="let item of cardMergeLimits" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <mat-form-field *ngIf="node.fromOldPackage" legacyAppearance>
      <mat-label i18n>訊息編號</mat-label>
      <input matInput [(ngModel)]="node.packageId" fxFlex="1 0 0" />
    </mat-form-field>
  </div>
</div>

<ng-container *ngIf="node.package">
  <ms-submenu [package]="node.package" [toolbars]="node.package.toolbars">
  </ms-submenu>
  <ms-preview [package]="node.package" mode="EDIT"></ms-preview>
</ng-container>

<ng-template #footer>
  <ng-container *ngIf="node.package">
    <ms-toolbar [style.flex]="'1 0 0'" [package]="node.package"> </ms-toolbar>

    <button mat-button color="primary" (click)="dynamic.submit()" i18n>
      確定
    </button>
  </ng-container>
</ng-template>
