<button mat-flat-button color="warn" class="warn" i18n>
  <mat-icon>warning</mat-icon>
  回覆留言僅能接在事件類型為「使用者回應文章」的起始點後面
</button>
<div>
  <mat-form-field legacyAppearance>
    <mat-label i18n>回應方式</mat-label>
    <mat-select [(ngModel)]="node.way" name="item" i18n>
      <mat-option value="private_replies">透過私訊回覆</mat-option>
      <mat-option value="comments">透過留言回覆</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-checkbox
    class="form-field"
    fxFlex="0 0 140px"
    [(ngModel)]="node.processed"
    value="true"
    color="primary"
    i18n
  >
    標示為已處理
  </mat-checkbox>
</div>

<div>
  <mat-form-field legacyAppearance>
    <mat-label i18n>回應內容</mat-label>
    <textarea matInput [(ngModel)]="node.content"></textarea>
  </mat-form-field>
</div>

<div fxLayout="row" *ngIf="node.way === 'comments'">
  <mat-form-field fxFlex="1 0 0" legacyAppearance>
    <mat-label i18n>回應圖片</mat-label>
    <input matInput [(ngModel)]="node.imgUrl" />
  </mat-form-field>
  <button
    class="upload"
    mat-raised-button
    color="primary"
    disableRipple="true"
    element-ref
    #elementRef="elementRef"
    (click)="pickImage(elementRef)"
    i18n
  >
    上傳
  </button>
</div>
