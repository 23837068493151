import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SelectableService {
  public oriUserSelect = '';
  public counter: number = 0;

  public disable() {
    this.counter++;
    if (this.counter === 1) {
      this.oriUserSelect = document.body.style.userSelect;
      document.body.style.userSelect = 'none';
    }
  }

  public enable() {
    this.counter--;
    if (this.counter === 0) {
      document.body.style.userSelect = this.oriUserSelect;
    }
  }
}
