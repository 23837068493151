import { Component } from '@angular/core';
import { host, NodeComponent } from '../node.component';
import { ResponseNode } from './class';
import { JunctionComponent } from '../../junction/junction.component';
import { NgIf } from '@angular/common';

@Component({
    selector: ':svg:g.rich-menu.node',
    templateUrl: '../node.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host,
    standalone: true,
    imports: [NgIf, JunctionComponent],
})
export class ResponseNodeComponent extends NodeComponent<ResponseNode> {}
