import { Component, forwardRef } from '@angular/core';
import { host, NodeComponent } from '../node.component';
import { SendNode } from './class';
import { ConditionComponent } from '../condition/condition.component';
import { JunctionComponent } from '../../junction/junction.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: ':svg:g.send.node',
    templateUrl: './node.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['node'],
    providers: [
        {
            provide: NodeComponent,
            useExisting: forwardRef(() => SendNodeComponent),
        },
    ],
    standalone: true,
    imports: [
        NgIf,
        JunctionComponent,
        NgFor,
        ConditionComponent,
    ],
})
export class SendNodeComponent extends NodeComponent<SendNode> {
  public fill = '#EEEEEE';
  public borderColor = '#999999';
  public fontColor = '#444444';

  public trackByData(item) {
    return item;
  }
}
