import { Node } from '../class';

export type THeader = { key: string; value: string };
export class ResponseNode extends Node {
  public icon = 'send';
  public statusCode: string = '200';
  public response: any = 'OK';
  public headers: THeader[] = [];
  public readonly extraProperties = [
    'statusCode',
    'response',
    'headers',
    'processed',
  ];
}
