import { Injectable, OnDestroy } from '@angular/core';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import cloneDeep from 'lodash/cloneDeep';
import { Subject, fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { RegularizedFlex } from '../regularized-flex.class';
import { CHILD_TYPES, FlexEditorService } from './editor.service';

export type ClipboardType = 'cut' | 'copy' | 'paste' | 'delete';

@Injectable({
  providedIn: 'root',
})
export class FlexEditorClipBoardService implements OnDestroy {
  public clipboard$ = new Subject();
  public clipboardData: RegularizedFlex;

  public get canKeyEvent(): boolean {
    let dialogs = this._matConnectedDialog.openDialogs;
    let isMainDialog =
      dialogs[dialogs.length - 1] == this._flexEditorService.editorDialogRef;

    return (
      isMainDialog &&
      !!this._flexEditorService.selected$.value &&
      !this._flexEditorService.isInputFocus$.value
    );
  }

  public get canDelete(): boolean {
    let select = this._flexEditorService.selected$.value;
    let parent = this._flexEditorService.selectedParent;
    if (!select || !parent) return false;
    let isFirstLevel = parent.type == 'flex';
    let isContentType = CHILD_TYPES.bubble.includes(select.type);
    let isFirstContainer = parent.type === 'body';
    if (isFirstLevel || isContentType || isFirstContainer) return false;
    return true;
  }

  public get canPaste(): boolean {
    let select = this._flexEditorService.selected$.value;
    let copyData = this.clipboardData;
    if (!select || !copyData) return false;
    let isContentType = CHILD_TYPES.bubble.includes(select.type);
    let hasContent = isContentType && select.contents.length;
    let isMenuType = this._flexEditorService
      .findChildTypes(select)
      ?.includes(copyData.type);
    if (!isMenuType || hasContent) return false;
    return true;
  }

  public get canCopy(): boolean {
    let select = this._flexEditorService.selected$.value;
    let parent = this._flexEditorService.selectedParent;
    if (!select || !parent) return false;
    let isFirstLevel = parent.type == 'flex';
    let isContentType = CHILD_TYPES.bubble.includes(select.type);
    return !isFirstLevel && !isContentType;
  }

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly _flexEditorService: FlexEditorService,
    private readonly _matConnectedDialog: MatConnectedDialog,
  ) {
    this.subscribeKeyEvent();
  }

  public ngOnDestroy(): void {
    this.clipboard$.complete();

    this._destroy$.next();
    this._destroy$.complete();
  }

  protected subscribeKeyEvent() {
    fromEvent(window, 'keydown')
      .pipe(
        filter(() => this.canKeyEvent),
        takeUntil(this._destroy$),
      )
      .subscribe((event: KeyboardEvent) => this.onKeydown(event));

    fromEvent(window, 'cut')
      .pipe(
        filter(() => this.canKeyEvent),
        takeUntil(this._destroy$),
      )
      .subscribe(() => this.cut());

    fromEvent(window, 'copy')
      .pipe(
        filter(() => this.canKeyEvent),
        takeUntil(this._destroy$),
      )
      .subscribe(() => this.copy());

    fromEvent(window, 'paste')
      .pipe(
        filter(() => this.canKeyEvent),
        takeUntil(this._destroy$),
      )
      .subscribe(() => this.paste());

    this.clipboard$
      .pipe(
        filter(() => this.canKeyEvent),
        takeUntil(this._destroy$),
      )
      .subscribe((type) => {
        switch (type) {
          case 'cut':
            this.cut();
            break;
          case 'copy':
            this.copy();
            break;
          case 'paste':
            this.paste();
            break;
          case 'delete':
            this.delete();
            break;
        }
      });
  }

  protected onKeydown(event: KeyboardEvent) {
    let isDeleteKey = event.key == 'Backspace' || event.key == 'Delete';
    if (isDeleteKey && this.canDelete) this.delete();
  }

  public cut() {
    if (!this.canDelete) return;
    this.copy();
    this.delete();
  }

  public copy() {
    let select = this._flexEditorService.selected$.value;
    if (!this.canCopy) return;
    this.clipboardData = cloneDeep(select);
  }

  public async paste() {
    if (!this.canPaste) return;
    let data = cloneDeep(this.clipboardData);
    if (!data || !this.canPaste) return;

    this.addContent(data);
  }

  public addContent(data: RegularizedFlex) {
    const selected = this._flexEditorService.selected$.value;
    this._flexEditorService.beforeChange$.next();
    if (!selected.contents) selected.contents = [];
    selected.contents.push(data);
    selected.isExpanded = true;
    this._flexEditorService.selected$.next(data);
    this._flexEditorService.afterChange$.next();
  }

  public delete() {
    let select = this._flexEditorService.selected$.value;
    let parent = this._flexEditorService.selectedParent;
    if (!this.canDelete) return;
    this._flexEditorService.beforeChange$.next();
    let idx = parent.contents.indexOf(select);
    parent.contents.splice(idx, 1);
    this._flexEditorService.selected$.next(null);
    this._flexEditorService.afterChange$.next();
  }
}
