import {
  CommonModule,
  NgFor,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Flex } from '@ay/bot';
import { FlexEditorService } from '../../../editor/editor.service';
import {
  calcOffsetBottom,
  calcOffsetEnd,
  calcOffsetStart,
  calcOffsetTop,
} from '../../../flex.util';
import { RegularizedFlex } from '../../../regularized-flex.class';
import { FlexButtonComponent } from '../button/button.component';
import { FlexFillerComponent } from '../filler/filler.component';
import { FlexIconComponent } from '../icon/icon.component';
import { FlexImageComponent } from '../image/image.component';
import { FlexSeparatorComponent } from '../separator/separator.component';
import { FlexSpacerComponent } from '../spacer/spacer.component';
import { FlexTextComponent } from '../text/text.component';

@Component({
  selector: 'flex-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[style.flex]': 'flexSize',
    '[style.width]': 'data.width',
    '[style.height]': 'data.height',
    '[style.borderColor]': 'data.borderColor',
    '[style.top]': 'offsetTop',
    '[style.left]': 'offsetStart',
    '[style.right]': 'offsetEnd',
    '[style.bottom]': 'offsetBottom',
    '[style.paddingTop]': 'data.paddingTop || data.paddingAll',
    '[style.paddingBottom]': 'data.paddingBottom || data.paddingAll',
    '[style.paddingLeft]': 'paddingLeft || data.paddingAll',
    '[style.paddingRight]': 'paddingRight ||data.paddingAll',
    '[style.backgroundColor]': 'data.backgroundColor',
    '[style.backgroundImage]': 'backgroundImage',
  },
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    FlexTextComponent,
    FlexFillerComponent,
    FlexImageComponent,
    FlexIconComponent,
    FlexSpacerComponent,
    FlexButtonComponent,
    FlexSeparatorComponent,
  ],
})
export class FlexBoxComponent {
  @Input()
  public data: Flex.Block.Box | RegularizedFlex;

  @Input()
  public bubble: Flex.Content.Bubble;

  @Input()
  public flex: Flex.Message;

  @Input()
  public level: number = -1;

  @Input()
  public layout: Flex.BoxLayout;

  @Input()
  public index: number;

  public get flexSize() {
    if (!this.data.flex) return;
    return this.data.flex + ' 0 0';
  }

  public get paddingLeft() {
    return this.bubble.direction === 'rtl'
      ? this.data.paddingEnd
      : this.data.paddingStart;
  }

  public get paddingRight() {
    return this.bubble.direction === 'rtl'
      ? this.data.paddingStart
      : this.data.paddingEnd;
  }

  public get offsetTop() {
    return calcOffsetTop(this.data.offsetTop, this._elementRef.nativeElement);
  }

  public get offsetBottom() {
    return calcOffsetBottom(
      this.data.offsetBottom,
      this._elementRef.nativeElement,
    );
  }

  public get offsetStart() {
    return calcOffsetStart(
      this.data.offsetStart,
      this._elementRef.nativeElement,
    );
  }

  public get offsetEnd() {
    return calcOffsetEnd(this.data.offsetEnd, this._elementRef.nativeElement);
  }

  public get backgroundImage() {
    if (!this.data.background) return undefined;

    const { angle, startColor, endColor, centerColor, centerPosition } =
      this.data.background;

    if (centerColor && centerPosition) {
      return this._domSanitizer.bypassSecurityTrustStyle(
        `linear-gradient(${angle}, ${startColor} 0%, ${centerColor} ${centerPosition}, ${endColor} 100%)`,
      );
    } else {
      return this._domSanitizer.bypassSecurityTrustStyle(
        `linear-gradient(${angle}, ${startColor} 0%, ${endColor} 100%)`,
      );
    }
  }

  protected layoutClass = {
    horizontal: 'hr',
    vertical: 'vr',
    baseline: 'hr bl',
  };

  protected spacingClass = {
    none: '',
    xs: 'spcXs',
    sm: 'spcSm',
    md: 'spcMd',
    lg: 'spcLg',
    xl: 'spcXl',
    xxl: 'spcXXl',
  };

  protected borderWidthClass = {
    none: 'ExBdrWdtNone',
    light: 'ExBdrWdtLgh',
    normal: 'ExBdrWdtNml',
    medium: 'ExBdrWdtMdm',
    'semi-bold': 'ExBdrWdtSbd',
    bold: 'ExBdrWdtBld',
  };

  protected cornerRadiusClass = {
    none: 'ExBdrRadNone',
    xs: 'ExBdrRadXs',
    sm: 'ExBdrRadSm',
    md: 'ExBdrRadMd',
    lg: 'ExBdrRadLg',
    xl: 'ExBdrRadXl',
    xxl: 'ExBdrRadXXl',
  };

  protected offsetTopClass = {
    none: 'ExTNone',
    xs: 'ExTXs',
    sm: 'ExTSm',
    md: 'ExTMd',
    xl: 'ExTXl',
    xxl: 'ExTXXl',
  };

  protected offsetBottomClass = {
    none: 'ExBNone',
    xs: 'ExBXs',
    sm: 'ExBSm',
    md: 'ExBMd',
    xl: 'ExBXl',
    xxl: 'ExBXXl',
  };

  protected offsetStartClass = {
    none: 'ExLNone',
    xs: 'ExLXs',
    sm: 'ExLSm',
    md: 'ExLMd',
    xl: 'ExLXl',
    xxl: 'ExLXXl',
  };

  protected offsetEndClass = {
    none: 'ExRNone',
    xs: 'ExRXs',
    sm: 'ExRSm',
    md: 'ExRMd',
    xl: 'ExRXl',
    xxl: 'ExRXXl',
  };

  protected paddingAllClass = {
    none: 'ExPadANone',
    xs: 'ExPadAXs',
    sm: 'ExPadASm',
    md: 'ExPadAMd',
    lg: 'ExPadALg',
    xl: 'ExPadAXl',
    xxl: 'ExPadAXXl',
  };

  protected paddingTopClass = {
    none: 'ExPadTNone',
    xs: 'ExPadTXs',
    sm: 'ExPadTSm',
    md: 'ExPadTMd',
    lg: 'ExPadTLg',
    xl: 'ExPadTXl',
    xxl: 'ExPadTXXl',
  };

  protected paddingBottomClass = {
    none: 'ExPadBNone',
    xs: 'ExPadBXs',
    sm: 'ExPadBSm',
    md: 'ExPadBMd',
    lg: 'ExPadBLg',
    xl: 'ExPadBXl',
    xxl: 'ExPadBXXl',
  };

  protected paddingStartClass = {
    none: 'ExPadLNone',
    xs: 'ExPadLXs',
    sm: 'ExPadLSm',
    md: 'ExPadLMd',
    lg: 'ExPadLLg',
    xl: 'ExPadLXl',
    xxl: 'ExPadLXXl',
  };

  protected paddingEndClass = {
    none: 'ExPadRNone',
    xs: 'ExPadRXs',
    sm: 'ExPadRSm',
    md: 'ExPadRMd',
    lg: 'ExPadRLg',
    xl: 'ExPadRXl',
    xxl: 'ExPadRXXl',
  };

  protected get marginClass() {
    return {
      none: this.layout == 'vertical' ? 'ExMgnTNone' : 'ExMgnLNone',
      xs: this.layout == 'vertical' ? 'ExMgnTXs' : 'ExMgnLXs',
      sm: this.layout == 'vertical' ? 'ExMgnTSm' : 'ExMgnLSm',
      md: this.layout == 'vertical' ? 'ExMgnTMd' : 'ExMgnLMd',
      lg: this.layout == 'vertical' ? 'ExMgnTLg' : 'ExMgnLLg',
      xl: this.layout == 'vertical' ? 'ExMgnTXl' : 'ExMgnLXl',
      xxl: this.layout == 'vertical' ? 'ExMgnTXXl' : 'ExMgnLXXl',
    };
  }

  protected positionClass = {
    absolute: 'ExAbs',
  };

  protected flexClass = ['fl0', 'fl1', 'fl2'];

  public constructor(
    public readonly flexEditorService: FlexEditorService,
    private readonly _elementRef: ElementRef,
    private readonly _domSanitizer: DomSanitizer,
  ) {}

  @HostBinding('class')
  public get hostClass(): string {
    const list = ['MdBx', 'fxC' + this.level];
    if (!!this.index) list.push(this.marginClass[this.data.margin]);
    list.push(this.positionClass[this.data.position]);
    list.push(this.layoutClass[this.data.layout]);
    list.push(this.spacingClass[this.data.spacing]);
    list.push(this.borderWidthClass[this.data.borderWidth]);
    list.push(this.cornerRadiusClass[this.data.cornerRadius]);
    list.push(this.flexClass[this.data.flex]);
    list.push(this.offsetTopClass[this.data.offsetTop]);
    list.push(this.offsetBottomClass[this.data.offsetBottom]);
    list.push(this.offsetStartClass[this.data.offsetStart]);
    list.push(this.offsetEndClass[this.data.offsetEnd]);

    list.push(this.paddingAllClass[this.data.paddingAll]);
    list.push(this.paddingTopClass[this.data.paddingTop]);
    list.push(this.paddingBottomClass[this.data.paddingBottom]);
    list.push(this.paddingStartClass[this.data.paddingStart]);
    list.push(this.paddingEndClass[this.data.paddingEnd]);

    if (this.data.width) list.push(this.flexClass[0]);
    return list.filter(Boolean).join(' ');
  }

  @HostBinding('style.borderWidth')
  public get hostBorderWidth() {
    if (/\d+px/.test(this.data.borderWidth)) {
      return this.data.borderWidth;
    }
    return '';
  }

  @HostBinding('style.borderRadius')
  public get hostBorderRadius() {
    if (/\d+px/.test(this.data.cornerRadius)) {
      return this.data.cornerRadius;
    }
    return '';
  }
}
