import { Component, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { firstValueFrom } from 'rxjs';
import { ColorPickerDialog } from '../../material/color-picker';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { MatIconButton, MatButton } from '@angular/material/button';
import { NgFor } from '@angular/common';

@Component({
    selector: 'dl-basic-color-palette-dialog',
    templateUrl: './basic-color-palette.dialog.html',
    styleUrls: ['./basic-color-palette.dialog.scss'],
    standalone: true,
    imports: [
        NgFor,
        MatIconButton,
        MatDivider,
        MatButton,
        MatIcon,
    ],
})
export class BasicColorPaletteDialog {
  public colors: string[] = [
    '#E53935',
    '#e67e22',
    '#f1c40f',
    '#1abc9c',
    '#2ecc71',
    '#34495e',
    '#3498db',
    '#9b59b6',
    '#CCCCCC',
    '#333333',
  ];

  public constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _matConnectedDialog: MatConnectedDialog,
    private _matDialogRef: MatDialogRef<BasicColorPaletteDialog>,
  ) {}

  public setColor(color: string) {
    this._matDialogRef.close(color);
  }

  public async openAdvancedPalette() {
    const dialogRef = this._matConnectedDialog.open(ColorPickerDialog, {
      elementRef: this._elementRef,
    });

    const color = await firstValueFrom(dialogRef.afterClosed());

    if (color) {
      this._matDialogRef.close(color.hex);
    }
  }
}
