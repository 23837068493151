import { NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Flex } from '@ay/bot';
import { FlexEditorService } from '../../../editor/editor.service';
import {
  calcOffsetBottom,
  calcOffsetEnd,
  calcOffsetStart,
  calcOffsetTop,
} from '../../../flex.util';
import { RegularizedFlex } from '../../../regularized-flex.class';
import { FlexSpanComponent } from '../span/span.component';

@Component({
  selector: 'flex-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[style.color]': 'data.color',
    '[style.flex]': 'flexSize',
    '[style.maxWidth]': "maxWidth + 'px'",
    '[style.top]': 'offsetTop',
    '[style.left]': 'offsetStart',
    '[style.right]': 'offsetEnd',
    '[style.bottom]': 'offsetBottom',
    '[style.marginTop]': "layout == 'vertical'? margin:null",
    '[style.marginLeft]': "layout != 'vertical'? margin:null",
  },
  standalone: true,
  imports: [NgIf, NgFor, FlexSpanComponent],
})
export class FlexTextComponent implements AfterViewInit {
  @ViewChild('p')
  public p: ElementRef<HTMLParagraphElement>;

  @Input()
  public data: Flex.Block.Text | RegularizedFlex;

  @Input()
  public bubble: Flex.Content.Bubble;

  @Input()
  public flex: Flex.Message;

  @Input()
  public level: number;

  @Input()
  public layout: Flex.BoxLayout;

  @Input()
  public index: number;

  public gravityClass = {
    bottom: 'grvB',
    center: 'grvC',
  };

  public sizeClass = {
    xxs: 'ExXXs',
    xs: 'ExXs',
    sm: 'ExSm',
    md: 'ExMd',
    lg: 'ExLg',
    xl: 'ExXl',
    xxl: 'ExXXl',
    '3xl': 'Ex3Xl',
    '4xl': 'Ex4Xl',
    '5xl': 'Ex5Xl',
  };

  public weightClass = {
    regular: 'ExWR',
    bold: 'ExWB',
  };

  public styleClass = {
    normal: 'ExFntStyNml',
    italic: 'ExFntStyIt',
  };

  protected decorationClass = {
    none: 'ExTxtDecNone',
    underline: 'ExTxtDecUl',
    'line-through': 'ExTxtDecLt',
  };

  protected positionClass = {
    absolute: 'ExAbs',
  };

  protected wrapClass = {
    true: 'ExWrap',
  };

  protected flexClass = ['fl0', 'fl1', 'fl2'];

  protected alignClass = {
    start: 'ExAlgS',
    center: 'ExAlgC',
    end: 'ExAlgE',
  };

  protected offsetTopClass = {
    none: 'ExTNone',
    xs: 'ExTXs',
    sm: 'ExTSm',
    md: 'ExTMd',
    xl: 'ExTXl',
    xxl: 'ExTXXl',
  };

  protected offsetBottomClass = {
    none: 'ExBNone',
    xs: 'ExBXs',
    sm: 'ExBSm',
    md: 'ExBMd',
    xl: 'ExBXl',
    xxl: 'ExBXXl',
  };

  protected offsetStartClass = {
    none: 'ExLNone',
    xs: 'ExLXs',
    sm: 'ExLSm',
    md: 'ExLMd',
    xl: 'ExLXl',
    xxl: 'ExLXXl',
  };

  protected offsetEndClass = {
    none: 'ExRNone',
    xs: 'ExRXs',
    sm: 'ExRSm',
    md: 'ExRMd',
    xl: 'ExRXl',
    xxl: 'ExRXXl',
  };

  public get flexSize() {
    if (!this.data.flex) return;
    return this.data.flex + ' 0 0';
  }

  protected get marginClass() {
    return {
      none: this.layout == 'vertical' ? 'ExMgnTNone' : 'ExMgnLNone',
      xs: this.layout == 'vertical' ? 'ExMgnTXs' : 'ExMgnLXs',
      sm: this.layout == 'vertical' ? 'ExMgnTSm' : 'ExMgnLSm',
      md: this.layout == 'vertical' ? 'ExMgnTMd' : 'ExMgnLMd',
      lg: this.layout == 'vertical' ? 'ExMgnTLg' : 'ExMgnLLg',
      xl: this.layout == 'vertical' ? 'ExMgnTXl' : 'ExMgnLXl',
      xxl: this.layout == 'vertical' ? 'ExMgnTXXl' : 'ExMgnLXXl',
    };
  }

  public get margin() {
    if (!this.index || this.marginClass[this.data.margin]) return null;
    return this.data.margin;
  }

  private get _useMaxLines() {
    return this.data.maxLines && !!this.data.wrap;
  }

  public get dataText() {
    if (!this.data.text) return;
    return this.data.text
      .split('')
      .map(
        (d) =>
          `<font class="${this.weightClass[this.data.weight] || ''} ${
            this.styleClass[this.data.style] || ''
          }">${d}</font>`,
      )
      .join('');
  }

  public get maxWidth() {
    let el = this._elementRef.nativeElement as HTMLElement;
    let isAbs = el.classList.contains('ExAbs');
    if (!isAbs) return;

    let parrent = el.parentElement;
    let styles = getComputedStyle(parrent);

    while (styles.position != 'relative') {
      parrent = parrent.parentElement;
      styles = getComputedStyle(parrent);
    }

    return parrent.offsetWidth - parseInt(styles.padding) * 2;
  }

  public get offsetTop() {
    return calcOffsetTop(this.data.offsetTop, this._elementRef.nativeElement);
  }

  public get offsetBottom() {
    return calcOffsetBottom(
      this.data.offsetBottom,
      this._elementRef.nativeElement,
    );
  }

  public get offsetStart() {
    return calcOffsetStart(
      this.data.offsetStart,
      this._elementRef.nativeElement,
    );
  }

  public get offsetEnd() {
    return calcOffsetEnd(this.data.offsetEnd, this._elementRef.nativeElement);
  }

  public constructor(
    private readonly _renderer2: Renderer2,
    private readonly _elementRef: ElementRef,
    public readonly flexEditorService: FlexEditorService,
  ) {}

  public ngAfterViewInit(): void {
    if (this._useMaxLines) this._setMaxLines();
  }

  private _setMaxLines() {
    let lineHeight = 1.4;
    this._renderer2.setStyle(
      this.p.nativeElement,
      'webkitLineClamp',
      this.data.maxLines,
    );

    this._setBreak();
  }

  private _setBreak() {
    let p = this.p.nativeElement as HTMLParagraphElement;
    let fonts = Array.from(p.querySelectorAll('font'));
    let fontsY = [...new Set(fonts.map((font: HTMLElement) => font.offsetTop))];
    let maxLineY = fontsY[this.data.maxLines - 1];
    if (!maxLineY) return;
    let afterMax = fonts.filter(
      (font: HTMLElement) => font.offsetTop >= maxLineY,
    );
    let div = document.createElement('div');
    div.style.whiteSpace = 'nowrap';
    div.style.textOverflow = 'ellipsis';
    div.style.overflow = 'hidden';
    afterMax.forEach((font) => div.appendChild(font));
    p.appendChild(div);
  }

  @HostBinding('class')
  public get hostClass(): string {
    const list = ['MdTxt', 'fxC' + this.level];
    if (this._useMaxLines) list.push('max-lines');
    if (!!this.index) list.push(this.marginClass[this.data.margin]);
    list.push(this.sizeClass[this.data.size]);
    list.push(this.weightClass[this.data.weight]);
    list.push(this.styleClass[this.data.style]);
    list.push(this.alignClass[this.data.align]);
    list.push(this.gravityClass[this.data.gravity]);
    list.push(this.decorationClass[this.data.decoration]);
    list.push(this.positionClass[this.data.position]);
    list.push(this.wrapClass[this.data.wrap?.toString()]);
    list.push(this.flexClass[this.data.flex]);
    list.push(this.offsetTopClass[this.data.offsetTop]);
    list.push(this.offsetBottomClass[this.data.offsetBottom]);
    list.push(this.offsetStartClass[this.data.offsetStart]);
    list.push(this.offsetEndClass[this.data.offsetEnd]);
    return list.filter(Boolean).join(' ');
  }
}
