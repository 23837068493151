import { v4 } from 'uuid';

export type ButtonType =
  | 'uri'
  | 'postback'
  | 'message'
  | 'next'
  | 'datetimepicker'
  | 'add-through-promotion'
  | 'share-message-through-promotion';

export type DatetimePickerMode = 'date' | 'time' | 'datetime';

export namespace Button {
  export type Any =
    | Uri
    | Postback
    | Message
    | Next
    | DatetimePicker
    | AddThroughPromotion
    | ShareMessageThroughPromotion;

  export class Base {
    // 在轉化短網址時所需要的資訊，為了統計用途
    public buttonId?: number;
    public cardId?: number;
    public recordId?: number;
    public packageId?: number;
    public isSelected?: boolean;

    public constructor(
      // 按鈕類型
      public type: ButtonType,
      // 按鈕文字
      public label: string,
    ) {}
  }

  export class Uri extends Base {
    public type: 'uri';

    public constructor(
      // 按鈕文字
      public label: string,
      // 連結
      public uri: string,
    ) {
      super('uri', label);
    }
  }

  export class Postback extends Base {
    public type: 'postback';

    public constructor(
      // 按鈕文字
      public label: string,
      // 帶回伺服器的data
      public data: any = {},
      // 幫客戶發送的訊息, 最多三百字,
      public displayText?: string,
      // 幫客戶發送的訊息, 最多三百字, 會再以 webhook 通知回伺服器
      public text?: string,
    ) {
      super('postback', label);
    }
  }

  export class Message extends Base {
    public type: 'message';

    public constructor(
      // 按鈕文字
      public label: string,
      // 幫客戶發送的訊息
      public text: string,
    ) {
      super('message', label);
    }
  }

  export class Next extends Base {
    public type: 'next';

    public constructor(
      // 按鈕文字
      public label: string,
      // 幫客戶發送的訊息
      public text: string,
      // 用來識別每一個 Next 按鈕，如果不給值將會是 UUID
      public data: string = v4(),
    ) {
      super('next', label);
    }
  }

  export class DatetimePicker extends Base {
    public type: 'datetimepicker';

    public constructor(
      // 按鈕文字
      public label: string,
      public data: string,
      public mode: DatetimePickerMode = 'datetime',
      public initial?: string,
      public max?: string,
      public min?: string,
    ) {
      super('datetimepicker', label);
    }
  }

  export class AddThroughPromotion extends Base {
    public type: 'add-through-promotion';

    public constructor(public label: string) {
      super('add-through-promotion', label);
    }
  }

  export class ShareMessageThroughPromotion extends Base {
    public type: 'share-message-through-promotion';

    public constructor(
      public label: string,
      public promotionId: number,
    ) {
      super('share-message-through-promotion', label);
    }
  }
}
